import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import excelImg from '../../../assets/images/excel.png';

function Reports() {

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container p={2} justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={12} md={2} style={{ PaddingRight: '0%' }}>
                    <Typography variant="h8" gutterBottom style={{ whiteSpace: 'nowrap' }}>
                        Fecha Inicio
                    </Typography>
                    {/* <MainInput
                        label="DNI o Nombre"
                        fullWidth
                        variant="outlined"
                        type="date"
                    /> */}
                </Grid>
                <Grid item xs={12} md={2} style={{ marginLeft: '0vw' }}>
                    <Typography variant="h8" gutterBottom style={{ whiteSpace: 'nowrap' }}>
                        Fecha Fin
                    </Typography>
                    {/* <MainInput
                        label="DNI o Nombre"
                        fullWidth
                        variant="outlined"
                        type="date"
                    /> */}
                </Grid>
                <Grid item xs={12} md={3} style={{ marginLeft: '0vw' }}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h8" gutterBottom style={{ whiteSpace: 'nowrap' }}>
                            Empresa Afiliada
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginTop: '1vh' }}>
                        {/* <MainSelect
                            label="Relojes/Oficina"
                            select
                            fullWidth
                            variant="outlined"
                        >
                        </MainSelect> */}
                    </Grid>
                </Grid>
            </Grid>

            <Grid container p={2} spacing={100} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={5} style={{ marginLeft: '0vw', display: 'flex' }}>
                    <img src={excelImg} alt="Imagen de Excel" style={{ width: '3vw', height: '6vh', paddingTop: '2vh'}} />
                    <Typography variant="h8" gutterBottom style={{ whiteSpace: 'nowrap', paddingTop: '3vh' }}>
                        Reporte B
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5.6}>
                    {/* <MainButton title={"Descargar"} startIcon={<FileDownloadIcon />}
                    /> */}
                </Grid>
            </Grid>
            <Grid container p={2} spacing={100} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={5} style={{ marginLeft: '0vw', display: 'flex'  }}>
                    <img src={excelImg} alt="Imagen de Excel" style={{ width: '3vw', height: '6vh', paddingTop: '2vh'}} />
                    <Typography variant="h8" gutterBottom style={{ whiteSpace: 'nowrap', paddingTop: '3vh'  }}>
                        Reporte Extendido
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5.6}>
                    {/* <MainButton title={"Descargar"} startIcon={<FileDownloadIcon />}
                    /> */}
                </Grid>
            </Grid>

        </Box>
    );
}

export default Reports;