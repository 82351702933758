import React, { useState } from 'react';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomCheck from '../general/CustomCheck';
import CustomPagination from '../general/CustomPagination';
import { sortDataList, } from '../../../utils/function';
import _ from 'lodash';

const columns = [
  { id: '#', label: '#', width: 30, align: 'center', },
  { id: 'codSghh', label: 'Cód.', width: 100, align: 'center', order: true, },
  { id: 'numdoc', label: 'Doc.', width: 100, align: 'center', order: true, },
  { id: 'trabajador', label: 'Trabajador', width: 400, align: 'left', order: true, },
  { id: 'sel', label: 'seleccionar', width: 40, align: 'center' },
];

const BodyWorkersBiometric = (props) => {
  const { page, setPage, totalPage, data, search, setSearch, getInfo, selectedIdPersona, setSelectedIdPersona, selectedRowPersona, setSelectedRowPersona } = props;

  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const srtdData = sortDataList(data, sortConfigTable);
  // /** fin ordenamiento de columnas */

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && getInfo) {
      getInfo();
    }
  };

  return (
    <>
      <TextField
        hiddenLabel
        id="filled-hidden-label-small"
        placeholder='Busqueda por código / trabajador'
        variant="filled"
        size="small"
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton color='primary' onClick={getInfo} style={{ padding: 2, border: 'solid 1px' }}>
              <SearchIcon fontSize='small' />
            </IconButton>
          ),
          style: { fontSize: 12, },
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyPress={handleKeyPress}
        className='textField-search'
        inputProps={{ style: { fontSize: 12, color: 'black', fontWeight: 'bold', }, }}
      />

      <div className="table-body-frame">
        <div className="table-body">
          <table className="table table-striped table-hover table-bordered table-sm" >
            <thead className='sticky-top' style={{ whiteSpace: 'nowrap' }}>
              <tr className="table-header table-dark">
                {columns.map((col) => (
                  <th
                    key={col.id}
                    className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                    style={{ width: col.width, maxWidth: col.width, }}
                    onClick={() => col.order && handleSortTable(col.id)}
                  >
                    {col.id === 'sel' ?
                      <div style={{ marginTop: '-1rem' }}>
                      </div>
                      : <>
                        {col.label}
                        {col.order && <span className="sort-icon"><OrderedListOutlined /></span>}
                      </>}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {srtdData?.map((row, idxRow) => {
                return (
                  <tr key={"row" + idxRow}>
                    {columns.map((col, indexColumn) => {
                      const value = row[col.id];
                      return (
                        <td key={'col' + indexColumn}
                          style={{
                            textAlign: col.align,
                            width: col.width,
                            maxWidth: col.width,
                          }}
                        >
                          {col.id === 'sel' &&
                            <CustomCheck
                              forCheck={row.id_persona}

                              checked={row.id_persona === selectedIdPersona}
                              onChange={() => {
                                if (row.id_persona === selectedIdPersona) {
                                  setSelectedIdPersona(null);
                                  setSelectedRowPersona([]);
                                } else {
                                  // setSelectedIdPersona(row.id_persona);
                                  setSelectedIdPersona(row.numdoc);
                                  const newData = { id_persona: row.id_persona, numdoc: row.numdoc };
                                  setSelectedRowPersona([newData]);

                                }
                              }}
                            />
                          }
                          {col.id === '#' ?
                            idxRow + 1
                            : col.id === 'id_persona' ?
                              row.id_persona
                              : value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {totalPage > 1 &&
          < div className="table-footer">
            <CustomPagination
              totalPages={totalPage}
              page={page}
              setPage={setPage}
            />
          </div>
        }
      </div >
    </>
  );
}

export default BodyWorkersBiometric;