import React, { useState } from 'react';
import { Grid, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BodyClocks from '../../components/Clocks/BodyClocks';
import AddClock from './AddClock/AddClock';
import CustomButton from '../../components/general/CustomButton';

const newClock =
{
    serial: '',
    sede_id: null,
    id_empresa: null,
    id_cliente: null,
    id_unidad: null,
    id_sede: null,
};

const ClockMaintenance = (props) => {
    const { userToken, dataClocks, getRelojes } = props;

    const perfil_id = userToken?.perfil?.id_perfil;
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [search, setSearch] = useState(null);
    const [infoClock, setInfoClock] = useState(null);

    const [typeSave, setTypeSave] = useState(null);

    const editClock = (info = newClock, typeSave = null) => {
        setInfoClock(info);
        setTypeSave(typeSave)
        handleOpen();
    }

    return (
        <>
            <AddClock
                userToken={userToken}
                open={open}
                handleClose={handleClose}
                infoClock={infoClock}
                setInfoClock={setInfoClock}
                getRelojes={getRelojes}
                typeSave={typeSave}
                setTypeSave={setTypeSave}
            />
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={11} md={9}>
                    <span className="badge text-dark" style={{ fontSize: '1rem' }}>Mantenimiento de Relojes</span><br />
                    <div className='d-flex justify-content-evenly'>
                        <TextField
                            hiddenLabel
                            id="filled-hidden-label-small"
                            placeholder='Busqueda por serial'
                            variant="filled"
                            size="small"
                            style={{ width: '80%' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize='10px' />
                                    </InputAdornment>
                                ),
                                style: {
                                    fontSize: 12,
                                },
                            }}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        {perfil_id === 1 &&
                            <CustomButton
                                title={'Agregar Reloj'}
                                variant={'warning'}
                                value={<AddCircleOutlineIcon />}
                                onClick={() => editClock()}
                            />
                        }
                        <CustomButton
                            title={'Buscar relojes'}
                            variant={'success'}
                            value={<SearchIcon />}
                            onClick={getRelojes}
                        />
                    </div>
                    <BodyClocks
                        userToken={userToken}
                        search={search}
                        data={dataClocks}
                        editClock={editClock}
                        getRelojes={getRelojes}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ClockMaintenance;