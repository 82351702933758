import React, { useState, useEffect } from 'react';
import { useDispatch, } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/actions';
import { Grid, } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClockService from '../../../services/clock';
import BodyClocksHistory from '../../components/Clocks/BodyClocksHistory';
import CustomSelect from '../../components/general/CustomSelect';
import CustomButton from '../../components/general/CustomButton';
import { mapData } from '../../../utils/function';
import { CODES } from '../../../utils/codesHTTP';
import { simpleAlerts } from '../../../utils/alerts';

const ClockOperationHistory = (props) => {
    const { dataClocks, } = props;
    const optClocks = mapData(dataClocks, ['id_reloj', 'serial', 'unidad_id']);
    const [history, setHistory] = useState(null);
    const [idClock, setIdClock] = useState(null);

    const dispatch = useDispatch();
    const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
    const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

    useEffect(() => {
        setHistory(null)
        if (idClock)
            getHistoryClock();
    }, [idClock]);

    const getHistoryClock = async () => {
        if (!idClock || idClock === 0) {
            simpleAlerts({ message: "Debe seleccionar un reloj!" });
            return;
        }
        handleOpenSpinner(true);
        handleTitleSpinner('Cargando historial ...');
        try {
            const result = await ClockService.getHistoryClock(idClock);
            if (result.status === CODES.SUCCESS_200) {
                setHistory(result.data);
            }
        } catch (error) {
            console.log(`Error en getRelojes: ${error}`);
        } finally {
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={9} md={9} >
                <div className='d-flex justify-content-center align-items-end'>
                    <CustomSelect
                        placeholder={'Seleccionar Reloj'}
                        options={optClocks}
                        width={"50%"}
                        value={idClock || 0}
                        onChange={(value, event) => setIdClock(optClocks?.find(element => +element.value === +value)?.value)}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <CustomButton
                        title={'Buscar comandos'}
                        variant={'success'}
                        value={<SearchIcon />}
                        onClick={getHistoryClock}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={10} md={10}>
                <BodyClocksHistory
                    data={history}
                />
            </Grid>
        </Grid>
    )
}

export default ClockOperationHistory;