import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class ReportService {
    exportAssistanceByClock(body) {
        const ruta = `${API_URL}/report/assistance-clock`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }

    exportWorkerOnClock(body) {
        const ruta = `${API_URL}/report/worker-on-clock`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }
}

export default new ReportService();
