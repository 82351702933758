import React, { useState } from 'react';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomCheck from '../general/CustomCheck';
import CustomPagination from '../general/CustomPagination';
import { formatDate, globalIndex, sortDataList, } from '../../../utils/function';
import _ from 'lodash';

const columns = [
  { id: '#', label: '#', width: 30, align: 'center', },
  { id: 'fecha', label: 'Fecha', width: 100, align: 'center', order: true, },
  { id: 'horaMarca', label: 'Hora', width: 100, align: 'center', format: (value) => value?.substring(0, 8) },
  { id: 'desMarca', label: 'Marca', width: 100, align: 'center', order: true, },
];

const BodyWorkersAssistance = (props) => {
  const { forCheckBody, pageSize, page, setPage, totalPage, data, search, setSearch, getInfo, dataSel, setDataSel, } = props;

  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const srtdData = sortDataList(data, sortConfigTable);
  // /** fin ordenamiento de columnas */

  const handleChangeGeneral = (checked) => {
    let newData;
    if (checked)
      newData = data;
    else
      newData = [];
    return setDataSel(newData);
  }

  const handleChangeCheck = (checked, info) => {
    let newData = _.cloneDeep(dataSel);
    if (checked) {
      newData.push(info);
    } else {
      newData = newData.filter((e) => e.numdoc !== info.numdoc);
    }
    return setDataSel(newData);
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && getInfo) {
      getInfo();
    }
  };

  return (
    <div className="table-body-frame">
      <div className="table-body">
        <table className="table table-striped table-hover table-bordered table-sm">
          <thead className='sticky-top' style={{ whiteSpace: 'nowrap' }}>
            <tr className="table-header table-dark">
              {columns.map((col) => (
                <th
                  key={col.id}
                  className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                  style={{ width: col.width, maxWidth: col.width, }}
                  onClick={() => col.order && handleSortTable(col.id)}
                >
                  {col.id === 'sel' ?
                    <div style={{ marginTop: '-1rem' }}>
                      <CustomCheck
                        forCheck={`checkbox-title-body${forCheckBody}`}
                        checked={(dataSel?.length === data?.length && dataSel?.length > 0) ? true : false}
                        onChange={(e) => handleChangeGeneral(e.target.checked)}
                      />
                    </div>
                    : <>
                      {col.label}
                      {col.order && <span className="sort-icon"><OrderedListOutlined /></span>}
                    </>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {srtdData?.map((row, idxRow) => {
              return (
                <tr key={"row" + idxRow}>
                  {columns.map((col, indexColumn) => {
                    const value = row[col.id];
                    return (
                      <td key={'col' + indexColumn}
                        style={{
                          textAlign: col.align,
                          width: col.width,
                          maxWidth: col.width,
                        }}
                      >
                        {col.id === '#' ?
                          globalIndex(page, pageSize, idxRow)
                          : col.format ?
                            (value ? col.format(value) : '')
                            : value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {totalPage > 1 &&
        < div className="table-footer">
          <CustomPagination
            totalPages={totalPage}
            page={page}
            setPage={setPage}
          />
        </div>
      }
    </div >
  );
}

export default BodyWorkersAssistance;