import React, { useState, useEffect } from 'react';
import CustomModal from "../../../components/general/CustomModal";
import CustomButton from '../../../components/general/CustomButton';
import TakeFingerPrint from '../../../components/Workers/AddUploadClock/TakeFingerPrint';
import InformationUpload from '../../../components/Workers/AddUploadClock/InformationUpload';
import RequestAssistances from '../../../components/Workers/AddUploadClock/RequestAssistances';
import { getDateOL } from '../../../../utils/function';

const AddUploadClock = (props) => {
    const { open, cleanInfo, saveWorker, checkMassive, setCheckMassive,
        checkFingerPrint, setCheckFingerPrint, fingers,
        infoWorker, typeSave, idEstado, dataTypeUserClock, typeUserClock,
        setTypeUserClock, passUserClock, setPassUserClock, cardUserClock,
        setCardUserClock, } = props;

    useEffect(() => {
        setTypeUserClock(infoWorker?.tipoUsuario_id);
        setPassUserClock(infoWorker?.clave);
        setCardUserClock(infoWorker?.tarjeta);
    }, [infoWorker]);

    const [dateInit, setDateInit] = useState(getDateOL())
    const [dateEnd, setDateEnd] = useState(getDateOL())

    return (
        <CustomModal
            open={open}
            handleClose={cleanInfo}
            title={'Mantenimiento trabajadores'}
            subTitle={'Creación y edición de trabajadores externos'}
            bodyModal={
                typeSave === 'ASSISTANCE' ?
                    <RequestAssistances
                        typeSave={typeSave}
                        infoWorker={infoWorker}
                        dateInit={dateInit}
                        setDateInit={setDateInit}
                        dateEnd={dateEnd}
                        setDateEnd={setDateEnd}
                    />
                    : (typeSave === 'SAVE' || typeSave === 'UPDATE') ?
                        <InformationUpload
                            typeSave={typeSave}
                            idEstado={idEstado}
                            checkMassive={checkMassive}
                            setCheckMassive={setCheckMassive}
                            checkFingerPrint={checkFingerPrint}
                            setCheckFingerPrint={setCheckFingerPrint}
                            dataTypeUserClock={dataTypeUserClock}
                            typeUserClock={typeUserClock}
                            setTypeUserClock={setTypeUserClock}
                            passUserClock={passUserClock}
                            setPassUserClock={setPassUserClock}
                            cardUserClock={cardUserClock}
                            setCardUserClock={setCardUserClock}
                        />
                        : typeSave === 'FINGERPRINT' ?
                            <TakeFingerPrint
                                fingers={fingers}
                                infoWorker={infoWorker}
                            />
                            : null
            }
            buttonActions={[
                (typeSave === 'FINGERPRINT') ?
                    null
                    : <CustomButton
                        key='btn-modal-user-save1'
                        variant={'primary'}
                        value={'Guardar'}
                        type={'button'}
                        onClick={
                            typeSave === 'ASSISTANCE' ?
                                (e) => saveWorker(infoWorker, dateInit, dateEnd)
                                : (typeSave === 'SAVE' || typeSave === 'UPDATE') ?
                                    (e) => saveWorker(infoWorker)
                                    : (typeSave === 'FINGERPRINT') ?
                                        null
                                        : saveWorker}
                    />
            ]}
        />
    )
}

export default AddUploadClock