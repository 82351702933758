import React, { useEffect, useState } from 'react';
import CustomModal from '../../../components/general/CustomModal';
import CustomButton from '../../../components/general/CustomButton';
import { CODES } from '../../../../utils/codesHTTP';
import { simpleAlerts } from '../../../../utils/alerts';
import { colors } from '../../../../assets/styles/colors';
import ProfileService from '../../../../services/profile';
import InfoProfile from '../../../components/Profiles/AddProfile/InfoProfile';
import Swal from 'sweetalert2';

const AddProfile = (props) => {
    const { open, handleClose, id_usuario, setOpenModalLoader, setTitleSpinner,
        screens, typeEdition, setTypeEdition, infoProfile, setInfoProfile, } = props;

    const url = "'" + window.location.pathname + "'";
    let newProfile = { "desPerfil": "", "codPerfil": "", "usuario_id": id_usuario }
    const [screensProfile, setScreensProfile] = useState([]);
    const [screensDelete, setScreensDelete] = useState([]);
    const system = 'ICLOCK';

    useEffect(() => {
        if (typeEdition === 1) { //crear perfil
            setInfoProfile(newProfile);
        }
    }, [])
    const saveProfile = async () => {
        if (!infoProfile?.desPerfil?.trim() || infoProfile?.desPerfil?.trim() === '') {
            simpleAlerts({ message: 'Debe ingresar una descripción del perfil', type: 'warning' });
            return;
        }
        if (!infoProfile?.codPerfil?.trim() || infoProfile?.codPerfil?.trim() === '') {
            simpleAlerts({ message: 'Debe ingresar un código para el perfil', type: 'warning' });
            return;
        }
        if (screensProfile?.length === 0) {
            Swal.fire({
                title: '¿Seguro de no marcar ninguna pantalla?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#28A745', //colors.secondary.hex,
                cancelButtonColor: colors.secondary.hex, //colors.primary.hex,
                confirmButtonText: 'Si, guardar'
            }).then((result) => {
                if (result.isConfirmed) {
                    saveGeneral();
                }
            })
            return;
        }
        saveGeneral();
    }

    const saveGeneral = async () => {
        setOpenModalLoader(true);
        setTitleSpinner('Guardando ...');
        let profileTmp;
        try {
            infoProfile.usuario_id = id_usuario;
            const result = await ProfileService.savePerfil(url, infoProfile);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts({ message: result.data.message, type: 'success' });
                profileTmp = result.data;
            } else {
                simpleAlerts(result?.response?.data?.message, 'error');
                setOpenModalLoader(false);
                setTitleSpinner(null);
                return;
            }
        } catch (err) {
            console.log('Error en saveProfile', err);
        }

        if (profileTmp) {
            const deletePromises = screensDelete.map(async (screen) => {
                try {
                    const result = await ProfileService.deletePerfilPantalla(url, profileTmp.id_perfil, screen.id_pantalla);
                    if (result.status !== CODES.DELETE_204) {
                        simpleAlerts({ message: 'No se logro eliminar la asociación', type: 'info' });
                    }
                } catch (error) {
                    console.log(`Error en deletePerfilPantalla: ${error}`);
                }
            });
            await Promise.all([...deletePromises]);
            const savePromises = screensProfile.map(async (screen) => {
                try {
                    let item = {};
                    item.perfil_id = profileTmp.id_perfil;
                    item.pantalla_id = screen.id_pantalla;
                    const result = await ProfileService.savePerfilPantalla(url, item);
                    if (result.status !== CODES.CREATE_201) {
                        simpleAlerts({ message: "Error al asociar la pantalla", type: 'error' });
                    }
                } catch (error) {
                    console.log(`Error en savePerfilPantalla: ${error}`);
                }
            });

            await Promise.all([...savePromises]);
            // getPerfiles();
            handleClose();
            simpleAlerts({ message: "Perfil guardado correctamente!", type: 'success' });
            window.location.reload();
        }
    }

    const cleanInfo = () => {
        console.log('limpiando')
        return setInfoProfile(null),
            setTypeEdition(null),
            setScreensProfile([]),
            setScreensDelete([]);
    };

    return (
        <>
            <CustomModal
                open={open}
                handleClose={() => { handleClose(); cleanInfo() }}
                centrado={'centrado'}
                title={'Mantenimiento de Perfiles'}
                subTitle={'Creación y edición de perfiles'}
                bodyModal={
                    <>
                        <InfoProfile
                            typeEdition={typeEdition}
                            infoProfile={infoProfile}
                            setInfoProfile={setInfoProfile}

                            screens={screens}
                            system={system}
                            screensProfile={screensProfile}
                            setScreensProfile={setScreensProfile}
                            screensDelete={screensDelete}
                            setScreensDelete={setScreensDelete}
                        />
                    </>
                }
                buttonActions={[
                    <CustomButton
                        key='btn-modal-user-save1'
                        value={'Guardar'}
                        variant={'primary'}
                        typeStyle={'outlined'}
                        onClick={saveProfile}
                    />
                ]}

            />
        </>
    );
};



export default AddProfile;