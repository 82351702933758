import React, { useState, useEffect } from 'react';
import CustomSelect from './CustomSelect';
import GeneralService from '../../../services/general';
import { CODES } from '../../../utils/codesHTTP';

const GroupingUnits = ({
    col = 3,
    company, setCompany,
    client, setClient,
    unit, setUnit,
    campus, setCampus
}) => {
    const isMobile = window.innerWidth <= 576; // Define el ancho de pantalla para considerar como móvil
    const customtStyle = {
        marginTop: isMobile ? 5 : 0,
    };

    const [companies, setCompanies] = useState(null);
    const [clients, setClients] = useState(null);
    const [units, setUnits] = useState(null);

    useEffect(() => {
        getEmpresas();
    }, [])

    useEffect(() => {
        setClients(null);
        setUnits(null);
        setCampus(null);

        setClient(null);
        setUnit(null);
        if (company)
            getClientesByEmpresa()
    }, [company])

    useEffect(() => {
        setUnits(null);
        setCampus(null);

        setUnit(null);
        if (client)
            getUnidadesByCliente();
    }, [client])

    useEffect(() => {
        setCampus(null);
        if (unit)
            getSedesByUnidad();
    }, [unit])

    const mapData = (data, properties) => {
        return data?.map(element => {
            const mappedElement = { value: element[properties[0]], label: element[properties[1]] };
            properties.forEach(property => {
                mappedElement[property] = element[property];
            });
            return mappedElement;
        });
    };

    const getEmpresas = async () => {
        const result = await GeneralService.getEmpresas();
        if (result.status === CODES.SUCCESS_200) {
            const empresasTmp = mapData(result.data, ['id_empresa', 'desEmpresa']);
            setCompanies(empresasTmp);
        }
    }
    const getClientesByEmpresa = async () => {
        const result = await GeneralService.getClientesByEmpresa(company.value);
        if (result.status === CODES.SUCCESS_200) {
            const clientesTmp = mapData(result.data, ['id_cliente', 'desCliente', 'empresa_id']);
            setClients(clientesTmp);
        }
    }
    const getUnidadesByCliente = async () => {
        const result = await GeneralService.getUnidadesByCliente(client.value);
        if (result.status === CODES.SUCCESS_200) {
            const unidadesTmp = mapData(result.data, ['id_unidad', 'desUnidad', 'cliente_id']);
            setUnits(unidadesTmp);
        }
    }
    const getSedesByUnidad = async () => {
        const result = await GeneralService.getSedesByUnidad(unit.value);
        if (result.status === CODES.SUCCESS_200) {
            const sedesTmp = mapData(result.data, ['id_sede', 'desSede', 'unidad_id']);
            setCampus(sedesTmp);
        }
    }

    return (
        <div className='row'>
            <div className={`col-md-${col} mt-1`}>
                <CustomSelect
                    placeholder={'Seleccionar Empresa'}
                    customtStyle={customtStyle}
                    options={companies}
                    value={company?.value || 0}
                    onChange={(value, event) => setCompany(companies?.find(element => +element.value === +value))}
                />
            </div>
            <div className={`col-md-${col} mt-1`}>
                <CustomSelect
                    placeholder={'Seleccionar Cliente'}
                    customtStyle={customtStyle}
                    options={clients}
                    value={client?.value || 0}
                    onChange={(value, event) => setClient(clients?.find(element => +element.value === +value))}
                />
            </div>
            <div className={`col-md-${col} mt-1`}>
                <CustomSelect
                    placeholder={'Seleccionar Unidad'}
                    customtStyle={customtStyle}
                    options={units}
                    value={unit?.value || 0}
                    onChange={(value, event) => setUnit(units?.find(element => +element.value === +value))}
                />
            </div>
        </div>
    );
};

export default GroupingUnits;