import React from 'react'

const CustomCheck = ({ forCheck, title, checked, onChange, fontSize = '' }) => {
    return (
        <div className="item">
            <div className="checkbox-circle">
                <input
                    type="checkbox"
                    name="check"
                    id={`checkbox-${forCheck}`}
                    checked={checked}
                    onChange={onChange}
                />
                <label htmlFor={`checkbox-${forCheck}`} style={{ fontSize: fontSize }}>{title}</label>
            </div>
        </div>
    )
}

export default CustomCheck