import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/actions';
import WorkerService from '../../../../services/worker';
import CustomModal from "../../../components/general/CustomModal";
import CustomButton from '../../../components/general/CustomButton';
import InformationWorker from '../../../components/Workers/AddInfo/InformationWorker';
import { CODES } from '../../../../utils/codesHTTP';
import { simpleAlerts } from '../../../../utils/alerts';

const AddWorker = (props) => {
    const { open, handleClose, infoPerson, setInfoPerson, getWorkers, } = props;

    const dispatch = useDispatch();
    const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
    const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

    // const infoClock = useSelector(state => state.infoClock);
    const infoGrouping = useSelector(state => state.infoGrouping);
    const valueSedes = infoGrouping?.map(e => +e.value);
    
    const cleanInfo = () => {
        handleClose();
        setInfoPerson(null);
    }

    const saveWorker = async (e) => {
        e.preventDefault();
        try {
            handleOpenSpinner(true);
            handleTitleSpinner("Guardando persona ...");
            const result = await WorkerService.saveWorker(infoPerson);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts({ message: result?.data?.message || "Guardo!", type: "success" });
                if (valueSedes.length > 0) {
                    saveWorkerSede(result?.data)
                } else {
                    simpleAlerts({ message: "El trabajador no se asigno a ninguna sede!" });
                    cleanInfo();
                    getWorkers();
                    handleOpenSpinner(false);
                    handleTitleSpinner(null);
                }
            } else {
                simpleAlerts({ message: result?.response?.data?.error || result?.response?.data?.message || "Error en la busqueda!", type: "error" });
                handleOpenSpinner(false);
                handleTitleSpinner(null);
            }
        } catch (error) {
            console.log(`Error en saveWorker: ${error}`);
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    const saveWorkerSede = async (info) => {
        try {
            handleOpenSpinner(true);
            handleTitleSpinner("Asignando a la sede ...");
            const body = {
                id_persona: info.id_persona,
                numdoc: info.numdoc,
                sedes: valueSedes,
                id_estado:1
            }
            const result = await WorkerService.saveWorkerSedes(body);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts({ message: result?.data?.message || "Guardo!", type: "success" });
                cleanInfo();
                getWorkers();
            } else {
                simpleAlerts({ message: result?.response?.data?.error || result?.response?.data?.message || "Error en la busqueda!", type: "error" });
            }
        } catch (error) {
            console.log(`Error saveWorkerSede: ${error}`);
        } finally {
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    return (
        <>
            <CustomModal
                open={open}
                handleClose={cleanInfo}
                formSubmit={saveWorker}
                title={'Mantenimiento trabajadores'}
                subTitle={'Creación y edición de trabajadores externos'}
                bodyModal={
                    <InformationWorker
                        infoPerson={infoPerson}
                        setInfoPerson={setInfoPerson}
                    />
                }
                buttonActions={[
                    <CustomButton
                        key='btn-modal-user-save1'
                        variant={'primary'}
                        value={'Guardar'}
                        type={"submit"}
                    />
                ]}
            />
        </>
    )
}

export default AddWorker