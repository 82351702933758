import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/actions';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomInput from '../../general/CustomInput';
import GroupingUnits from '../../general/GroupingUnits';
import CustomButton from '../../general/CustomButton';
import ClockFunctions from '../../../pages/Clocks/Logic/ClockFunctions';
import CustomCheck from '../../general/CustomCheck';
import { CODES } from '../../../../utils/codesHTTP';
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';
import Swal from 'sweetalert2';

const InformationClock = ({ userToken, infoClock, setInfoClock, selectedGrouping, setSelectedGrouping }) => {
    const clock = new ClockFunctions(userToken?.perfil?.id_perfil);

    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (tabIndex, event) => {
        event.preventDefault();
        setActiveTab(tabIndex);
    };
    const [sedes, setSedes] = useState(null);

    const handleChangeGeneral = (checked) => {
        let newData;
        if (checked)
            newData = campusFree;
        else
            newData = [];
        return setSelectedGrouping(newData);
    }
    const handleChangeCheck = (checked, info, key) => {
        let newData = _.cloneDeep(selectedGrouping);
        if (checked) {
            newData.push(info);
        } else {
            newData = newData.filter((e) => +e[key] !== +info[key]);
        }
        return setSelectedGrouping(newData);
    }

    const relojSedes = infoClock?.reloj_sedes;
    const campusFree = sedes?.filter(sede =>
        !relojSedes?.some(existente => +existente.sede_id === +sede.id_sede)
    );

    const [company, setCompany] = useState(null);
    const [client, setClient] = useState(null);
    const [unit, setUnit] = useState(null);

    const dispatch = useDispatch();
    const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
    const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

    const deleteGroupingFromClock = async (info) => {
        const grouping = info.agrupamiento;
        const resultSwal = await Swal.fire({
            title: `Seguro de Retirar el agrupamiento`,
            html: `<label style="font-size: 12px;">Esta por retirar el agupamiento <br/> <b>(${grouping.desEmpresa} / ${grouping.desCliente} / ${grouping.desUnidad} / ${grouping.desSede})</b></label>`,
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
        });

        if (resultSwal.isConfirmed) {
            handleOpenSpinner(true);
            handleTitleSpinner('Asignando agrupamiento ...')
            try {
                const result = await clock.deleteGroupingFromClock(info.id_relojSede);
                if (result.status === CODES.SUCCESS_200) {
                    let updInfoClock = _.cloneDeep(infoClock);
                    const reloj_sedesTmp = updInfoClock.reloj_sedes.filter(e => +e.id_relojSede !== +info.id_relojSede)
                    updInfoClock.reloj_sedes = reloj_sedesTmp;
                    setInfoClock(updInfoClock);
                    simpleAlerts({ message: (result?.data?.message || 'Agrupamiento retirado!'), type: 'success', })
                } else {
                    simpleAlerts({ message: (result?.error || result?.response?.data?.error || 'Hubo un error!'), type: (result?.type || 'error'), })
                }
                return [];
            } catch (error) {
                console.log(`Error en saveClock: ${error.message}`);
            } finally {
                handleOpenSpinner(false);
                handleTitleSpinner(null);
            }
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <CustomInput
                        title={'Serial'}
                        placeholder={'Ingrese Nro de Serial'}
                        value={infoClock?.serial}
                        name={'serial'}
                        onChange={(e) => setInfoClock({ ...infoClock, serial: e.target.value })}
                        required={true}
                        propsInput={{ min: "12" }}
                    />
                    <CustomInput
                        title={'Desccripción'}
                        placeholder={'Ingrese descripción'}
                        value={infoClock?.descripcion}
                        name={'descripcion'}
                        onChange={(e) => setInfoClock({ ...infoClock, descripcion: e.target.value })}
                        required={true}
                    />
                    <CustomInput
                        title={'Línea'}
                        placeholder={'Ingrese línea'}
                        value={infoClock?.linea}
                        name={'linea'}
                        onChange={(e) => setInfoClock({ ...infoClock, linea: e.target.value })}
                        propsInput={{ maxLength: "9", minLength: "9" }}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <ul class="nav nav-tabs nav-tabs-body">
                        <li class="nav-item">
                            <a class={`nav-link ${activeTab === 0 ? 'active' : ''}`} href="#"
                                onClick={(e) => handleTabClick(0, e)}
                            >Listado Agrupamientos</a>
                        </li>
                        <li class="nav-item">
                            <a class={`nav-link ${activeTab === 1 ? 'active' : ''}`} href="#"
                                onClick={(e) => handleTabClick(1, e)}
                            >Agregar Agrupamientos</a>
                        </li>
                    </ul>
                    {activeTab === 0 ?
                        (relojSedes?.length > 0 ?
                            <div className="mt-2 table-responsive" style={{ maxHeight: '10rem' }}>
                                <table className="table table-dark table-bordered border-primary table-striped table-sm align-middle">
                                    <thead className='sticky-top'>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Cód.</th>
                                            <th scope="col">Agrupamiento</th>
                                            <th scope="col"><DeleteIcon fontSize='small' /></th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-group-divider table-light'>
                                        {infoClock.reloj_sedes?.map((row, idxRow) => {
                                            const grouping = row.agrupamiento;
                                            return (
                                                <tr key={`grouping-row-${idxRow}`}>
                                                    <th scope="row">{idxRow + 1}</th>
                                                    <td>{`${grouping.id_sede}`}</td>
                                                    <td>{`${grouping.desEmpresa} / ${grouping.desCliente} / ${grouping.desUnidad} / ${grouping.desSede}`}</td>
                                                    <td>
                                                        <CustomButton
                                                            title='Eliminar agrupamiento'
                                                            styleType={'outline'}
                                                            variant={'danger'}
                                                            value={<DeleteIcon fontSize='small' />}
                                                            onClick={() => deleteGroupingFromClock(row)}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            : <div className="d-flex justify-content-center">
                                <label className='badge text-bg-dark mt-2' style={{ letterSpacing: 1, padding: '5px 10px', whiteSpace: 'pre-line', marginBottom: '8px' }}>
                                    Aún no se le asignaron agrupamientos
                                </label>
                            </div>)
                        : <div className='row'>
                            <div className='col'>
                                <GroupingUnits
                                    col={4}
                                    company={company} setCompany={setCompany}
                                    client={client} setClient={setClient}
                                    unit={unit} setUnit={setUnit}
                                    campus={sedes} setCampus={setSedes}
                                />
                            </div>
                            <div className='row'>
                                <div className="mt-2 table-responsive" style={{ maxHeight: '10rem' }}>
                                    <table className="table table-dark table-bordered border-primary table-striped table-sm align-middle">
                                        <thead className='sticky-top'>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Cód.</th>
                                                <th scope="col">Agrupamiento</th>
                                                <th scope="col" style={{ width: '10px', }}>
                                                    <div style={{ marginTop: '-1rem' }}>
                                                        <CustomCheck
                                                            forCheck={`checkbox-title-body-agrupamientos`}
                                                            checked={(selectedGrouping?.length === campusFree?.length && selectedGrouping?.length > 0) ? true : false}
                                                            onChange={(e) => handleChangeGeneral(e.target.checked)}
                                                        />
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='table-group-divider table-light'>
                                            {campusFree?.map((row, idxRow) => {
                                                return (
                                                    <tr key={`grouping-row-${idxRow}`}>
                                                        <th scope="row">{idxRow + 1}</th>
                                                        <td>{`${row.id_sede}`}</td>
                                                        <td>{`${row.desSede}`}</td>
                                                        <td>
                                                            <div style={{ marginTop: '-0.35rem' }}>
                                                                <CustomCheck
                                                                    forCheck={`new-sede-${idxRow}`}
                                                                    checked={(selectedGrouping?.find(e => +e.id_sede === row.id_sede) ? true : false)}
                                                                    onChange={(e) => handleChangeCheck(e.target.checked, row, 'id_sede')}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        </div >
    )
}

export default InformationClock