import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomCheck from '../general/CustomCheck';
import CustomButton from '../general/CustomButton';
import deleteUser from '../../../assets/images/delete_user.png';
import deleteAssistance from '../../../assets/images/delete_assistance.png';
import assistanceHistoryImg from '../../../assets/images/assistance_history.png'
import buscarInfoUserImgd from '../../../assets/images/buscar_info_user.png'
import { setOpenLoader, setTitleLoader } from '../../../config/actions';
import { sortDataListComplete, filterData, getValueFromPath } from '../../../utils/function';
import { CODES } from '../../../utils/codesHTTP';
import ClockFunctions from '../../pages/Clocks/Logic/ClockFunctions';
import { simpleAlerts } from '../../../utils/alerts';
import Swal from 'sweetalert2';

const BodyClocks = (props) => {
  const { userToken, forCheckBody, data, search, editClock, getRelojes, } = props;
  const clock = new ClockFunctions(userToken?.perfil?.id_perfil);

  const dispatch = useDispatch();
  const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
  const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

  const columns = clock.getColumnsReloj();
  const columnsMap = clock.getColumnsMapReloj();

  const resultSearch = filterData(data, search, [
    'serial',
    columnsMap.id_estado,
    columnsMap.id_sede,]);

  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
  const handleSortTable = (key) => {
    if (key === 'id_estado') {
      key = columnsMap.id_estado;
    }
    if (key === 'id_sede') {
      key = columnsMap.id_sede;
    }
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const srtdData = sortDataListComplete(resultSearch, sortConfigTable);
  // /** fin ordenamiento de columnas */

  const stateClock = async (info, value) => {
    info.estado_id = value;
    try {
      const result = await clock.saveClock(info);
      if (result.status === CODES.CREATE_201) {
        getRelojes();
      } else {
        simpleAlerts({ message: (result?.error || result?.response?.data?.error || 'Hubo un error!'), type: (result?.type || 'error'), })
      }
      return [];
    } catch (error) {
      console.log(`Error en stateClock: ${error}`);
    }
  }

  const deleteUsersClock = async (id_reloj, serial) => {
    const resultSwal = await Swal.fire({
      title: `Seguro de eliminar a los usuarios del reloj (${serial})`,
      html: `<label style="font-size: 15px; color: blue;">Una vez ejecutado el comando la información <br/><b>YA NO SE PODRA RECUPERAR</b></label>`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });

    if (resultSwal.isConfirmed) {
      handleOpenSpinner(true);
      handleTitleSpinner('Enviando solicitud ...')
      try {
        const result = await clock.deleteUsersClock(id_reloj);
        if (result.status === CODES.SUCCESS_200) {
          simpleAlerts({ message: (result?.data?.message || 'Comando enviaso!'), type: 'success', })
        } else {
          simpleAlerts({ message: (result?.error || result?.response?.data?.error || 'Hubo un error!'), type: (result?.type || 'error'), })
        }
        return [];
      } catch (error) {
        console.log(`Error en deleteUsersClock: ${error.message}`);
      } finally {
        handleOpenSpinner(false);
        handleTitleSpinner(null);
      }
    }
  }

  const deleteAssistanceClock = async (id_reloj, serial) => {
    const resultSwal = await Swal.fire({
      title: `Seguro de eliminar las asistencias del reloj (${serial})`,
      html: `<label style="font-size: 15px; color: blue;">Una vez ejecutado el comando la información <br/><b>YA NO SE PODRA RECUPERAR</b></label>`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });

    if (resultSwal.isConfirmed) {
      handleOpenSpinner(true);
      handleTitleSpinner('Enviando solicitud ...')
      try {
        const result = await clock.deleteAssistanceClock(id_reloj);
        if (result.status === CODES.SUCCESS_200) {
          simpleAlerts({ message: (result?.data?.message || 'Comando enviaso!'), type: 'success', })
        } else {
          simpleAlerts({ message: (result?.error || result?.response?.data?.error || 'Hubo un error!'), type: (result?.type || 'error'), })
        }
        return [];
      } catch (error) {
        console.log(`Error en deleteAssistanceClock: ${error.message}`);
      } finally {
        handleOpenSpinner(false);
        handleTitleSpinner(null);
      }
    }
  }

  const requestInfoWorkers = async (info) => {
    const resultSwal = await Swal.fire({
      title: `Seguro solicitar la información de todos los usuario del reloj (${info.serial})`,
      html: `<label style="font-size: 12px;">Se actualizara toda la información de los usuarios <br/>Nombres y Huellas</b></label>`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });

    if (resultSwal.isConfirmed) {
      try {
        handleOpenSpinner(true);
        handleTitleSpinner('Enviando solicitud (puede tomar varios minutos) ...')

        const result = await clock.requestInfoWorkerFromClock(info);
        if (result.status === CODES.SUCCESS_200) {
          simpleAlerts({ message: (result?.data?.message || 'Solicitud enviada!'), type: (+result?.data?.status === 0 ? 'error' : 'success'), })
        } else {
          simpleAlerts({ message: (result?.error || result?.response?.data?.error || 'Hubo un error!'), type: (result?.type || 'error'), })
        }
        return [];
      } catch (error) {
        console.log(`Error en requestInfoWorkerFromClock: ${error.message}`);
      } finally {
        handleOpenSpinner(false);
        handleTitleSpinner(null);
      }
    }
  }

  return (
    <div className="table-body-frame">
      <div className="table-body">
        <table className='table table-striped table-hover table-bordered table-sm'>
          <thead className='sticky-top' style={{ whiteSpace: 'nowrap' }}>
            <tr className="table-header table-dark">
              {/* <th className="left sortable th-table-head">Izquierda <span className="sort-icon">&#x25B2;</span></th>
              <th className="center sortable th-table-head">Centro <span className="sort-icon"><OrderedListOutlined /></span></th>
              <th className="right sortable th-table-head">Derecha <span className="sort-icon">&#x25B2;</span></th> */}
              {columns.map((col) => (
                <th
                  key={col.id}
                  className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                  style={{ width: col.width, maxWidth: col.width, }}
                  onClick={() => col.order && handleSortTable(col.id)}
                >
                  {col.id === 'actions' ?
                    <span className="actions-icon">
                      <SettingsIcon fontSize='small' />
                    </span>
                    : col.id === 'sel' ?
                      <div className="item" style={{ marginTop: '-1rem' }}>
                        <div className="checkbox-circle">
                          <input type="checkbox" id={`checkbox-title-body${forCheckBody}`} name="check" />
                          <label htmlFor={`checkbox-title-body${forCheckBody}`}></label>
                        </div>
                      </div>
                      :
                      <>
                        {col.label}
                        {col.order && <span className="sort-icon"><OrderedListOutlined /></span>}
                      </>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {srtdData?.map((row, idxRow) => {
              return (
                <tr key={"row-" + idxRow} className={`${+row.estado_id === 2 ? 'table-danger' : ''}`}>
                  {columns.map((col, indexColumn) => {
                    const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                    return (
                      <td
                        key={'col' + indexColumn}
                        className={`${col.align}`}
                        style={{
                          width: col.width,
                          maxWidth: col.width,
                          backgroundColor: `${(col.id === 'advanced') ? 'rgba(149,189,255, 0.5)' : ''}`,
                          border: `${(col.id === 'advanced') ? 'solid 2px #FF7878' : ''}`,
                        }}
                      >
                        {col.format ?
                          (value ? col.format(value) : '')
                          : col.id === 'advanced' ?
                            <div className='d-flex justify-content-evenly gap-1'>
                              <CustomButton
                                title='Solicitar asistencias'
                                styleType={'outline'}
                                variant={'success'}
                                value={<img src={assistanceHistoryImg} width={18} />}
                                onClick={() => editClock(row, 'ASSISTANCE')}
                              />
                              <CustomButton
                                title='Solicitar información usuarion'
                                styleType={'outline'}
                                variant={'success'}
                                value={<img src={buscarInfoUserImgd} width={18} />}
                                onClick={() => requestInfoWorkers(row)}
                              />
                              <CustomButton
                                title='Eliminar usuarios'
                                variant={'danger'}
                                value={<img src={deleteUser} width={18} />}
                                onClick={() => deleteUsersClock(row.id_reloj, row.serial)}
                              />
                              <CustomButton
                                title='Eliminar asistencias'
                                variant={'danger'}
                                value={<img src={deleteAssistance} width={18} />}
                                onClick={() => deleteAssistanceClock(row.id_reloj, row.serial)}
                              />
                            </div>
                            : col.id === '#' ?
                              idxRow + 1
                              : col.id === 'sel' ?
                                <CustomCheck
                                  forCheck={`${row.numdoc}${forCheckBody} `}
                                />
                                : col.id === 'actions' ?
                                  <div className='d-flex justify-content-between'>
                                    <CustomButton
                                      title='Editar reloj'
                                      variant={'primary'}
                                      value={<EditIcon style={{ fontSize: '15px' }} />}
                                      onClick={() => editClock(row)}
                                    />
                                    <IconButton
                                      aria-label="active"
                                      size="small"
                                      style={{ border: 'solid 1px', padding: '0px 1px', borderRadius: 2 }}
                                      color={+row.estado_id === 1 ? 'error' : 'success'}
                                      onClick={() => stateClock(row, (+row.estado_id === 1 ? 2 : 1))}
                                      title={(+row.estado_id === 1 ? 'Desactivar Reloj' : 'Activar Reloj')}
                                    >
                                      {+row.estado_id === 1 ? <ToggleOffIcon fontSize="inherit" /> : <ToggleOnIcon fontSize="inherit" />}
                                    </IconButton>
                                  </div>
                                  : value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BodyClocks;