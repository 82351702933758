import React, { useState, useEffect } from 'react';
import { useDispatch, } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/actions';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import UserService from '../../../../services/user';
import ClockService from '../../../../services/clock';
import CustomButton from '../../general/CustomButton';
import { sortDataList, } from '../../../../utils/function';
import { CODES } from '../../../../utils/codesHTTP';
import { simpleAlerts } from '../../../../utils/alerts';

const columns = [
  { id: '#', label: '#', width: 30, align: 'center', },
  { id: 'serial', label: 'Serial', width: 80, align: 'center', order: true, },
  { id: 'descripcion', label: 'Descripción', width: 100, align: 'left', order: true, },
  { id: 'linea', label: 'Linea', width: 100, align: 'left', order: true, },
  //{ id: 'id_sede', label: 'Ubicación', width: 200, align: 'center', order: true, },
  { id: 'actions', label: 'actions', width: 20, align: 'center' },
];

const InformationClocks = ({ infoUser }) => {
  const [clocks, setClocks] = useState(null);

  const dispatch = useDispatch();
  const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
  const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const srtdData = sortDataList(clocks, sortConfigTable);
  // /** fin ordenamiento de columnas */

  useEffect(() => {
    setClocks(null);
    if (infoUser?.id_usuario)
      getRelojesByUser();
  }, [infoUser])

  const getRelojesByUser = async () => {
    try {
      handleOpenSpinner(true);
      handleTitleSpinner("Buscando relojes ...");
      const result = await ClockService.getRelojesByUser(infoUser?.id_usuario);
      if (result.status !== CODES.SUCCESS_200) {
        simpleAlerts({ message: result?.response?.data?.error || result?.response?.data?.message || "No se encontro relojes!", type: "error" });
        return;
      }
      setClocks(result?.data);
    } catch (error) {
      console.log(`Error en getRelojesByUser: ${error}`);
    } finally {
      handleOpenSpinner(false);
      handleTitleSpinner(null);
    }
  }

  const saveUsuarioToReloj = async (info, id_estado) => {
    try {
      handleOpenSpinner(true);
      handleTitleSpinner("Retirando reloj ...");
      const body = {
        usuario_id: infoUser.id_usuario,
        reloj_id: info.id_reloj,
        estado_id: id_estado,
      }
      const result = await UserService.saveUsuarioToReloj(body);
      if (result.status === CODES.CREATE_201) {
        simpleAlerts({ message: result?.data?.message || "Guardo!", type: "success" });
      } else {
        simpleAlerts({ message: result?.response?.data?.error || result?.response?.data?.message || "Error en la asignación!", type: "error" });
      }
    } catch (error) {
      console.log(`Error en saveUsuarioToReloj: ${error}`);
    } finally {
      handleOpenSpinner(false);
      handleTitleSpinner(null);
      getRelojesByUser();
    }
  }

  return (
    <div className="table-body-frame">
      <div className="table-body">
        <table className='table table-striped table-hover table-bordered table-sm'>
          <thead className='sticky-top order-1' style={{ whiteSpace: 'nowrap' }}>
            <tr className="table-header table-dark">
              {columns.map((col) => (
                <th
                  key={col.id}
                  className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                  style={{ width: col.width, maxWidth: col.width, }}
                  onClick={() => col.order && handleSortTable(col.id)}
                >
                  {col.id === 'actions' ?
                    <span className="actions-icon">
                      <SettingsIcon style={{ fontSize: 'medium' }} />
                    </span>
                    : <>
                      {col.label}
                      {col.order && <span className="sort-icon"><OrderedListOutlined /></span>}
                    </>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {srtdData?.map((row, idxRow) => {
              return (
                <tr
                  key={"row" + idxRow}
                >
                  {columns.map((col, indexColumn) => {
                    const value = row[col.id];
                    return (
                      <td
                        key={'col' + indexColumn}
                        className={`${col.align}`}
                        style={{ width: col.width, maxWidth: col.width, }}
                      >
                        {col.id === '#' ?
                          idxRow + 1
                          : col.id === 'actions' ?
                            <div className='d-flex justify-content-evenly'>
                              <CustomButton
                                title='Eliminar Reloj'
                                variant={'danger'}
                                value={<DeleteIcon style={{ fontSize: '15px' }} />}
                                onClick={() => saveUsuarioToReloj(row, 2)}
                              />
                            </div>
                            : col.id === 'id_sede' ?
                              `${row.sede.unidad.cliente.empresa.desEmpresa} | ${row.sede.unidad.cliente.desCliente} | ${row.sede.unidad.desUnidad} | ${row.sede.desSede}`
                              : value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default InformationClocks;