import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class ClockService {
    saveReloj(body) {
        const ruta = `${API_URL}/clock`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getRelojes(estado_id = 0) {
        const ruta = `${API_URL}/clock?estado_id=${estado_id}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getRelojesByUser(id_usuario) {
        const ruta = `${API_URL}/clock/user/${id_usuario}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getHistoryClock(id_reloj) {
        const ruta = `${API_URL}/clock/history/${id_reloj}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    deleteUsersClock(body) {
        const ruta = `${API_URL}/clock/delete-users`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    deleteAssistanceClock(body) {
        const ruta = `${API_URL}/clock/delete-assistances`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveRelojAndSedes(body) {
        const ruta = `${API_URL}/clock/grouping-massive`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
    
    deleteGroupingFromClock(body) {
        const ruta = `${API_URL}/clock/grouping`;
        return axios.delete(ruta, {
            headers: headerRequest(),
            data: body
        }).catch((error) => {
            return error;
        });
    }

    saveRelationRelojAndSedes(body) {
        const ruta = `${API_URL}/clock/grouping`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new ClockService();