import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/actions';
import CustomModal from '../../../components/general/CustomModal';
import CustomButton from '../../../components/general/CustomButton';
import InformationClock from '../../../components/Clocks/AddClock/InformationClock';
import RequestAssistances from '../../../components/Workers/AddUploadClock/RequestAssistances';
import { CODES } from '../../../../utils/codesHTTP';
import { simpleAlerts } from '../../../../utils/alerts';
import ClockFunctions from '../Logic/ClockFunctions';
import { getDateOL } from '../../../../utils/function';
import Swal from 'sweetalert2';

const AddClock = (props) => {
    const { userToken, open, handleClose, infoClock, setInfoClock, getRelojes, typeSave, setTypeSave } = props;

    const clock = new ClockFunctions(userToken?.perfil?.id_perfil);

    const dispatch = useDispatch();
    const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
    const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

    const [dateInit, setDateInit] = useState(null)
    const [dateEnd, setDateEnd] = useState(null)

    const [selectedGrouping, setSelectedGrouping] = useState([]);

    useEffect(() => {
        setDateInit(getDateOL)
        setDateEnd(getDateOL);
    }, [infoClock])

    const cleanInfo = () => {
        setInfoClock(null);
        setTypeSave(null);
        setDateInit(null);
        setDateEnd(null);
        setSelectedGrouping([]);
        handleClose();
    }

    const saveClock = async (e) => {
        e.preventDefault();

        const resultSwal = await Swal.fire({
            title: `Seguro de guardor los cambios del reloj`,
            html: `<label style="font-size: 12px;">Se actualizaran los datos del reloj</label>`,
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
        });

        if (resultSwal.isConfirmed) {
            handleOpenSpinner(true);
            handleTitleSpinner('Guardando Reloj ...')
            try {
                const body = {
                    ...infoClock,
                    reloj_sedes: selectedGrouping,
                }
                // const result = await clock.saveClock(infoClock, sede);
                const result = await clock.saveRelojAndSedes(body);
                if (result.status === CODES.CREATE_201) {
                    simpleAlerts({ message: (result?.data?.message || 'Reloj creado!'), type: 'success', })
                    cleanInfo();
                    getRelojes();
                } else {
                    simpleAlerts({ message: (result?.error || result?.response?.data?.error || 'Hubo un error!'), type: (result?.type || 'error'), })
                }
                return [];
            } catch (error) {
                console.log(`Error en saveClock: ${error.message}`);
            } finally {
                handleOpenSpinner(false);
                handleTitleSpinner(null);
            }
        }
    }

    const requestAssistancesWorker = async (e) => {
        e.preventDefault();

        const htmlSubtitle =
            ((dateInit !== '' && dateEnd !== '') ?
                `<label style="font-size: 12px;">Las fechas solicitadas son: <b>${dateInit} - ${dateEnd}</b></label>`
                : `<label style="font-size: 12px;"><b>Solicitara todas las asistencias del reloj (esta acción puede tomar varios minutos)</b></label>`)

        const resultSwal = await Swal.fire({
            title: `Seguro solicitar las asistencias`,
            html: htmlSubtitle,
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
        });

        if (resultSwal.isConfirmed) {
            try {
                handleOpenSpinner(true);
                handleTitleSpinner('Enviando solicitud (puede tomar varios minutos) ...')
                const result = await clock.requestAssistancesWorker(infoClock, dateInit, dateEnd);
                if (result.status === CODES.SUCCESS_200) {
                    simpleAlerts({ message: (result?.data?.message || 'Solicitud enviada!'), type: (+result?.data?.status === 0 ? 'error' : 'success'), })
                } else {
                    simpleAlerts({ message: (result?.error || result?.response?.data?.error || 'Hubo un error!'), type: (result?.type || 'error'), })
                }
                return [];
            } catch (error) {
                console.log(`Error en requestInfoWorkerFromClock: ${error.message}`);
            } finally {
                handleOpenSpinner(false);
                handleTitleSpinner(null);
                cleanInfo();
            }
        }
    }

    return (
        <CustomModal
            size={'md'}
            open={open}
            handleClose={cleanInfo}
            formSubmit={
                typeSave === "ASSISTANCE" ?
                    requestAssistancesWorker
                    : saveClock
            }
            title={'Mantenimiento de Relojes'}
            subTitle={'Creación y edición y comandos para el reloj'}
            bodyModal={
                typeSave === "ASSISTANCE" ?
                    <RequestAssistances
                        typeSave={'ALL'}
                        infoWorker={infoClock}
                        dateInit={dateInit}
                        setDateInit={setDateInit}
                        dateEnd={dateEnd}
                        setDateEnd={setDateEnd}
                    />
                    : <InformationClock
                        userToken={userToken}
                        infoClock={infoClock}
                        setInfoClock={setInfoClock}
                        selectedGrouping={selectedGrouping}
                        setSelectedGrouping={setSelectedGrouping}
                    />
            }
            buttonActions={[
                <CustomButton
                    key={'btn-save'}
                    variant={'success'}
                    value={'Guardar'}
                    styleType={'outline'}
                    type={'submit'}
                />
            ]}
        />
    )
}

export default AddClock