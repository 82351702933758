import React from 'react'

const CustomSwitch = ({ leftLabel, rightLabel, checked, onChange }) => {

    return (
        <div className="body-switch">
            <input
                id="toggle-on"
                className="toggle-custom toggle-left"
                name="toggle"
                value="false"
                type="radio"
                checked={checked}
                onChange={() => onChange(false)}
            />
            <label htmlFor="toggle-on" className="btn-label-switch">
                <p className="opt-1">{leftLabel}</p>
            </label>
            <input
                id="toggle-off"
                className="toggle-custom toggle-right"
                name="toggle"
                value="true"
                type="radio"
                checked={!checked}
                onChange={() => onChange(true)}
            />
            <label htmlFor="toggle-off" className="btn-label-switch">
                <p className="opt-2">{rightLabel}</p>
            </label>
        </div>
    )
}

export default CustomSwitch