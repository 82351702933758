import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class UserService {
    getUsers({ search = '', page = 1, pageSize = 20, pagination = 1, estado = 0 }) {
        const ruta = `${API_URL}/user?search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}&estado=${estado}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getAgrupamientoByUsuario(id_usuario) {
        const ruta = `${API_URL}/user/grouping/${id_usuario}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    register(body) {
        const ruta = `${API_URL}/user/register`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveUsuarioToReloj(body) {
        const ruta = `${API_URL}/user/clock`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveUsuarioToRelojMassive(body) {
        const ruta = `${API_URL}/user/clock/massive`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveUsuarioToRelojSomes(body) {
        const ruta = `${API_URL}/user/clock/somes`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getUserNotification() {
        const ruta = `${API_URL}/user/notifications`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    viewNotification(body) {
        const ruta = `${API_URL}/user/view/notification`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new UserService();
