import React, { useState } from 'react'
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { IconButton, TextField } from '@mui/material';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import EditIcon from '@mui/icons-material/Edit';
import CustomPagination from '../general/CustomPagination';
import CustomButton from '../general/CustomButton';
import CustomCheck from '../general/CustomCheck';
import UserService from '../../../services/user';
import { sortDataList, } from '../../../utils/function';
import { CODES } from '../../../utils/codesHTTP';
import _ from 'lodash';
import { simpleAlerts } from '../../../utils/alerts';

const columns = [
    { id: '#', label: '#', width: 30, align: 'center', },
    { id: 'numdoc', label: 'Doc.', width: 80, align: 'center', order: true, },
    { id: 'trabajador', label: 'Trabajador', width: 200, align: 'left', order: true, },
    { id: 'usuario', label: 'Usuario', width: 80, align: 'center', order: true, },
    { id: 'desPerfil', label: 'Perfil', width: 80, align: 'center', order: true, },
    { id: 'actions', label: 'actions', width: 80, align: 'center' },
    { id: 'sel', label: 'seleccionar', width: 25, align: 'center' },
];

const BodyUsers = (props) => {
    const { forCheckBody, page, setPage, totalPage, data, setData, search, setSearch, editInfo, getInfo, dataSel, setDataSel, } = props;

    // /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
    const handleSortTable = (key) => {
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };
    const srtdData = sortDataList(data, sortConfigTable);
    // /** fin ordenamiento de columnas */

    const handleChangeGeneral = (checked) => {
        let newData;
        if (checked)
            newData = data;
        else
            newData = [];
        return setDataSel(newData);
    }

    const handleChangeCheck = (checked, info) => {
        let newData = _.cloneDeep(dataSel);
        if (checked) {
            newData.push(info);
        } else {
            newData = newData.filter((e) => e.id_usuario !== info.id_usuario);
        }
        return setDataSel(newData);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && getInfo) {
            getInfo();
        }
    };

    const saveUser = async (info, key, value) => {
        info[key] = value;
        const indexToUpdate = data.findIndex(item => +item.id_usuario === +info.id_usuario);
        const newData = _.cloneDeep(data);
        newData[indexToUpdate] = { ...info };
        try {
            const result = await UserService.register(info);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts({ message: result?.data?.message || "Actualizado!", type: "success" });
                setData(newData);
            } else {
                simpleAlerts({ message: result?.response?.data?.error || result?.response?.data?.message || "No se pudo actualizar usuario!", type: "error" });
            }
        } catch (error) {
            console.log(`Error en register: ${error}`);
        }
    }

    return (
        <>
            <TextField
                hiddenLabel
                id="filled-hidden-label-small"
                placeholder='Busqueda por documento / trabajador / usuario'
                variant="filled"
                size="small"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <IconButton color='primary' style={{ padding: 2, border: 'solid 1px' }}
                            onClick={getInfo}
                        >
                            <SearchIcon fontSize='small' />
                        </IconButton>
                    ),
                    style: { fontSize: 12, },
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={handleKeyPress}
                className='textField-search'
                inputProps={{ style: { fontSize: 12, color: 'black', fontWeight: 'bold', }, }}
            />
            <div className="table-body-frame">
                <div className="table-body">
                    <table className='table table-striped table-hover table-bordered table-sm'>
                        <thead className='sticky-top order-1' style={{ whiteSpace: 'nowrap' }}>
                            <tr className="table-header table-dark">
                                {columns.map((col) => (
                                    <th
                                        key={col.id}
                                        className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                                        style={{ width: col.width, maxWidth: col.width, }}
                                        onClick={() => col.order && handleSortTable(col.id)}
                                    >
                                        {col.id === 'actions' ?
                                            <span className="actions-icon">
                                                <SettingsIcon style={{ fontSize: 'medium' }} />
                                            </span>
                                            : col.id === 'sel' ?
                                                <div style={{ marginTop: '-1rem' }}>
                                                    <CustomCheck
                                                        forCheck={`checkbox-title-body${forCheckBody}`}
                                                        checked={(dataSel?.length === data?.length && dataSel?.length > 0) ? true : false}
                                                        onChange={(e) => handleChangeGeneral(e.target.checked)}
                                                    />
                                                </div>
                                                : <>
                                                    {col.label}
                                                    {col.order && <span className="sort-icon"><OrderedListOutlined /></span>}
                                                </>}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            {srtdData?.map((row, idxRow) => {
                                return (
                                    <tr
                                        key={"row" + idxRow}
                                        className={+row?.id_estado !== 1 ? 'table-danger' : ''}
                                    >
                                        {columns.map((col, indexColumn) => {
                                            const value = row[col.id];
                                            return (
                                                <td
                                                    key={'col' + indexColumn}
                                                    className={`${col.align}`}
                                                    style={{ width: col.width, maxWidth: col.width, }}
                                                >
                                                    {col.id === 'sel' ?
                                                        <CustomCheck
                                                            forCheck={`${row.id_usuario}${forCheckBody}`}
                                                            checked={(dataSel?.find(e => e.id_usuario === row.id_usuario) ? true : false)}
                                                            onChange={(e) => handleChangeCheck(e.target.checked, row)}
                                                        />
                                                        : col.id === 'actions' ?
                                                            <div className='d-flex justify-content-evenly'>
                                                                <IconButton
                                                                    aria-label="active"
                                                                    size="small"
                                                                    style={{ border: 'solid 1px', padding: '0px 1px', borderRadius: 2 }}
                                                                    color={'warning'}
                                                                    onClick={() => editInfo(row, 2)}
                                                                    title={(+row.id_estado === 1 ? 'Desactivar Reloj' : 'Activar Reloj')}
                                                                >
                                                                    <AddAlarmIcon fontSize="inherit" />
                                                                </IconButton>
                                                                <CustomButton
                                                                    title='Editar Usuario'
                                                                    variant={'primary'}
                                                                    value={<EditIcon style={{ fontSize: '15px' }} />}
                                                                    onClick={() => editInfo(row, 1)}
                                                                />
                                                                <IconButton
                                                                    aria-label="active"
                                                                    size="small"
                                                                    style={{ border: 'solid 1px', padding: '0px 1px', borderRadius: 2 }}
                                                                    color={+row.id_estado === 1 ? 'error' : 'success'}
                                                                    onClick={() => saveUser(row, 'id_estado', (+row.id_estado === 1 ? 2 : 1))}
                                                                    title={(+row.id_estado === 1 ? 'Desactivar Reloj' : 'Activar Reloj')}
                                                                >
                                                                    {+row.id_estado === 1 ? <ToggleOffIcon fontSize="inherit" /> : <ToggleOnIcon fontSize="inherit" />}
                                                                </IconButton>
                                                            </div>
                                                            : col.id === '#' ?
                                                                row.id_usuario
                                                                : value}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {totalPage > 1 &&
                    < div className="table-footer">
                        <CustomPagination
                            totalPages={totalPage}
                            page={page}
                            setPage={setPage}
                        />
                    </div>
                }
            </div >
        </>
    )
}

export default BodyUsers