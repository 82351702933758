import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class ExternoService {
    apiDNI(dni) {
        const ruta = `${API_URL}/externo/person/${dni}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new ExternoService();
