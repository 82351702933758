import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../services/auth';
import { CODES } from '../../../utils/codesHTTP';
import { Global } from '../../../utils/general';
import ProfileService from '../../../services/profile';
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/actions';
import { simpleAlerts } from '../../../utils/alerts';
import logout from '../../../assets/images/logout.png';
import clock from '../../../assets/images/reloj.png';
import worker from '../../../assets/images/trabajadores.png';
import reports from '../../../assets/images/reporte.png';
import profiles from '../../../assets/images/profiles.png';
import users from '../../../assets/images/users.png';

const Menu = (props) => {
  const { userToken, setUserToken } = props;

  const url = "'" + window.location.pathname + "'";
  const dispatch = useDispatch();
  const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
  const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));
  const [pantalla, setPantalla] = useState(null);
  const system = 'ICLOCK';

  const navigate = useNavigate();
  const info = Global.info;
  const infoTime = Global.infoTime;
  const pathInit = Global.path;

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => setIsActive(!isActive);

  const [activeItem, setActiveItem] = useState(null);
  const handleItemClick = (route) => {
    navigate(route);
    setActiveItem(route);
    setIsActive(false);
  };

  useEffect(() => {
    getPantallasByPerfil();
  }, [])

  const signOut = async () => {
    try {
      const result = await AuthService.signOut();
      if (result.status === CODES.BAD_TOKEN_498 || result.status === CODES.SUCCESS_200) {
        localStorage.removeItem(info);
        localStorage.removeItem(infoTime);
        navigate(`${pathInit}`)
        window.location.reload();
      }
    } catch (error) {
      console.log(`Error en logOut: ${error}`);
    }
  }

  const getPantallasByPerfil = async () => {
    try {
      handleOpenSpinner(true);
      handleTitleSpinner("Buscando Permisos");
      const result = await ProfileService.getPantallasByPerfil(system, url, userToken.perfil.id_perfil);
      if (result.status === CODES.SUCCESS_200) {
        setPantalla(result.data);
      } else if (result?.response?.status === CODES.BAD_TOKEN_498 || result?.message === 'Network Error') {
        simpleAlerts((result?.response?.data.error || 'Sesión expirada'), 'error');
        localStorage.removeItem(Global.info);
        setUserToken(null)
        window.location.reload();
      } else {
        simpleAlerts('Hubo un error en perfiles de pantallas', 'error');
      }
    } catch (err) {
      console.log('getPantallasByPerfil', err);
    } finally {
      handleOpenSpinner(false);
      handleTitleSpinner(null);
    }
  };

  const getOptionImage = (opcion) => {
    switch (opcion.desPagina) {
      case 'Relojes':
        return <img src={clock} alt={`menu-${opcion.desPagina.toLowerCase()}-img`} />;
      case 'Trabajadores':
        return <img src={worker} alt={`menu-${opcion.desPagina.toLowerCase()}-img`} />;
      case 'Reportes':
        return <img src={reports} alt={`menu-${opcion.desPagina.toLowerCase()}-img`} />;
      case 'Perfiles':
        return <img src={profiles} alt={`menu-${opcion.desPagina.toLowerCase()}-img`} />;
      case 'Usuarios':
        return <img src={users} alt={`menu-${opcion.desPagina.toLowerCase()}-img`} />;
      default:
        return null;
    }
  };

  return (
    <div className={`body-menu ${isActive ? 'active' : ''}`}>
      <div className={`menu ${isActive ? 'active' : ''}`} style={{ "--item-count": pantalla?.length + 1 }}>
        <div className="toggle" onClick={toggleMenu}>&#10133;</div>
        {
          pantalla?.map((opcion, index) => (
            <li
              key={index}
              style={{ "--i": `${index}`, zIndex: "1" }}
              onClick={() => handleItemClick(`${pathInit || ''}${opcion.link}`)}
              title={opcion.desPagina}
            >
              <a
                className={activeItem === `${pathInit || ''}${opcion.link}` ? 'active' : ''}
              >
                {getOptionImage(opcion)}
              </a>
            </li>
          ))}
        <li style={{ "--i": "5", zIndex: "2" }} onClick={signOut} title="Cerrar Sesión">
          <a>
            <img src={logout} alt='menu-logout-img' />
          </a>
        </li>
      </div>
    </div>
  );
}

export default Menu;
