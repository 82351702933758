import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import CustomButton from '../../components/general/CustomButton';
import UserService from '../../../services/user';
import ClockService from '../../../services/clock';
import GeneralService from '../../../services/general';
import ProfileService from '../../../services/profile';
import BodyUsers from '../../components/Users/BodyUsers';
import BodyClocks from '../../components/Users/BodyClocks';
import AddUser from './AddInfo/AddUser';
import clocksImg from '../../../assets/images/clocks.png';
import operadorImg from '../../../assets/images/operador.png';
import { CODES } from '../../../utils/codesHTTP';
import { setOpenLoader, setTitleLoader } from '../../../config/actions';
import { mapData } from '../../../utils/function';
import { validationMessages } from '../../../utils/messagesError';
import { simpleAlerts } from '../../../utils/alerts';

const newInfo = {
  usuario: '',
  numdoc: '',
  clave: '',
  id_persona: '',
  tipoTrabajador: '',
  trabajador: '',
  id_perfil: '',
  id_estado: '',
}

const Users = ({ userToken }) => {
  const dispatch = useDispatch();
  const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
  const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));
  const infoClock = useSelector(state => state.infoClock);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [states, setStates] = useState(null);
  const [profiles, setProfiles] = useState(null);

  const [dataSelect, setDataSelect] = useState([]);

  const [dataUsers, setDataUsers] = useState(null);
  const [searchU, setSearchU] = useState('');
  const [pageU, setPageU] = useState(1);
  const [totalPageU, setTotalPageU] = useState(null);

  const [infoUser, setInfoUser] = useState(null);

  const [dataClocks, setDataClocks] = useState(null);
  const [searchC, setSearchC] = useState('');
  const [dataSelectClock, setDataSelectClock] = useState([]);

  const [seeClocks, setSeeClocks] = useState(false);
  const [isComponentVisible, setIsComponentVisible] = useState(true);

  const [viewNumber, setViewNumber] = useState(null);

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    handleOpenSpinner(true);
    Promise.all([
      getPerfiles(),
      getEstados(),
      getUsers(),
      getRelojes(),
    ]).then(() => handleOpenSpinner(false));
  }

  const getPerfiles = async () => {
    try {
      const result = await ProfileService.getPerfiles();
      if (result.status === CODES.SUCCESS_200) {
        const stateTmp = mapData(result?.data, ['id_perfil', 'desPerfil']);
        setProfiles(stateTmp);
      }
    } catch (error) {
      console.log(`Error en getPerfiles: ${error}`);
    }
  }

  const getEstados = async () => {
    try {
      const result = await GeneralService.getEstados();
      if (result.status === CODES.SUCCESS_200) {
        const stateTmp = mapData(result?.data, ['id_estado', 'descEstado']);
        setStates(stateTmp);
      }
    } catch (error) {
      console.log(`Error en getUsers: ${error}`);
    }
  }

  const getUsers = async () => {
    try {
      handleOpenSpinner(true);
      handleTitleSpinner("Buscando trabajadores");
      setDataUsers(null);
      const result = await UserService.getUsers({ search: searchU, page: pageU });
      if (result.status === CODES.SUCCESS_200) {
        setDataUsers(result?.data?.data || result?.data);
        setTotalPageU(result?.data?.last_page || 0);
      }
    } catch (error) {
      console.log(`Error en getUsers: ${error}`);
    } finally {
      handleOpenSpinner(false);
      handleTitleSpinner(null);
    }
  }

  const getRelojes = async () => {
    try {
      setDataClocks(null);
      const result = await ClockService.getRelojes(1);
      if (result.status === CODES.SUCCESS_200) {
        setDataClocks(result?.data);
      }
    } catch (error) {
      console.log(`Error en getUsers: ${error}`);
    }
  }

  const editInformation = (info = newInfo, view) => {
    setInfoUser(info);
    setViewNumber(view);
    handleOpen();
  }

  const saveUsuarioToRelojSomes = async () => {
    if (!dataSelectClock || dataSelectClock?.length === 0) {
      simpleAlerts({ message: validationMessages.clockLength, type: "warning" });
      return;
    }
    if (!dataSelect || dataSelect?.length === 0) {
      simpleAlerts({ message: validationMessages.trabajadoresLength, type: "warning" });
      return;
    }
    try {
      handleOpenSpinner(true);
      handleTitleSpinner("Asignando usuarios ...");
      const body = {
        id_estado: 1,
        relojes: dataSelectClock,
        usuarios: dataSelect,
      }
      const result = await UserService.saveUsuarioToRelojSomes(body);
      if (result.status === CODES.SUCCESS_200) {
        simpleAlerts({ message: result?.data?.message || "Guardo!", type: "success" });
        window.location.reload()
      } else {
        simpleAlerts({ message: result?.response?.data?.error || result?.response?.data?.message || "Error en la asignación!", type: "error" });
      }
    } catch (error) {
      console.log(`Error en saveUsuarioToRelojMassive: ${error}`);
    } finally {
      setDataSelect([]);
      setDataSelectClock([]);
      handleOpenSpinner(false);
      handleTitleSpinner(null);
    }
  }

  const saveUsuarioToRelojMassive = async () => {
    if (!infoClock || infoClock?.id_reloj === 0) {
      simpleAlerts({ message: validationMessages.clock, type: "warning" });
      return;
    }
    if (dataSelect?.length === 0) {
      simpleAlerts({ message: validationMessages.trabajadoresLength, type: "warning" });
      return;
    }
    try {
      handleOpenSpinner(true);
      handleTitleSpinner("Asignando usuarios ...");
      const body = {
        id_reloj: infoClock.id_reloj,
        id_estado: 1,
        usuarios: dataSelect,
      }
      const result = await UserService.saveUsuarioToRelojMassive(body);
      if (result.status === CODES.SUCCESS_200) {
        simpleAlerts({ message: result?.data?.message || "Guardo!", type: "success" });
      } else {
        simpleAlerts({ message: result?.response?.data?.error || result?.response?.data?.message || "Error en la asignación!", type: "error" });
      }
    } catch (error) {
      console.log(`Error en saveUsuarioToRelojMassive: ${error}`);
    } finally {
      setDataSelect([]);
      handleOpenSpinner(false);
      handleTitleSpinner(null);
    }
  }

  const handleSeeClocks = (value) => {
    setSeeClocks(value);
    setTimeout(() => { setIsComponentVisible(!value) }, 1500);
  }

  return (
    <div className="mt-4">
      <AddUser
        open={open}
        handleClose={handleClose}
        viewNumber={viewNumber}
        setViewNumber={setViewNumber}
        states={states}
        profiles={profiles}
        infoUser={infoUser}
        setInfoUser={setInfoUser}
        getUsers={getUsers}
      />
      <Grid container direction="row" justifyContent="center" alignItems="self-start">
        <Grid item xs={10} sm={10} md={seeClocks ? 5 : 8}>
          <span className="badge text-dark" style={{ fontSize: '1rem' }}>Usuarios:</span>
          <BodyUsers
            forCheckBody={'user'}
            data={dataUsers}
            setData={setDataUsers}
            page={pageU}
            setPage={setPageU}
            totalPage={totalPageU}
            search={searchU}
            setSearch={setSearchU}
            getInfo={getUsers}
            editInfo={editInformation}
            dataSel={dataSelect}
            setDataSel={setDataSelect}
          />
          <CustomButton
            size={"md"}
            title={"Crear Nuevo Trabajador"}
            value={"Crear"}
            variant={"warning"}
            onClick={() => editInformation(newInfo, 1)}
          />
        </Grid>
        {isComponentVisible &&
          <Grid item xs={10} md={1}
            className={`${seeClocks ? 'animate__animated animate__backOutLeft' : 'animate__animated animate__bounceInDown'}`}
            sx={{
              paddingTop: { xs: '0', sm: '0', md: '80px' },
              paddingLeft: { xs: '150px', sm: '150px', md: '50px' },
            }}
          >
            <CustomButton
              size={'lg'}
              style={{ borderRadius: '50%', padding: '20px', }}
              variant={'warning'}
              title={'Cerrar relojes'}
              value={<img src={clocksImg} width={80} />}
              onClick={() => handleSeeClocks(true)}
            />
          </Grid>
        }
        {seeClocks &&
          <>
            <Grid item xs={10} sm={10} md={1}
              className='animate__animated animate__backInUp'
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'row', sm: 'row', md: 'column' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 3,
                  height: { xs: '4rem', sm: '4rem', md: '25rem' },
                }}
              >
                <CustomButton
                  size={'lg'}
                  style={{ borderRadius: '50%', padding: '4px 6px' }}
                  styleType={'outline'}
                  variant={'success'}
                  title={'Asignar usuario al reloj'}
                  value={<><img src={operadorImg} width={20} /><ScheduleSendIcon fontSize="medium" /></>}
                  onClick={saveUsuarioToRelojSomes}
                />
                <CustomButton
                  size={'lg'}
                  style={{ borderRadius: '50%', padding: '4px 6px' }}
                  variant={'danger'}
                  title={'Cerrar relojes'}
                  value={<img src={clocksImg} width={28} />}
                  onClick={() => handleSeeClocks(false)}
                />
              </Box>
            </Grid>
            <Grid item xs={10} sm={10} md={3}
              className='animate__animated animate__backInRight'
            >
              <span className="badge text-dark" style={{ fontSize: '1rem' }}>Relojes:</span>
              <BodyClocks
                forCheckBody={'clock'}
                data={dataClocks}
                search={searchC}
                setSearch={setSearchC}
                dataSel={dataSelectClock}
                setDataSel={setDataSelectClock}
              />
            </Grid>
          </>
        }
      </Grid>
    </div>
  )
}

export default Users