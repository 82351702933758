import React, { useState } from "react";
import { darken } from 'polished';

const HandLeft_F = ({ highlightedFingers, onFingerClick }) => {
  const [hoveredFingers, setHoveredFingers] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleFingerClick = (fingerId) => {
    onFingerClick(fingerId);
  };

  const fingerOff = "#D5BFBF";
  const fingerOn = "#38E54D";
  const hoverColor = "#FFFB73";

  const handleMouseEnter = (fingerId) => {
    setHoveredFingers((prevHoveredFingers) => {
      const newHoveredFingers = [...prevHoveredFingers];
      newHoveredFingers[fingerId] = true;
      return newHoveredFingers;
    });
    setIsMouseOver(true);
  };

  const handleMouseLeave = (fingerId) => {
    setHoveredFingers((prevHoveredFingers) => {
      const newHoveredFingers = [...prevHoveredFingers];
      newHoveredFingers[fingerId] = false;
      return newHoveredFingers;
    });
    setIsMouseOver(false);
  };

  const fingerPaths = [
    {
      id: 0, description: 'Meñique',
      d: "M20948 26632 c-103 -114 -189 -294 -217 -456 -15 -82 -13 -238 4 -381 7 -66 13 -120 12 -121 -1 -1 -22 1 -47 5 -66 10 -218 4 -355 -14 -66 -9 -149 -19 -184 -23 -55 -6 -65 -10 -72 -31 -9 -23 -9 -23 43 -17 29 3 78 9 108 12 30 4 96 12 145 19 77 11 277 12 380 3 26 -3 30 0 29 22 -1 34 -12 137 -25 240 -14 106 -6 241 20 345 29 112 107 266 180 352 34 40 61 75 61 78 0 3 -11 5 -24 5 -15 0 -37 -14 -58 -38z" +
        "M21063 26576 c-87 -93 -157 -214 -191 -328 -17 -58 -18 -69 -6 -79 19 -16 27 -6 48 66 40 132 132 276 232 362 38 33 50 49 42 55 -28 17 -50 4 -125 -76z" +
        "M20823 26643 c-30 -6 -52 -35 -106 -141 -96 -185 -137 -393 -117 -588 6 -53 9 -98 8 -100 -2 -1 -43 -5 -93 -8 -49 -4 -117 -11 -150 -16 -33 -5 -87 -12 -120 -15 -117 -11 -133 -15 -139 -38 -3 -12 -4 -22 -3 -23 1 -1 67 7 147 17 80 10 202 22 272 25 95 5 128 11 132 21 3 8 1 44 -4 81 -37 253 25 518 169 731 23 34 38 60 34 60 -4 -1 -18 -4 -30 -6z" +
        "M21260 26593 c-95 -71 -210 -206 -210 -244 0 -39 35 -18 79 47 45 67 119 138 187 179 36 22 36 23 16 34 -29 15 -32 14 -72 -16z" +
        "M20612 26545 c-62 -52 -131 -310 -137 -505 l-3 -115 -63 -8 c-248 -29 -262 -32 -270 -55 -8 -22 -7 -22 49 -17 31 4 86 10 122 15 36 5 94 12 130 16 36 3 68 8 73 11 4 2 7 55 7 116 0 183 43 375 115 517 28 55 22 63 -23 25z" +
        "M21361 26513 c-89 -53 -172 -145 -220 -246 -67 -139 -73 -223 -36 -533 22 -186 22 -184 45 -184 24 0 24 -6 5 153 -28 242 -30 262 -28 332 2 88 17 142 62 230 46 90 135 180 224 225 59 31 65 37 50 48 -25 18 -32 16 -102 -25z" +
        "M21461 26429 c-174 -87 -274 -270 -257 -469 4 -43 9 -93 11 -111 3 -19 12 -95 20 -169 8 -74 17 -146 20 -160 2 -14 7 -52 10 -85 3 -33 9 -91 15 -130 5 -38 12 -95 15 -125 3 -30 12 -104 19 -165 8 -60 22 -171 31 -245 9 -74 23 -184 31 -245 22 -171 19 -243 -11 -288 -60 -88 -189 -100 -262 -25 -39 41 -49 66 -57 148 -4 36 -11 92 -16 125 -5 33 -12 87 -15 120 -13 130 -37 288 -50 324 -42 121 -159 225 -293 261 -46 12 -65 7 -60 -17 2 -10 24 -21 56 -29 68 -18 150 -70 193 -125 63 -79 74 -114 94 -281 8 -73 22 -185 30 -248 8 -63 17 -137 20 -165 15 -129 99 -205 224 -205 61 1 120 29 159 76 57 69 61 113 27 364 -8 58 -17 128 -20 155 -6 61 -50 427 -70 585 -15 127 -18 146 -40 335 -8 72 -22 184 -30 251 -16 139 -10 211 28 294 55 123 152 204 296 246 11 3 11 7 1 19 -19 23 -50 18 -119 -16z" +
        "M20428 26392 c-30 -33 -36 -50 -52 -137 -9 -55 -18 -127 -19 -160 l-2 -60 -70 -8 c-82 -10 -99 -17 -103 -43 -3 -19 0 -19 100 -6 57 8 106 20 110 26 4 6 8 39 8 73 0 67 32 247 55 312 8 23 13 41 11 41 -2 0 -19 -17 -38 -38z" +
        "M21553 26330 c-159 -57 -247 -210 -222 -386 12 -82 31 -241 65 -539 33 -295 41 -346 53 -358 8 -8 14 -8 22 0 9 9 9 37 0 110 -7 54 -14 114 -16 133 -7 58 -25 208 -41 330 -8 63 -16 135 -19 160 -2 25 -10 89 -16 143 -9 75 -9 109 0 151 15 69 61 138 116 176 42 29 136 60 182 60 21 0 22 2 11 20 -16 26 -65 26 -135 0z" +
        "M20987 26213 c-2 -4 -10 -39 -17 -77 -15 -78 -9 -228 14 -396 8 -58 17 -132 21 -165 3 -33 10 -89 15 -125 5 -36 12 -93 16 -128 l6 -63 -64 46 c-106 79 -191 114 -328 136 -52 8 -96 6 -235 -11 -93 -12 -213 -27 -265 -34 -86 -10 -95 -14 -98 -34 -4 -25 3 -26 93 -12 33 5 86 12 118 15 32 3 102 12 155 19 259 36 424 -10 588 -166 91 -87 98 -82 79 62 -17 133 -34 271 -40 335 -3 28 -12 97 -20 155 -23 176 -27 261 -15 335 17 102 17 109 -2 113 -9 2 -18 -1 -21 -5z" +
        "M21575 26203 c-60 -31 -102 -86 -119 -155 -5 -24 9 -179 34 -358 5 -36 11 -90 15 -120 3 -30 10 -89 15 -130 5 -41 12 -95 15 -120 14 -126 47 -404 70 -590 8 -63 17 -140 20 -170 3 -30 10 -82 15 -115 32 -201 -32 -365 -182 -465 -77 -51 -142 -70 -239 -70 -122 0 -229 48 -310 137 -73 80 -99 148 -118 303 -28 229 -40 330 -46 385 -10 94 -22 128 -57 162 -44 42 -88 52 -173 42 -39 -5 -128 -16 -200 -24 -323 -39 -328 -40 -339 -57 -22 -35 -27 -35 309 8 61 7 130 16 155 19 25 2 70 7 101 11 41 5 64 2 86 -9 53 -28 64 -57 78 -197 3 -30 12 -104 20 -165 7 -60 19 -152 25 -204 7 -52 20 -118 31 -148 77 -224 324 -358 552 -298 153 40 282 154 332 295 36 100 37 141 5 395 -16 132 -32 263 -35 290 -3 28 -10 82 -15 120 -5 39 -12 95 -15 125 -3 30 -10 87 -15 125 -5 39 -12 95 -15 125 -3 30 -10 87 -15 125 -5 39 -12 95 -15 125 -3 30 -15 127 -25 214 -11 88 -20 178 -20 200 1 121 139 202 249 146 21 -10 48 -32 60 -47 13 -18 18 -21 13 -8 -22 56 -47 94 -69 104 -36 16 -143 13 -178 -6z" +
        "M20255 26150 c-14 -29 -25 -53 -23 -55 2 -1 12 1 24 5 15 6 23 20 27 51 3 24 4 45 2 47 -2 2 -16 -19 -30 -48z" +
        "M21635 26096 c-62 -27 -68 -52 -46 -221 14 -118 18 -130 36 -130 15 0 20 6 19 25 -2 35 -13 146 -20 203 -7 51 12 87 46 87 44 0 52 -28 85 -315 30 -256 47 -358 66 -408 23 -58 77 -142 112 -175 l24 -23 5 25 c4 17 -5 37 -30 68 -84 106 -89 126 -148 648 -8 74 -20 148 -26 163 -19 50 -75 74 -123 53z" +
        "M21833 26065 c12 -44 32 -194 53 -400 17 -172 51 -326 76 -341 13 -8 8 60 -16 221 -8 55 -17 129 -21 165 -12 126 -28 206 -57 285 -27 75 -47 114 -35 70z" +
        "M20844 25953 c3 -65 8 -134 11 -153 28 -204 37 -300 30 -300 -3 0 -18 6 -33 14 -97 48 -273 59 -489 31 -70 -9 -165 -21 -210 -27 -65 -8 -82 -13 -86 -28 -7 -27 -4 -28 60 -19 32 5 86 11 119 14 34 4 103 12 155 20 52 7 144 12 204 11 94 -3 123 -8 200 -35 133 -47 130 -48 128 24 -2 33 -10 114 -18 180 -9 66 -18 136 -20 155 -3 19 -8 79 -11 133 -6 88 -9 97 -27 97 -19 0 -20 -5 -13 -117z" +
        "M21629 25608 c-4 -29 -4 -29 50 -483 22 -184 49 -406 56 -470 3 -22 12 -96 21 -165 21 -156 15 -255 -20 -340 -14 -35 -24 -66 -22 -67 9 -10 78 86 87 120 15 56 13 193 -4 327 -9 63 -23 176 -32 250 -8 74 -22 187 -30 250 -8 63 -22 178 -31 255 -37 319 -39 330 -57 333 -9 2 -18 -2 -18 -10z" +
        "M21153 25404 c-5 -14 3 -86 42 -399 8 -60 17 -132 20 -160 5 -53 16 -146 40 -340 19 -151 19 -190 -1 -209 -20 -21 -37 -20 -63 2 -17 13 -23 32 -28 89 -7 81 -14 99 -38 89 -14 -5 -15 -15 -10 -63 11 -107 16 -123 43 -149 23 -21 34 -25 71 -21 26 3 52 13 65 26 26 26 26 84 2 275 -9 69 -23 183 -31 253 -58 476 -74 603 -81 614 -8 14 -24 11 -31 -7z" +
        "M20438 25310 c-26 -4 -77 -11 -115 -15 -37 -3 -97 -10 -133 -15 -36 -5 -81 -11 -101 -13 -47 -6 -59 -13 -59 -38 0 -15 5 -18 23 -14 23 5 143 20 232 30 28 3 84 10 125 16 180 27 276 16 392 -42 137 -69 243 -221 262 -374 18 -148 28 -223 32 -242 4 -22 28 -31 38 -15 8 14 -33 313 -51 366 -50 153 -183 287 -338 342 -57 20 -231 28 -307 14z" +
        "M20330 25174 c-328 -40 -315 -38 -318 -62 -4 -25 -1 -25 163 -2 39 5 97 12 130 15 33 4 87 10 120 15 33 6 63 10 68 10 4 0 7 9 7 20 0 24 -12 24 -170 4z" +
        "M21804 25146 c-3 -8 8 -121 25 -252 16 -132 36 -294 43 -361 7 -68 15 -123 19 -123 11 0 22 144 15 202 -3 29 -8 78 -11 108 -3 30 -10 82 -15 115 -7 48 -22 182 -22 205 -1 20 7 17 45 -15 42 -36 42 -36 45 -12 2 19 -12 39 -58 86 -64 63 -77 71 -86 47z" +
        "M20405 25055 c-55 -7 -165 -20 -245 -29 -172 -20 -170 -20 -170 -47 0 -19 4 -21 43 -15 23 3 62 8 87 11 145 16 316 36 387 46 65 9 92 9 128 -1 57 -16 119 -60 145 -103 33 -53 37 -77 75 -412 24 -214 37 -281 67 -338 37 -69 82 -111 158 -146 46 -22 69 -26 145 -26 78 0 97 4 146 28 63 30 126 93 158 156 35 68 44 132 32 224 -6 45 -13 109 -16 142 -4 33 -10 87 -15 120 -5 33 -12 95 -15 137 -7 76 -22 107 -46 93 -7 -5 -9 -23 -5 -49 3 -22 8 -70 11 -106 3 -36 8 -72 10 -80 12 -48 36 -323 32 -367 -25 -239 -335 -344 -505 -170 -23 23 -50 62 -61 87 -17 39 -38 167 -56 335 -3 28 -9 82 -15 120 -5 39 -12 97 -15 130 -11 109 -70 201 -160 248 -50 26 -160 31 -300 12z" +
        "M20485 24813 c-60 -7 -263 -32 -393 -49 -125 -16 -133 -18 -130 -38 2 -12 9 -20 18 -19 16 2 194 23 410 48 74 8 145 17 157 20 25 6 28 -2 38 -90 3 -33 8 -78 11 -100 2 -22 8 -71 14 -110 5 -38 12 -99 16 -135 19 -210 98 -379 192 -409 17 -5 32 -7 32 -4 0 3 -20 27 -45 55 -82 91 -119 194 -139 388 -19 186 -48 408 -56 428 -10 23 -37 26 -125 15z" +
        "M20345 24675 c-49 -7 -154 -20 -232 -30 -123 -16 -143 -21 -143 -36 0 -10 3 -20 8 -22 4 -3 50 1 102 8 264 35 380 48 384 44 2 -2 7 -29 11 -59 3 -30 10 -84 15 -120 5 -36 12 -94 15 -130 17 -173 62 -314 108 -340 33 -18 43 -9 23 22 -48 74 -77 205 -106 493 -19 177 -20 180 -60 181 -19 1 -75 -4 -125 -11z" +
        "M20285 24544 c-11 -1 -82 -10 -157 -20 -76 -9 -138 -19 -138 -21 0 -2 4 -12 10 -22 8 -17 16 -17 102 -5 51 7 122 15 158 19 36 3 72 8 82 11 20 6 22 -2 43 -186 25 -209 46 -283 84 -293 26 -6 26 -4 2 64 -17 51 -25 99 -51 309 -20 160 -16 150 -69 149 -25 -1 -55 -3 -66 -5z" +
        "M20136 24404 c-49 -8 -92 -16 -95 -19 -2 -3 2 -12 11 -21 13 -13 26 -14 92 -5 120 17 113 20 120 -51 16 -151 27 -196 51 -212 27 -18 39 -20 31 -7 -8 12 -34 172 -42 251 -3 36 -8 68 -10 72 -7 11 -52 8 -158 -8z" +
        "M20143 24281 l-32 -6 45 -50 45 -50 -5 50 c-4 27 -10 52 -14 55 -4 3 -21 4 -39 1z"
    },
    {
      id: 1, description: 'Anular',
      d: "M17059 29427 c-182 -80 -299 -158 -438 -292 -81 -78 -188 -206 -220 -262 -9 -17 -12 -17 -56 -2 -43 16 -46 16 -55 -2 -12 -21 -9 -24 65 -49 l56 -19 34 53 c163 255 421 460 708 561 43 15 76 29 75 30 -2 1 -23 5 -48 9 -38 5 -58 0 -121 -27z" +
        "M16800 29402 c-107 -46 -192 -107 -283 -202 -80 -82 -177 -214 -177 -239 0 -25 21 -6 88 77 98 123 220 236 340 316 129 86 132 90 32 48z" +
        "M17285 29380 c-231 -60 -448 -187 -618 -364 -55 -58 -165 -208 -213 -291 -10 -17 -15 -17 -102 14 -89 32 -112 33 -112 6 0 -13 148 -73 205 -82 28 -4 32 -1 65 63 98 188 298 387 500 497 119 65 296 124 420 139 25 4 25 4 -7 21 -39 21 -48 20 -138 -3z" +
        "M17559 29296 c-2 -2 -35 -6 -74 -9 -384 -31 -759 -295 -944 -664 -17 -35 -35 -63 -39 -63 -4 0 -70 22 -147 50 -137 49 -154 51 -155 15 0 -9 300 -120 324 -120 5 0 32 46 60 101 65 126 124 209 210 296 201 203 456 323 731 345 l100 8 -31 22 c-17 12 -33 21 -35 19z" +
        "M17363 29145 c-234 -61 -429 -183 -572 -359 -94 -116 -146 -212 -204 -378 -5 -15 -15 -13 -85 17 -43 18 -132 51 -197 74 -92 32 -120 39 -127 29 -13 -22 -4 -30 52 -50 127 -45 248 -93 294 -114 28 -13 58 -24 67 -24 16 0 38 44 64 130 4 14 21 52 38 85 107 214 305 401 520 492 142 60 225 76 389 77 82 1 148 4 148 7 0 3 -8 13 -18 22 -30 28 -251 23 -369 -8z" +
        "M17400 29030 c-264 -66 -486 -233 -615 -465 -20 -35 -59 -126 -86 -202 -28 -76 -53 -141 -57 -145 -4 -4 -32 9 -62 28 -30 20 -80 47 -110 61 -52 23 -300 113 -314 113 -3 0 -6 -10 -6 -23 0 -20 14 -27 117 -62 134 -44 265 -106 338 -159 30 -22 53 -32 59 -26 5 5 35 81 66 170 73 202 123 290 228 401 126 133 275 220 452 265 96 24 329 25 403 1 26 -8 47 -11 47 -7 0 21 -39 52 -75 60 -71 17 -302 11 -385 -10z" +
        "M17410 28905 c-159 -44 -283 -114 -387 -221 -66 -67 -73 -81 -48 -90 10 -4 45 22 98 72 120 113 259 184 417 212 123 22 336 -4 431 -54 33 -17 35 -14 14 25 -37 72 -347 105 -525 56z" +
        "M17446 28790 c-123 -33 -236 -92 -236 -122 0 -26 17 -22 93 18 104 55 184 74 311 74 150 0 270 -36 380 -113 l29 -21 -7 30 c-11 41 -20 50 -91 83 -149 71 -334 90 -479 51z" +
        "M17470 28671 c-145 -39 -291 -148 -364 -273 -40 -69 -179 -469 -167 -481 22 -22 39 10 100 189 76 225 106 285 180 364 107 114 237 170 395 170 102 0 168 -16 260 -62 56 -28 162 -123 199 -178 l17 -25 -6 40 c-3 22 -15 54 -26 70 -44 65 -171 148 -277 181 -72 23 -234 25 -311 5z" +
        "M17063 28546 c-56 -63 -103 -163 -174 -366 -33 -96 -84 -241 -112 -322 -28 -82 -54 -148 -58 -148 -3 0 -16 23 -28 51 -49 120 -170 248 -289 308 -60 30 -251 101 -271 101 -7 0 -11 -9 -9 -21 2 -17 26 -29 119 -63 151 -54 216 -94 300 -184 69 -74 108 -142 140 -242 12 -41 22 -55 35 -55 16 0 42 66 155 387 137 387 157 435 225 523 31 41 34 49 21 62 -12 13 -19 9 -54 -31z" +
        "M17509 28555 c-119 -32 -226 -107 -288 -203 -17 -26 -56 -120 -87 -207 -30 -88 -82 -234 -114 -325 -161 -457 -325 -924 -352 -1002 -38 -111 -62 -147 -118 -176 -102 -52 -223 12 -244 130 -7 38 10 91 155 503 78 222 61 356 -63 493 -53 59 -82 76 -97 58 -9 -11 -1 -24 42 -65 95 -90 140 -218 118 -331 -6 -30 -54 -176 -106 -324 -53 -150 -95 -285 -95 -305 0 -88 47 -163 125 -200 62 -29 128 -28 193 5 61 30 86 67 130 194 17 47 78 222 136 390 59 168 116 328 126 355 11 28 53 147 94 265 145 417 167 476 202 527 44 64 105 115 181 151 53 24 69 27 177 28 120 0 143 8 111 34 -21 17 -167 21 -226 5z" +
        "M16884 28495 c-20 -31 -75 -173 -134 -350 -24 -71 -47 -134 -51 -139 -4 -5 -40 21 -80 57 -40 37 -104 83 -143 104 -81 42 -326 134 -338 127 -14 -9 -9 -33 10 -43 9 -5 71 -28 137 -51 149 -52 242 -109 337 -205 40 -41 78 -75 84 -75 12 0 17 12 119 304 36 104 75 207 86 229 31 59 7 95 -27 42z" +
        "M17824 28488 c-4 -6 5 -19 22 -31 60 -42 127 -121 157 -185 29 -61 32 -76 33 -172 2 -97 3 -105 22 -108 18 -3 21 4 27 51 18 140 -41 294 -150 396 -64 59 -96 74 -111 49z" +
        "M17515 28426 c-22 -7 -51 -18 -64 -25 -42 -23 -112 -94 -133 -136 -31 -60 -328 -905 -328 -932 0 -26 31 -12 45 20 8 17 60 163 116 322 202 583 208 597 271 650 42 35 100 60 162 69 68 11 171 -23 230 -76 59 -51 98 -131 101 -205 2 -66 -5 -92 -124 -428 -45 -126 -84 -253 -87 -282 -12 -97 42 -209 125 -261 42 -27 293 -122 321 -122 6 0 10 10 8 21 -2 17 -26 29 -128 65 -152 54 -183 69 -222 113 -42 48 -60 96 -60 161 1 55 41 181 163 510 38 103 43 124 43 205 0 105 -18 156 -80 225 -93 102 -236 145 -359 106z" +
        "M17525 28291 c-71 -32 -96 -73 -163 -262 -344 -983 -495 -1407 -518 -1449 -57 -104 -192 -195 -308 -207 -31 -3 -56 -9 -56 -13 0 -4 18 -13 40 -20 36 -12 48 -11 98 5 107 33 223 129 272 225 12 23 66 169 120 324 363 1033 451 1280 470 1306 42 59 129 83 195 56 51 -21 72 -42 97 -90 30 -58 22 -94 -86 -398 -116 -326 -125 -367 -102 -469 27 -122 93 -217 190 -274 39 -23 357 -145 379 -145 4 0 7 10 7 23 0 20 -15 27 -127 66 -71 24 -156 56 -190 72 -131 58 -213 175 -221 317 -5 85 -3 92 142 504 48 136 66 200 66 238 0 152 -165 255 -305 191z" +
        "M17558 28170 c-17 -13 -38 -55 -66 -138 -46 -131 -52 -165 -25 -160 11 2 31 44 62 131 29 81 53 133 65 139 23 13 62 -3 70 -30 4 -12 -18 -89 -55 -193 -148 -422 -152 -436 -153 -534 0 -134 33 -232 116 -341 41 -55 148 -139 176 -139 35 0 22 32 -27 63 -104 67 -187 189 -212 311 -25 120 -11 190 100 505 55 158 101 301 101 318 0 70 -96 113 -152 68z" +
        "M16120 28026 c0 -17 8 -27 28 -34 15 -6 59 -22 99 -37 91 -33 149 -72 212 -141 90 -98 141 -249 127 -372 -6 -49 -83 -297 -107 -343 -10 -20 16 -48 31 -33 5 5 33 77 62 159 53 149 53 151 52 265 -1 136 -18 193 -90 302 -73 109 -155 166 -326 227 l-88 30 0 -23z" +
        "M16110 27900 c0 -15 11 -26 35 -36 38 -15 55 -12 55 11 0 16 -21 29 -62 39 -24 5 -28 3 -28 -14z" +
        "M18103 27828 c-12 -35 -47 -135 -78 -223 -80 -224 -82 -232 -65 -249 8 -8 47 -25 85 -39 39 -14 78 -28 88 -32 13 -6 17 -2 17 18 0 18 -7 28 -22 33 -13 3 -47 15 -76 26 -64 24 -65 4 15 228 54 152 82 275 65 292 -4 4 -18 -20 -29 -54z" +
        "M17957 27778 c-14 -40 -50 -146 -81 -234 -46 -133 -54 -167 -47 -192 14 -48 41 -88 72 -106 24 -14 242 -96 256 -96 2 0 3 10 1 22 -2 19 -22 30 -116 64 -62 23 -120 48 -128 55 -20 17 -44 63 -44 86 0 10 34 114 75 232 41 117 75 220 75 227 0 8 -9 14 -19 14 -15 0 -25 -17 -44 -72z" +
        "M16840 27673 c-23 -65 -89 -253 -147 -418 -189 -544 -182 -529 -237 -504 -38 18 -39 36 -9 121 20 58 25 80 16 89 -18 18 -28 5 -58 -76 -41 -114 -22 -171 62 -182 71 -9 82 9 189 312 53 149 104 295 114 325 16 49 92 263 134 379 21 57 20 71 -3 71 -15 0 -27 -24 -61 -117z" +
        "M16110 27762 c0 -13 17 -25 56 -42 114 -47 169 -121 168 -230 0 -56 -14 -102 -96 -336 -53 -149 -99 -291 -103 -315 -8 -57 19 -132 84 -231 64 -96 94 -123 165 -143 154 -45 329 30 395 169 44 90 192 529 182 539 -19 19 -36 -1 -59 -69 -92 -267 -156 -444 -173 -471 -48 -78 -147 -134 -239 -137 -124 -3 -223 56 -279 166 -56 110 -52 140 65 468 96 267 99 277 99 360 0 67 -5 93 -22 125 -34 64 -97 120 -162 144 -71 26 -81 26 -81 3z" +
        "M17392 27743 c-5 -10 -19 -45 -30 -78 -39 -115 -340 -971 -365 -1037 -34 -95 -83 -170 -150 -235 -45 -44 -55 -58 -46 -69 16 -19 42 -3 105 63 68 70 110 153 177 348 72 208 165 474 266 758 45 126 81 237 81 248 0 24 -26 25 -38 2z" +
        "M16110 27625 c0 -16 6 -25 16 -25 9 0 31 -15 50 -34 48 -48 45 -76 -26 -277 -47 -133 -60 -182 -60 -227 0 -37 4 -52 9 -42 5 8 41 107 81 220 79 227 87 275 50 335 -11 19 -40 43 -64 54 -54 26 -56 26 -56 -4z" +
        "M18116 27501 c-19 -62 -20 -64 -1 -77 37 -27 41 -15 27 91 l-7 50 -19 -64z" +
        "M16101 27458 l1 -63 14 34 c17 38 15 82 -3 89 -10 3 -13 -13 -12 -60z" +
        "M17225 26908 c-70 -200 -144 -394 -164 -433 -22 -43 -64 -99 -108 -144 -49 -52 -68 -78 -60 -83 19 -12 28 -10 62 17 66 50 155 187 195 300 77 217 147 414 172 490 17 50 34 93 38 98 3 4 25 -30 48 -77 66 -133 188 -246 327 -304 95 -40 360 -130 368 -125 4 2 7 12 7 22 0 12 -15 22 -47 33 -181 60 -356 130 -403 160 -117 75 -219 212 -260 348 -30 102 -35 93 -175 -302z" +
        "M17366 26942 c-3 -5 -39 -107 -81 -228 -87 -249 -134 -346 -205 -426 -28 -31 -50 -59 -50 -62 0 -3 13 -6 28 -6 23 0 37 12 79 68 70 92 99 156 178 382 38 107 70 197 72 199 1 2 32 -24 67 -57 96 -89 182 -135 395 -211 185 -66 211 -70 211 -36 0 5 -57 30 -127 54 -287 99 -371 145 -484 262 -66 70 -74 76 -83 61z" +
        "M17856 26881 c-3 -5 0 -14 8 -20 13 -11 250 -101 266 -101 9 0 23 31 16 38 -9 8 -251 92 -268 92 -9 0 -19 -4 -22 -9z" +
        "M17405 26688 c-20 -46 -105 -287 -105 -298 0 -6 8 -10 19 -10 21 0 42 43 86 180 15 47 30 88 32 93 2 4 16 -1 31 -11 69 -48 169 -95 302 -142 80 -28 156 -55 169 -61 19 -8 28 -6 40 5 21 22 12 28 -109 69 -198 67 -273 100 -353 153 -44 30 -84 54 -89 54 -4 0 -15 -15 -23 -32z" +
        "M17472 26513 c-5 -10 -15 -36 -22 -58 -17 -54 -86 -192 -116 -232 -31 -40 -30 -45 3 -41 33 4 81 77 132 203 18 44 35 81 36 83 2 2 54 -16 117 -41 207 -81 245 -92 263 -77 9 7 12 17 8 21 -4 4 -73 31 -153 59 -80 29 -166 63 -192 76 -57 29 -65 29 -76 7z" +
        "M17505 26280 c-25 -49 -45 -92 -45 -95 0 -3 9 -5 20 -5 18 0 70 65 70 88 0 4 6 16 13 25 11 16 17 16 90 -9 66 -22 83 -24 104 -15 14 7 24 14 21 16 -2 2 -54 22 -116 44 l-112 40 -45 -89z" +
        "M16680 26286 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0 -15 -2 -15 -4z" +
        "M17201 26241 c-27 -37 -26 -41 3 -41 30 0 60 42 43 59 -17 17 -24 14 -46 -18z"
    },
    {
      id: 2, description: 'Medio',
      d: "M12720 29925 c-232 -37 -418 -128 -588 -289 -126 -120 -236 -338 -301 -601 -26 -102 -30 -135 -27 -220 2 -55 7 -121 11 -147 8 -47 7 -48 -19 -48 -23 0 -27 -4 -24 -22 3 -20 8 -22 48 -19 25 1 48 7 51 13 3 5 0 37 -7 71 -44 217 -15 434 86 642 115 238 293 407 536 509 70 29 109 41 204 62 60 13 265 16 337 5 152 -23 310 -86 426 -168 39 -27 45 -30 31 -11 -50 65 -177 150 -276 183 -154 51 -325 65 -488 40z" +
        "M12745 29805 c-397 -55 -708 -344 -800 -743 -21 -88 -20 -122 3 -122 15 0 21 13 32 73 67 377 364 676 735 741 271 47 533 -21 743 -195 178 -147 277 -334 329 -619 l19 -105 47 2 c64 2 77 8 70 32 -5 18 -11 20 -43 15 l-37 -6 -23 118 c-28 151 -68 260 -132 364 -97 158 -240 285 -409 364 -109 50 -181 71 -299 86 -100 12 -118 12 -235 -5z" +
        "M12835 29693 c-312 -24 -569 -191 -701 -458 -101 -205 -115 -377 -54 -665 5 -25 12 -61 15 -80 3 -19 11 -61 17 -93 6 -32 9 -59 7 -62 -2 -2 -36 5 -74 16 -45 13 -106 21 -170 22 -97 2 -100 1 -103 -20 -3 -22 -1 -23 82 -23 89 0 196 -18 258 -44 21 -9 43 -16 49 -16 15 0 8 64 -32 280 -41 224 -49 297 -38 388 23 193 91 333 229 472 105 105 172 149 289 191 91 32 118 37 231 45 200 14 406 -60 569 -204 99 -89 186 -229 224 -363 15 -54 50 -232 62 -320 11 -76 9 -75 132 -55 118 19 123 21 123 41 0 23 -13 25 -96 10 -118 -21 -109 -26 -124 63 -44 270 -83 389 -168 514 -133 195 -361 334 -580 353 -37 4 -76 7 -87 9 -11 1 -38 1 -60 -1z" +
        "M12755 29563 c-16 -2 -59 -13 -95 -25 -189 -61 -341 -193 -426 -373 -81 -170 -88 -302 -31 -605 20 -107 44 -235 52 -285 9 -49 20 -108 25 -130 5 -21 7 -41 5 -43 -2 -2 -32 16 -67 39 -83 57 -184 95 -287 109 -103 14 -151 7 -151 -20 0 -18 7 -20 69 -20 78 0 176 -20 247 -50 54 -23 157 -95 193 -134 32 -34 51 -33 51 2 0 35 -51 324 -108 617 -24 126 -23 331 2 405 38 111 88 192 171 275 121 122 220 171 393 196 304 43 614 -153 711 -449 17 -53 33 -132 57 -277 5 -34 19 -108 35 -190 10 -53 12 -55 42 -55 17 0 65 7 107 15 41 8 106 21 144 28 69 12 83 21 68 44 -8 12 -91 2 -212 -26 -25 -6 -59 -11 -75 -11 l-30 0 -33 191 c-44 252 -54 287 -108 398 -38 76 -61 108 -129 177 -138 137 -305 205 -500 202 -49 0 -103 -3 -120 -5z" +
        "M12760 29440 c-62 -11 -159 -51 -217 -91 -59 -40 -143 -125 -143 -144 0 -33 26 -22 92 40 123 117 270 169 453 162 47 -2 60 1 60 13 0 8 -11 17 -25 21 -33 9 -163 9 -220 -1z" +
        "M13091 29397 c-18 -18 -14 -22 55 -63 72 -42 165 -135 206 -206 43 -72 65 -165 133 -563 9 -49 18 -100 20 -112 2 -13 6 -27 10 -33 8 -13 53 -13 108 -1 42 10 144 28 285 52 58 10 72 16 72 30 0 10 -3 20 -7 22 -5 3 -55 -4 -113 -14 -58 -11 -143 -26 -190 -34 -47 -9 -93 -18 -102 -21 -19 -6 -19 -6 -39 111 -6 39 -13 79 -15 90 -8 38 -46 250 -54 300 -26 150 -114 295 -232 378 -89 63 -122 79 -137 64z" +
        "M12780 29321 c-132 -25 -264 -130 -328 -261 -32 -67 -37 -85 -40 -171 -3 -69 2 -127 17 -210 12 -63 28 -152 36 -199 8 -47 28 -157 44 -245 16 -88 41 -230 56 -315 14 -85 32 -186 40 -225 7 -38 23 -124 35 -190 12 -66 28 -151 35 -190 34 -173 30 -217 -28 -273 -110 -106 -268 -46 -296 112 -90 502 -103 564 -130 623 -41 87 -100 146 -188 190 -61 30 -87 37 -154 41 -75 4 -81 3 -77 -14 3 -15 16 -20 71 -26 88 -10 181 -53 236 -109 46 -47 97 -142 106 -199 3 -19 16 -93 29 -165 14 -71 32 -175 41 -230 35 -203 54 -246 132 -292 94 -55 224 -25 287 65 51 72 48 109 -35 552 -11 58 -31 168 -44 245 -29 170 -105 588 -142 780 -18 95 -27 174 -27 246 -1 102 0 107 37 181 43 89 112 157 203 203 56 28 65 30 179 30 109 -1 125 -3 178 -28 72 -33 174 -133 206 -202 23 -48 47 -160 86 -385 34 -197 54 -284 73 -310 10 -14 40 -36 66 -49 47 -24 48 -24 129 -8 81 16 180 35 310 57 61 11 67 14 67 37 0 18 -4 23 -17 19 -21 -5 -64 -14 -113 -21 -19 -3 -55 -10 -80 -15 -25 -5 -58 -12 -73 -14 -16 -3 -61 -10 -101 -17 -84 -14 -118 -6 -150 38 -19 25 -27 64 -85 393 -38 213 -61 289 -110 360 -46 67 -140 141 -217 170 -65 24 -192 34 -264 21z" +
        "M12748 29177 c-122 -52 -196 -149 -212 -277 -6 -53 7 -152 60 -435 8 -44 18 -99 21 -122 6 -47 27 -73 44 -56 8 8 5 45 -10 124 -12 63 -28 155 -37 204 -8 50 -20 112 -25 138 -17 90 -11 173 16 232 51 107 150 168 275 169 89 0 154 -28 213 -93 64 -70 68 -85 133 -456 48 -274 63 -317 135 -379 82 -70 164 -79 364 -42 50 10 133 25 185 34 83 15 95 20 95 37 0 27 -6 26 -230 -15 -246 -45 -264 -46 -330 -14 -40 19 -109 91 -123 129 -9 24 -73 358 -108 565 -16 99 -84 191 -176 240 -55 30 -74 34 -148 37 -72 3 -94 0 -142 -20z" +
        "M12323 29073 c-8 -21 -22 -76 -30 -122 -15 -89 -12 -135 22 -326 58 -316 77 -421 91 -500 24 -138 43 -243 58 -325 8 -41 18 -93 21 -115 15 -95 27 -135 40 -135 8 0 17 5 20 10 3 6 -8 84 -25 174 -16 90 -42 231 -56 313 -39 228 -42 242 -88 493 -51 272 -56 398 -18 500 11 30 18 58 16 62 -12 19 -36 5 -51 -29z" +
        "M12814 29066 c-62 -20 -102 -55 -130 -113 -35 -71 -33 -91 62 -608 44 -238 83 -456 99 -545 8 -47 34 -186 57 -310 33 -173 43 -250 43 -331 0 -102 -1 -108 -38 -181 -46 -95 -128 -174 -213 -208 -59 -24 -77 -37 -68 -52 20 -31 152 30 227 105 98 99 158 259 142 382 -4 27 -20 122 -36 210 -16 88 -36 201 -45 250 -8 50 -22 124 -30 165 -15 84 -35 191 -58 325 -9 50 -29 164 -46 255 -91 499 -86 459 -62 512 11 25 36 58 58 74 32 25 46 29 100 29 55 0 67 -4 101 -31 21 -17 44 -44 52 -59 7 -16 31 -125 52 -244 61 -342 68 -372 101 -439 18 -35 52 -82 80 -108 83 -78 258 -141 275 -99 8 22 -9 31 -66 40 -60 9 -126 44 -179 95 -80 78 -101 133 -137 355 -9 55 -25 143 -35 195 -10 52 -24 123 -31 158 -7 38 -23 78 -40 101 -49 68 -156 103 -235 77z" +
        "M12809 28921 c-34 -35 -35 -43 -10 -181 10 -58 29 -161 41 -230 12 -69 35 -197 51 -285 16 -88 36 -198 44 -245 34 -190 37 -200 56 -200 24 0 24 8 -1 145 -18 97 -34 188 -85 475 -38 212 -57 320 -65 365 -4 28 -11 62 -15 77 -12 49 54 90 86 52 7 -8 20 -50 29 -92 13 -59 21 -78 35 -81 16 -3 17 2 12 55 -14 130 -42 174 -108 174 -31 0 -47 -7 -70 -29z" +
        "M11932 28797 c-9 -10 -5 -47 13 -153 14 -76 25 -141 25 -144 0 -3 -45 -4 -100 -2 -100 4 -100 4 -100 -19 0 -23 2 -24 98 -27 53 -2 108 -4 122 -5 22 -2 25 2 24 33 -1 59 -46 304 -59 318 -10 11 -14 11 -23 -1z" +
        "M12984 28610 c-11 -11 2 -95 42 -278 35 -160 140 -295 284 -365 120 -59 215 -70 374 -42 47 8 102 18 120 21 19 3 76 13 128 23 81 15 93 20 96 39 2 13 -2 22 -10 22 -13 0 -114 -18 -278 -50 -52 -10 -120 -21 -150 -24 -136 -15 -287 41 -390 144 -94 94 -115 149 -172 444 -13 69 -24 86 -44 66z" +
        "M13880 28130 c-47 -9 -118 -23 -158 -30 -78 -14 -93 -24 -71 -45 12 -12 24 -12 74 -1 33 8 107 21 165 31 133 22 130 21 130 45 0 25 -21 25 -140 0z" +
        "M11822 28128 c-20 -20 -1 -38 42 -38 142 0 310 -94 390 -218 49 -75 68 -129 90 -252 31 -165 46 -246 62 -340 33 -191 44 -210 119 -210 35 0 52 6 70 25 31 30 31 69 2 220 -17 86 -26 110 -40 113 -23 5 -22 -20 4 -164 19 -110 19 -117 3 -135 -9 -10 -27 -19 -39 -19 -24 0 -55 29 -55 51 0 19 -66 390 -90 509 -40 190 -132 323 -278 398 -102 52 -255 85 -280 60z" +
        "M12664 28126 c-3 -8 1 -50 10 -93 8 -43 33 -184 56 -313 23 -129 55 -307 71 -395 20 -103 28 -174 25 -200 -16 -107 -97 -205 -200 -244 -106 -40 -212 -19 -297 57 -86 77 -82 63 -174 587 -14 83 -34 168 -44 191 -44 99 -141 164 -244 164 -50 0 -58 -2 -55 -17 2 -12 14 -19 38 -21 130 -11 222 -97 244 -227 48 -274 91 -510 102 -550 27 -104 110 -192 216 -230 70 -25 195 -18 263 16 108 52 185 167 193 284 3 40 -6 114 -27 220 -31 167 -114 627 -126 705 -11 71 -37 103 -51 66z" +
        "M13055 28040 c-7 -11 5 -82 92 -545 33 -175 37 -219 37 -352 1 -136 2 -153 17 -153 13 0 20 15 28 63 21 119 23 103 -88 717 -17 90 -28 166 -26 168 2 2 23 -9 47 -26 63 -43 154 -87 221 -106 63 -18 235 -21 305 -5 42 9 257 49 316 58 32 5 40 10 41 30 2 26 14 26 -150 -3 -44 -8 -134 -24 -200 -36 -231 -42 -406 4 -564 146 -63 57 -66 59 -76 44z" +
        "M13995 27780 c-22 -4 -69 -12 -105 -19 -36 -6 -114 -20 -175 -31 -167 -30 -290 -23 -424 26 -81 29 -80 44 -21 -271 11 -60 28 -162 36 -225 9 -63 17 -117 19 -119 2 -2 11 -1 20 2 25 10 18 77 -41 404 -15 84 -26 155 -23 157 3 3 27 -2 54 -10 28 -9 102 -18 165 -21 112 -5 160 -1 335 32 44 8 113 21 153 27 65 11 72 15 72 35 0 24 -2 25 -65 13z" +
        "M11826 27751 c-11 -17 6 -41 30 -41 72 0 101 -42 125 -183 54 -319 66 -366 92 -350 12 7 3 74 -39 301 -37 203 -54 240 -120 268 -38 16 -80 18 -88 5z" +
        "M14000 27658 c-49 -10 -256 -48 -310 -56 -25 -4 -105 -7 -177 -6 -156 1 -145 16 -113 -148 30 -160 42 -277 35 -348 -16 -156 -59 -294 -126 -403 -16 -27 -28 -51 -25 -54 2 -3 17 1 33 9 49 23 121 191 149 346 19 110 17 222 -7 359 -11 65 -23 136 -26 157 l-6 39 61 -7 c64 -7 190 5 307 29 39 7 116 22 173 31 94 16 102 19 102 40 0 24 -8 26 -70 12z" +
        "M13000 27637 c0 -19 47 -295 59 -348 20 -85 13 -213 -15 -297 -32 -98 -68 -154 -147 -229 -69 -67 -119 -96 -220 -128 -56 -18 -65 -23 -45 -28 53 -15 101 -7 167 28 181 96 296 271 316 480 6 70 -2 133 -56 423 -18 96 -24 112 -40 112 -10 0 -19 -6 -19 -13z" +
        "M11839 27638 c-7 -24 -5 -44 7 -66 8 -15 14 -35 14 -46 0 -10 16 -102 35 -204 29 -151 41 -195 66 -236 l30 -51 -6 40 c-7 37 -15 85 -61 335 -8 47 -20 112 -25 145 -6 35 -17 65 -26 73 -15 11 -32 16 -34 10z" +
        "M14000 27535 c-30 -7 -71 -16 -90 -19 -19 -3 -89 -14 -155 -26 -66 -11 -141 -20 -166 -20 -64 0 -69 -10 -53 -100 23 -134 27 -238 10 -350 -14 -103 -62 -261 -93 -309 -15 -23 -15 -24 8 -18 13 4 27 7 30 7 8 0 41 77 62 145 46 145 60 351 33 490 -8 39 -12 73 -10 77 3 4 26 10 52 13 26 4 88 13 137 21 50 8 106 17 125 20 19 3 61 12 93 19 67 16 64 15 77 15 12 0 14 37 3 43 -5 2 -33 -1 -63 -8z" +
        "M13870 27388 c-85 -16 -165 -31 -178 -34 -23 -5 -23 -5 -16 -132 7 -140 -9 -276 -45 -392 -11 -35 -19 -65 -18 -67 2 -1 15 8 31 20 46 37 68 146 72 357 l3 175 38 6 c166 27 289 53 295 63 13 19 9 36 -9 35 -10 -1 -88 -15 -173 -31z" +
        "M13950 27279 c-25 -5 -69 -14 -97 -20 -29 -6 -53 -16 -54 -22 0 -7 -1 -50 -1 -97 0 -47 -6 -121 -13 -165 -8 -43 -12 -81 -10 -83 2 -2 15 9 29 24 26 31 34 71 41 214 l4 84 78 13 c49 9 83 20 91 30 25 35 15 38 -68 22z" +
        "M13932 27148 c-12 -16 -21 -89 -10 -82 8 5 48 79 48 89 0 10 -28 5 -38 -7z" +
        "M13305 27038 c-2 -7 -6 -30 -10 -52 -16 -95 -73 -211 -170 -342 -15 -21 -15 -22 11 -20 35 1 86 64 137 167 38 77 81 224 72 246 -6 17 -34 17 -40 1z" +
        "M12083 27024 c-8 -21 33 -107 79 -165 47 -60 143 -124 225 -149 71 -22 138 -26 148 -10 12 20 -16 38 -58 39 -124 0 -267 102 -336 240 -32 62 -47 74 -58 45z" +
        "M13094 26809 c-49 -75 -114 -147 -169 -188 l-30 -22 45 5 c39 5 53 14 101 65 31 32 73 86 94 120 34 56 36 64 22 78 -13 14 -20 8 -63 -58z"
    },
    {
      id: 3, description: 'Índice',
      d: "M8165 28633 c-263 -136 -456 -425 -494 -740 -11 -90 -7 -116 18 -111 12 3 18 25 27 103 12 113 25 164 61 250 88 209 273 402 465 485 32 14 58 28 58 33 0 17 -88 5 -135 -20z" +
        "M7955 28631 c-22 -10 -68 -47 -101 -82 -224 -233 -339 -558 -305 -863 6 -58 19 -128 27 -156 19 -63 14 -80 -23 -81 -15 0 -98 -19 -183 -43 -145 -40 -155 -44 -158 -68 l-3 -25 83 24 c159 46 272 73 305 73 22 0 36 6 40 16 3 9 -5 60 -18 113 -21 82 -24 119 -24 261 1 151 3 173 29 260 65 222 187 409 350 536 55 44 64 54 45 54 -13 0 -42 -9 -64 -19z" +
        "M8365 28586 c-374 -121 -611 -482 -574 -876 5 -47 15 -110 24 -140 70 -248 102 -365 100 -368 -1 -1 -45 3 -98 10 -127 16 -236 2 -437 -55 -134 -38 -155 -47 -158 -66 -2 -11 2 -21 9 -21 6 0 92 23 191 51 170 48 185 51 296 50 76 0 135 -5 167 -15 81 -25 89 -23 81 23 -3 22 -22 95 -42 163 -90 313 -88 303 -88 448 0 148 13 214 65 328 66 146 208 298 344 369 80 41 193 80 253 86 45 5 48 13 11 26 -44 15 -63 14 -144 -13z" +
        "M7683 28504 c-74 -52 -132 -146 -191 -314 -61 -173 -81 -373 -59 -567 l9 -71 -59 -16 c-149 -42 -168 -50 -171 -74 -3 -20 0 -23 15 -18 21 7 95 27 188 52 84 23 84 22 69 101 -18 92 -17 289 1 395 30 170 97 329 198 471 31 42 55 77 54 77 -2 0 -26 -16 -54 -36z" +
        "M8535 28502 c-58 -6 -151 -34 -220 -66 -178 -83 -310 -234 -376 -431 -21 -61 -24 -88 -24 -215 l1 -145 73 -255 c89 -310 113 -401 108 -407 -3 -2 -27 10 -53 27 -27 17 -85 43 -129 57 -67 22 -99 26 -195 27 -106 0 -127 -3 -260 -41 -80 -23 -164 -46 -187 -53 -32 -8 -43 -16 -43 -30 0 -32 0 -32 260 46 118 35 280 43 374 18 76 -20 167 -66 230 -115 52 -42 64 -46 71 -26 5 10 -51 220 -181 682 -38 135 -40 293 -5 405 34 112 85 195 170 281 129 128 263 188 441 196 58 3 124 0 148 -5 23 -5 42 -9 42 -7 0 1 -18 15 -40 30 -33 23 -51 28 -107 29 -38 0 -81 0 -98 -2z" +
        "M8538 28380 c-162 -28 -307 -116 -399 -243 -27 -37 -28 -42 -13 -56 14 -15 17 -14 32 9 9 14 45 55 81 91 102 102 240 159 383 159 71 0 94 13 63 37 -20 14 -73 15 -147 3z" +
        "M8776 28341 c-8 -12 6 -21 88 -61 96 -46 196 -146 241 -243 19 -40 64 -180 100 -312 91 -332 82 -310 136 -309 24 0 59 7 77 15 29 12 31 16 20 32 -12 17 -16 17 -57 2 -25 -8 -48 -12 -52 -8 -4 5 -12 26 -17 48 -20 86 -125 452 -143 502 -19 50 -90 151 -162 230 -61 67 -212 135 -231 104z" +
        "M8560 28259 c-119 -17 -202 -61 -283 -148 -122 -132 -154 -282 -103 -476 24 -89 55 -202 162 -585 29 -107 61 -222 70 -255 9 -33 24 -87 34 -120 10 -33 37 -130 60 -215 23 -85 56 -202 72 -260 21 -74 29 -119 26 -152 -9 -86 -85 -152 -174 -150 -80 1 -143 55 -169 145 -8 29 -46 166 -85 306 -39 139 -75 263 -81 275 -26 50 -94 128 -136 156 -136 89 -230 93 -491 21 -101 -28 -185 -51 -188 -51 -6 0 -5 -36 1 -42 3 -3 83 18 178 45 147 43 185 50 262 51 77 1 98 -3 147 -26 67 -31 140 -96 173 -152 13 -23 62 -178 109 -345 54 -194 94 -317 110 -341 29 -45 113 -90 167 -90 122 0 222 97 220 215 0 29 -20 123 -46 216 -25 90 -66 238 -91 329 -25 91 -52 185 -59 210 -8 25 -55 196 -106 380 -50 184 -102 373 -116 420 -58 202 -31 338 93 471 140 149 401 167 562 39 97 -78 142 -163 195 -375 31 -126 100 -362 112 -385 25 -49 77 -81 132 -82 69 -1 165 29 161 50 -4 20 -28 20 -110 1 -97 -22 -140 11 -172 133 -64 243 -140 497 -162 539 -85 169 -289 275 -474 248z" +
        "M7361 28106 c-49 -108 -55 -140 -55 -309 l-1 -158 -42 -11 c-36 -9 -43 -14 -43 -35 0 -16 5 -23 13 -20 6 2 36 10 65 17 28 8 52 19 53 24 0 6 0 76 -1 156 -1 138 12 244 40 350 18 66 3 59 -29 -14z" +
        "M8545 28126 c-111 -28 -194 -97 -241 -200 -42 -92 -38 -155 20 -361 26 -93 60 -213 74 -265 20 -69 32 -96 45 -98 24 -5 22 25 -7 128 -109 384 -119 423 -113 486 15 194 216 323 399 254 66 -24 121 -69 152 -123 14 -23 59 -166 101 -317 42 -151 83 -289 90 -306 19 -45 82 -112 129 -137 54 -29 195 -31 274 -4 54 19 57 23 43 46 -7 11 -18 10 -62 -5 -119 -41 -240 -18 -304 58 -39 46 -44 59 -130 368 -42 151 -87 293 -100 316 -72 123 -237 195 -370 160z" +
        "M8565 28001 c-89 -22 -165 -118 -165 -209 0 -13 41 -170 91 -350 49 -180 119 -433 155 -562 36 -129 78 -280 93 -335 71 -248 102 -364 111 -417 30 -161 -72 -359 -226 -438 -27 -14 -50 -34 -52 -43 -6 -29 40 -20 104 21 136 88 217 232 223 397 2 65 -6 95 -214 840 -59 209 -115 414 -126 455 -11 41 -38 138 -61 215 -22 77 -42 169 -45 205 -4 57 -2 69 19 99 41 60 71 76 147 76 59 0 72 -4 104 -28 20 -15 41 -36 46 -46 9 -16 132 -446 152 -534 39 -164 195 -300 359 -314 67 -6 70 -5 70 16 0 16 -6 21 -27 21 -77 0 -182 44 -249 103 -44 39 -114 159 -114 196 0 13 -7 38 -50 186 -10 33 -35 123 -56 200 -34 123 -43 145 -77 182 -56 61 -131 83 -212 64z" +
        "M8053 27962 c-9 -21 -18 -80 -20 -137 -6 -110 0 -142 82 -435 45 -157 128 -456 212 -762 38 -140 49 -168 65 -168 11 0 18 7 18 18 0 16 -144 545 -285 1042 -59 212 -64 288 -29 441 4 19 1 28 -11 32 -12 5 -20 -3 -32 -31z" +
        "M8595 27883 c-28 -7 -62 -41 -69 -68 -7 -26 -6 -27 88 -370 157 -565 199 -711 207 -720 6 -5 14 -4 21 3 10 10 7 33 -14 110 -38 144 -184 669 -224 807 -39 138 -42 170 -15 185 53 28 73 7 106 -113 12 -45 20 -58 33 -55 20 4 20 21 -3 110 -24 90 -67 127 -130 111z" +
        "M9341 27696 c-2 -22 41 -146 51 -146 6 0 8 10 4 25 -8 32 -54 133 -55 121z" +
        "M7685 27640 c-8 -13 -2 -43 36 -180 16 -58 29 -108 29 -112 0 -5 -34 -8 -75 -8 -53 0 -112 -10 -207 -35 -266 -70 -258 -67 -258 -92 0 -27 -9 -29 190 28 164 46 180 49 299 54 51 2 97 7 101 12 4 4 -5 51 -20 103 -15 52 -36 126 -46 164 -19 67 -35 89 -49 66z" +
        "M8746 27552 c-5 -8 37 -166 75 -281 48 -147 153 -260 302 -325 69 -30 77 -31 202 -30 103 1 142 5 190 21 62 21 70 29 56 51 -7 11 -21 10 -77 -8 -196 -61 -374 -20 -514 120 -78 78 -107 134 -150 294 -17 66 -36 130 -41 143 -8 22 -33 31 -43 15z" +
        "M9495 27108 c-59 -21 -52 -55 9 -44 40 8 49 17 40 40 -7 18 -8 18 -49 4z" +
        "M8478 27048 c-5 -11 64 -278 136 -528 100 -346 110 -387 110 -455 1 -85 -24 -145 -83 -204 -57 -57 -114 -83 -192 -89 -114 -8 -214 43 -275 139 -14 23 -58 160 -108 339 -51 182 -94 317 -111 345 -32 56 -91 100 -158 120 -72 21 -97 19 -242 -21 -72 -20 -157 -44 -190 -53 -48 -13 -61 -21 -63 -39 -2 -12 1 -22 6 -22 9 0 185 49 308 85 136 40 258 -3 312 -111 10 -22 47 -140 81 -264 99 -356 108 -383 145 -431 124 -163 356 -181 510 -39 62 57 98 136 104 226 5 74 8 59 -123 529 -62 222 -94 340 -113 410 -18 67 -42 95 -54 63z" +
        "M8876 26991 c-8 -12 -4 -25 149 -571 67 -239 73 -271 70 -372 -2 -62 1 -81 12 -85 25 -10 33 18 33 113 0 73 -7 115 -34 215 -19 68 -64 232 -100 363 -37 131 -65 241 -63 242 2 2 39 -14 83 -36 174 -88 338 -96 552 -29 34 11 37 16 24 37 -7 10 -30 7 -107 -13 -60 -15 -128 -25 -170 -25 -132 0 -278 52 -382 136 -43 35 -58 41 -67 25z" +
        "M7638 26963 c-19 -23 2 -32 82 -34 141 -4 256 -55 349 -155 32 -34 70 -86 85 -116 14 -29 53 -152 86 -273 102 -371 106 -382 142 -401 65 -33 138 9 138 80 0 61 -63 270 -82 274 -9 2 -19 -1 -22 -6 -3 -5 11 -64 30 -131 19 -68 34 -132 32 -144 -4 -27 -51 -46 -76 -31 -17 11 -35 66 -132 413 -60 217 -66 231 -120 311 -65 96 -171 170 -295 207 -68 21 -202 24 -217 6z" +
        "M7373 26902 c-113 -32 -123 -37 -123 -59 0 -19 4 -23 18 -19 73 23 155 46 200 57 55 13 78 31 67 49 -9 15 -27 12 -162 -28z" +
        "M9510 26734 c-109 -29 -244 -31 -353 -5 -67 16 -79 16 -84 4 -3 -7 27 -130 66 -272 39 -142 71 -272 71 -288 0 -37 27 -65 40 -42 14 22 3 75 -63 316 -33 123 -58 226 -54 229 3 3 44 1 91 -6 51 -7 114 -9 156 -5 64 6 246 47 256 58 10 9 -9 37 -25 36 -9 -1 -54 -12 -101 -25z" +
        "M9545 26618 c-41 -12 -117 -22 -183 -25 -85 -4 -115 -9 -119 -20 -3 -8 13 -84 37 -169 23 -85 46 -184 52 -222 22 -147 -13 -365 -78 -494 -14 -27 -24 -52 -22 -54 2 -2 21 10 41 27 29 24 41 46 61 109 40 125 49 200 43 344 -5 112 -12 152 -46 276 -23 80 -41 146 -41 147 0 2 22 3 49 3 54 0 187 23 269 46 39 12 52 20 52 35 0 24 -20 23 -115 -3z" +
        "M8864 26596 c-7 -19 -3 -39 26 -141 69 -238 82 -300 82 -385 0 -121 -20 -195 -79 -288 -99 -157 -246 -246 -428 -259 -44 -3 -107 0 -140 6 -154 29 -309 145 -379 284 -37 74 -45 99 -137 436 -29 106 -60 202 -69 213 -16 20 -17 20 -146 -16 -239 -67 -244 -68 -244 -86 0 -28 9 -29 93 -4 182 54 223 65 247 67 24 2 26 -5 102 -278 91 -329 122 -399 218 -495 116 -117 249 -170 426 -170 165 0 282 48 406 168 104 100 162 225 174 373 8 95 -3 158 -67 384 -50 179 -71 227 -85 191z" +
        "M7507 26550 c-93 -27 -172 -52 -177 -55 -12 -7 1 -47 14 -42 6 2 47 14 91 27 44 13 125 36 179 52 84 24 105 27 133 17 59 -19 74 -50 138 -283 34 -125 65 -221 73 -224 33 -12 30 19 -13 173 -81 292 -92 322 -131 352 -58 44 -111 41 -307 -17z" +
        "M9610 26509 c-14 -6 -63 -17 -110 -26 -47 -9 -90 -19 -96 -24 -7 -6 -1 -44 19 -121 27 -101 30 -129 31 -268 0 -88 -5 -172 -12 -195 l-11 -40 29 34 c18 21 32 51 36 79 12 79 -3 301 -25 382 -11 41 -21 80 -21 86 0 7 17 15 38 19 20 3 67 13 105 22 59 14 67 19 67 40 0 25 -11 27 -50 12z" +
        "M9590 26377 c-41 -14 -40 -12 -18 -177 l14 -105 17 55 c12 41 14 65 7 95 -15 65 -12 85 14 94 19 8 36 29 36 47 0 7 -35 3 -70 -9z" +
        "M7590 26321 c-14 -5 -69 -21 -123 -36 -74 -21 -97 -32 -92 -42 3 -8 5 -18 5 -23 0 -4 21 -2 48 6 100 31 195 55 199 50 3 -3 33 -106 68 -230 34 -123 76 -254 93 -291 85 -182 260 -326 448 -371 107 -25 288 -27 378 -4 199 51 356 181 455 378 17 34 29 67 26 72 -15 25 -37 4 -77 -72 -53 -103 -172 -227 -263 -274 -121 -63 -187 -79 -330 -79 -101 0 -142 4 -185 19 -195 67 -333 186 -414 356 -16 36 -60 173 -96 305 -63 226 -68 240 -91 242 -13 1 -35 -2 -49 -6z" +
        "M7515 26173 c-11 -2 -41 -11 -66 -19 -39 -12 -46 -18 -42 -34 6 -24 1 -24 75 -4 33 8 61 14 63 12 1 -2 24 -79 50 -173 60 -217 88 -278 150 -333 55 -48 68 -48 32 0 -50 66 -88 161 -143 357 -61 216 -56 208 -119 194z" +
        "M9222 26022 c-5 -9 -9 -33 -10 -52 -4 -59 -40 -177 -78 -253 -44 -86 -71 -125 -138 -194 -28 -29 -47 -53 -44 -53 3 0 27 9 51 19 106 46 231 282 252 477 5 50 4 62 -9 67 -9 4 -19 -1 -24 -11z" +
        "M7450 26018 c0 -16 80 -161 86 -156 2 3 -6 40 -18 84 -19 66 -27 80 -45 82 -14 2 -23 -2 -23 -10z" +
        "M8003 25893 c-9 -24 34 -97 93 -158 34 -34 81 -67 130 -91 71 -36 84 -39 163 -39 70 0 86 3 86 15 0 11 -19 17 -80 23 -144 13 -283 105 -346 228 -21 42 -36 49 -46 22z"
    },
    {
      id: 4, description: 'Pulgar',
      d: "M3945 16405 c-120 -43 -188 -82 -253 -145 -225 -220 -324 -483 -309 -826 19 -431 251 -928 679 -1455 83 -103 108 -149 108 -202 0 -37 32 -78 45 -57 12 19 -5 117 -28 162 -12 24 -61 90 -108 148 -339 416 -556 833 -630 1212 -34 168 -31 385 5 523 48 183 145 347 276 469 75 69 128 105 219 151 39 20 71 38 71 40 0 6 5 8 -75 -20z" +
        "M4245 16401 c-216 -42 -362 -118 -501 -263 -104 -107 -168 -222 -215 -383 -21 -71 -24 -101 -24 -255 1 -181 9 -238 58 -405 94 -321 325 -724 609 -1060 32 -38 71 -95 86 -125 24 -49 27 -67 28 -163 0 -153 31 -170 50 -28 14 108 -32 229 -131 346 -24 28 -86 108 -138 178 -270 361 -435 697 -503 1027 -27 130 -25 350 4 460 89 335 337 563 682 628 113 21 316 20 449 -2 328 -54 668 -188 1042 -412 59 -35 111 -64 115 -64 13 0 -174 124 -306 203 -294 176 -553 272 -860 318 -153 23 -323 23 -445 0z" +
        "M4254 16280 c-182 -36 -310 -105 -427 -230 -84 -89 -144 -194 -177 -310 -29 -99 -36 -327 -14 -445 66 -354 295 -786 627 -1180 32 -38 70 -90 85 -114 57 -97 78 -256 47 -370 -10 -40 -11 -58 -3 -68 18 -22 26 -15 43 42 38 131 26 263 -34 387 -17 35 -71 112 -120 172 -617 747 -785 1450 -439 1840 240 270 653 323 1172 151 200 -66 452 -185 665 -314 57 -35 131 -76 163 -91 60 -28 222 -60 235 -46 12 11 -39 46 -67 46 -36 0 -119 24 -160 45 -19 10 -100 56 -180 103 -137 81 -387 206 -468 236 -20 7 -75 28 -122 45 -278 105 -605 145 -826 101z" +
        "M4280 16165 c-146 -30 -249 -84 -351 -185 -89 -88 -148 -195 -174 -316 -25 -110 -17 -304 19 -440 33 -131 84 -264 103 -271 29 -11 30 19 2 88 -114 279 -128 541 -39 742 30 67 103 161 162 207 128 102 266 144 468 144 190 -1 384 -42 615 -133 101 -39 115 -41 115 -16 0 39 -337 151 -540 181 -118 17 -294 16 -380 -1z" +
        "M4365 16054 c-11 -3 -47 -9 -80 -15 -97 -18 -201 -73 -271 -143 -211 -211 -206 -563 15 -1000 91 -180 217 -376 242 -376 10 0 19 5 19 11 0 6 -39 73 -86 148 -146 231 -239 443 -279 630 -20 97 -20 281 1 352 53 177 175 288 370 335 46 11 110 17 192 17 110 -1 122 1 122 17 0 12 -11 19 -37 24 -41 6 -175 6 -208 0z" +
        "M4680 16025 c-7 -9 -10 -18 -6 -22 3 -4 55 -19 114 -34 248 -63 504 -177 822 -369 155 -93 248 -125 395 -136 81 -6 229 12 289 35 29 12 29 12 9 26 -19 13 -31 13 -100 -1 -56 -12 -109 -15 -188 -12 -146 7 -231 36 -400 138 -307 184 -599 314 -824 365 -36 9 -73 18 -82 21 -9 2 -22 -3 -29 -11z" +
        "M3348 15907 c-54 -97 -63 -124 -79 -236 -11 -81 -8 -320 6 -401 35 -197 100 -395 200 -606 132 -279 249 -453 425 -634 l131 -135 -79 100 c-301 380 -512 775 -598 1124 -74 295 -67 557 20 796 14 38 24 71 22 72 -1 2 -23 -34 -48 -80z" +
        "M5273 15945 c-7 -19 -20 -10 122 -84 66 -35 179 -99 252 -143 144 -86 200 -109 313 -128 73 -12 186 -8 230 8 19 7 20 10 6 21 -10 9 -57 14 -148 15 -161 3 -202 16 -383 126 -134 82 -355 200 -374 200 -6 0 -14 -7 -18 -15z" +
        "M4330 15921 c-203 -39 -331 -181 -334 -371 -1 -60 2 -75 14 -75 11 0 17 17 22 67 22 197 128 304 331 334 217 32 545 -48 875 -215 34 -17 67 -29 72 -26 29 18 2 40 -112 95 -267 127 -488 190 -693 195 -71 2 -150 0 -175 -4z" +
        "M4315 15786 c-117 -36 -172 -99 -195 -223 -10 -57 -9 -82 6 -195 9 -70 72 -245 129 -358 94 -187 240 -404 399 -594 169 -200 256 -424 256 -658 0 -86 -26 -238 -54 -319 -21 -61 -22 -68 -8 -74 22 -8 37 15 60 93 75 252 51 525 -64 756 -44 88 -76 134 -199 286 -343 425 -531 856 -475 1090 16 68 67 121 141 146 63 22 250 24 333 5 34 -8 52 -9 60 -1 17 17 -14 38 -74 48 -76 14 -267 12 -315 -2z" +
        "M4790 15724 c0 -16 12 -22 160 -75 112 -40 368 -168 530 -266 224 -135 354 -174 575 -174 168 -1 235 12 393 74 55 22 60 28 39 46 -10 8 -34 4 -94 -19 -117 -42 -203 -57 -335 -58 -200 -2 -371 54 -598 194 -166 102 -367 200 -544 265 -89 33 -126 37 -126 13z" +
        "M4357 15671 c-87 -18 -119 -67 -119 -183 2 -178 103 -422 295 -710 66 -98 95 -125 111 -100 2 4 -36 68 -85 142 -162 243 -268 490 -276 645 -8 145 19 169 192 168 84 0 134 -7 214 -27 205 -53 456 -163 686 -302 196 -118 290 -159 448 -196 131 -30 344 -30 476 1 123 28 205 61 209 84 5 23 -17 22 -102 -7 -116 -40 -210 -55 -346 -55 -247 0 -384 46 -700 234 -388 230 -781 350 -1003 306z" +
        "M5424 15589 c-3 -6 0 -17 8 -25 30 -30 235 -145 308 -173 135 -52 372 -79 415 -48 36 27 7 37 -101 37 -178 0 -301 39 -493 155 -113 67 -125 72 -137 54z" +
        "M4380 15553 c-24 -9 -27 -21 -23 -76 16 -217 173 -518 453 -868 137 -172 200 -271 251 -396 25 -64 37 -83 52 -83 28 0 18 41 -41 164 -58 120 -99 185 -182 286 -100 122 -223 289 -280 380 -109 174 -210 422 -210 515 0 28 4 33 28 38 35 7 163 -8 243 -28 162 -41 349 -119 539 -225 112 -62 140 -71 140 -45 0 21 -68 62 -255 155 -179 88 -315 138 -456 168 -92 19 -229 27 -259 15z" +
        "M4501 15431 c-37 -24 81 -292 217 -496 44 -66 133 -185 197 -265 65 -80 138 -181 164 -225 190 -322 240 -695 141 -1047 l-18 -68 22 0 c19 0 26 9 39 58 57 197 67 433 27 622 -28 137 -69 259 -121 362 -60 119 -98 175 -224 333 -136 170 -227 301 -289 417 -59 109 -117 251 -108 260 9 9 157 -29 277 -73 105 -38 338 -155 444 -223 164 -106 329 -175 513 -216 101 -23 311 -34 413 -23 61 7 70 11 70 28 0 17 -6 20 -35 18 -83 -5 -289 -4 -339 2 -92 11 -276 63 -364 102 -45 20 -136 69 -202 109 -296 177 -503 268 -732 318 -84 19 -74 18 -92 7z" +
        "M6322 15419 c-82 -36 -22 -60 62 -25 32 13 37 18 26 31 -16 19 -35 18 -88 -6z" +
        "M4014 15316 c-11 -28 55 -211 130 -362 107 -214 210 -368 421 -629 96 -120 144 -208 181 -335 25 -86 28 -109 27 -240 0 -128 -4 -155 -27 -230 -35 -109 -35 -114 -10 -118 18 -3 24 7 43 65 36 110 53 268 41 376 -24 216 -82 341 -261 562 -228 282 -405 584 -482 819 -31 96 -50 125 -63 92z" +
        "M4680 15254 c0 -47 147 -280 289 -457 149 -187 199 -259 257 -370 52 -101 114 -259 114 -291 0 -22 29 -41 42 -28 16 16 -38 181 -101 310 -63 127 -104 189 -246 367 -61 77 -132 169 -157 205 -49 70 -128 202 -128 213 0 21 272 -110 455 -220 183 -110 273 -152 417 -198 126 -40 181 -45 176 -17 -2 12 -27 23 -93 40 -141 36 -278 96 -435 190 -190 114 -343 192 -462 236 -108 40 -128 43 -128 20z" +
        "M3206 15165 c15 -200 134 -506 257 -667 24 -32 21 -23 -55 132 -79 162 -147 345 -182 495 -26 110 -26 110 -20 40z" +
        "M5486 15122 c-9 -14 8 -27 71 -55 202 -90 480 -126 688 -90 166 28 365 97 365 127 0 27 -16 26 -87 -4 -38 -16 -117 -42 -174 -57 -95 -24 -121 -27 -289 -27 -172 -1 -193 1 -295 28 -60 15 -139 41 -174 57 -64 29 -96 36 -105 21z" +
        "M6615 15007 c-11 -6 -67 -27 -125 -48 -63 -22 -105 -42 -105 -50 0 -22 31 -22 103 1 114 37 162 61 162 81 0 24 -11 30 -35 16z" +
        "M4984 14996 c-6 -15 46 -91 132 -191 28 -33 75 -93 103 -132 52 -72 81 -91 81 -54 0 16 -70 116 -173 248 -29 36 -6 28 95 -34 155 -94 328 -162 528 -205 124 -27 434 -33 555 -12 146 26 304 71 366 104 23 13 25 40 3 40 -9 0 -57 -14 -105 -30 -331 -114 -688 -114 -1021 1 -119 41 -210 85 -391 192 -81 48 -152 87 -157 87 -6 0 -13 -6 -16 -14z" +
        "M6630 14883 c-111 -48 -289 -97 -403 -110 -34 -4 -126 -7 -204 -6 -132 1 -143 0 -143 -17 0 -15 11 -20 57 -26 126 -15 384 9 528 51 92 27 215 78 215 89 -1 29 -15 34 -50 19z" +
        "M3947 14833 c-15 -15 -4 -44 62 -153 92 -155 179 -279 317 -451 129 -162 165 -226 190 -336 21 -96 15 -243 -13 -326 -20 -57 -20 -58 -1 -68 16 -8 21 -5 33 23 27 66 47 195 41 268 -13 167 -60 274 -188 432 -163 201 -286 378 -382 551 -37 65 -45 74 -59 60z" +
        "M5400 14630 c0 -6 22 -55 50 -109 64 -128 123 -302 152 -444 19 -95 22 -144 22 -322 0 -152 -5 -231 -16 -285 -9 -41 -15 -76 -13 -78 2 -2 12 1 23 7 36 19 47 103 46 366 0 209 -3 253 -22 340 -27 121 -93 318 -134 398 -16 33 -27 61 -24 64 2 3 44 -7 93 -20 190 -55 242 -62 488 -61 255 0 329 11 523 76 81 28 98 37 100 56 2 12 1 22 -1 22 -2 0 -47 -14 -98 -31 -189 -62 -326 -84 -529 -84 -207 0 -358 24 -535 84 -95 33 -125 38 -125 21z" +
        "M4716 14573 c-14 -15 -2 -37 64 -120 78 -96 166 -260 199 -367 66 -216 65 -466 -3 -668 -24 -72 -23 -78 5 -78 20 0 49 84 75 216 18 97 18 296 0 399 -37 202 -128 394 -271 565 -41 49 -59 63 -69 53z" +
        "M6625 14494 c-73 -28 -289 -72 -410 -85 -144 -14 -349 -6 -478 21 -52 11 -67 7 -67 -15 0 -16 15 -21 116 -40 57 -10 141 -13 299 -11 191 3 235 7 338 29 181 39 244 63 250 92 5 29 5 29 -48 9z" +
        "M5350 14471 c0 -10 13 -47 29 -82 43 -98 78 -205 102 -320 31 -147 35 -411 9 -564 -10 -60 -21 -118 -25 -127 -5 -14 -2 -18 14 -18 26 0 35 19 50 105 20 120 21 136 22 307 0 141 -4 186 -26 291 -40 197 -126 427 -159 427 -9 0 -16 -8 -16 -19z" +
        "M4345 14420 c-7 -11 28 -61 134 -190 114 -138 171 -294 171 -466 0 -96 -18 -207 -45 -271 -15 -36 -10 -52 17 -53 21 0 66 165 74 274 11 137 -32 312 -106 436 -45 75 -205 274 -225 278 -7 2 -16 -2 -20 -8z" +
        "M6570 14348 c-209 -56 -509 -82 -693 -59 -67 8 -83 -2 -68 -42 5 -14 15 -54 21 -89 15 -87 19 -98 36 -98 24 0 26 21 9 99 -8 41 -14 76 -12 78 2 2 96 4 208 5 206 1 270 8 452 49 71 16 101 28 108 41 17 32 7 35 -61 16z" +
        "M5674 14316 c-3 -7 6 -56 20 -107 13 -52 30 -128 38 -169 15 -90 16 -471 0 -545 -6 -27 -9 -51 -7 -53 2 -2 12 1 24 7 31 17 36 64 36 321 0 189 -4 252 -18 320 -35 163 -59 240 -73 240 -8 0 -17 -6 -20 -14z" +
        "M6535 14215 c-22 -7 -78 -18 -124 -25 -51 -9 -85 -19 -88 -28 -12 -29 14 -31 125 -11 88 17 115 26 123 41 16 30 7 35 -36 23z" +
        "M5965 14150 c-4 -6 -4 -27 -1 -48 4 -20 9 -53 12 -72 3 -19 9 -126 12 -238 7 -197 8 -203 26 -189 15 11 20 30 24 94 4 71 -9 266 -24 374 l-5 37 103 7 c95 7 124 17 112 37 -8 12 -252 10 -259 -2z" +
        "M6430 14070 c-35 -9 -109 -17 -250 -29 l-75 -6 0 -47 c1 -27 2 -104 3 -173 l2 -126 26 18 25 18 -6 138 -7 137 59 0 c143 2 259 25 281 58 13 20 13 22 -4 21 -11 -1 -35 -5 -54 -9z" +
        "M5134 13996 c-3 -8 -1 -46 5 -85 23 -144 6 -355 -39 -501 -25 -81 -25 -82 1 -78 25 4 39 40 71 193 35 170 23 469 -19 483 -7 2 -16 -3 -19 -12z" +
        "M5389 13968 c0 -2 -2 -93 -3 -203 -2 -158 -7 -220 -23 -295 -12 -52 -24 -103 -28 -112 -6 -14 -2 -18 18 -18 23 0 27 6 41 68 31 138 37 195 37 339 -1 155 -3 192 -15 211 -6 11 -24 17 -27 10z" +
        "M5869 13773 c0 -92 -3 -189 -5 -215 -5 -51 3 -59 28 -30 12 13 17 50 22 147 7 165 -2 258 -26 263 -17 3 -18 -10 -19 -165z" +
        "M6320 13933 c-89 -7 -90 -7 -90 -68 0 -29 4 -56 8 -59 12 -7 40 28 44 56 2 15 11 25 28 28 28 6 70 31 70 42 0 5 -3 7 -7 6 -5 -1 -28 -3 -53 -5z"
    }
  ];

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <svg
        version="1.1"
        id="Layer_2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 2548.4 2650.2"
        style={{ enableBackground: "new 0 0 1274.2 1220.6" }}
        xmlSpace="preserve"
      >
        <g
          transform="translate(0.000000,2995.000000)  scale(0.1,-0.1)"
          fill="#000000"
          stroke="none"
        >
          {fingerPaths.map((finger) => (
            <path
              key={finger.id}
              fill={
                hoveredFingers[finger.id]
                  ? hoverColor // Si el mouse está sobre el dedo
                  : highlightedFingers.includes(String(finger.id))
                    ? darken(0.2, fingerOn) // Oscurecer si el dedo está marcado
                    : darken(0.3, fingerOff) // Si no, usar el color original
              }
              stroke={
                hoveredFingers[finger.id] &&
                  !highlightedFingers.includes(String(finger.id))
                  ? hoverColor
                  : "transparent"
              }
              strokeWidth={
                hoveredFingers[finger.id] &&
                  !highlightedFingers.includes(String(finger.id))
                  ? "50"
                  : "0"
              }
              onClick={() => handleFingerClick(String(finger.id))}
              onMouseEnter={() => handleMouseEnter(finger.id)}
              onMouseLeave={() => handleMouseLeave(finger.id)}
              d={finger.d}
              style={{ cursor: 'pointer' }}
            >
              <title>{finger.description}</title>
            </path>
          ))}

          <path
            fill={darken(0.3, fingerOff)}
            d="M13468 26117 c-41 -52 -94 -124 -118 -161 -38 -58 -45 -65 -59 -53
              -9 7 -43 37 -75 65 -63 55 -83 62 -93 36 -4 -11 5 -25 28 -43 18 -14 60 -50
              93 -78 32 -29 62 -53 67 -53 5 0 35 40 68 88 32 48 87 123 122 166 113 139
              105 126 71 126 -25 0 -40 -14 -104 -93z
              M13384 26190 c-12 -4 -38 -31 -58 -59 -21 -28 -42 -51 -47 -51 -6 0
              -40 23 -76 50 -63 48 -102 60 -110 35 -1 -5 14 -21 34 -35 20 -14 61 -44 89
              -67 29 -24 60 -43 68 -43 8 0 40 33 70 74 30 41 59 77 65 81 15 9 14 25 -1 24
              -7 0 -22 -4 -34 -9z
              M13641 26134 c-84 -92 -143 -174 -137 -189 9 -25 22 -17 67 38 24 28
              71 84 106 123 49 56 59 73 47 78 -26 10 -30 8 -83 -50z
              M12883 26144 c22 -23 154 -91 161 -84 14 14 -1 39 -31 51 -15 6 -37
              16 -48 22 -20 10 -91 20 -82 11z
              M13756 26078 c-72 -77 -149 -173 -204 -255 -59 -87 -152 -266 -152
              -291 0 -50 32 -16 90 96 76 147 169 278 284 400 67 72 85 96 75 103 -24 15
              -32 10 -93 -53z
              M12700 26111 c0 -5 51 -37 112 -71 151 -83 293 -188 419 -310 57 -55
              107 -100 111 -100 8 0 16 12 77 119 26 48 38 78 32 84 -18 18 -39 -1 -76 -67
              -20 -36 -40 -66 -44 -66 -4 0 -27 21 -51 46 -111 113 -320 272 -415 314 -27
              13 -67 31 -88 41 -41 20 -77 25 -77 10z
              M12502 26067 c-10 -11 1 -19 61 -42 231 -90 508 -283 663 -463 36
              -42 77 -89 90 -104 26 -30 39 -34 49 -17 17 27 -164 226 -314 347 -150 121
              -365 247 -466 273 -16 4 -39 11 -51 14 -12 4 -25 1 -32 -8z
              M13810 25958 c-50 -57 -120 -145 -155 -198 -97 -144 -196 -362 -235
              -517 -6 -24 -14 -43 -18 -43 -4 0 -22 24 -40 54 -137 229 -375 462 -607 595
              -102 59 -284 139 -332 147 -32 5 -41 3 -46 -10 -4 -11 0 -19 11 -23 391 -132
              691 -359 901 -679 36 -56 79 -123 94 -149 18 -32 31 -45 38 -38 5 5 23 60 39
              121 73 278 221 542 414 740 44 46 83 87 84 93 2 5 -10 9 -27 9 -26 0 -43 -15
              -121 -102z
              M13940 25922 c-41 -42 -105 -118 -142 -167 -142 -190 -310 -588 -243
              -573 10 2 30 46 54 115 83 239 209 442 382 611 53 53 66 71 57 80 -20 20 -31
              14 -108 -66z
              M12261 25891 c-8 -6 -9 -11 -3 -15 6 -3 14 -1 17 5 9 14 2 20 -14 10z
              M14020 25833 c-93 -100 -173 -210 -166 -228 10 -27 30 -15 66 37 19
              29 75 94 123 145 49 51 86 97 82 103 -14 23 -46 6 -105 -57z
              M12383 25864 c-8 -21 1 -28 92 -63 195 -78 370 -197 536 -368 183
              -189 298 -388 394 -688 31 -95 62 -181 70 -191 27 -37 38 -2 42 128 2 68 8
              170 13 227 13 136 13 141 -5 141 -26 0 -37 -50 -42 -202 -3 -82 -9 -148 -12
              -148 -3 1 -25 60 -49 133 -153 470 -496 838 -947 1017 -88 35 -84 34 -92 14z
              M16595 25764 c-55 -47 -131 -116 -170 -154 -38 -37 -80 -73 -92 -79
              -30 -15 -89 -14 -122 4 -26 13 -30 13 -42 -1 -32 -39 84 -78 157 -54 22 8 71
              44 119 89 44 42 121 110 170 153 50 42 104 88 120 102 l30 25 -35 1 c-30 0
              -50 -13 -135 -86z
              M16503 25823 c-26 -9 -75 -45 -133 -99 -75 -68 -96 -83 -115 -78 -33
              8 -43 -15 -15 -38 12 -10 29 -18 37 -18 8 0 46 30 86 68 40 37 99 91 131 120
              32 29 57 54 55 56 -2 2 -23 -3 -46 -11z
              M16832 25802 c-87 -66 -226 -186 -319 -275 -140 -134 -225 -159 -348
              -103 -45 20 -60 24 -67 14 -13 -21 -4 -31 48 -58 40 -21 62 -25 129 -24 103 0
              142 20 255 128 87 83 212 190 333 285 37 29 67 54 67 56 0 1 -11 5 -25 8 -19
              3 -39 -5 -73 -31z
              M14158 25800 c-35 -27 -157 -173 -205 -245 -52 -77 -113 -200 -147
              -293 -60 -168 -81 -300 -91 -568 -3 -89 -12 -213 -20 -275 -24 -180 -94 -437
              -123 -447 -16 -5 -100 121 -169 253 -68 133 -133 294 -183 455 -64 209 -130
              345 -238 490 -134 181 -385 383 -381 308 0 -7 34 -38 75 -68 70 -51 191 -167
              247 -235 46 -56 128 -189 164 -264 19 -41 61 -154 93 -251 108 -328 213 -546
              339 -702 68 -86 74 -83 131 72 58 159 97 382 105 600 15 393 48 548 162 774
              52 103 124 206 208 295 41 44 75 87 75 95 0 25 -16 27 -42 6z
              M12172 25788 c2 -18 16 -25 77 -40 226 -55 486 -203 646 -367 61 -62
              95 -77 95 -42 0 22 -108 125 -214 205 -147 110 -347 207 -511 246 -99 25 -97
              25 -93 -2z
              M16953 25743 c-115 -87 -234 -187 -361 -307 -57 -53 -125 -107 -151
              -120 -102 -52 -224 -54 -325 -5 -50 24 -56 24 -56 -1 0 -45 131 -86 248 -77
              113 9 178 44 324 179 178 164 297 262 421 347 26 17 47 35 47 39 0 4 -13 8
              -29 9 -22 2 -51 -14 -118 -64z
              M17155 25738 c-173 -119 -320 -239 -492 -399 -118 -110 -191 -153
              -295 -175 -84 -18 -163 -13 -250 17 -71 24 -70 24 -66 -3 7 -52 213 -84 341
              -54 102 24 170 62 261 146 227 209 348 309 508 418 65 43 115 82 111 86 -17
              16 -63 2 -118 -36z
              M17500 25664 c-161 -86 -450 -288 -450 -315 0 -31 38 -16 123 49 135
              102 254 177 393 247 115 59 121 63 92 69 -47 9 -46 9 -158 -50z
              M14164 25633 c-37 -43 -87 -109 -111 -146 -36 -55 -42 -71 -33 -82
              17 -20 23 -14 73 63 24 37 72 97 106 134 33 36 61 70 61 76 0 5 -6 15 -14 21
              -12 10 -27 -2 -82 -66z
              M12120 25677 c0 -21 7 -25 78 -40 42 -10 125 -37 184 -61 102 -41
              128 -44 128 -14 0 18 -229 106 -320 123 -19 4 -43 9 -52 11 -14 4 -18 -1 -18
              -19z
              M17230 25644 c-153 -102 -323 -241 -502 -411 -121 -114 -217 -166
              -356 -190 -85 -15 -100 -15 -185 -1 -50 9 -103 19 -115 23 -21 7 -23 5 -20
              -16 5 -39 62 -53 218 -53 107 -1 146 3 190 18 107 36 192 89 285 176 179 168
              330 292 493 404 105 73 126 97 91 104 -8 1 -52 -23 -99 -54z
              M17731 25630 c-243 -96 -540 -299 -814 -558 -61 -58 -144 -126 -184
              -153 -178 -116 -416 -164 -608 -124 -89 19 -87 19 -83 -7 2 -18 13 -24 63 -35
              82 -17 259 -16 342 2 189 40 318 111 478 263 161 154 340 297 500 402 88 57
              300 165 355 180 49 14 64 25 57 44 -9 22 -20 20 -106 -14z
              M12070 25565 c0 -20 8 -25 53 -35 159 -36 327 -109 452 -196 161
              -112 314 -290 397 -461 42 -88 53 -102 70 -85 16 16 -41 139 -115 249 -34 51
              -98 129 -142 174 -190 194 -367 293 -642 359 l-73 17 0 -22z
              M14244 25542 c-81 -95 -171 -246 -211 -355 -42 -116 -72 -288 -73
              -415 0 -56 3 -74 15 -79 9 -3 18 -4 20 -2 2 3 9 67 14 144 6 77 18 169 26 205
              34 144 118 316 215 438 30 38 44 64 42 80 -4 29 -11 27 -48 -16z
              M17840 25534 c-257 -86 -540 -274 -840 -559 -186 -177 -331 -256
              -565 -310 -45 -10 -295 -7 -335 5 -52 15 -62 13 -58 -12 2 -18 13 -24 63 -35
              93 -19 258 -16 366 7 115 24 161 40 264 91 102 51 162 97 305 230 342 321 710
              543 937 566 55 6 61 11 41 31 -19 19 -97 13 -178 -14z
              M13766 25473 c-11 -11 -44 -89 -76 -178 -62 -171 -84 -311 -95 -590
              -9 -234 -43 -474 -65 -460 -26 16 -123 251 -200 485 -68 205 -119 317 -201
              440 -67 100 -74 107 -94 90 -11 -9 -2 -28 49 -104 87 -127 142 -248 216 -471
              95 -288 207 -530 245 -530 15 0 23 15 37 70 27 107 48 276 54 425 11 331 45
              526 120 693 20 45 37 93 38 106 1 24 -14 38 -28 24z
              M12043 25455 c-8 -22 4 -33 48 -40 74 -13 75 27 1 44 -34 7 -44 6
              -49 -4z
              M17906 25425 c-106 -31 -299 -127 -417 -207 -127 -86 -269 -200 -269
              -216 0 -34 35 -18 132 59 256 204 496 325 666 336 l88 6 26 -31 c89 -106 48
              -436 -101 -812 -12 -30 -69 -152 -126 -270 -58 -120 -116 -255 -130 -305 -70
              -240 -57 -506 36 -747 32 -83 52 -109 71 -90 8 8 4 26 -15 69 -73 164 -107
              373 -88 554 16 158 50 259 170 509 151 312 207 469 252 703 31 162 13 280 -63
              407 l-33 55 -60 1 c-37 1 -90 -7 -139 -21z
              M12221 25406 c-13 -16 -1 -29 39 -41 45 -14 177 -82 242 -126 140
              -95 303 -288 373 -444 15 -33 53 -139 85 -235 134 -401 274 -665 445 -836 131
              -131 210 -155 287 -86 64 56 74 69 122 167 75 150 121 306 155 533 28 182 29
              222 7 222 -21 0 -27 -13 -32 -73 -13 -156 -63 -397 -109 -527 -59 -164 -167
              -320 -222 -320 -51 0 -158 81 -235 178 -151 188 -266 420 -380 766 -92 281
              -157 396 -311 554 -117 119 -250 208 -390 260 -68 26 -62 25 -76 8z
              M14273 25382 c-47 -64 -118 -208 -142 -286 -30 -97 -51 -236 -51
              -336 0 -89 -22 -344 -39 -466 -26 -176 -82 -365 -152 -512 -85 -177 -208 -279
              -310 -257 -90 20 -201 100 -291 211 -50 62 -70 75 -83 54 -10 -17 21 -58 119
              -155 119 -118 180 -150 287 -150 71 0 79 2 133 38 151 100 271 363 335 732 25
              149 37 272 47 510 9 217 49 372 130 508 21 34 43 71 49 82 7 11 9 31 6 45 -7
              25 -8 24 -38 -18z
              M12030 25327 c0 -21 7 -25 58 -36 183 -40 370 -146 502 -288 119
              -127 180 -240 250 -463 47 -153 124 -347 183 -465 57 -116 116 -215 128 -215
              22 0 20 34 -5 78 -102 180 -198 404 -266 622 -51 161 -83 235 -142 327 -144
              220 -358 374 -609 438 -35 9 -72 18 -81 21 -14 4 -18 -1 -18 -19z
              M18010 25323 c-228 -35 -516 -215 -859 -536 -145 -136 -259 -216
              -388 -272 -29 -12 -43 -24 -43 -37 0 -35 54 -16 195 67 94 56 130 84 347 280
              202 183 392 318 538 383 84 37 190 72 221 72 25 0 28 -4 35 -47 22 -148 -63
              -479 -197 -762 -61 -129 -70 -161 -43 -161 10 0 36 43 74 123 144 303 217 576
              207 774 -5 119 -12 128 -87 116z
              M13942 25278 c-18 -23 -61 -166 -78 -256 -8 -48 -19 -177 -24 -287
              -16 -330 -57 -574 -130 -762 -32 -84 -95 -193 -112 -193 -33 0 -143 121 -225
              249 -60 93 -165 318 -217 464 -46 129 -67 161 -82 124 -7 -20 89 -275 157
              -417 104 -216 253 -415 346 -463 37 -20 42 -16 94 59 116 168 189 482 208 889
              14 288 25 362 77 512 24 67 25 93 5 93 -5 0 -13 -6 -19 -12z
              M16846 25178 c-134 -126 -160 -145 -251 -189 -171 -82 -306 -96 -518
              -53 -26 5 -28 3 -25 -17 3 -21 12 -25 73 -37 94 -18 279 -10 365 16 72 21 162
              65 227 109 74 50 262 233 256 249 -10 26 -29 14 -127 -78z
              M14291 25160 c-57 -128 -81 -243 -86 -419 -11 -386 -63 -685 -161
              -924 -50 -124 -57 -152 -34 -152 28 0 109 196 150 361 53 216 88 492 91 724 1
              129 21 240 61 344 24 62 29 84 21 109 -8 31 -9 31 -42 -43z
              M12040 25192 c0 -16 10 -24 43 -32 23 -7 71 -23 106 -36 64 -25 90
              -22 78 9 -5 15 -128 62 -189 73 -34 6 -38 4 -38 -14z
              M17885 25163 c-180 -73 -409 -236 -647 -460 -233 -219 -431 -328
              -698 -385 -103 -22 -319 -29 -443 -15 -67 8 -68 7 -65 -15 4 -31 43 -37 241
              -37 169 -1 285 16 431 64 182 60 373 174 516 310 182 172 323 289 452 371 89
              58 247 138 255 130 10 -10 -15 -156 -42 -251 -41 -139 -104 -294 -198 -480
              -144 -289 -185 -430 -194 -675 -6 -166 8 -325 32 -349 18 -19 38 -4 31 25 -20
              77 -27 311 -14 434 17 159 68 310 183 539 135 271 209 476 240 666 22 133 21
              145 -12 144 -16 0 -46 -7 -68 -16z
              M9370 25114 c0 -1 48 -51 108 -111 201 -203 316 -399 423 -725 49
              -152 65 -188 79 -188 24 0 28 22 35 200 3 80 8 180 11 223 6 73 5 77 -14 77
              -17 0 -22 -8 -27 -42 -10 -68 -13 -116 -14 -218 -1 -51 -5 -90 -9 -85 -5 6
              -25 62 -46 125 -83 258 -228 493 -418 676 -44 44 -73 63 -95 66 -18 3 -33 3
              -33 2z
              M9560 25104 c0 -3 33 -41 73 -85 82 -91 160 -200 219 -306 22 -40 46
              -73 54 -73 7 0 18 17 23 38 5 20 17 66 26 102 9 35 21 73 27 83 6 13 6 23 -3
              33 -23 28 -33 14 -64 -98 -9 -32 -19 -58 -23 -58 -4 0 -22 26 -40 58 -43 75
              -150 217 -199 263 -33 32 -93 60 -93 43z
              M9214 25079 c-2 -4 18 -23 46 -43 27 -20 83 -67 123 -106 76 -72 91
              -80 100 -56 6 14 -129 152 -189 194 -29 20 -71 26 -80 11z
              M12040 25066 c0 -19 17 -30 85 -55 149 -55 299 -180 380 -319 28 -47
              61 -127 86 -207 99 -314 222 -587 358 -792 76 -114 100 -138 116 -113 3 5 -28
              60 -69 122 -141 213 -243 434 -348 753 -71 217 -117 302 -218 406 -88 90 -172
              147 -281 189 -87 33 -109 37 -109 16z
              M12366 25061 c-10 -16 4 -35 60 -79 126 -100 224 -257 288 -464 37
              -118 49 -139 69 -119 16 16 -45 208 -111 341 -52 106 -65 124 -163 222 -99
              101 -129 122 -143 99z
              M9100 25022 c0 -11 27 -38 68 -67 151 -110 309 -300 400 -482 23 -45
              70 -168 105 -275 78 -234 154 -412 233 -544 60 -101 147 -214 165 -214 60 0
              176 484 179 750 1 67 -16 159 -31 175 -4 3 -9 -61 -12 -142 -6 -152 -25 -315
              -51 -439 -20 -93 -74 -262 -87 -270 -12 -7 -20 3 -86 101 -99 147 -198 371
              -284 645 -47 147 -128 316 -204 425 -89 126 -333 355 -379 355 -9 0 -16 -8
              -16 -18z
              M9800 25011 c14 -27 47 -45 55 -30 8 12 9 12 -33 33 -33 17 -33 17
              -22 -3z
              M8928 24992 c-38 -5 -35 -15 10 -36 74 -35 203 -129 279 -204 104
              -102 172 -195 236 -319 36 -71 57 -103 70 -103 9 0 17 7 17 15 0 24 -67 152
              -128 245 -122 184 -399 414 -484 402z
              M17745 24953 c-127 -78 -262 -187 -430 -344 -109 -102 -215 -185
              -310 -241 -66 -40 -238 -118 -259 -118 -22 0 -41 -29 -28 -42 16 -16 95 11
              223 75 138 70 245 146 385 276 196 181 425 363 440 348 5 -5 -44 -148 -83
              -242 -14 -33 -65 -140 -113 -237 -90 -180 -145 -329 -174 -470 -20 -97 -20
              -128 -2 -128 19 0 28 15 33 59 7 61 54 232 84 308 16 39 64 143 108 230 92
              183 149 321 186 448 24 84 26 115 8 115 -5 0 -35 -17 -68 -37z
              M8745 24935 l-30 -14 24 -7 c52 -15 200 -94 267 -142 143 -101 263
              -242 345 -404 21 -42 64 -154 95 -248 135 -416 297 -714 476 -882 55 -52 138
              -98 175 -98 92 0 204 150 278 373 30 88 35 116 29 153 -3 25 -10 42 -14 37 -4
              -4 -17 -46 -30 -93 -56 -205 -151 -374 -232 -416 -26 -13 -32 -13 -74 5 -107
              48 -259 234 -373 461 -82 160 -130 278 -201 495 -61 187 -104 277 -191 398
              -99 139 -232 255 -386 335 -114 59 -124 62 -158 47z
              M12057 24930 c3 -12 35 -34 89 -60 102 -49 212 -153 261 -245 17 -33
              59 -143 92 -244 98 -295 137 -379 162 -354 8 8 -4 50 -44 154 -30 79 -74 203
              -97 274 -58 179 -90 235 -185 331 -65 65 -98 90 -166 123 -97 46 -120 51 -112
              21z
              M14345 24913 c-4 -21 -11 -105 -15 -188 -5 -119 -4 -153 6 -165 28
              -29 36 4 36 156 1 194 -11 279 -27 197z
              M17035 24843 c-98 -92 -176 -145 -296 -202 -179 -85 -371 -120 -564
              -101 -56 5 -109 12 -118 16 -13 5 -17 1 -17 -19 0 -22 6 -26 60 -36 87 -17
              286 -12 396 10 113 23 252 75 344 129 125 73 310 235 296 259 -13 20 -31 10
              -101 -56z
              M8502 24867 c-21 -10 -11 -14 89 -39 239 -58 436 -189 572 -380 68
              -96 108 -182 172 -378 93 -283 257 -640 302 -657 33 -13 34 15 3 65 -73 119
              -182 367 -253 578 -103 302 -147 387 -280 530 -113 121 -310 239 -468 278 -75
              18 -105 19 -137 3z
              M9524 24796 c-3 -7 20 -50 51 -96 86 -126 141 -246 210 -456 93 -283
              216 -554 251 -554 19 0 28 25 52 150 28 143 41 274 47 468 2 89 9 190 15 223
              11 59 1 129 -19 129 -12 0 -33 -169 -37 -310 -8 -244 -43 -552 -65 -566 -20
              -12 -124 234 -202 474 -62 189 -121 324 -192 433 -71 111 -99 137 -111 105z
              M10037 24789 c-12 -44 -8 -71 9 -67 27 5 36 44 15 69 l-18 22 -6 -24z
              M12097 24793 c-13 -13 -6 -21 63 -71 115 -84 142 -131 228 -387 162
              -485 357 -833 598 -1065 135 -131 238 -198 373 -245 347 -122 706 19 931 365
              84 130 190 377 190 443 -1 89 -13 80 -51 -40 -136 -414 -363 -670 -670 -751
              -20 -5 -88 -10 -151 -11 -98 -2 -126 2 -198 24 -404 126 -748 583 -985 1305
              -31 96 -69 197 -83 224 -35 65 -110 146 -170 185 -50 32 -64 36 -75 24z
              M8265 24764 c-35 -9 -108 -48 -96 -52 6 -2 42 1 80 7 135 21 274 6
              406 -44 88 -33 98 -34 103 -12 4 21 -65 54 -168 81 -63 17 -283 30 -325 20z
              M18218 24725 c-3 -6 -16 -41 -28 -80 -33 -101 -106 -268 -193 -441
              -119 -238 -154 -383 -143 -594 9 -191 74 -383 177 -531 33 -47 45 -57 55 -48
              20 17 18 23 -26 87 -59 87 -107 195 -136 307 -22 85 -26 121 -26 240 0 192 24
              285 131 502 128 257 194 419 199 485 4 57 0 90 -10 73z
              M12080 24659 c0 -13 21 -43 51 -72 46 -45 109 -151 109 -183 0 -35
              120 -357 195 -524 195 -433 482 -777 772 -923 98 -50 202 -79 257 -73 28 3 75
              7 104 8 84 2 60 14 -41 21 -197 13 -389 109 -586 292 -241 224 -492 673 -636
              1135 -50 162 -85 229 -151 289 -61 56 -74 61 -74 30z
              M17459 24577 c-55 -50 -135 -122 -177 -159 -77 -68 -93 -90 -72 -103
              12 -7 62 32 185 143 44 39 82 72 86 72 3 0 -21 -55 -55 -122 -153 -306 -209
              -645 -161 -979 18 -128 71 -318 119 -421 35 -79 43 -87 63 -67 8 8 2 31 -21
              82 -109 241 -156 544 -126 812 24 209 77 375 197 617 51 104 93 193 93 198 0
              4 -7 11 -15 14 -11 4 -49 -25 -116 -87z
              M8327 24643 c-10 -3 -17 -12 -15 -21 3 -14 20 -18 83 -24 111 -9 177
              -23 249 -55 147 -64 277 -178 355 -310 24 -42 65 -144 104 -263 87 -262 172
              -459 280 -645 51 -88 144 -215 157 -215 29 0 14 37 -53 136 -130 193 -248 447
              -346 746 -61 187 -74 217 -118 287 -94 147 -216 247 -384 313 -87 35 -264 64
              -312 51z
              M8105 24618 c-33 -5 -62 -13 -65 -16 -3 -4 -14 -12 -24 -18 -46 -25
              -23 -30 76 -15 91 14 103 19 103 36 0 24 -12 26 -90 13z
              M8855 24600 c-4 -6 35 -50 85 -97 141 -132 194 -220 261 -430 23 -71
              45 -132 50 -137 5 -5 14 -4 23 3 12 10 8 32 -34 156 -55 163 -88 231 -163 330
              -52 70 -179 185 -203 185 -7 0 -16 -5 -19 -10z
              M8240 24514 c-14 -2 -74 -11 -135 -20 -60 -8 -125 -17 -142 -20 -18
              -3 -37 -13 -43 -24 -15 -27 -13 -30 18 -25 15 2 79 11 142 20 63 9 142 20 176
              26 199 30 387 -27 527 -161 85 -81 130 -151 164 -257 38 -118 132 -381 159
              -440 23 -53 40 -66 50 -39 3 8 -15 65 -39 128 -25 62 -64 172 -87 243 -23 72
              -51 153 -62 180 -66 161 -206 293 -384 361 -59 23 -88 27 -194 30 -69 1 -136
              1 -150 -2z
              M12092 24480 c6 -52 105 -344 171 -506 49 -121 168 -362 199 -406 20
              -29 48 -38 48 -16 0 7 -38 87 -84 178 -94 184 -163 351 -226 545 -47 148 -77
              220 -98 238 -12 10 -14 5 -10 -33z
              M16500 24434 c-64 -17 -291 -23 -397 -11 -64 7 -71 6 -69 -9 5 -32
              39 -38 211 -41 159 -3 208 2 333 32 13 3 22 13 22 25 0 24 -19 24 -100 4z
              M14317 24433 c-3 -5 -8 -37 -12 -73 -13 -128 -57 -337 -95 -458 -99
              -308 -233 -498 -417 -589 -74 -37 -80 -38 -182 -38 -81 0 -118 5 -156 20 -83
              32 -182 96 -261 169 -48 44 -80 66 -90 62 -23 -9 -5 -34 77 -110 138 -127 268
              -186 417 -187 142 -1 250 39 356 132 190 168 333 519 391 959 6 52 10 101 7
              108 -5 13 -28 16 -35 5z
              M18230 24390 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20
              -5 0 -10 -9 -10 -20z
              M8255 24394 c-16 -3 -73 -11 -127 -19 -53 -9 -123 -18 -155 -21 -76
              -9 -99 -18 -106 -44 -4 -16 -2 -20 11 -16 9 3 58 10 107 16 50 6 115 15 145
              20 155 27 253 33 314 20 50 -10 61 -10 73 3 14 13 13 16 -8 28 -24 12 -196 21
              -254 13z
              M8587 24333 c-13 -13 -6 -21 43 -53 55 -35 142 -126 166 -172 8 -16
              46 -121 85 -233 143 -421 294 -715 491 -951 312 -374 699 -494 1048 -324 83
              40 99 52 110 84 14 40 7 42 -32 13 -56 -43 -179 -96 -263 -113 -214 -44 -433
              18 -637 180 -279 222 -506 605 -692 1171 -36 108 -69 189 -91 223 -73 108
              -198 205 -228 175z
              M14397 24155 c-21 -114 -26 -160 -18 -168 18 -18 30 3 46 79 16 74
              18 146 7 207 -6 33 -11 16 -35 -118z
              M8280 24275 c-8 -2 -71 -11 -140 -21 -69 -9 -129 -21 -135 -26 -6 -5
              -4 -14 6 -23 13 -13 25 -13 99 -1 84 14 126 20 225 32 59 7 160 -15 214 -48
              47 -27 107 -85 132 -125 11 -17 50 -123 88 -235 83 -244 122 -338 218 -528
              196 -387 446 -666 720 -804 145 -73 334 -107 486 -87 133 18 197 39 230 75 15
              16 27 34 27 38 0 5 -12 2 -27 -6 -107 -55 -324 -81 -459 -56 -217 41 -381 137
              -574 337 -247 255 -428 586 -594 1088 -62 188 -96 248 -173 307 -79 60 -136
              80 -238 84 -49 1 -97 1 -105 -1z
              M12806 24271 c-7 -11 58 -162 132 -306 89 -175 183 -308 296 -420
              190 -189 356 -238 529 -157 77 36 171 120 161 145 -10 26 -23 21 -80 -33 -77
              -73 -141 -100 -234 -100 -85 0 -152 23 -235 80 -79 54 -196 179 -273 294 -66
              97 -189 331 -239 454 -20 48 -43 66 -57 43z
              M16972 24214 c-110 -55 -276 -112 -407 -139 -101 -20 -333 -30 -447
              -18 -87 9 -88 9 -88 -13 0 -31 46 -38 255 -37 195 1 315 20 485 76 106 35 278
              111 315 140 18 14 18 17 5 30 -13 12 -29 7 -118 -39z
              M18158 24238 c-49 -76 -131 -265 -154 -353 -39 -153 -36 -385 5 -385
              15 0 16 16 17 153 0 188 16 255 109 444 36 72 65 139 65 147 0 23 -26 19 -42
              -6z
              M17179 24192 c-134 -85 -311 -165 -448 -202 -180 -49 -227 -55 -474
              -55 -217 0 -237 -1 -237 -17 0 -26 51 -32 260 -31 229 0 345 19 560 91 89 30
              238 98 298 136 24 15 46 26 48 24 2 -2 -1 -20 -6 -39 -5 -19 -19 -88 -30 -154
              -50 -288 -15 -624 95 -910 47 -121 75 -167 103 -172 26 -3 27 0 8 30 -31 49
              -96 219 -125 327 -59 221 -76 460 -46 667 14 101 50 264 65 293 9 19 2 40 -14
              40 -7 0 -32 -13 -57 -28z
              M7846 24194 c-9 -22 -8 -24 9 -24 8 0 15 9 15 20 0 24 -15 27 -24 4z
              M16515 24188 c-48 -12 -382 -16 -442 -5 -41 7 -43 6 -43 -17 0 -31
              22 -34 245 -34 231 0 334 18 304 54 -13 16 -10 16 -64 2z
              M9565 24161 c-3 -6 15 -68 41 -138 119 -329 243 -544 396 -689 81
              -77 89 -79 126 -41 72 76 150 271 195 487 22 111 22 117 7 170 l-17 55 -7 -55
              c-34 -263 -109 -497 -195 -609 -23 -30 -39 -22 -116 61 -137 148 -250 362
              -380 721 -16 44 -36 59 -50 38z
              M17692 24118 c-80 -215 -102 -443 -62 -670 30 -177 94 -340 191 -486
              43 -66 63 -82 80 -65 7 7 -5 33 -36 79 -51 76 -117 208 -147 293 -45 133 -60
              230 -61 396 0 173 12 255 59 388 32 88 34 110 12 115 -12 2 -22 -11 -36 -50z
              M8260 24150 c-30 -4 -109 -15 -175 -24 -222 -30 -245 -36 -245 -58 0
              -19 4 -20 53 -14 62 7 241 32 352 48 117 17 177 14 229 -11 76 -37 110 -85
              151 -213 55 -170 91 -268 161 -433 76 -178 174 -359 197 -363 29 -6 19 24 -52
              161 -91 173 -169 360 -242 582 -64 192 -70 204 -119 254 -69 68 -172 92 -310
              71z
              M18220 24083 c-132 -243 -149 -488 -50 -725 17 -41 37 -84 45 -94 13
              -18 14 -18 24 8 8 22 7 34 -9 60 -10 18 -31 69 -46 113 -25 72 -28 94 -28 220
              -1 152 10 205 64 320 16 33 30 81 32 108 2 26 2 47 1 47 -1 0 -16 -26 -33 -57z
              M12129 24095 c-8 -53 7 -130 43 -215 151 -355 355 -669 569 -876 124
              -119 188 -164 233 -164 l37 0 -78 59 c-177 133 -314 285 -466 516 -89 136
              -237 428 -297 585 -22 58 -40 101 -41 95z
              M8295 24033 c-16 -2 -73 -10 -125 -18 -52 -7 -131 -19 -175 -25 -128
              -18 -135 -20 -135 -41 0 -22 20 -24 96 -10 27 5 74 12 104 15 30 4 91 12 136
              20 44 7 112 14 151 15 67 2 73 0 107 -29 55 -49 81 -18 28 33 -43 41 -93 52
              -187 40z
              M12683 23943 c-14 -35 162 -335 281 -478 142 -172 288 -278 461 -335
              102 -34 270 -33 373 3 199 69 350 218 471 467 64 130 99 236 84 251 -18 18
              -30 3 -54 -69 -77 -232 -221 -439 -378 -541 -103 -66 -176 -86 -316 -86 -108
              0 -128 3 -199 29 -231 85 -471 337 -645 676 -52 100 -66 115 -78 83z
              M17026 23924 c-13 -34 -27 -253 -21 -334 17 -226 58 -420 128 -597
              27 -71 45 -104 59 -109 11 -3 22 -3 25 -1 3 3 -13 50 -35 104 -45 111 -74 208
              -92 303 -6 36 -16 88 -22 115 -15 74 -21 349 -10 435 6 49 6 79 0 88 -14 16
              -25 15 -32 -4z
              M16835 23898 c-175 -58 -300 -79 -515 -83 -107 -2 -219 0 -247 4 -50
              7 -53 6 -53 -15 0 -30 42 -37 245 -37 214 0 321 14 524 69 114 31 166 54 166
              74 0 23 -23 21 -120 -12z
              M8315 23913 c-16 -2 -73 -10 -125 -18 -52 -7 -131 -19 -175 -25 -142
              -20 -144 -20 -127 -48 7 -10 31 -10 128 3 65 8 144 19 174 25 30 5 84 12 120
              15 l65 7 43 -134 c96 -302 314 -778 357 -778 29 0 24 22 -29 118 -104 188
              -205 424 -291 684 -23 70 -48 134 -55 143 -12 15 -24 16 -85 8z
              M18247 23845 c-20 -85 -26 -179 -17 -247 11 -81 30 -161 37 -154 7 7
              2 278 -6 361 -6 57 -8 65 -14 40z
              M8520 23825 c0 -23 54 -179 110 -320 214 -534 493 -918 830 -1142 98
              -66 174 -104 288 -143 130 -44 185 -51 241 -32 l46 15 -100 18 c-263 45 -458
              152 -686 379 -186 185 -307 358 -453 646 -66 130 -163 364 -222 534 -12 34
              -26 56 -37 58 -10 2 -17 -3 -17 -13z
              M9302 23817 c-7 -8 10 -58 57 -162 234 -526 532 -806 802 -755 75 14
              149 52 208 108 53 50 62 69 36 79 -10 3 -32 -11 -61 -40 -26 -25 -72 -58 -103
              -73 -49 -25 -67 -29 -142 -29 -79 0 -91 3 -165 39 -108 54 -252 197 -353 351
              -69 104 -188 336 -226 437 -18 49 -37 65 -53 45z
              M16871 23781 c-8 -5 -48 -17 -89 -27 -71 -17 -95 -35 -75 -55 6 -6
              31 -4 69 7 32 9 71 18 86 21 l27 5 -1 -113 c0 -63 2 -134 6 -159 27 -177 42
              -248 71 -349 46 -159 69 -211 95 -211 11 0 20 2 20 5 0 2 -15 47 -34 99 -37
              101 -89 305 -101 396 -4 30 -11 129 -14 220 -6 155 -7 165 -26 168 -11 1 -26
              -2 -34 -7z
              M8085 23760 c-173 -25 -170 -24 -173 -49 -4 -23 -2 -24 35 -18 21 4
              77 12 125 18 112 13 143 22 143 40 0 17 -46 20 -130 9z
              M8300 23727 c0 -61 140 -410 257 -639 248 -489 566 -839 925 -1019
              71 -36 84 -39 108 -29 27 11 24 13 -91 72 -271 139 -511 367 -724 688 -161
              244 -268 462 -390 800 -35 97 -58 146 -69 148 -11 2 -16 -4 -16 -21z
              M17374 23738 c-11 -17 -7 -171 6 -272 27 -197 109 -431 203 -579 38
              -59 43 -62 65 -49 9 6 0 29 -37 90 -64 107 -115 230 -151 366 -33 125 -40 172
              -49 335 -6 110 -17 142 -37 109z
              M12158 23668 c3 -37 18 -80 50 -141 54 -103 73 -125 91 -103 10 12 7
              22 -12 54 -14 21 -48 84 -76 140 -28 56 -53 102 -55 102 -2 0 -1 -24 2 -52z
              M16485 23698 c-38 -5 -159 -8 -267 -6 -197 4 -198 4 -198 -17 0 -20
              6 -22 63 -26 160 -10 472 1 498 17 14 9 10 31 -7 38 -9 3 -16 5 -17 5 -1 -1
              -33 -6 -72 -11z
              M8105 23639 c-176 -24 -186 -27 -167 -56 5 -8 34 -8 117 3 61 7 118
              16 127 18 23 5 29 -5 58 -89 60 -179 251 -592 278 -602 39 -16 29 21 -42 163
              -72 145 -155 339 -211 497 l-28 77 -36 -1 c-20 -1 -63 -5 -96 -10z
              M16745 23619 c-58 -17 -201 -38 -338 -49 -127 -10 -165 -9 -329 6
              -56 5 -58 4 -58 -19 0 -25 1 -25 127 -32 161 -10 336 0 483 26 63 12 119 21
              125 22 5 1 13 -36 17 -83 9 -113 15 -132 36 -128 19 4 19 0 8 133 -11 146 -9
              143 -71 124z
              M20328 23573 c-2 -5 -1 -66 2 -138 4 -105 8 -130 20 -130 30 0 38
              178 10 249 -9 25 -25 34 -32 19z
              M14441 23503 c-40 -91 -151 -248 -236 -334 -127 -129 -247 -200 -395
              -234 -144 -32 -129 -40 25 -14 278 49 530 277 615 557 12 40 21 75 19 77 -2 2
              -15 -22 -28 -52z
              M20203 23443 c0 -145 -18 -225 -78 -348 -57 -117 -118 -189 -234
              -276 -187 -141 -228 -177 -234 -206 -3 -15 -4 -30 -2 -32 2 -2 28 17 57 41 29
              25 107 85 173 135 66 49 136 108 156 129 127 142 208 349 209 535 0 102 -8
              139 -30 139 -16 0 -18 -12 -17 -117z
              M20460 23548 c0 -2 -2 -68 -4 -148 -3 -110 -9 -164 -25 -225 -66
              -238 -194 -421 -400 -570 -162 -117 -404 -322 -415 -352 -8 -20 -8 -53 0 -53
              4 0 44 36 91 80 89 85 212 185 375 308 199 150 321 328 387 565 44 155 49 387
              9 395 -10 2 -18 2 -18 0z
              M20100 23520 c-11 -7 -15 -31 -16 -97 -2 -145 -46 -272 -131 -385
              -21 -27 -91 -90 -155 -139 -103 -79 -118 -94 -118 -119 l0 -30 58 45 c31 25
              88 69 127 98 164 124 250 291 262 508 6 102 -2 135 -27 119z
              M7960 23474 c0 -22 3 -25 23 -20 12 3 28 6 35 6 6 0 12 9 12 20 0 16
              -7 20 -35 20 -31 0 -35 -3 -35 -26z
              M8132 23492 c-30 -20 206 -534 357 -775 200 -320 446 -586 698 -754
              70 -46 85 -53 103 -43 11 6 20 12 20 15 0 2 -17 14 -37 26 -138 83 -376 293
              -509 449 -192 225 -409 601 -547 949 -30 74 -58 136 -63 138 -5 1 -15 -1 -22
              -5z
              M9174 23487 c-7 -19 78 -187 149 -295 176 -271 355 -434 556 -506
              222 -80 465 -33 644 124 36 32 47 49 47 71 l0 29 -72 -61 c-400 -336 -880
              -131 -1230 526 -62 117 -82 141 -94 112z
              M20700 23415 c0 -315 -129 -630 -349 -852 -72 -73 -87 -103 -52 -103
              23 0 145 129 213 225 95 133 179 329 208 481 16 88 31 256 25 284 -3 14 -14
              31 -25 38 -19 12 -20 9 -20 -73z
              M10431 23440 c-21 -71 -99 -214 -146 -268 -140 -159 -257 -143 -458
              60 -74 75 -113 108 -123 104 -14 -5 -14 -10 3 -35 65 -104 206 -225 303 -262
              131 -50 249 5 353 164 73 111 109 196 101 236 -9 45 -19 46 -33 1z
              M16615 23470 c-90 -18 -372 -32 -472 -23 -102 9 -103 8 -103 -13 0
              -20 7 -23 78 -30 76 -7 355 0 442 12 102 13 92 22 112 -98 15 -99 65 -301 88
              -362 11 -31 56 -39 47 -8 -45 153 -78 288 -91 377 -26 175 -17 161 -101 145z
              M12557 23453 c-16 -15 -3 -42 64 -132 92 -123 292 -323 389 -388 110
              -74 132 -83 174 -76 l37 6 -73 39 c-184 100 -369 273 -515 481 -53 75 -63 84
              -76 70z
              M19980 23450 c-8 -5 -11 -12 -8 -16 10 -9 38 3 38 16 0 12 -10 13
              -30 0z
              M20830 23438 c0 -12 -5 -65 -10 -117 -10 -97 -4 -129 22 -103 27 27
              28 231 1 240 -8 2 -13 -6 -13 -20z
              M20587 23434 c-4 -4 -7 -31 -7 -59 0 -29 -7 -90 -15 -137 -54 -309
              -199 -532 -490 -751 -239 -180 -445 -367 -445 -404 0 -39 16 -33 81 34 82 84
              256 235 343 299 196 142 286 229 374 361 117 173 185 380 194 583 2 61 0 75
              -12 78 -9 1 -19 0 -23 -4z
              M20963 23412 c-6 -4 -18 -68 -27 -142 -25 -198 -67 -343 -146 -504
              -24 -49 -30 -70 -22 -78 24 -24 58 33 127 212 49 129 78 269 90 427 6 79 1 99
              -22 85z
              M21065 23342 c-30 -319 -107 -552 -262 -792 -117 -180 -236 -300
              -470 -474 -92 -68 -133 -105 -133 -119 0 -17 5 -18 38 -13 20 4 105 27 187 51
              83 24 175 50 205 58 58 14 75 40 30 45 -21 2 -239 -52 -292 -73 -41 -16 -13
              15 62 68 385 277 635 726 675 1214 6 75 5 82 -13 92 -19 10 -21 7 -27 -57z
              M8013 23383 c-34 -4 -38 -10 -27 -37 4 -11 14 -13 33 -10 27 6 29 3
              61 -78 57 -145 193 -418 283 -567 181 -299 433 -590 659 -760 84 -63 101 -72
              125 -66 l28 7 -25 19 c-14 11 -70 55 -125 99 -236 185 -411 387 -608 698 -78
              123 -237 438 -300 592 -44 108 -46 110 -104 103z
              M18034 23376 c-3 -8 5 -38 17 -68 25 -58 100 -188 109 -188 3 0 10 8
              17 18 9 15 5 27 -21 68 -18 27 -43 74 -56 104 -35 81 -53 99 -66 66z
              M21180 23316 c0 -77 -35 -271 -71 -395 -35 -121 -144 -351 -216 -459
              -27 -39 -84 -113 -127 -162 -42 -50 -74 -95 -70 -101 4 -7 26 -9 63 -5 103 12
              325 7 429 -9 175 -26 361 -84 504 -156 32 -16 60 -29 63 -29 3 0 5 11 5 24 0
              20 -15 30 -98 69 -228 105 -519 165 -766 155 -58 -2 -106 -2 -106 0 0 2 22 32
              49 66 146 184 254 392 316 608 50 175 82 402 61 437 -4 6 -14 11 -22 11 -10 0
              -14 -14 -14 -54z
              M19960 23335 c-5 -14 -13 -38 -16 -53 -17 -69 -60 -139 -123 -202
              -36 -36 -76 -73 -87 -81 -21 -14 -42 -69 -27 -69 5 0 39 23 77 52 113 85 207
              235 210 335 1 44 -20 55 -34 18z
              M19848 23318 c-60 -82 -129 -223 -105 -215 26 8 107 130 123 183 9
              31 14 58 11 60 -3 3 -16 -10 -29 -28z
              M16420 23328 c-47 -5 -142 -6 -212 -4 -71 3 -128 2 -128 -3 0 -4 9
              -16 20 -26 18 -16 38 -17 239 -13 l219 6 6 -52 c8 -55 50 -227 66 -263 12 -28
              56 -33 45 -5 -9 24 -73 288 -80 335 -6 36 -7 37 -48 36 -23 -1 -80 -6 -127
              -11z
              M21299 23313 c-3 -146 -58 -405 -119 -558 -37 -94 -112 -244 -157
              -314 -32 -50 -37 -81 -13 -81 26 0 147 215 208 370 92 236 152 598 100 608
              -14 3 -18 -3 -19 -25z
              M12240 23307 c16 -45 92 -157 144 -212 26 -27 38 -36 27 -20 -38 53
              -156 222 -168 241 -11 16 -11 14 -3 -9z
              M12357 23313 c-15 -14 -4 -34 75 -139 105 -139 250 -290 286 -297 15
              -3 36 -9 47 -13 11 -4 -11 21 -48 56 -94 87 -193 201 -292 338 -44 60 -55 69
              -68 55z
              M21415 23233 c-27 -255 -88 -470 -191 -675 -57 -115 -57 -125 4 -133
              26 -4 74 -13 107 -20 59 -13 80 -8 72 20 -4 11 -89 34 -134 37 -41 2 -41 12
              -2 90 74 146 136 341 169 531 29 165 26 207 -15 207 -2 0 -6 -26 -10 -57z
              M10491 23251 c-11 -19 -10 -26 3 -39 13 -14 16 -14 21 -1 3 9 2 26
              -3 39 l-9 24 -12 -23z
              M16810 23242 c0 -28 54 -231 79 -294 7 -20 18 -28 34 -28 l23 0 -29
              86 c-15 47 -38 125 -50 172 -16 63 -27 88 -40 90 -13 3 -17 -4 -17 -26z
              M17564 23237 c-18 -48 162 -387 206 -387 28 0 25 13 -19 78 -45 66
              -113 199 -131 257 -19 61 -44 83 -56 52z
              M21536 23198 c-3 -12 -12 -69 -21 -124 -8 -56 -22 -123 -29 -148 -12
              -39 -12 -47 1 -57 9 -8 17 -9 24 -2 13 13 59 234 59 287 0 51 -26 85 -34 44z
              M16160 23199 c0 -3 8 -13 18 -22 15 -14 42 -17 149 -17 l131 0 6 -37
              c4 -21 14 -63 23 -95 12 -46 20 -57 39 -61 20 -3 23 -1 19 13 -3 10 -17 62
              -30 115 -13 54 -27 100 -30 104 -8 8 -325 8 -325 0z
              M8021 23190 c-1 -3 8 -48 19 -100 39 -178 245 -536 471 -820 165
              -207 414 -440 471 -440 35 0 36 13 3 34 -52 34 -252 216 -315 287 -165 185
              -311 387 -429 596 -43 76 -161 315 -209 423 -6 14 -11 23 -11 20z
              M20294 23153 c-89 -220 -168 -320 -362 -463 -95 -70 -127 -110 -87
              -110 22 0 233 160 288 219 94 100 220 333 203 376 -9 26 -26 17 -42 -22z
              M21635 23053 c-8 -69 -54 -248 -90 -352 -17 -50 -22 -78 -15 -85 5
              -5 63 -29 129 -52 112 -39 120 -41 124 -23 6 23 -14 35 -130 74 -46 15 -83 28
              -83 29 0 1 9 24 20 53 25 66 76 263 85 328 5 43 3 53 -13 68 -19 18 -20 17
              -27 -40z
              M10518 23058 c-15 -24 -51 -66 -80 -95 -221 -216 -497 -197 -763 51
              -33 31 -65 56 -72 56 -79 0 141 -196 293 -261 76 -32 85 -33 205 -34 l126 0
              88 44 c68 33 106 61 162 117 73 73 93 110 77 145 -8 17 -12 15 -36 -23z
              M20785 23088 c-2 -7 -16 -49 -30 -92 -68 -209 -221 -442 -374 -574
              -36 -32 -129 -104 -206 -162 -147 -110 -435 -383 -435 -413 0 -39 28 -21 143
              96 129 131 219 208 408 349 200 149 335 313 439 529 79 166 109 279 75 279 -9
              0 -18 -6 -20 -12z
              M16295 23071 c15 -14 106 -63 111 -59 2 2 0 17 -5 34 -7 27 -11 29
              -62 32 -35 2 -50 0 -44 -7z
              M17905 23070 c-8 -13 65 -120 82 -120 28 0 23 29 -12 79 -35 49 -57
              62 -70 41z
              M9043 22984 c-6 -17 27 -67 135 -202 173 -215 394 -379 609 -451 163
              -55 400 -66 462 -22 43 31 39 41 -11 29 -24 -5 -106 -8 -183 -5 -116 4 -155 9
              -225 32 -257 82 -480 257 -692 543 -38 50 -73 92 -79 92 -5 0 -12 -7 -16 -16z
              M21706 22821 c-15 -66 -25 -124 -22 -130 4 -6 21 -14 39 -20 18 -5
              40 -12 50 -16 13 -6 17 -2 17 18 0 20 -6 27 -30 32 -35 8 -35 8 -11 103 15 56
              17 80 9 103 -5 16 -13 29 -16 29 -4 0 -20 -54 -36 -119z
              M8840 22847 c0 -22 120 -184 214 -289 150 -169 317 -298 501 -388
              125 -62 179 -76 220 -61 29 12 28 13 -58 41 -111 38 -200 83 -317 161 -150
              101 -368 321 -484 492 -37 54 -76 76 -76 44z
              M8567 22835 c-4 -8 29 -67 77 -139 196 -293 418 -517 665 -670 98
              -60 102 -62 133 -45 19 10 14 15 -52 50 -263 143 -534 413 -741 738 -51 79
              -71 96 -82 66z
              M21399 22655 c-49 -123 -49 -128 6 -140 65 -15 261 -78 313 -101 30
              -13 56 -24 58 -24 2 0 4 11 4 24 0 21 -10 29 -78 54 -42 17 -124 44 -181 61
              -58 17 -106 32 -107 34 -2 1 13 42 32 90 20 49 33 95 30 103 -13 34 -34 7 -77
              -101z
              M8120 22704 c0 -5 8 -40 19 -76 34 -126 266 -444 481 -660 94 -95
              123 -118 153 -123 21 -4 39 -6 41 -4 2 2 -32 36 -75 74 -186 164 -387 416
              -556 694 -35 57 -63 100 -63 95z
              M20653 22543 c-90 -121 -203 -228 -383 -363 -175 -132 -436 -376
              -428 -401 7 -21 59 -22 176 -4 133 21 273 54 422 99 254 76 357 91 575 83 144
              -5 155 -4 155 13 0 22 -45 30 -190 35 -186 6 -304 -14 -561 -91 -208 -63 -489
              -119 -489 -97 0 10 120 128 200 197 25 21 108 86 185 144 158 119 252 205 334
              307 74 91 94 123 87 141 -10 26 -24 15 -83 -63z
              M19672 22481 c-29 -27 -42 -47 -42 -66 l0 -27 38 30 c55 44 75 69 69
              87 -9 22 -18 19 -65 -24z
              M16788 22456 c-47 -25 -108 -58 -136 -75 l-51 -30 -37 44 c-31 38
              -39 43 -56 34 -18 -10 -17 -13 19 -57 63 -78 59 -78 186 -8 54 29 129 68 167
              87 39 18 70 36 70 39 0 4 -18 7 -39 8 -28 1 -61 -10 -123 -42z
              M13750 22466 c0 -2 39 -32 86 -66 138 -100 291 -238 425 -382 174
              -187 200 -211 269 -258 64 -43 169 -94 177 -86 14 14 -14 50 -53 66 -86 37
              -161 100 -338 285 -263 275 -467 445 -532 445 -19 0 -34 -2 -34 -4z
              M16615 22460 c3 -5 12 -7 20 -3 21 7 19 13 -6 13 -11 0 -18 -4 -14
              -10z
              M16920 22387 c-91 -46 -187 -97 -215 -114 -102 -64 -136 -58 -227 40
              -57 61 -68 69 -82 58 -13 -11 -9 -20 36 -74 71 -87 109 -109 178 -104 46 3 76
              15 180 74 121 68 312 163 358 177 35 12 26 23 -21 24 -35 2 -71 -12 -207 -81z
              M13510 22456 c0 -2 37 -24 83 -50 101 -57 261 -176 384 -284 50 -45
              96 -82 101 -82 6 0 12 6 15 14 12 30 -275 273 -437 370 -44 27 -71 36 -103 36
              -24 0 -43 -2 -43 -4z
              M14010 22420 c41 -34 60 -38 60 -10 0 14 -11 22 -37 29 -57 16 -60
              14 -23 -19z
              M13160 22430 c-19 -6 -13 -8 28 -9 60 -1 173 -36 263 -82 48 -24 64
              -28 75 -18 21 17 6 30 -88 75 -75 35 -91 39 -168 40 -47 1 -96 -2 -110 -6z
              M17235 22402 c-136 -58 -308 -143 -425 -209 -58 -33 -122 -66 -144
              -73 -48 -16 -122 -8 -173 19 -39 19 -114 93 -147 144 -20 30 -43 35 -52 12 -6
              -16 91 -140 135 -172 67 -49 184 -68 262 -41 19 6 97 46 174 89 139 77 414
              205 490 229 45 14 68 30 43 30 -8 0 -28 2 -44 5 -20 4 -57 -7 -119 -33z
              M12994 22410 c-52 -21 -103 -126 -123 -251 -40 -246 41 -647 216
              -1064 70 -166 89 -234 104 -366 11 -99 10 -129 -4 -227 -19 -128 -61 -250
              -128 -368 -65 -117 -219 -268 -371 -364 -65 -41 -118 -77 -118 -80 0 -3 8 -10
              18 -16 15 -10 37 1 134 65 151 100 213 154 293 254 183 229 261 530 210 817
              -18 104 -33 151 -100 310 -99 238 -171 481 -200 680 -21 141 -16 366 9 447 20
              64 70 130 116 154 l35 19 -35 -1 c-19 0 -45 -4 -56 -9z
              M17480 22366 c-193 -67 -517 -217 -651 -302 -196 -124 -392 -89 -539
              97 -30 38 -58 69 -61 69 -4 0 -12 -7 -19 -15 -9 -11 -1 -27 44 -84 98 -122
              188 -172 323 -179 111 -6 156 7 303 89 241 134 450 228 670 300 121 39 119 36
              40 54 -8 2 -58 -11 -110 -29z
              M21524 22385 c-9 -23 -3 -27 121 -74 61 -24 115 -45 120 -47 6 -2 11
              7 11 20 2 21 -9 28 -100 64 -140 55 -145 56 -152 37z
              M12812 22373 c-17 -6 -54 -91 -46 -104 14 -23 36 -5 59 47 14 31 25
              58 25 60 0 5 -22 4 -38 -3z
              M20109 22363 c-95 -66 -287 -236 -387 -345 -45 -48 -52 -60 -43 -76
              9 -17 24 -6 138 107 116 116 237 220 305 265 32 21 41 41 24 55 -9 7 -19 6
              -37 -6z
              M14131 22347 c-9 -11 19 -44 132 -158 78 -79 174 -178 212 -220 61
              -66 191 -166 202 -155 2 2 1 15 -2 29 -4 15 -26 37 -55 56 -27 18 -106 94
              -176 169 -109 118 -287 292 -298 292 -2 0 -9 -6 -15 -13z
              M21090 22341 c0 -11 3 -21 8 -21 4 0 34 -4 67 -10 33 -5 78 -12 100
              -15 114 -18 329 -87 437 -141 58 -29 68 -30 68 -3 0 53 -385 183 -607 205 -70
              6 -73 6 -73 -15z
              M8220 22301 c11 -30 26 -63 32 -75 22 -43 110 -155 151 -193 46 -42
              136 -107 142 -101 1 1 -33 41 -77 88 -44 47 -119 137 -166 200 -109 146 -107
              145 -82 81z
              M13094 22341 c-145 -63 -144 -483 2 -923 20 -59 39 -110 43 -112 4
              -3 13 -1 21 4 12 8 9 27 -22 122 -73 222 -108 412 -109 583 0 154 25 250 70
              275 32 16 117 12 183 -11 85 -28 168 -72 271 -141 172 -115 290 -223 574 -520
              111 -117 207 -190 325 -248 76 -37 201 -82 266 -95 28 -6 32 -4 32 15 0 11 -6
              22 -12 24 -82 22 -177 54 -235 81 -141 65 -208 119 -468 380 -323 325 -480
              444 -704 534 -89 35 -195 50 -237 32z
              M17695 22305 c-225 -60 -492 -172 -750 -314 -94 -53 -185 -96 -221
              -105 -174 -45 -346 6 -476 142 -35 37 -72 78 -82 92 -16 24 -17 24 -31 6 -13
              -17 -12 -23 8 -50 12 -17 49 -58 82 -91 103 -103 236 -158 380 -158 104 1 177
              22 295 88 346 192 671 326 888 366 39 7 72 16 72 20 0 7 -47 29 -63 29 -7 -1
              -52 -12 -102 -25z
              M12651 22303 c-16 -14 -24 -37 -32 -103 -16 -120 -6 -389 20 -515 32
              -155 105 -407 119 -413 32 -12 30 19 -8 150 -43 149 -76 304 -88 407 -13 115
              -6 350 12 417 9 34 16 65 16 68 0 11 -18 6 -39 -11z
              M14405 22219 c55 -57 120 -128 144 -157 24 -28 46 -50 49 -48 9 10
              -88 133 -154 196 -119 113 -144 119 -39 9z
              M13603 22273 c-4 -10 0 -21 11 -27 41 -26 159 -117 242 -187 82 -71
              153 -142 366 -366 91 -97 156 -147 258 -201 77 -42 244 -105 253 -96 3 3 3 14
              0 24 -4 13 -26 26 -72 41 -181 60 -267 122 -511 372 -207 212 -316 311 -446
              405 -78 56 -91 61 -101 35z
              M17890 22229 c-73 -10 -110 -25 -110 -43 0 -22 13 -26 51 -16 19 4
              68 14 109 20 l74 12 -23 19 c-13 11 -28 18 -35 18 -6 -1 -36 -5 -66 -10z
              M12503 22204 c-14 -23 -17 -51 -16 -188 2 -168 10 -221 34 -221 12 0
              13 27 11 165 -2 91 1 189 6 218 10 60 -5 71 -35 26z
              M13129 22203 c-23 -62 -32 -184 -20 -292 26 -229 80 -423 206 -736
              83 -206 91 -236 114 -400 13 -91 21 -120 31 -120 21 0 24 54 10 155 -18 124
              -40 199 -118 392 -84 207 -142 387 -172 529 -25 116 -37 340 -23 404 9 38 11
              40 45 38 77 -4 282 -120 438 -247 41 -33 133 -118 205 -188 116 -115 131 -126
              145 -113 13 14 0 31 -136 164 -225 222 -416 356 -595 416 -95 33 -117 32 -130
              -2z
              M17510 22121 c-152 -56 -346 -147 -522 -244 -177 -99 -262 -127 -383
              -127 -189 0 -349 79 -488 240 -31 35 -40 41 -47 30 -16 -26 -12 -35 50 -103
              148 -161 372 -239 586 -204 98 16 162 43 349 147 136 76 360 179 497 228 67
              25 103 43 103 52 0 26 -36 21 -145 -19z
              M17946 22114 c-150 -25 -341 -82 -509 -153 -141 -59 -147 -63 -147
              -84 0 -25 15 -22 150 36 235 99 465 161 630 168 59 3 65 7 44 30 -20 22 -52
              23 -168 3z
              M20833 22123 c-7 -3 -13 -13 -13 -23 0 -17 10 -19 123 -19 251 0 524
              -67 725 -177 74 -40 84 -42 80 -11 -6 40 -235 141 -437 192 -133 34 -425 57
              -478 38z
              M12388 22093 c-43 -47 -9 -391 66 -674 37 -138 115 -366 175 -507 64
              -154 75 -203 68 -315 -5 -76 -11 -99 -46 -170 -50 -104 -103 -158 -247 -250
              -62 -39 -113 -76 -113 -82 -1 -5 3 -16 8 -23 11 -18 256 142 306 201 108 124
              161 301 135 447 -6 35 -40 134 -74 219 -173 427 -249 750 -259 1095 -2 75 -3
              77 -19 59z
              M12736 22068 c-12 -90 18 -338 64 -519 34 -133 109 -356 165 -489 75
              -178 96 -255 102 -386 12 -243 -75 -468 -249 -643 -34 -35 -121 -102 -193
              -149 -71 -46 -134 -88 -138 -92 -9 -8 1 -40 12 -40 24 0 295 192 351 248 149
              151 238 340 258 547 17 176 -12 322 -111 555 -127 300 -203 607 -215 865 -6
              118 -10 140 -23 143 -13 2 -18 -7 -23 -40z
              M13230 22059 c-14 -24 1 -203 26 -319 28 -129 93 -328 169 -516 102
              -253 135 -398 135 -595 0 -419 -216 -838 -558 -1084 -29 -20 -52 -39 -50 -40
              2 -1 15 -5 30 -9 22 -5 39 4 105 58 245 198 417 479 489 801 8 39 18 143 21
              231 9 257 -15 369 -152 708 -75 189 -111 299 -144 451 -22 101 -38 275 -26
              275 13 0 143 -78 205 -123 129 -94 283 -238 417 -389 218 -248 471 -404 763
              -469 106 -23 110 -23 110 1 0 20 -8 23 -130 50 -131 30 -276 94 -420 187 -66
              43 -139 110 -325 298 -241 244 -317 310 -455 402 -77 52 -172 103 -189 103 -6
              0 -15 -9 -21 -21z
              M18005 21993 c-239 -30 -539 -142 -903 -336 -256 -136 -301 -150
              -497 -150 -154 0 -254 22 -380 84 -80 40 -225 143 -225 160 0 5 -4 9 -10 9 -5
              0 -10 -13 -10 -29 0 -64 233 -207 410 -251 117 -30 302 -31 415 -1 106 27 187
              61 282 117 306 181 654 314 917 349 60 8 111 17 114 20 2 2 2 11 -1 20 -6 15
              -39 17 -112 8z
              M14170 21954 c0 -8 37 -58 82 -110 118 -135 234 -224 363 -277 101
              -41 105 -42 105 -13 0 19 -7 27 -37 37 -132 42 -246 122 -378 263 -103 111
              -135 134 -135 100z
              M12267 21924 c-15 -31 -16 -47 -7 -127 32 -267 120 -597 230 -862 83
              -199 93 -232 88 -315 -8 -144 -71 -236 -230 -336 -59 -38 -88 -62 -88 -74 0
              -10 3 -21 8 -23 12 -8 214 130 250 170 48 54 89 144 102 223 15 87 3 150 -51
              278 -151 357 -239 679 -269 982 -6 63 -12 116 -14 117 -1 2 -10 -13 -19 -33z
              M21314 21945 c-9 -23 0 -28 96 -61 47 -16 132 -52 189 -81 78 -40
              107 -50 113 -41 4 7 6 18 5 23 -5 15 -174 99 -269 133 -128 45 -127 45 -134
              27z
              M16014 21884 c-7 -26 0 -35 66 -96 141 -130 318 -199 515 -202 167
              -2 269 29 470 142 112 63 143 92 104 100 -8 1 -55 -20 -105 -48 -49 -28 -116
              -64 -149 -80 -274 -134 -620 -71 -837 153 l-57 58 -7 -27z
              M20855 21882 c-103 -7 -246 -36 -396 -81 -224 -68 -427 -111 -519
              -111 -39 0 -38 -14 1 -30 25 -11 47 -11 117 0 114 16 271 53 407 95 154 47
              188 55 290 71 267 41 546 -7 800 -136 88 -45 95 -47 101 -28 3 11 4 21 2 22
              -22 19 -153 83 -227 111 -167 64 -398 99 -576 87z
              M13363 21853 c-16 -40 85 -370 195 -638 83 -202 121 -382 122 -567 0
              -92 8 -109 35 -81 15 14 16 30 10 122 -14 209 -60 393 -152 609 -55 128 -138
              380 -149 447 l-7 49 64 -50 c68 -53 218 -200 394 -385 113 -119 227 -211 341
              -275 35 -19 68 -33 74 -29 23 14 6 34 -62 75 -132 78 -213 147 -404 346 -180
              188 -312 309 -399 367 -46 32 -53 33 -62 10z
              M17915 21849 c-238 -49 -472 -148 -866 -367 -57 -32 -216 -87 -279
              -96 -270 -42 -522 17 -737 170 l-63 45 0 -29 c0 -23 10 -35 54 -66 79 -55 200
              -111 301 -141 109 -32 296 -44 423 -27 110 15 254 64 349 119 103 60 374 196
              443 223 257 102 429 143 607 144 73 1 133 2 133 4 0 2 -5 12 -11 23 -9 18 -20
              20 -137 18 -78 -1 -162 -9 -217 -20z
              M10270 21791 c-48 -15 -235 -100 -297 -134 -178 -101 -349 -230 -481
              -365 -71 -71 -85 -82 -95 -69 -100 127 -134 156 -152 128 -3 -6 21 -41 53 -78
              32 -37 67 -80 76 -95 10 -16 24 -28 31 -28 7 0 50 39 96 86 108 111 237 218
              361 300 115 76 321 179 428 213 87 29 107 41 63 41 -17 0 -37 2 -45 4 -7 2
              -24 1 -38 -3z
              M20890 21764 c-142 -14 -237 -33 -399 -80 -102 -30 -195 -56 -206
              -59 -11 -2 -47 -9 -80 -16 -91 -18 -94 -20 -46 -35 42 -13 53 -12 160 16 64
              16 170 46 236 66 142 42 275 63 402 64 90 0 109 5 95 27 -8 11 -108 22 -162
              17z
              M17926 21724 c-159 -35 -295 -83 -493 -175 -58 -27 -77 -49 -54 -63
              5 -3 69 21 142 53 268 117 440 163 624 164 82 1 127 -3 143 -12 28 -15 36 -10
              28 17 -10 30 -53 40 -172 40 -77 0 -141 -7 -218 -24z
              M10390 21704 c-212 -65 -479 -209 -655 -354 -103 -85 -132 -117 -117
              -132 15 -15 13 -16 113 70 225 192 540 351 813 412 77 17 80 18 53 29 -44 17
              -84 13 -207 -25z
              M21184 21725 c-7 -18 3 -35 21 -35 29 0 203 -63 278 -100 62 -31 83
              -38 93 -29 8 6 14 15 14 18 0 17 -165 93 -273 125 -65 20 -120 36 -123 36 -2
              0 -7 -7 -10 -15z
              M9813 21681 c-121 -50 -137 -61 -360 -255 l-41 -36 -32 40 c-31 39
              -62 51 -76 29 -8 -13 94 -119 114 -119 8 0 51 34 95 76 96 90 256 211 345 261
              58 32 73 44 55 42 -5 0 -50 -17 -100 -38z
              M12175 21652 c-13 -117 79 -457 217 -797 73 -179 82 -234 48 -313
              -24 -55 -66 -99 -142 -147 -34 -21 -48 -37 -48 -52 0 -13 4 -23 8 -23 14 0
              156 100 175 123 30 37 57 94 68 147 13 62 2 114 -48 233 -114 268 -205 565
              -243 797 -7 41 -16 79 -20 83 -4 5 -11 -18 -15 -51z
              M12535 21660 c-23 -38 75 -384 185 -650 90 -218 102 -268 97 -395 -4
              -92 -9 -123 -35 -187 -61 -157 -142 -246 -335 -368 -102 -64 -115 -75 -106
              -91 5 -11 12 -19 14 -19 19 0 282 186 325 230 101 104 161 231 182 389 16 122
              -3 215 -86 419 -88 214 -137 362 -175 522 -32 132 -51 175 -66 150z
              M10655 21645 c-153 -35 -208 -51 -315 -91 -279 -105 -521 -266 -724
              -485 -95 -102 -178 -215 -174 -235 6 -33 26 -25 59 23 45 66 167 208 225 264
              204 192 457 340 723 423 108 34 251 66 296 66 41 0 45 11 10 34 -28 18 -22 18
              -100 1z
              M20765 21630 c-111 -16 -275 -70 -275 -90 0 -22 31 -21 117 5 175 54
              329 66 493 40 120 -20 152 -29 273 -78 88 -35 101 -38 114 -24 14 13 8 18 -49
              46 -93 44 -241 91 -323 101 -82 11 -278 11 -350 0z
              M17960 21610 c-197 -43 -444 -144 -703 -288 -175 -98 -263 -134 -394
              -163 -107 -24 -124 -32 -105 -51 9 -9 28 -9 83 0 124 21 251 71 411 159 307
              169 489 246 703 294 86 19 268 30 281 16 10 -10 -24 -141 -58 -227 -59 -151
              -216 -398 -358 -562 -56 -65 -68 -98 -35 -98 28 0 246 291 338 450 126 220
              193 450 140 478 -31 17 -213 12 -303 -8z
              M10829 21546 c-2 -2 -33 -6 -69 -9 -97 -9 -201 -33 -335 -78 -429
              -143 -787 -454 -990 -858 -26 -51 -50 -89 -54 -85 -4 5 -25 50 -46 99 -81 194
              -190 359 -341 514 -89 93 -103 104 -118 92 -14 -12 -6 -24 75 -109 183 -191
              295 -372 384 -617 18 -50 39 -90 46 -90 7 0 31 41 53 91 220 491 636 838 1156
              964 63 16 147 31 185 35 39 3 85 9 103 12 32 5 32 6 16 24 -15 17 -53 26 -65
              15z
              M13575 21520 c-3 -5 27 -92 68 -192 41 -101 79 -202 86 -225 13 -48
              26 -60 46 -43 12 10 9 25 -17 98 -16 48 -43 116 -59 151 -16 35 -29 68 -29 74
              0 5 57 -49 128 -120 261 -266 536 -411 905 -478 49 -9 91 -14 94 -12 13 13
              -10 47 -31 47 -47 0 -247 50 -342 86 -247 92 -427 212 -611 406 -43 45 -107
              113 -142 150 -62 66 -83 79 -96 58z
              M20805 21514 c-63 -9 -205 -47 -205 -54 0 -12 74 -20 107 -11 16 5
              78 15 136 22 92 11 121 10 219 -3 62 -9 148 -28 191 -43 99 -34 162 -20 82 18
              -96 46 -207 68 -355 72 -80 2 -158 1 -175 -1z
              M14101 21496 c-15 -18 12 -46 120 -126 135 -101 334 -189 492 -219
              46 -8 48 -8 45 12 -2 13 -13 25 -28 29 -255 73 -377 130 -519 243 -52 41 -96
              75 -97 75 -1 0 -7 -6 -13 -14z
              M17985 21490 c-183 -38 -430 -139 -668 -274 -182 -104 -335 -162
              -497 -191 -82 -14 -305 -17 -389 -6 -152 22 -314 71 -431 131 l-40 20 0 -25
              c0 -23 10 -31 74 -60 181 -82 354 -119 566 -119 260 1 493 63 700 189 96 58
              348 180 454 220 138 51 336 99 336 81 0 -2 -14 -37 -31 -77 -77 -183 -207
              -371 -452 -654 -163 -189 -266 -400 -316 -647 -29 -144 -32 -238 -6 -238 20 0
              22 9 30 95 7 96 30 203 65 307 60 177 149 326 311 513 114 132 223 274 286
              375 85 137 171 334 160 364 -8 19 -45 18 -152 -4z
              M15970 21422 c0 -23 10 -33 63 -64 80 -46 164 -81 274 -114 79 -24
              106 -27 273 -30 165 -2 195 0 275 20 111 28 204 65 313 124 84 45 104 64 84
              84 -7 7 -31 -2 -79 -30 -237 -138 -488 -188 -739 -147 -153 25 -275 69 -399
              144 l-65 40 0 -27z
              M10775 21419 c-87 -10 -285 -61 -374 -95 -313 -123 -595 -353 -776
              -632 -59 -92 -117 -210 -110 -222 15 -24 37 -5 69 60 178 354 498 633 877 765
              139 48 248 71 405 86 124 12 131 14 121 31 -13 20 -81 23 -212 7z
              M18317 21379 c-61 -196 -242 -482 -470 -743 -112 -129 -172 -216
              -228 -331 -105 -218 -142 -488 -95 -697 9 -42 16 -53 31 -53 22 0 22 2 5 100
              -34 205 10 461 116 667 52 100 82 141 209 288 186 215 313 402 408 598 58 120
              59 125 51 175 l-9 52 -18 -56z
              M20915 21379 c17 -6 82 -12 145 -15 87 -3 108 -1 85 6 -16 6 -82 13
              -145 15 -89 4 -108 3 -85 -6z
              M9077 21359 c-16 -9 -10 -18 52 -82 77 -80 178 -204 230 -285 18 -28
              37 -52 41 -52 14 0 151 180 145 190 -14 22 -37 7 -87 -55 -28 -36 -55 -62 -59
              -58 -4 5 -41 53 -82 108 -74 99 -203 245 -215 245 -4 0 -15 -5 -25 -11z
              M17825 21321 c-128 -45 -276 -114 -428 -199 -78 -44 -164 -90 -192
              -102 -127 -58 -280 -103 -385 -116 -99 -11 -125 -19 -128 -38 -4 -18 1 -19 78
              -12 187 15 367 76 605 203 88 47 169 91 180 98 75 45 315 139 315 123 0 -31
              -191 -290 -319 -433 -109 -121 -187 -237 -253 -373 -49 -100 -58 -125 -48
              -137 7 -8 16 -13 20 -10 5 3 31 53 59 112 62 129 140 246 240 361 41 48 78 89
              81 92 16 12 152 192 197 260 62 93 106 178 98 190 -9 15 -32 11 -120 -19z
              M8970 21295 c-11 -13 0 -29 72 -103 98 -100 185 -215 265 -348 52
              -88 73 -108 88 -84 9 15 -114 218 -191 315 -60 76 -206 235 -217 235 -2 0 -10
              -7 -17 -15z
              M10772 21294 c-301 -44 -591 -181 -802 -381 -39 -36 -70 -71 -70 -79
              0 -31 25 -22 78 28 184 174 418 301 670 362 103 25 343 52 387 44 26 -5 29 -1
              19 26 -5 13 -23 16 -98 15 -50 -1 -133 -7 -184 -15z
              M15960 21281 c0 -11 10 -27 22 -35 43 -30 235 -106 319 -125 119 -28
              307 -45 325 -30 28 23 -4 36 -109 42 -165 10 -366 68 -494 143 -50 29 -63 30
              -63 5z
              M10795 21175 c-301 -42 -558 -169 -771 -380 -123 -122 -187 -208
              -258 -345 -70 -135 -108 -246 -141 -406 -32 -157 -84 -347 -131 -474 -38 -105
              -134 -292 -152 -298 -28 -9 -132 262 -176 460 -24 108 -49 265 -61 373 -13
              119 -65 292 -126 422 -55 115 -166 276 -242 352 -60 58 -65 62 -72 42 -5 -17
              5 -35 53 -86 75 -80 180 -237 225 -335 73 -161 97 -258 142 -570 35 -241 110
              -499 189 -646 51 -96 67 -100 114 -29 107 158 219 472 282 790 73 367 268 664
              569 869 135 92 286 159 444 196 101 24 294 41 370 33 60 -6 63 -4 47 28 -10
              17 -21 19 -113 18 -56 -1 -142 -7 -192 -14z
              M13190 21174 c0 -9 13 -46 29 -83 185 -421 103 -898 -210 -1218 -83
              -85 -151 -139 -256 -204 -40 -24 -72 -47 -73 -51 0 -4 9 -12 19 -18 17 -8 38
              2 128 62 397 266 593 704 518 1163 -17 105 -23 125 -78 263 -31 77 -45 102
              -59 102 -10 0 -18 -7 -18 -16z
              M12190 21134 c0 -74 19 -146 85 -311 36 -89 65 -174 65 -190 0 -37
              -31 -87 -69 -109 -23 -14 -31 -26 -31 -47 0 -29 0 -29 25 -13 14 9 35 23 48
              31 25 16 61 75 70 115 8 33 -4 77 -49 185 -20 50 -61 155 -90 235 -46 125 -53
              139 -54 104z
              M12800 21145 c0 -8 25 -76 55 -149 69 -168 85 -236 85 -357 0 -120
              -25 -225 -82 -339 -60 -120 -180 -240 -340 -339 -111 -68 -137 -94 -104 -107
              24 -9 273 161 350 240 182 186 263 469 205 714 -6 26 -39 116 -72 200 -47 118
              -65 152 -79 152 -10 0 -18 -7 -18 -15z
              M8792 21128 c-8 -8 10 -33 76 -103 112 -117 206 -250 276 -391 90
              -181 133 -333 171 -614 19 -139 28 -170 48 -170 15 0 27 35 52 155 9 44 30
              135 47 203 17 67 28 126 24 130 -26 25 -70 -86 -97 -248 -20 -115 -27 -105
              -49 66 -34 263 -167 572 -340 789 -40 50 -180 195 -189 195 -4 0 -12 -5 -19
              -12z
              M18322 21096 c-61 -127 -255 -399 -395 -552 -180 -199 -272 -398
              -297 -642 -9 -86 -2 -218 16 -302 15 -70 60 -193 72 -197 29 -12 31 14 6 85
              -102 295 -55 635 124 887 19 28 75 95 123 150 113 129 250 311 323 430 51 84
              57 99 54 141 l-3 47 -23 -47z
              M17480 21029 c-69 -39 -162 -89 -207 -110 -85 -40 -102 -54 -85 -71
              14 -14 93 18 217 89 53 30 99 53 102 50 3 -3 -15 -27 -39 -54 -220 -241 -357
              -515 -419 -835 -43 -227 -22 -635 34 -646 21 -4 21 6 3 118 -21 123 -18 379 5
              513 38 212 124 429 244 606 35 53 118 159 184 236 167 194 159 217 -39 104z
              M20064 21079 c-29 -47 -41 -492 -14 -524 7 -8 16 -12 21 -9 5 3 9 92
              9 197 1 109 6 223 14 265 7 40 10 75 7 77 -8 9 -30 5 -37 -6z
              M20186 21082 c-17 -28 -30 -161 -30 -317 0 -181 14 -304 51 -433 8
              -30 13 -55 10 -58 -2 -2 -60 22 -128 55 -202 96 -366 144 -568 166 -74 8 -136
              13 -137 12 -1 -1 -5 -12 -8 -24 -7 -22 -4 -23 41 -23 43 0 132 -11 218 -26
              185 -32 418 -122 590 -228 61 -37 72 -24 47 55 -76 239 -92 455 -56 772 6 48
              4 57 -9 57 -9 0 -18 -3 -21 -8z
              M10860 21063 c-176 -13 -402 -94 -572 -205 -86 -56 -268 -223 -268
              -246 0 -35 33 -20 98 46 239 242 534 362 893 362 118 0 130 2 127 17 -2 9 -14
              20 -28 24 -25 7 -168 8 -250 2z
              M19949 21063 c-22 -5 -35 -83 -17 -95 26 -16 30 -11 43 65 3 20 2 36
              -2 36 -4 -1 -15 -3 -24 -6z
              M20289 20998 c-28 -154 -14 -452 28 -611 17 -61 28 -76 46 -58 8 8 6
              31 -6 83 -8 40 -21 104 -27 143 -14 86 -14 342 0 414 15 80 13 101 -9 101 -16
              0 -21 -12 -32 -72z
              M13875 21050 c-3 -5 -4 -14 -1 -18 16 -26 39 -229 41 -362 8 -404
              -99 -737 -339 -1060 -39 -52 -72 -98 -74 -101 -2 -4 9 -6 23 -6 19 0 37 14 73
              61 193 246 311 538 357 886 14 101 5 384 -15 475 -5 22 -7 41 -5 43 2 3 39
              -18 82 -45 201 -129 405 -209 642 -254 135 -25 151 -25 151 1 0 24 0 24 -114
              39 -246 33 -558 165 -757 322 -39 31 -54 35 -64 19z
              M8721 21033 c-8 -14 4 -31 63 -93 39 -41 98 -112 131 -157 59 -82 78
              -96 88 -69 10 26 -179 270 -244 316 -26 19 -28 19 -38 3z
              M20415 21027 c-3 -12 -9 -55 -13 -95 -6 -55 -5 -75 4 -83 22 -18 31
              -2 38 68 4 38 9 80 12 94 4 21 0 28 -16 32 -15 4 -21 0 -25 -16z
              M14370 21026 c0 -7 6 -18 13 -24 26 -21 191 -70 307 -92 36 -6 71
              -14 78 -16 7 -2 12 6 12 20 0 21 -8 24 -87 40 -49 10 -134 33 -190 52 -113 38
              -133 41 -133 20z
              M15960 21006 c0 -13 8 -27 18 -31 166 -67 316 -107 467 -124 90 -11
              113 -6 102 22 -3 9 -29 16 -79 21 -146 13 -283 47 -415 101 -46 19 -85 35 -88
              35 -3 0 -5 -11 -5 -24z
              M19824 21018 c-18 -8 -22 -22 -28 -101 -3 -49 -8 -92 -10 -94 -1 -2
              -32 3 -67 10 -85 18 -144 17 -144 -3 0 -15 12 -19 148 -45 66 -12 92 -14 102
              -5 9 8 14 37 14 97 0 47 3 101 7 120 3 18 5 33 3 32 -2 0 -13 -5 -25 -11z
              M20536 21011 c-3 -3 -10 -61 -17 -128 -10 -96 -10 -145 0 -235 26
              -240 64 -356 207 -633 143 -278 227 -508 249 -683 l7 -53 -59 26 c-184 81
              -393 227 -598 416 -229 211 -406 313 -653 378 -129 34 -275 50 -287 31 -13
              -21 8 -29 120 -45 179 -27 324 -74 472 -156 100 -55 174 -112 332 -253 217
              -194 368 -301 546 -388 56 -27 118 -51 136 -51 l34 -2 -3 55 c-11 188 -98 440
              -261 755 -101 194 -139 290 -172 425 -20 82 -24 121 -24 285 0 105 3 204 7
              221 6 27 4 33 -12 37 -11 3 -22 2 -24 -2z
              M20650 20980 c0 -6 -5 -54 -11 -106 -8 -74 -7 -99 2 -110 25 -30 37
              6 41 116 3 93 1 105 -14 108 -10 2 -18 -2 -18 -8z
              M19690 20940 l-25 -20 23 0 c12 0 25 7 29 15 9 24 0 25 -27 5z
              M20763 20903 c-22 -159 6 -392 66 -547 35 -91 118 -246 132 -246 26
              0 16 40 -40 152 -80 160 -110 278 -118 453 -3 72 -1 153 4 182 8 42 7 53 -4
              57 -28 11 -32 6 -40 -51z
              M10850 20939 c-365 -38 -712 -281 -879 -617 -75 -148 -139 -388 -113
              -414 19 -19 30 1 47 87 91 441 375 748 805 872 72 21 113 26 276 30 175 5 191
              7 187 23 -7 28 -158 37 -323 19z
              M13784 20916 c-4 -9 -1 -51 5 -93 27 -183 0 -465 -65 -676 -57 -185
              -198 -425 -341 -584 -61 -67 -62 -68 -35 -72 69 -8 293 313 386 554 39 102 68
              209 91 340 20 110 22 392 3 479 -13 59 -32 82 -44 52z
              M20885 20900 c-9 -39 -8 -224 0 -286 18 -128 52 -217 167 -444 102
              -200 151 -312 198 -451 150 -447 123 -757 -70 -818 -70 -22 -226 0 -351 50
              -62 24 -79 24 -79 0 0 -15 15 -25 58 -41 154 -58 300 -77 392 -49 64 19 140
              97 164 169 27 79 32 251 13 376 -36 225 -119 457 -275 764 -162 320 -176 367
              -177 598 0 144 -2 162 -17 162 -10 0 -19 -11 -23 -30z
              M15950 20880 c0 -24 19 -34 129 -71 291 -99 579 -114 878 -47 95 21
              124 37 101 56 -9 7 -35 5 -86 -8 -149 -37 -340 -54 -475 -40 -207 20 -288 38
              -443 96 -51 19 -95 34 -98 34 -3 0 -6 -9 -6 -20z
              M18325 20853 c-16 -27 -51 -78 -77 -114 -27 -36 -48 -73 -48 -83 0
              -36 35 -9 97 76 57 78 64 92 61 128 l-3 40 -30 -47z
              M21007 20873 c-3 -16 -4 -80 -3 -143 5 -162 33 -247 165 -510 194
              -388 268 -613 307 -933 4 -38 9 -48 22 -45 24 5 26 46 7 162 -46 274 -138 532
              -309 861 -125 240 -150 328 -149 518 0 105 -1 117 -17 117 -11 0 -20 -10 -23
              -27z
              M21134 20844 c-10 -39 2 -210 20 -279 19 -73 174 -385 192 -385 36 0
              28 24 -57 182 -26 47 -58 117 -72 155 -30 83 -51 228 -43 288 6 38 4 45 -13
              55 -18 9 -21 7 -27 -16z
              M19917 20843 c-4 -3 -7 -44 -7 -90 0 -60 -3 -83 -12 -83 -7 0 -58 11
              -113 24 -152 36 -281 52 -300 36 -31 -26 -30 -26 185 -56 19 -3 86 -19 149
              -35 63 -17 120 -28 127 -25 18 7 20 206 2 224 -14 14 -22 15 -31 5z
              M10940 20827 c-3 -3 -36 -7 -75 -10 -85 -7 -157 -25 -261 -67 -141
              -56 -239 -122 -346 -232 -150 -153 -232 -316 -283 -563 -79 -379 -194 -687
              -337 -898 -92 -135 -222 -237 -304 -237 -47 0 -109 25 -155 62 -49 39 -136
              153 -173 226 -18 36 -32 52 -46 52 -28 0 -25 -14 16 -88 71 -130 163 -231 249
              -272 62 -30 151 -27 218 6 146 74 317 314 419 588 66 178 158 507 158 565 0
              39 53 199 91 275 129 259 344 433 634 517 71 20 102 23 272 24 188 0 204 3
              185 31 -7 10 -252 30 -262 21z
              M8628 20804 c-16 -16 -7 -45 26 -81 102 -116 193 -277 241 -433 40
              -129 43 -134 60 -120 17 14 15 28 -22 145 -47 154 -143 324 -250 443 -26 30
              -51 50 -55 46z
              M21253 20723 c8 -124 25 -171 142 -403 111 -220 159 -330 211 -480
              23 -68 34 -90 39 -77 24 60 -43 245 -210 577 -116 230 -130 268 -140 380 -7
              69 -16 89 -39 90 -5 0 -6 -38 -3 -87z
              M14079 20774 c-18 -22 7 -39 140 -96 176 -76 374 -129 539 -145 57
              -5 62 -4 62 14 0 18 -12 22 -112 37 -62 10 -156 28 -208 42 -105 28 -302 103
              -366 140 -38 22 -42 23 -55 8z
              M15952 20749 c4 -29 64 -55 214 -94 275 -71 594 -71 865 -1 49 13 89
              19 89 14 0 -4 -18 -44 -40 -87 -129 -258 -195 -604 -170 -899 13 -160 27 -229
              46 -236 29 -11 32 3 16 71 -12 51 -16 125 -16 298 0 198 3 244 22 330 34 157
              81 289 158 442 39 78 68 147 64 153 -5 8 -24 5 -66 -9 -208 -69 -458 -101
              -656 -82 -144 14 -179 19 -233 33 -22 5 -51 12 -65 15 -23 4 -168 51 -213 69
              -16 6 -18 3 -15 -17z
              M12226 20670 c6 -81 10 -92 24 -70 15 23 11 62 -10 105 l-20 40 6
              -75z
              M21370 20717 c0 -52 47 -182 103 -285 63 -116 158 -316 172 -359 11
              -38 25 -26 25 20 0 36 -21 87 -114 273 -63 126 -120 249 -127 274 -19 64 -59
              117 -59 77z
              M9740 20646 c-94 -141 -175 -325 -213 -491 -9 -38 -30 -131 -47 -205
              -34 -148 -117 -410 -130 -410 -5 0 -11 8 -14 18 -2 9 -14 49 -25 87 -30 104
              -49 201 -76 390 -13 94 -31 200 -39 236 -36 154 -123 369 -150 369 -28 0 -26
              -17 9 -92 61 -133 116 -327 129 -458 26 -259 123 -647 161 -644 30 2 133 289
              179 499 78 355 122 474 248 667 46 71 56 108 29 108 -6 0 -33 -33 -61 -74z
              M10820 20684 c-168 -35 -315 -109 -439 -219 -111 -98 -226 -294 -261
              -445 -77 -332 -98 -411 -142 -540 -57 -169 -154 -375 -227 -483 -53 -79 -58
              -91 -40 -102 26 -17 105 102 198 296 96 200 160 399 226 696 41 189 53 225
              101 312 94 171 216 285 396 371 139 67 204 79 423 78 104 0 190 3 192 8 1 5
              -1 16 -7 24 -13 20 -328 24 -420 4z
              M20396 20692 c-8 -13 20 -208 44 -302 33 -129 74 -227 176 -425 91
              -177 174 -367 189 -435 l7 -34 -28 18 c-119 76 -292 214 -409 325 -118 113
              -250 205 -389 273 -79 38 -110 46 -120 30 -9 -15 9 -28 78 -60 147 -69 255
              -143 410 -285 194 -178 451 -367 498 -367 18 0 20 4 14 39 -16 104 -94 293
              -226 546 -123 238 -170 378 -194 580 -9 78 -15 100 -28 103 -9 2 -19 -1 -22
              -6z
              M14045 20668 c-2 -7 -6 -66 -9 -130 -15 -321 -121 -638 -306 -915
              -67 -103 -68 -103 -40 -103 24 0 36 13 90 96 181 279 293 634 299 942 2 106 0
              122 -14 122 -8 0 -17 -6 -20 -12z
              M18292 20588 c-39 -51 -105 -131 -146 -178 -198 -224 -269 -395 -261
              -629 2 -75 11 -130 28 -189 14 -46 27 -85 30 -88 2 -2 12 -1 21 2 12 5 16 13
              11 28 -42 143 -47 181 -43 305 4 103 10 140 32 206 33 98 95 200 174 285 32
              36 98 114 145 174 71 88 87 115 87 142 0 19 -2 34 -4 34 -2 0 -35 -42 -74 -92z
              M8598 20636 c-4 -18 8 -43 39 -88 51 -72 112 -196 141 -283 19 -60
              37 -154 67 -360 21 -145 40 -232 86 -395 44 -154 73 -229 135 -350 114 -220
              228 -301 338 -236 96 56 224 233 307 427 50 114 124 330 134 387 6 37 5 42
              -12 42 -16 0 -23 -13 -37 -62 -106 -383 -314 -739 -452 -774 -120 -30 -308
              296 -404 701 -26 107 -38 176 -81 465 -23 156 -99 343 -195 476 -53 76 -60 81
              -66 50z
              M15952 20618 c2 -19 18 -27 93 -50 120 -38 208 -57 340 -73 188 -24
              451 -17 462 12 11 28 -12 32 -122 21 -102 -10 -290 -2 -400 17 -92 17 -181 38
              -265 65 -52 16 -99 30 -103 30 -5 0 -7 -10 -5 -22z
              M20649 20618 c-5 -64 33 -226 77 -331 15 -34 64 -134 109 -222 94
              -181 171 -360 208 -485 64 -218 84 -427 42 -444 -49 -18 -303 90 -468 201 -91
              60 -240 176 -327 252 -37 33 -54 38 -65 20 -10 -15 24 -48 160 -158 262 -210
              517 -345 681 -358 72 -6 73 -5 81 102 15 207 -83 524 -278 902 -106 204 -151
              322 -172 449 -10 59 -18 80 -30 82 -9 2 -17 -2 -18 -10z
              M19416 20604 c-4 -9 -5 -18 -4 -19 2 -1 53 -8 113 -14 144 -17 261
              -43 387 -87 143 -50 148 -51 148 -26 0 16 -16 27 -80 51 -101 39 -258 79 -380
              97 -131 19 -176 18 -184 -2z
              M14167 20603 c-3 -4 -8 -59 -12 -120 -7 -102 -6 -113 9 -113 21 0 25
              9 34 100 4 41 12 74 17 73 6 -1 57 -18 114 -38 57 -19 143 -44 190 -55 107
              -24 291 -51 302 -44 5 3 9 14 9 25 0 17 -26 26 -62 20 -4 0 -69 11 -145 24
              -110 21 -352 92 -434 129 -10 4 -19 4 -22 -1z
              M10815 20551 c-146 -37 -262 -102 -366 -204 -119 -116 -185 -244
              -224 -435 -14 -64 -29 -132 -34 -151 -11 -37 -7 -51 15 -51 19 0 27 23 57 172
              29 142 61 228 114 308 99 150 241 254 423 311 70 22 89 24 278 22 189 -2 202
              -1 202 16 0 24 -59 31 -250 30 -104 0 -165 -5 -215 -18z
              M17592 20507 c-136 -215 -205 -449 -205 -692 0 -138 17 -257 55 -377
              19 -59 25 -68 46 -68 19 0 23 4 17 18 -67 170 -92 428 -60 618 30 170 106 365
              194 494 36 52 39 70 12 70 -13 0 -33 -21 -59 -63z
              M18030 20468 c-138 -155 -206 -272 -245 -421 -23 -88 -26 -192 -5
              -192 11 0 18 21 27 78 30 196 100 332 269 519 61 68 74 96 48 105 -6 2 -48
              -38 -94 -89z
              M13437 20523 c-3 -5 -8 -33 -12 -63 -26 -210 -119 -418 -268 -605
              -71 -88 -157 -165 -284 -252 -72 -50 -75 -53 -52 -62 29 -11 59 5 174 94 211
              163 370 400 442 659 20 72 42 208 36 223 -6 14 -29 18 -36 6z
              M16905 20478 c-92 -228 -137 -539 -116 -802 14 -177 20 -200 51 -204
              17 -3 21 0 17 12 -17 45 -29 246 -24 406 7 191 29 319 86 495 17 52 31 103 31
              114 0 36 -27 23 -45 -21z
              M15940 20491 c0 -19 35 -35 140 -65 166 -46 355 -65 355 -36 0 10
              -16 17 -50 22 -126 19 -238 43 -325 69 -113 34 -120 35 -120 10z
              M9892 20433 c-76 -131 -111 -238 -183 -558 -74 -330 -196 -607 -336
              -764 l-39 -45 -21 21 c-51 51 -130 192 -177 316 -50 132 -112 386 -131 535 -7
              52 -26 75 -45 56 -18 -18 55 -368 112 -539 59 -179 171 -380 233 -421 29 -19
              47 -11 101 48 158 172 303 525 380 932 29 150 86 304 157 418 15 26 26 52 22
              58 -14 23 -36 6 -73 -57z
              M18309 20414 c-31 -38 -84 -100 -118 -139 -133 -151 -182 -272 -184
              -455 -1 -86 3 -132 17 -182 20 -71 26 -78 45 -54 9 11 8 28 -4 74 -8 33 -15
              101 -15 152 0 164 46 286 157 415 158 182 165 192 161 226 l-3 33 -56 -70z
              M8616 20390 c8 -36 25 -99 39 -140 30 -93 41 -142 54 -250 6 -47 29
              -170 51 -275 80 -375 126 -513 161 -478 9 9 4 33 -19 96 -35 96 -73 234 -97
              352 -24 117 -55 306 -55 340 0 82 -87 345 -130 398 -19 22 -19 21 -4 -43z
              M10820 20427 c-138 -42 -244 -111 -337 -220 -70 -83 -108 -174 -148
              -357 -57 -261 -114 -451 -194 -648 -19 -48 -19 -53 -5 -59 24 -9 37 13 87 151
              57 155 106 328 152 536 47 214 81 286 185 391 81 81 168 133 275 164 49 14 97
              17 272 16 202 -1 211 -1 206 17 -4 16 -18 20 -101 25 -189 13 -311 8 -392 -16z
              M14277 20423 c-2 -5 -10 -46 -17 -93 -35 -241 -145 -555 -256 -732
              -30 -47 -30 -50 4 -46 24 3 34 18 92 133 93 183 164 405 201 623 10 59 16 72
              28 69 126 -38 183 -49 192 -40 17 17 2 31 -44 44 -23 6 -71 20 -106 30 -69 21
              -88 23 -94 12z
              M13666 20412 c-2 -4 -7 -25 -11 -47 -53 -301 -246 -641 -469 -825
              -64 -54 -65 -55 -38 -61 35 -7 60 11 148 103 203 214 333 457 394 734 13 59
              15 85 7 93 -13 13 -24 14 -31 3z
              M16640 20401 c-66 -5 -85 -9 -85 -21 0 -12 18 -16 93 -18 84 -2 93
              -5 87 -20 -13 -33 -44 -178 -55 -257 -18 -132 -22 -350 -8 -475 12 -108 14
              -115 36 -118 22 -3 23 -1 17 40 -35 247 -19 525 46 781 11 43 17 82 14 87 -4
              6 -18 9 -33 8 -15 -1 -65 -4 -112 -7z
              M15944 20375 c-10 -24 4 -33 89 -59 124 -38 277 -65 417 -73 125 -8
              125 -8 124 -33 -1 -35 -15 -142 -20 -161 -6 -22 20 -44 33 -27 10 14 35 161
              37 218 l1 35 -55 2 c-50 3 -195 17 -295 30 -51 6 -234 50 -286 68 -34 12 -41
              12 -45 0z
              M19355 20372 c-10 -27 -4 -32 38 -32 131 0 368 -52 523 -114 194 -79
              336 -173 543 -360 137 -125 148 -133 164 -117 9 9 -13 62 -90 214 -56 111
              -104 210 -107 220 -4 11 -13 17 -23 15 -29 -6 -18 -43 52 -182 36 -71 63 -131
              61 -134 -3 -2 -40 28 -83 67 -218 200 -475 333 -769 400 -123 28 -302 41 -309
              23z
              M14624 20335 c-9 -23 10 -33 74 -41 79 -10 103 -8 113 7 12 21 0 27
              -66 33 -33 4 -72 9 -88 12 -20 4 -29 1 -33 -11z
              M10854 20312 c-156 -47 -277 -147 -344 -287 -15 -33 -40 -116 -55
              -185 -137 -654 -368 -1119 -676 -1356 -61 -47 -197 -119 -244 -129 -58 -13
              -61 -17 -20 -29 35 -11 47 -9 100 12 398 159 723 710 885 1502 12 58 35 132
              51 166 55 118 163 210 297 256 61 20 83 22 237 20 94 -1 191 -5 218 -8 36 -4
              47 -3 47 8 -1 34 -15 36 -225 42 -185 5 -217 4 -271 -12z
              M18295 20213 c-84 -97 -135 -187 -160 -282 -20 -78 -17 -164 6 -169
              13 -2 18 10 23 70 8 81 36 174 69 228 11 19 49 68 84 108 47 54 63 80 63 102
              0 17 -2 30 -4 30 -2 0 -39 -39 -81 -87z
              M14388 20272 c-11 -2 -20 -17 -24 -40 -30 -169 -98 -377 -177 -542
              -46 -97 -51 -111 -33 -106 12 3 24 6 28 6 10 0 85 162 123 265 36 95 79 243
              91 310 9 54 20 66 49 55 29 -11 238 -49 285 -52 20 -1 41 2 48 6 20 12 14 36
              -10 37 -63 2 -240 31 -318 53 -25 7 -53 11 -62 8z
              M19340 20245 c-6 -15 -3 -20 9 -22 9 -1 69 -7 135 -14 65 -6 157 -22
              204 -35 89 -23 102 -23 102 2 0 25 -105 54 -265 73 -162 19 -176 19 -185 -4z
              M15952 20238 c2 -17 14 -27 43 -36 65 -22 245 -60 310 -66 33 -4 77
              -9 98 -12 l38 -6 -8 -71 c-4 -40 -8 -158 -8 -263 0 -196 5 -224 42 -224 11 0
              13 9 7 48 -11 72 -8 356 5 451 8 54 9 87 3 93 -6 6 -63 16 -128 23 -121 14
              -259 41 -349 69 -27 9 -51 16 -53 16 -2 0 -2 -10 0 -22z
              M14126 20241 c-3 -5 -13 -38 -21 -74 -37 -162 -137 -399 -234 -559
              -23 -37 -41 -69 -41 -72 0 -4 9 -6 20 -6 58 0 215 325 289 596 16 60 27 112
              25 116 -7 11 -32 10 -38 -1z
              M17205 20240 c-12 -19 -47 -198 -53 -275 -14 -162 -5 -330 26 -469
              16 -73 40 -112 57 -94 4 3 -3 50 -15 105 -42 185 -32 545 18 692 5 14 7 32 5
              39 -5 14 -29 16 -38 2z
              M10890 20196 c-70 -21 -91 -31 -143 -69 -90 -65 -149 -168 -172 -303
              -16 -88 -69 -297 -107 -419 -89 -286 -201 -523 -346 -733 -69 -100 -254 -280
              -339 -331 -74 -44 -84 -61 -35 -61 83 0 323 231 458 440 178 276 304 604 400
              1045 22 98 45 178 61 208 31 57 94 117 155 148 83 43 131 48 355 38 113 -5
              208 -7 210 -5 3 2 0 14 -7 25 -9 18 -20 21 -79 21 -37 0 -135 2 -217 5 -109 3
              -161 1 -194 -9z
              M14465 20118 c-2 -7 -15 -57 -30 -110 -20 -77 -86 -254 -132 -357 -4
              -10 1 -9 25 3 20 10 38 32 50 60 32 71 80 206 101 285 11 41 22 76 25 78 2 3
              52 -4 111 -14 105 -17 107 -17 121 1 18 25 15 27 -43 33 -26 2 -82 11 -123 19
              -92 17 -100 17 -105 2z
              M15980 20112 c0 -35 105 -69 270 -90 l54 -7 -3 -175 c-3 -173 -2
              -175 21 -197 l24 -23 1 215 c0 244 11 217 -92 230 -27 4 -96 18 -152 31 -113
              26 -123 28 -123 16z
              M18318 20049 c-45 -65 -67 -124 -75 -201 -9 -92 9 -71 58 62 15 41
              34 95 43 119 27 71 15 81 -26 20z
              M10903 20076 c-71 -23 -136 -78 -172 -146 -10 -19 -26 -73 -36 -120
              -125 -636 -366 -1144 -682 -1440 -71 -67 -73 -70 -41 -63 56 10 82 29 149 105
              35 40 72 82 82 93 11 11 50 67 88 124 195 294 348 694 445 1161 28 137 63 189
              158 230 53 24 108 26 380 14 141 -7 148 -6 144 12 -4 25 -43 30 -283 37 -144
              5 -200 3 -232 -7z
              M21390 20071 c0 -6 20 -59 44 -118 103 -257 150 -449 165 -678 4 -71
              9 -107 10 -80 1 28 6 100 10 160 7 96 5 123 -15 210 -28 125 -70 258 -128 400
              -34 86 -48 111 -65 113 -12 2 -21 -1 -21 -7z
              M19307 20013 c-22 -21 -2 -31 76 -37 251 -21 424 -78 613 -201 78
              -51 114 -62 114 -36 0 22 -150 120 -262 170 -90 41 -225 81 -331 97 -98 15
              -199 18 -210 7z
              M21022 19998 c-7 -7 -3 -27 15 -67 82 -179 156 -413 176 -551 24
              -166 14 -289 -28 -338 -21 -25 -29 -27 -97 -27 -91 1 -199 35 -348 111 -199
              101 -375 230 -608 446 -152 140 -319 235 -503 287 -91 25 -116 29 -241 37 -94
              7 -98 6 -98 -13 0 -19 9 -21 88 -28 309 -25 490 -108 750 -342 292 -262 545
              -429 777 -509 66 -23 97 -28 176 -29 83 0 100 3 119 20 120 107 79 478 -100
              902 -43 102 -58 121 -78 101z
              M14556 19979 c-3 -8 -21 -60 -40 -116 -19 -57 -31 -103 -27 -103 19
              0 58 57 77 113 20 61 21 62 58 63 50 1 82 44 34 44 -13 0 -40 3 -60 6 -27 5
              -39 3 -42 -7z
              M16045 19957 c10 -16 32 -26 75 -35 l60 -13 0 -73 c0 -58 4 -76 18
              -89 10 -10 20 -17 23 -17 3 0 3 181 0 211 -1 6 -152 39 -179 39 -9 0 -9 -6 3
              -23z
              M10934 19960 c-51 -16 -100 -79 -114 -146 -119 -572 -300 -1019 -535
              -1324 -31 -39 -55 -74 -53 -75 2 -2 24 9 50 25 36 22 64 55 118 136 193 293
              349 696 445 1147 29 137 35 151 78 177 35 22 127 24 395 11 74 -3 135 -5 137
              -3 1 2 1 11 -2 21 -4 17 -21 19 -141 25 -310 14 -348 15 -378 6z
              M16544 19905 c-8 -19 2 -276 12 -342 4 -29 12 -43 25 -46 18 -5 24 5
              19 28 -1 6 -5 91 -9 190 -5 160 -8 180 -24 183 -9 2 -20 -4 -23 -13z
              M10963 19833 c-16 -13 -27 -46 -43 -123 -89 -432 -256 -870 -436
              -1146 l-18 -26 40 20 c33 17 49 37 92 114 152 275 278 626 357 996 l29 132 80
              -1 c45 0 155 -4 246 -8 91 -4 168 -6 172 -4 3 1 4 11 1 22 -4 17 -20 19 -196
              25 -106 4 -217 9 -247 12 -45 4 -60 2 -77 -13z
              M19277 19773 c-4 -3 -7 -15 -7 -26 0 -15 5 -18 25 -13 22 6 151 -5
              230 -19 70 -13 216 -68 286 -109 84 -49 116 -74 289 -229 139 -124 303 -248
              437 -330 88 -54 125 -67 137 -49 8 14 -7 27 -69 61 -144 80 -339 228 -545 413
              -164 147 -247 199 -405 253 -124 42 -355 72 -378 48z
              M17763 19724 c-12 -33 54 -284 75 -284 29 1 30 19 6 90 -14 41 -28
              98 -31 125 -9 75 -34 110 -50 69z
              M11046 19709 c-3 -8 -26 -99 -52 -204 -68 -279 -165 -548 -276 -768
              -21 -44 -38 -81 -36 -82 2 -2 18 5 35 16 24 15 44 48 88 147 104 229 166 417
              262 787 l18 70 45 0 c25 -1 118 -4 208 -8 l162 -7 0 25 c0 24 -2 25 -73 25
              -41 0 -142 3 -225 7 -125 5 -152 4 -156 -8z
              M17270 19681 c0 -34 26 -176 46 -251 9 -34 23 -50 44 -50 17 0 17 3
              4 48 -25 81 -47 198 -53 285 -1 4 -10 7 -21 7 -17 0 -20 -7 -20 -39z
              M18145 19679 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17
              -11z
              M19399 19640 c-18 -12 -3 -40 23 -41 120 -6 282 -67 400 -151 25 -18
              87 -70 137 -115 50 -46 96 -83 101 -83 6 0 13 7 16 15 10 24 -184 199 -286
              259 -128 75 -350 141 -391 116z
              M19250 19620 c0 -11 4 -20 9 -20 11 0 22 26 14 34 -12 12 -23 5 -23
              -14z
              M10111 19473 c-226 -694 -606 -1032 -970 -862 -61 28 -63 28 -49 7
              42 -64 157 -105 271 -96 286 24 538 288 725 758 60 150 106 296 97 310 -18 29
              -35 1 -74 -117z
              M11146 19591 c-3 -5 -22 -71 -41 -147 -54 -210 -159 -512 -222 -637
              -13 -27 -22 -51 -20 -53 2 -3 16 1 30 7 29 13 63 87 143 307 44 121 116 370
              130 446 l7 39 162 -7 c145 -6 163 -5 169 9 3 9 6 18 6 20 0 13 -357 28 -364
              16z
              M19240 19497 c0 -23 3 -23 73 -22 146 2 313 -46 423 -123 29 -21 117
              -95 197 -165 171 -151 251 -213 406 -316 131 -86 333 -191 367 -191 52 0 20
              31 -93 87 -255 128 -445 264 -736 530 -167 152 -342 223 -546 223 -89 0 -91
              -1 -91 -23z
              M17572 19467 c-17 -20 9 -81 36 -85 26 -4 27 3 7 51 -17 42 -29 51
              -43 34z
              M11238 19464 c-3 -5 -22 -69 -42 -141 -39 -142 -102 -331 -142 -425
              -13 -32 -24 -60 -24 -63 0 -3 13 1 29 9 24 13 36 35 74 138 40 110 114 348
              131 420 l6 27 105 -6 c92 -5 105 -4 111 11 4 9 4 20 0 23 -10 11 -243 16 -248
              7z
              M19244 19384 c3 -9 6 -19 6 -24 0 -4 42 -8 93 -8 105 -1 198 -25 285
              -74 30 -17 120 -90 201 -162 239 -212 331 -275 331 -227 0 6 -44 45 -97 88
              -54 42 -143 118 -198 168 -55 50 -122 109 -150 130 -105 80 -245 125 -392 125
              -74 0 -84 -2 -79 -16z
              M11310 19278 c-12 -40 -41 -130 -64 -200 -36 -105 -40 -125 -25 -118
              32 18 56 64 88 170 18 58 37 120 42 138 10 30 14 32 54 32 40 0 55 11 55 38 0
              4 -29 8 -65 10 l-64 3 -21 -73z
              M19294 19265 c-9 -23 9 -33 66 -33 60 0 152 -26 210 -59 33 -20 201
              -161 240 -203 34 -36 234 -191 345 -267 217 -149 458 -264 667 -319 53 -13 71
              -14 127 -3 l66 13 -82 13 c-344 54 -793 313 -1188 684 -132 124 -235 175 -372
              186 -63 5 -73 4 -79 -12z
              M20165 19180 c-10 -16 8 -33 109 -108 199 -148 415 -264 591 -318 77
              -24 107 -28 215 -28 107 0 133 3 182 22 67 27 148 96 184 158 37 63 66 164 52
              181 -19 23 -26 15 -48 -54 -42 -132 -104 -204 -209 -244 -84 -31 -227 -29
              -346 6 -183 54 -377 155 -583 305 -68 50 -127 90 -132 90 -5 0 -12 -5 -15 -10z
              M14395 19149 c-279 -105 -890 -276 -1230 -344 -97 -20 -134 -31 -147
              -47 -29 -33 -22 -37 40 -23 31 7 93 21 137 30 106 22 387 89 505 121 290 79
              861 263 885 285 5 5 -15 9 -50 8 -41 0 -85 -10 -140 -30z
              M14760 19149 c-225 -91 -753 -272 -906 -310 -46 -12 -67 -35 -44 -49
              13 -8 32 -3 305 82 289 90 798 278 824 303 2 3 -20 5 -50 4 -36 0 -79 -10
              -129 -30z
              M14090 19160 c-36 -4 -101 -19 -145 -33 -134 -43 -431 -122 -605
              -162 -145 -32 -169 -41 -195 -67 -24 -24 -26 -29 -10 -25 11 3 65 15 120 27
              161 34 406 97 605 155 284 84 332 100 324 108 -4 4 -12 6 -18 6 -6 -1 -40 -5
              -76 -9z
              M15050 19131 c-340 -150 -742 -292 -1235 -435 -178 -52 -730 -178
              -882 -201 -30 -5 -44 -12 -48 -27 -4 -11 -5 -21 -3 -23 13 -13 544 99 803 170
              50 14 133 36 185 50 384 105 977 319 1310 473 l65 31 -55 0 c-42 0 -75 -9
              -140 -38z
              M15410 19153 c-8 -3 -78 -33 -155 -66 -262 -112 -300 -128 -438 -183
              -268 -107 -741 -266 -932 -314 -16 -4 -66 -18 -110 -30 -174 -48 -466 -116
              -665 -155 -243 -48 -245 -48 -248 -74 -3 -18 0 -22 15 -18 19 6 57 13 113 22
              214 36 762 165 992 235 496 151 753 245 1240 454 136 58 258 108 272 112 47
              11 38 24 -16 23 -29 0 -60 -3 -68 -6z
              M19310 19153 c0 -24 28 -41 75 -45 78 -7 136 -41 248 -141 272 -245
              432 -360 588 -426 66 -28 123 -51 127 -51 4 0 -31 23 -78 51 -175 105 -336
              223 -508 374 -199 174 -227 195 -282 216 -61 23 -170 37 -170 22z
              M15575 19086 c-155 -59 -212 -82 -395 -163 -504 -223 -1171 -442
              -1725 -568 -88 -20 -189 -43 -225 -51 -36 -8 -76 -17 -90 -19 -14 -2 -52 -9
              -85 -15 -33 -7 -91 -17 -129 -24 -70 -12 -83 -20 -68 -44 9 -14 57 -7 307 43
              72 15 141 29 155 31 14 3 48 11 75 19 28 7 104 25 170 40 66 15 140 33 165 40
              25 7 72 20 105 29 416 112 948 297 1300 451 230 100 477 200 583 236 56 18
              102 36 102 39 0 3 -24 6 -52 7 -43 2 -79 -7 -193 -51z
              M21590 19111 c0 -19 -7 -64 -16 -100 -47 -190 -184 -326 -362 -361
              -78 -16 -256 -8 -327 14 -67 21 -95 21 -95 -2 0 -18 79 -46 170 -60 95 -14
              252 -5 319 17 71 25 152 74 193 117 64 68 155 351 127 395 -6 10 -9 4 -9 -20z
              M15855 19056 c-233 -78 -301 -105 -597 -232 -148 -63 -328 -137 -400
              -165 -146 -55 -168 -69 -145 -88 12 -9 53 4 203 63 104 40 245 98 314 129 335
              148 709 285 880 323 l35 8 -30 7 c-62 14 -105 7 -260 -45z
              M16255 19048 c-285 -73 -631 -194 -910 -318 -437 -194 -932 -372
              -1360 -490 -349 -96 -463 -123 -819 -194 -143 -29 -261 -57 -263 -62 -1 -5 1
              -15 5 -21 8 -13 168 13 412 67 52 11 117 25 144 30 27 6 65 14 85 20 20 5 72
              18 116 29 44 11 141 36 215 56 74 20 158 43 185 50 308 83 841 276 1255 455
              129 56 305 127 391 160 182 68 539 176 702 211 31 7 55 14 53 16 -2 2 -25 7
              -52 11 -39 7 -72 2 -159 -20z
              M19380 19034 c0 -17 25 -46 50 -59 16 -8 78 -58 137 -111 127 -114
              135 -119 205 -145 l53 -19 -25 21 c-110 94 -243 211 -260 230 -31 33 -160 101
              -160 83z
              M10032 18974 c-113 -190 -247 -345 -370 -425 -85 -56 -174 -87 -291
              -104 l-86 -12 38 -18 c36 -18 41 -18 123 -2 228 44 433 215 603 503 22 38 41
              78 41 87 0 32 -31 17 -58 -29z
              M16670 19014 c-423 -76 -808 -195 -1265 -391 -427 -183 -593 -246
              -945 -364 -420 -139 -926 -271 -1325 -344 -192 -36 -194 -36 -176 -63 9 -14
              20 -13 166 15 196 37 382 78 560 122 586 144 1132 330 1710 581 325 141 607
              240 900 315 159 41 412 93 498 102 32 3 54 9 50 13 -10 10 -139 20 -173 14z
              M8926 18963 c17 -73 49 -125 112 -184 152 -142 332 -167 495 -69 70
              43 88 63 69 82 -9 9 -21 5 -54 -19 -170 -121 -333 -104 -489 49 -32 31 -75 82
              -95 113 -20 30 -39 55 -41 55 -2 0 0 -12 3 -27z
              M16965 18934 c-548 -71 -960 -187 -1540 -434 -401 -171 -577 -238
              -945 -363 -369 -126 -1072 -303 -1433 -362 -34 -5 -36 -8 -26 -26 14 -27 21
              -27 414 57 296 63 774 195 1115 307 275 91 680 248 885 342 495 229 1178 412
              1680 451 42 3 98 9 123 13 l47 7 -75 15 c-70 14 -86 14 -245 -7z
              M17485 18854 c-121 -4 -413 -27 -505 -39 -19 -3 -55 -7 -80 -10 -231
              -30 -656 -128 -865 -200 -221 -76 -371 -135 -605 -235 -728 -312 -1399 -522
              -2160 -676 -96 -20 -176 -37 -178 -38 -1 -2 7 -11 17 -20 21 -19 16 -20 201
              19 370 78 604 137 950 241 466 139 746 242 1305 482 211 90 446 175 640 232
              127 37 461 113 565 129 215 32 283 40 365 46 50 3 104 9 120 12 17 3 154 9
              305 12 l275 6 -90 19 c-101 22 -132 24 -260 20z
              M20217 18834 c-13 -14 9 -36 83 -84 271 -173 519 -265 746 -277 132
              -6 170 1 219 43 20 17 24 24 11 20 -10 -4 -60 -11 -110 -18 -218 -27 -529 72
              -826 261 -93 59 -110 67 -123 55z
              M13520 18750 c-43 -14 -376 -88 -504 -111 -52 -10 -72 -19 -83 -36
              -9 -13 -14 -25 -12 -27 2 -2 42 4 89 15 605 133 595 130 588 152 -5 15 -44 19
              -78 7z
              M17200 18715 c-598 -50 -1110 -186 -1730 -460 -440 -195 -1002 -390
              -1515 -526 -170 -46 -507 -124 -635 -149 -149 -28 -143 -26 -130 -50 13 -25 6
              -26 425 70 233 53 383 91 530 134 50 14 115 33 145 42 287 80 801 268 1190
              434 447 191 610 248 990 344 87 21 351 73 420 81 30 3 82 10 115 15 33 5 98
              12 145 16 47 3 130 10 185 14 333 24 916 -7 1223 -65 75 -15 130 -19 121 -11
              -7 7 -271 73 -340 85 -226 38 -828 52 -1139 26z
              M17379 18606 c-2 -2 -58 -6 -123 -10 -174 -8 -462 -50 -698 -100
              -299 -64 -677 -191 -1001 -335 -640 -285 -1402 -529 -2127 -682 -85 -18 -157
              -34 -159 -36 -2 -1 3 -11 13 -22 16 -17 21 -18 74 -5 31 7 149 34 262 60 113
              25 255 60 315 76 61 17 142 38 180 48 340 87 857 266 1275 441 102 42 214 90
              250 107 117 53 405 160 542 201 396 118 689 173 1078 202 169 13 200 20 179
              45 -10 13 -50 19 -60 10z
              M17635 18600 c-14 -22 11 -27 157 -34 177 -8 467 -32 571 -46 128
              -18 221 -31 252 -36 47 -7 183 -33 260 -50 39 -8 102 -22 140 -30 618 -134
              1318 -380 2113 -742 72 -34 132 -59 132 -57 0 9 -132 96 -200 132 -94 49 -299
              139 -545 238 -569 230 -1159 411 -1660 510 -44 8 -102 20 -130 25 -334 66
              -1067 127 -1090 90z
              M14361 18483 c-228 -76 -508 -157 -750 -216 -165 -40 -197 -55 -174
              -78 25 -25 1111 285 1155 329 14 14 1 32 -20 31 -9 0 -104 -30 -211 -66z
              M17400 18487 c-3 -2 -59 -7 -125 -10 -121 -6 -337 -33 -495 -61 -144
              -26 -226 -44 -410 -92 -185 -48 -457 -144 -682 -240 -546 -235 -874 -355
              -1396 -514 -123 -37 -307 -86 -532 -140 -265 -63 -293 -70 -336 -76 -58 -9
              -71 -18 -54 -39 9 -10 22 -14 39 -10 14 3 39 8 54 10 50 10 410 94 507 120
              575 151 1139 347 1659 575 203 89 357 148 532 205 540 173 1157 256 1692 226
              274 -16 303 -18 482 -41 909 -116 1834 -414 2979 -959 59 -29 110 -50 113 -47
              3 2 -5 18 -17 34 -21 28 -205 120 -530 263 -734 323 -1445 556 -2068 679 -215
              42 -539 89 -727 105 -89 7 -679 18 -685 12z
              M11568 18423 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z
              M17400 18363 c-596 -35 -1105 -152 -1625 -373 -172 -73 -195 -85
              -195 -102 0 -24 41 -13 191 50 153 65 278 113 424 160 126 42 249 75 405 110
              146 33 282 59 345 66 22 2 76 9 120 15 166 22 562 44 660 36 33 -2 119 -7 190
              -10 153 -7 223 -12 325 -25 41 -5 98 -12 125 -15 28 -3 75 -10 105 -15 30 -5
              73 -11 95 -15 316 -44 858 -184 1322 -341 65 -22 85 -26 95 -16 9 9 9 15 1 23
              -19 19 -438 154 -668 215 -33 9 -73 20 -90 25 -32 10 -247 61 -360 85 -135 29
              -396 72 -570 94 -240 31 -670 46 -895 33z
              M11715 18350 c-66 -4 -198 -8 -292 -9 -170 -1 -235 -12 -268 -44 -9
              -8 380 -3 580 8 190 11 265 18 265 24 0 4 -11 13 -25 19 -27 13 -65 13 -260 2z
              M12020 18250 c-180 -21 -735 -41 -910 -33 -113 5 -129 4 -165 -15
              l-40 -20 70 -7 c65 -7 679 1 805 10 233 17 366 29 369 31 6 5 -43 43 -54 42
              -5 0 -39 -4 -75 -8z
              M17345 18240 c-390 -26 -805 -106 -1160 -225 -159 -53 -265 -95 -578
              -229 -535 -229 -1138 -432 -1682 -565 -93 -23 -195 -48 -225 -55 -30 -8 -73
              -17 -95 -21 -51 -9 -59 -14 -44 -31 16 -19 41 -18 166 11 397 93 834 219 1163
              335 80 28 165 58 190 67 100 34 429 167 635 256 505 217 812 306 1299 377 192
              28 318 35 631 35 276 0 439 -7 580 -25 44 -5 103 -12 130 -16 206 -24 640
              -111 845 -169 25 -7 74 -21 110 -30 408 -110 1029 -335 1435 -520 17 -7 75
              -33 130 -58 151 -67 437 -203 554 -264 57 -30 106 -52 108 -49 3 3 3 16 -1 29
              -4 19 -51 46 -268 150 -253 121 -571 267 -582 267 -3 0 -35 14 -72 30 -302
              139 -930 361 -1309 463 -33 9 -91 25 -130 36 -63 17 -186 45 -375 86 -197 42
              -581 97 -765 109 -153 11 -564 14 -690 6z
              M13170 18169 c-47 -10 -125 -25 -175 -34 -49 -8 -98 -18 -107 -21
              -19 -6 -24 -29 -9 -39 18 -10 405 65 423 83 15 15 1 32 -25 31 -12 -1 -60 -10
              -107 -20z
              M11985 18125 c-251 -26 -756 -40 -1025 -27 -160 7 -203 6 -237 -6
              -24 -7 -45 -17 -48 -21 -6 -11 179 -16 560 -16 424 0 597 7 915 39 25 3 57 5
              73 5 25 1 26 3 15 21 -15 24 -63 25 -253 5z
              M17415 18120 c-624 -40 -1096 -153 -1635 -392 -293 -130 -714 -292
              -1017 -392 -116 -39 -134 -49 -114 -69 8 -8 42 -1 129 27 305 100 730 263
              1012 389 415 185 795 294 1245 356 227 32 235 33 595 36 299 3 345 5 348 18 2
              8 -5 18 -15 21 -35 10 -420 14 -548 6z
              M18185 18080 c-13 -21 8 -28 140 -45 72 -9 137 -18 145 -20 8 -2 35
              -7 60 -10 25 -3 135 -24 245 -46 190 -38 276 -59 460 -109 44 -12 89 -25 100
              -27 102 -25 442 -136 660 -216 258 -94 646 -254 880 -362 199 -93 469 -223
              562 -272 57 -29 105 -53 108 -53 3 0 5 11 5 24 0 22 -25 37 -237 141 -930 459
              -1782 769 -2528 918 -191 39 -233 47 -275 52 -25 3 -68 10 -96 15 -102 18
              -221 23 -229 10z
              M12220 18023 c-552 -54 -1019 -64 -1540 -33 -130 7 -148 6 -180 -10
              l-35 -18 55 -7 c140 -17 382 -23 815 -20 375 3 516 8 695 24 267 25 283 28
              276 48 -3 8 -6 17 -6 19 0 4 -17 4 -80 -3z
              M17310 17994 c-86 -5 -293 -31 -405 -50 -400 -70 -717 -168 -1135
              -352 -486 -213 -1024 -403 -1555 -546 -208 -56 -300 -81 -384 -100 -35 -9 -65
              -17 -67 -19 -2 -2 4 -11 14 -20 16 -15 29 -13 172 23 607 152 1158 333 1670
              550 107 45 242 102 300 127 357 151 811 278 1100 308 30 4 61 8 69 11 30 10
              424 34 556 34 130 0 551 -25 585 -35 8 -2 42 -6 75 -10 85 -8 327 -49 480 -81
              458 -97 969 -256 1487 -463 343 -137 872 -379 1170 -535 58 -31 109 -56 112
              -56 3 0 6 11 6 24 0 21 -30 38 -266 155 -224 111 -353 171 -719 336 -64 29
              -459 187 -552 221 -305 111 -436 155 -683 226 -172 49 -198 56 -360 93 -339
              78 -441 96 -790 141 -120 15 -716 27 -880 18z
              M12339 17916 c-2 -2 -38 -7 -79 -10 -41 -4 -142 -13 -223 -21 -427
              -42 -1254 -40 -1627 4 -50 6 -72 5 -97 -7 -18 -9 -33 -19 -33 -22 0 -4 21 -8
              48 -8 26 -1 83 -6 127 -12 425 -50 1187 -43 1745 15 20 2 68 7 108 10 39 4 72
              10 72 12 0 22 -29 50 -41 39z
              M17335 17874 c-503 -37 -978 -158 -1470 -374 -573 -252 -1239 -481
              -1805 -620 -96 -24 -178 -47 -183 -50 -4 -4 1 -14 10 -22 16 -15 31 -12 173
              24 581 148 1262 379 1720 583 251 111 508 209 655 250 204 55 278 74 335 84
              36 7 85 17 110 22 256 53 688 78 1005 60 172 -10 499 -44 545 -56 8 -2 38 -6
              65 -10 28 -3 68 -10 90 -15 22 -5 56 -11 75 -15 56 -8 198 -40 375 -84 630
              -153 1412 -455 2170 -835 l299 -151 38 -95 39 -95 -5 71 c-3 39 -14 90 -24
              115 -20 44 -20 44 -278 173 -937 467 -1792 781 -2534 931 -47 9 -103 21 -125
              25 -61 13 -231 38 -415 61 -128 16 -238 21 -490 24 -179 1 -347 1 -375 -1z
              M20111 17856 c-18 -21 3 -35 129 -82 313 -117 854 -356 1185 -523 43
              -22 81 -39 82 -37 2 2 -1 15 -7 30 -8 22 -41 42 -177 108 -372 178 -771 352
              -1090 473 -84 32 -115 40 -122 31z
              M15255 17774 c-391 -157 -952 -337 -1345 -432 -47 -12 -130 -32 -185
              -45 -55 -13 -139 -33 -187 -43 -49 -10 -88 -21 -88 -25 0 -3 10 -11 23 -18 30
              -16 142 9 707 155 346 89 1224 396 1260 439 13 16 5 36 -13 34 -7 -1 -84 -30
              -172 -65z
              M12305 17790 c-262 -34 -715 -60 -1058 -60 -350 0 -636 15 -1030 56
              -57 6 -122 -9 -112 -26 4 -7 60 -14 190 -26 22 -2 81 -8 130 -13 50 -6 140
              -13 200 -16 61 -3 137 -8 170 -10 305 -22 914 -10 1265 25 52 5 124 12 160 15
              75 6 224 25 228 29 1 2 0 11 -4 20 -7 18 -37 19 -139 6z
              M17310 17750 c-154 -13 -315 -36 -485 -69 -145 -29 -173 -35 -335
              -80 -149 -40 -345 -113 -617 -229 -324 -139 -550 -227 -828 -324 -251 -87
              -701 -224 -905 -274 -85 -21 -157 -39 -159 -41 -2 -1 2 -10 10 -19 17 -21 43
              -16 257 42 571 154 1083 333 1632 569 268 116 384 160 564 214 194 59 466 120
              611 137 28 3 77 10 110 15 73 11 330 24 480 24 429 0 886 -61 1355 -180 74
              -19 146 -38 160 -41 155 -39 595 -184 844 -278 143 -54 443 -178 571 -236 33
              -15 98 -44 145 -65 47 -21 220 -104 385 -184 295 -144 301 -148 316 -187 113
              -279 173 -421 180 -428 5 -5 6 18 3 54 -5 47 -26 111 -85 254 l-79 191 -168
              84 c-966 487 -1812 798 -2594 955 -107 21 -201 37 -373 62 -260 37 -753 54
              -995 34z
              M9945 17676 c-16 -12 -17 -16 -5 -20 35 -12 488 -61 660 -71 551 -33
              695 -32 1285 0 150 8 604 52 634 61 12 4 11 9 -5 24 -19 19 -23 19 -139 6
              -660 -80 -1413 -87 -2065 -21 -85 8 -164 17 -175 20 -11 2 -54 7 -95 10 -58 5
              -79 3 -95 -9z
              M17340 17630 c-224 -16 -430 -50 -673 -110 -272 -67 -394 -110 -782
              -275 -549 -234 -1069 -415 -1632 -566 -157 -42 -162 -44 -145 -62 18 -17 21
              -17 98 3 675 178 1151 346 1894 668 255 110 698 232 965 266 166 21 209 26
              280 31 106 7 507 7 620 0 81 -5 363 -38 425 -50 14 -2 43 -7 65 -10 57 -8 223
              -41 335 -66 52 -11 111 -24 130 -29 19 -4 47 -11 62 -15 21 -5 29 -3 34 10 4
              9 3 20 -2 25 -8 7 -284 72 -364 85 -19 3 -66 12 -105 19 -260 52 -505 76 -820
              81 -148 2 -322 0 -385 -5z
              M9785 17583 c-27 -15 -28 -17 -10 -24 11 -4 76 -15 145 -24 69 -9
              156 -21 194 -26 262 -36 799 -69 1126 -69 222 0 693 22 860 39 52 6 122 13
              155 16 121 10 340 37 345 41 6 7 -17 39 -27 38 -4 -1 -39 -5 -78 -8 -38 -4
              -97 -11 -130 -16 -33 -5 -98 -11 -145 -15 -47 -3 -96 -8 -110 -10 -78 -15
              -579 -35 -873 -35 -291 0 -748 19 -852 35 -16 2 -61 7 -100 10 -94 7 -379 45
              -450 60 -11 3 -33 -3 -50 -12z
              M17594 17507 c-3 -9 -3 -20 0 -25 3 -5 102 -12 221 -15 708 -21 1547
              -234 2485 -632 220 -94 567 -253 750 -345 96 -48 125 -59 136 -49 7 6 11 16 7
              22 -9 15 -431 218 -683 329 -1051 464 -1917 694 -2722 724 -167 6 -188 5 -194
              -9z
              M9630 17482 c-22 -18 -21 -18 71 -35 179 -32 411 -63 614 -82 155
              -15 206 -18 485 -35 211 -13 667 -13 880 0 91 5 208 12 260 15 117 7 354 29
              520 49 237 29 229 27 211 55 -9 15 -37 13 -261 -14 -163 -20 -417 -44 -540
              -50 -583 -31 -744 -31 -1260 0 -113 7 -454 41 -560 55 -146 21 -286 42 -333
              51 -63 12 -61 12 -87 -9z
              M17120 17485 c-266 -36 -540 -101 -772 -183 -120 -43 -145 -53 -448
              -183 -508 -217 -1023 -396 -1530 -534 -74 -20 -142 -39 -150 -41 -12 -4 -10
              -9 9 -23 22 -17 29 -17 65 -6 23 7 124 37 226 65 321 90 675 210 1078 365 52
              20 178 72 280 117 275 118 439 183 591 232 176 56 493 129 621 141 152 16 225
              30 225 45 0 20 -71 22 -195 5z
              M19124 17405 c-9 -23 2 -31 63 -44 103 -23 642 -206 863 -294 369
              -146 615 -255 1012 -449 l247 -121 20 -46 c10 -25 67 -163 125 -306 140 -343
              168 -408 177 -419 4 -4 5 20 2 54 -5 50 -34 131 -139 389 -74 179 -138 333
              -142 341 -16 30 -565 298 -936 457 -347 148 -785 309 -1085 398 -101 30 -187
              55 -192 55 -5 0 -12 -7 -15 -15z
              M9516 17381 c-16 -18 -15 -19 31 -29 122 -27 555 -89 741 -107 52 -5
              63 -3 69 10 9 24 -10 32 -96 39 -151 13 -547 70 -707 101 -12 3 -28 -3 -38
              -14z
              M17609 17396 c-3 -3 -64 -6 -137 -7 -182 -2 -439 -34 -662 -82 -242
              -53 -514 -145 -800 -272 -58 -26 -150 -65 -205 -86 -106 -43 -132 -60 -120
              -79 10 -17 28 -11 302 106 136 58 295 123 352 145 208 79 595 176 771 194 30
              4 84 11 120 16 107 17 665 20 815 4 244 -27 330 -40 570 -87 423 -83 948 -244
              1419 -435 106 -43 137 -45 123 -10 -5 14 -23 22 -292 124 -413 157 -848 287
              -1195 357 -47 10 -103 22 -125 26 -139 30 -536 77 -670 79 -49 1 -129 4 -176
              7 -47 2 -87 2 -90 0z
              M12729 17356 c-12 -12 -643 -81 -829 -91 -317 -17 -447 -23 -555 -24
              -117 -2 -144 -8 -131 -30 20 -32 880 10 1226 59 36 5 88 12 115 15 132 15 205
              29 205 41 0 13 -24 36 -31 30z
              M9437 17283 c-20 -19 -1 -32 66 -46 93 -20 351 -62 442 -72 28 -3 77
              -10 110 -15 33 -5 89 -12 125 -15 36 -3 101 -10 145 -15 432 -52 1234 -53
              1790 -4 234 20 260 23 340 34 39 6 93 12 120 15 28 3 79 9 115 15 36 5 86 12
              113 15 50 7 57 14 31 36 -13 11 -37 10 -142 -5 -70 -10 -138 -19 -152 -21
              -121 -17 -488 -52 -625 -60 -55 -3 -165 -10 -245 -15 -207 -12 -635 -12 -840
              0 -314 18 -353 21 -545 40 -280 29 -519 62 -715 100 -116 22 -124 23 -133 13z
              M17699 17275 c-3 -2 -81 -5 -174 -6 -289 -3 -607 -51 -895 -134 -199
              -58 -298 -95 -610 -228 -523 -222 -882 -351 -1378 -498 -100 -29 -182 -56
              -182 -59 0 -3 9 -11 20 -18 18 -11 43 -6 173 33 547 165 901 293 1431 520 444
              189 725 269 1131 321 272 35 807 22 1095 -26 30 -6 73 -12 94 -15 66 -8 332
              -64 481 -101 469 -117 1095 -342 1615 -582 319 -146 527 -249 532 -263 248
              -605 501 -1216 507 -1222 4 -4 12 3 18 15 8 19 -13 78 -134 373 -378 919 -356
              868 -389 890 -43 29 -548 266 -764 358 -575 246 -1071 410 -1580 523 -266 59
              -654 110 -863 113 -61 1 -114 4 -117 6 -4 3 -9 3 -11 0z
              M10553 17254 c-3 -9 -2 -19 3 -24 10 -9 328 -30 465 -30 83 0 114 10
              93 30 -5 4 -112 13 -239 20 -126 6 -249 13 -273 15 -32 3 -45 0 -49 -11z
              M14460 17240 c-74 -23 -405 -113 -535 -145 -298 -72 -279 -66 -258
              -86 15 -15 25 -17 58 -9 294 71 373 91 645 168 113 31 135 40 135 56 0 23 -11
              27 -45 16z
              M9380 17157 c0 -20 8 -24 103 -40 56 -11 125 -23 152 -28 114 -21
              403 -61 530 -74 33 -3 96 -10 140 -15 239 -27 571 -42 930 -43 367 0 645 13
              945 43 52 5 122 12 155 15 198 19 581 72 597 82 5 3 2 13 -6 22 -15 16 -23 17
              -84 6 -37 -7 -112 -18 -167 -24 -55 -7 -131 -17 -170 -22 -374 -50 -787 -74
              -1265 -74 -640 0 -1137 43 -1691 146 -85 16 -158 29 -162 29 -4 0 -7 -10 -7
              -23z
              M17220 17130 c-217 -27 -382 -61 -571 -116 -145 -42 -336 -110 -435
              -155 -427 -194 -992 -404 -1466 -545 -87 -26 -158 -50 -158 -54 0 -4 10 -11
              22 -17 25 -11 43 -6 358 94 379 120 790 274 1140 428 488 214 849 307 1309
              336 455 30 992 -34 1546 -183 125 -33 138 -37 365 -109 417 -131 1002 -371
              1480 -607 135 -67 114 -36 260 -392 51 -124 130 -317 177 -430 46 -113 112
              -274 146 -357 34 -84 64 -153 67 -153 3 0 10 8 17 19 10 15 -2 50 -68 212 -82
              198 -134 324 -324 789 -60 146 -113 271 -119 279 -9 12 -157 86 -361 181 -729
              339 -1421 582 -1975 694 -47 10 -103 22 -125 26 -45 10 -224 37 -300 46 -27 3
              -79 10 -115 16 -36 5 -213 12 -395 13 -273 3 -355 0 -475 -15z
              M9343 17045 c-7 -21 -1 -25 39 -31 18 -3 49 -9 68 -14 120 -30 526
              -89 787 -115 158 -15 185 -18 448 -36 218 -16 890 -16 1110 -1 94 7 202 15
              240 17 191 13 487 46 730 82 94 14 191 27 218 30 30 4 47 10 47 19 0 29 -34
              32 -135 15 -93 -17 -219 -34 -325 -46 -25 -3 -79 -10 -120 -15 -94 -12 -353
              -36 -575 -52 -260 -20 -1054 -17 -1335 5 -124 10 -263 22 -310 27 -47 6 -123
              15 -170 20 -47 6 -105 13 -130 15 -42 5 -76 11 -315 51 -49 8 -121 22 -159 30
              -87 18 -105 18 -113 -1z
              M17290 17015 c-58 -7 -130 -16 -160 -20 -100 -12 -380 -75 -508 -115
              -134 -41 -296 -104 -552 -215 -348 -151 -881 -346 -1202 -440 -68 -20 -130
              -38 -138 -41 -12 -4 -10 -9 9 -23 23 -17 26 -17 110 10 47 15 138 44 201 64
              365 116 814 287 1190 453 246 109 537 199 795 246 211 38 348 49 615 49 326 0
              449 -12 914 -94 51 -9 218 -48 316 -74 541 -144 1033 -328 1665 -621 88 -41
              191 -92 229 -112 l68 -37 119 -290 c165 -399 291 -707 352 -852 27 -68 53
              -123 57 -123 38 0 24 40 -233 666 l-262 639 -70 35 c-330 165 -847 388 -1160
              502 -441 160 -960 302 -1250 343 -22 4 -78 12 -125 20 -271 44 -744 59 -980
              30z
              M9310 16920 c0 -14 9 -21 38 -25 20 -3 57 -10 82 -16 230 -48 720
              -114 995 -133 39 -3 144 -11 235 -18 246 -19 940 -16 1235 5 336 24 536 44
              548 56 34 34 -41 37 -259 11 -44 -5 -124 -11 -179 -15 -55 -3 -174 -10 -265
              -15 -212 -13 -787 -13 -1000 0 -278 16 -342 21 -430 30 -47 5 -112 12 -145 15
              -180 17 -555 74 -729 111 -97 20 -126 19 -126 -6z
              M12965 16900 c-215 -36 -315 -50 -345 -50 -33 0 -51 -23 -32 -42 8
              -8 43 -6 124 6 230 35 314 48 366 58 52 9 53 10 36 29 -20 22 -9 22 -149 -1z
              M17405 16905 c-11 -2 -69 -9 -130 -15 -227 -23 -501 -84 -718 -159
              -62 -21 -209 -80 -327 -131 -427 -183 -813 -329 -1175 -444 -99 -31 -187 -60
              -195 -63 -11 -4 -6 -9 15 -20 30 -14 41 -11 304 77 399 133 724 257 1036 395
              378 167 643 245 1005 295 360 49 830 26 1270 -61 600 -119 1211 -329 1968
              -678 74 -34 138 -59 143 -56 25 16 2 38 -78 76 -610 286 -1183 498 -1702 630
              -63 16 -128 32 -145 35 -17 2 -47 9 -66 14 -41 11 -176 37 -235 45 -22 3 -63
              10 -90 16 -137 26 -329 41 -585 44 -151 2 -284 2 -295 0z
              M15415 16794 c-161 -61 -576 -200 -760 -254 -99 -29 -214 -63 -255
              -75 l-75 -22 29 -15 c33 -17 10 -22 326 73 448 134 863 283 868 312 5 26 -29
              21 -133 -19z
              M9310 16800 c0 -12 8 -21 23 -24 348 -75 826 -137 1287 -167 236 -16
              998 -16 1240 0 303 19 644 53 870 86 175 25 495 78 499 82 3 2 -3 11 -12 20
              -15 15 -24 16 -94 4 -43 -8 -87 -15 -98 -16 -11 -2 -99 -14 -195 -28 -595 -87
              -1016 -118 -1590 -118 -217 0 -460 5 -540 11 -80 5 -183 12 -230 16 -227 15
              -623 63 -860 104 -30 5 -73 12 -95 16 -22 3 -68 12 -103 20 -86 19 -102 18
              -102 -6z
              M17315 16774 c-372 -39 -668 -122 -1095 -309 -323 -141 -755 -305
              -1083 -411 -130 -42 -147 -50 -131 -61 10 -7 25 -13 34 -13 20 0 374 119 575
              193 208 76 320 122 617 248 142 61 300 125 352 144 181 65 452 129 661 155 50
              6 108 13 131 16 109 13 524 4 689 -15 266 -31 558 -86 775 -146 108 -29 464
              -137 499 -150 20 -8 108 -39 196 -71 266 -95 845 -342 1068 -456 l48 -24 78
              -190 c43 -104 156 -378 251 -609 95 -231 174 -423 177 -428 2 -4 13 -3 23 3
              17 9 14 20 -47 167 -35 87 -100 244 -142 348 -198 482 -261 634 -285 688 l-25
              58 -143 68 c-927 443 -1731 700 -2458 787 -175 21 -601 25 -765 8z
              M20322 16713 c4 -21 5 -21 243 -128 105 -47 273 -127 375 -178 l185
              -92 172 -420 c94 -231 202 -495 240 -587 37 -91 71 -164 74 -162 4 2 9 16 12
              30 5 27 -7 57 -218 569 -47 116 -123 300 -168 409 l-81 199 -211 103 c-281
              137 -585 274 -608 274 -12 0 -17 -6 -15 -17z
              M13210 16694 c-78 -14 -111 -20 -325 -53 -148 -22 -419 -57 -515 -66
              -36 -3 -101 -10 -145 -15 -44 -5 -123 -12 -175 -15 -52 -3 -158 -10 -235 -16
              -181 -13 -971 -12 -1157 1 -78 6 -177 12 -218 15 -65 5 -138 12 -320 31 -25 2
              -81 9 -125 15 -44 6 -98 13 -120 15 -22 3 -65 9 -95 14 -30 5 -84 14 -120 19
              -96 14 -296 50 -314 57 -12 4 -16 0 -16 -15 0 -28 23 -36 163 -61 328 -58 725
              -105 1107 -132 242 -17 1034 -17 1280 0 369 25 688 60 1000 107 14 2 74 11
              134 20 105 15 151 23 264 46 52 10 53 11 36 30 -9 10 -20 19 -23 18 -3 0 -40
              -7 -81 -15z
              M17455 16665 c-11 -2 -74 -9 -140 -15 -220 -21 -452 -72 -685 -150
              -67 -22 -43 -12 -365 -149 -347 -147 -632 -257 -907 -349 -193 -65 -225 -79
              -214 -91 8 -7 25 -13 38 -13 54 0 733 253 1043 388 259 113 334 143 456 182
              236 77 422 116 669 142 123 13 488 13 610 0 52 -6 120 -13 150 -16 51 -5 259
              -39 390 -64 345 -65 924 -250 1384 -441 280 -117 658 -291 673 -311 5 -7 38
              -83 73 -168 67 -165 133 -325 252 -615 41 -99 94 -228 118 -287 31 -75 49
              -108 60 -108 36 0 64 -76 -287 780 l-179 435 -110 54 c-676 332 -1427 597
              -2034 716 -100 20 -252 44 -314 51 -28 3 -85 9 -126 15 -74 10 -516 21 -555
              14z
              M13255 16579 c-77 -14 -158 -29 -180 -32 -22 -4 -98 -15 -170 -26
              -234 -35 -660 -85 -830 -96 -44 -2 -147 -10 -230 -16 -198 -15 -997 -15 -1200
              0 -88 6 -191 14 -230 17 -268 18 -825 93 -1010 134 -23 5 -44 5 -49 0 -19 -19
              5 -37 61 -48 102 -21 301 -52 418 -67 28 -4 77 -10 110 -15 87 -14 423 -47
              625 -62 233 -17 1091 -17 1340 0 466 33 919 88 1325 162 61 11 129 23 153 27
              52 7 59 16 29 35 -19 13 -38 11 -162 -13z
              M17485 16543 c-431 -34 -746 -111 -1084 -265 -256 -117 -818 -335
              -1058 -410 -35 -11 -63 -23 -63 -27 0 -5 10 -12 22 -18 17 -8 55 2 178 44 289
              101 609 226 900 353 362 158 605 225 960 266 294 33 839 -1 1107 -70 45 -12
              54 -12 64 0 9 11 9 18 2 25 -19 19 -255 60 -486 85 -121 13 -458 23 -542 17z
              M13380 16481 c-47 -10 -132 -26 -190 -35 -58 -10 -139 -24 -180 -32
              -41 -8 -97 -16 -125 -19 -27 -3 -75 -10 -105 -15 -30 -5 -84 -12 -120 -15 -36
              -3 -72 -8 -80 -10 -30 -8 -329 -37 -563 -54 -233 -17 -276 -26 -250 -52 16
              -16 387 11 783 57 180 20 481 64 620 90 41 7 91 16 110 19 61 10 244 46 259
              51 11 4 10 8 -5 19 -24 18 -49 18 -154 -4z
              M9372 16419 c3 -26 3 -26 168 -53 535 -87 1016 -130 1585 -140 143
              -3 307 -2 365 2 94 6 105 9 105 26 0 18 -17 19 -425 22 -486 3 -922 32 -1280
              83 -36 6 -103 15 -150 21 -47 7 -121 18 -165 26 -44 7 -96 16 -115 19 -19 3
              -48 8 -63 11 -26 5 -28 3 -25 -17z
              M17490 16423 c-43 -3 -198 -19 -270 -28 -230 -30 -538 -122 -805
              -242 -212 -96 -647 -267 -930 -366 l-70 -25 29 -13 c29 -14 57 -7 211 51 421
              158 514 195 830 332 418 181 720 243 1175 243 235 0 333 -7 555 -41 571 -87
              1244 -307 2006 -658 l146 -68 51 -121 c28 -67 110 -266 182 -442 197 -482 196
              -480 220 -483 11 -2 20 1 20 7 0 5 -43 115 -96 243 -53 128 -147 358 -210 512
              -63 153 -119 287 -125 298 -22 41 -591 291 -1004 441 -326 119 -750 241 -960
              277 -138 24 -295 49 -335 54 -192 22 -252 27 -403 29 -95 2 -192 2 -217 0z
              M18649 16403 c-7 -8 -8 -17 -3 -22 5 -5 88 -30 184 -56 384 -102 919
              -299 1335 -490 44 -20 125 -57 180 -83 55 -25 105 -50 112 -56 6 -6 63 -137
              126 -291 195 -477 340 -830 344 -834 1 -2 11 0 22 5 19 9 -5 72 -417 1068
              l-37 90 -190 87 c-591 270 -1068 447 -1532 567 -96 25 -114 27 -124 15z
              M13555 16389 c-41 -8 -68 -13 -260 -48 -165 -31 -485 -81 -600 -95
              -22 -3 -76 -10 -120 -15 -44 -6 -107 -13 -140 -16 -33 -3 -80 -7 -105 -10
              -152 -18 -450 -36 -748 -47 -450 -15 -1021 4 -1417 47 -27 3 -72 7 -100 10
              -121 12 -435 58 -554 81 -95 18 -131 18 -131 0 0 -21 14 -27 99 -42 291 -52
              644 -94 1051 -126 224 -18 1194 -18 1425 0 400 30 824 78 1085 122 185 31 253
              43 315 55 39 8 122 24 185 36 63 12 117 23 119 25 8 7 -33 33 -49 33 -8 -1
              -33 -5 -55 -10z
              M21267 16334 c-4 -4 -7 -14 -7 -21 0 -8 84 -217 186 -466 103 -249
              188 -458 191 -464 12 -31 22 -9 17 37 -5 55 -351 911 -370 917 -6 2 -13 0 -17
              -3z
              M17545 16304 c-237 -16 -439 -47 -614 -93 -83 -22 -100 -33 -82 -54
              10 -13 19 -12 69 3 56 18 224 54 292 64 19 3 76 11 125 18 118 17 519 17 655
              0 691 -85 1291 -273 2215 -691 l70 -32 195 -474 c180 -439 196 -475 218 -475
              16 0 22 4 19 14 -16 40 -212 517 -227 551 -10 22 -50 121 -90 220 -40 99 -78
              186 -84 192 -15 17 -417 197 -611 275 -780 313 -1394 463 -1975 481 -69 3
              -147 3 -175 1z
              M13600 16274 c-58 -12 -121 -25 -140 -28 -19 -3 -55 -10 -80 -15 -40
              -9 -103 -20 -290 -51 -127 -22 -536 -76 -630 -85 -36 -3 -101 -10 -145 -15
              -44 -5 -120 -12 -170 -15 -49 -3 -153 -11 -230 -17 -190 -17 -1150 -16 -1350
              0 -82 7 -181 15 -220 18 -83 6 -373 38 -535 59 -117 15 -169 24 -318 51 -46 9
              -85 14 -87 12 -1 -2 1 -12 5 -24 6 -16 23 -24 69 -33 180 -35 598 -89 831
              -106 41 -3 129 -10 195 -16 343 -31 1067 -30 1490 1 72 6 162 13 200 15 324
              25 718 77 1100 145 66 12 140 25 165 29 74 13 315 64 319 68 9 7 -35 32 -54
              32 -11 -1 -67 -12 -125 -25z
              M13685 16170 c-71 -16 -155 -34 -185 -39 -30 -6 -104 -19 -165 -31
              -124 -23 -161 -29 -400 -64 -225 -33 -386 -54 -460 -61 -33 -3 -100 -10 -150
              -15 -49 -5 -128 -12 -175 -15 -47 -3 -137 -10 -200 -15 -302 -26 -1141 -22
              -1517 7 -80 6 -83 5 -83 -16 0 -11 1 -21 3 -22 7 -1 130 -10 247 -18 384 -25
              1080 -19 1515 15 381 29 939 103 1290 170 260 50 440 89 475 102 l24 9 -21 12
              c-30 15 -48 13 -198 -19z
              M17340 16165 c-295 -36 -500 -93 -820 -232 -178 -78 -559 -233 -620
              -254 -14 -4 -61 -21 -105 -38 l-80 -29 30 -12 c26 -11 41 -9 110 15 104 36
              496 194 700 283 241 105 375 148 590 191 184 36 295 46 515 45 265 0 471 -23
              765 -85 55 -12 111 -23 125 -26 27 -4 35 -6 305 -84 320 -92 787 -266 1145
              -428 185 -84 182 -82 195 -116 7 -16 84 -203 170 -415 87 -212 163 -391 170
              -399 8 -8 18 -10 27 -5 12 7 -20 92 -166 448 l-181 438 -165 75 c-432 196
              -883 364 -1250 466 -148 42 -325 85 -395 98 -27 5 -102 18 -165 29 -276 49
              -661 64 -900 35z
              M16680 16132 c-15 -5 -476 -200 -539 -228 -37 -17 -311 -121 -469
              -180 -102 -37 -103 -38 -77 -52 25 -13 35 -11 104 14 154 55 601 234 766 306
              94 41 186 80 207 87 20 7 39 18 42 26 6 17 -14 34 -34 27z
              M13915 16095 c-123 -28 -352 -76 -425 -90 -162 -31 -248 -46 -305
              -55 -323 -48 -397 -59 -445 -65 -30 -3 -82 -10 -115 -15 -33 -5 -91 -12 -130
              -15 -38 -3 -106 -10 -150 -15 -44 -5 -111 -11 -150 -14 -38 -3 -137 -11 -220
              -18 -207 -18 -1266 -18 -1465 0 -80 7 -174 15 -210 18 -36 3 -101 9 -145 14
              -44 5 -109 12 -145 15 -36 4 -92 10 -125 15 -33 5 -82 12 -110 15 -27 3 -90
              13 -139 21 -48 8 -90 13 -92 11 -12 -12 50 -48 97 -57 155 -28 556 -72 874
              -96 352 -27 1133 -24 1510 4 695 54 1286 142 1923 287 71 16 73 17 56 36 -9
              10 -20 19 -23 18 -3 0 -33 -7 -66 -14z
              M9459 16043 c16 -32 33 -40 91 -47 30 -4 78 -11 105 -17 54 -10 316
              -43 427 -55 58 -5 69 -4 74 10 4 9 4 19 2 21 -3 3 -43 10 -89 16 -163 20 -315
              40 -374 49 -33 5 -94 14 -135 20 -41 6 -83 13 -93 16 -16 5 -17 3 -8 -13z
              M17355 16045 c-288 -34 -472 -88 -883 -264 -141 -60 -329 -137 -417
              -171 -88 -34 -163 -65 -167 -69 -4 -4 5 -12 20 -17 25 -10 55 0 258 81 126 51
              312 128 414 172 405 175 688 238 1075 238 535 0 1183 -155 1934 -461 231 -94
              501 -216 501 -225 0 -10 33 -12 43 -3 17 17 -24 50 -111 91 -238 110 -628 267
              -877 353 -146 50 -451 139 -597 174 -421 102 -860 139 -1193 101z
              M13975 15984 c-100 -24 -504 -106 -685 -138 -124 -23 -130 -25 -130
              -41 0 -24 18 -26 102 -11 230 43 567 109 598 117 19 5 76 18 125 29 164 37
              170 39 148 55 -24 19 -40 18 -158 -11z
              M20762 15958 c-7 -10 30 -111 143 -383 84 -203 198 -482 255 -620 56
              -137 104 -252 106 -254 3 -3 12 2 20 11 15 14 3 47 -126 359 -141 342 -231
              560 -319 778 -26 63 -52 116 -57 118 -6 2 -16 -2 -22 -9z
              M17380 15925 c-245 -27 -505 -100 -758 -212 -81 -36 -241 -103 -357
              -150 -115 -47 -214 -89 -219 -93 -5 -5 7 -11 25 -15 42 -7 170 41 559 212 508
              224 920 274 1520 187 58 -8 132 -21 165 -29 33 -7 96 -21 140 -31 181 -39 459
              -120 695 -202 237 -83 807 -318 838 -346 11 -11 100 -220 227 -536 37 -92 45
              -105 68 -108 l26 -3 -61 148 c-33 81 -96 234 -138 338 l-77 190 -59 29 c-95
              46 -414 182 -557 237 -819 316 -1496 443 -2037 384z
              M14055 15879 c-287 -69 -873 -182 -1060 -204 -27 -3 -90 -12 -140
              -20 -233 -38 -838 -91 -1165 -102 -354 -13 -1036 -6 -1180 12 -19 2 -96 9
              -170 15 -74 6 -197 18 -273 27 -76 8 -140 13 -143 10 -3 -2 11 -14 30 -25 42
              -25 117 -36 381 -57 395 -30 476 -33 905 -33 391 0 540 5 925 33 106 8 443 42
              542 55 133 18 343 47 468 66 90 13 622 116 730 141 39 9 139 33 224 53 151 36
              154 37 130 53 -28 21 -10 23 -204 -24z
              M14349 15826 c-4 -4 -576 -138 -649 -151 -422 -79 -743 -132 -902
              -150 -26 -3 -78 -9 -115 -15 -38 -5 -97 -12 -133 -15 -36 -3 -101 -10 -145
              -15 -44 -5 -127 -13 -185 -16 -58 -3 -107 -8 -110 -9 -3 -2 -68 -6 -145 -10
              -77 -4 -210 -10 -295 -15 -279 -14 -921 -3 -1230 21 -69 5 -163 12 -210 15
              l-85 5 25 -21 c33 -27 35 -27 265 -45 269 -22 1001 -31 1315 -16 791 36 1518
              137 2315 321 139 32 341 83 344 86 3 2 -4 11 -15 19 -19 14 -37 19 -45 11z
              M17410 15805 c-223 -23 -460 -80 -460 -110 0 -24 19 -27 68 -11 158
              53 408 86 642 86 292 0 553 -38 935 -135 313 -80 830 -265 1198 -431 l109 -49
              111 -272 c85 -207 117 -273 132 -277 10 -3 21 -3 23 -1 4 4 -47 135 -166 425
              -33 79 -69 152 -81 161 -31 26 -450 202 -666 281 -490 179 -924 291 -1250 324
              -186 18 -233 21 -345 22 -69 0 -181 -6 -250 -13z
              M12745 15765 c-201 -26 -448 -53 -560 -61 -44 -3 -127 -9 -185 -15
              -329 -30 -1167 -30 -1515 1 -60 5 -146 12 -190 15 -102 7 -318 31 -463 51 -62
              9 -115 14 -118 11 -3 -2 11 -15 30 -27 24 -14 69 -26 133 -35 123 -17 365 -43
              463 -50 41 -2 143 -10 225 -17 198 -16 1146 -16 1355 0 85 7 189 15 230 17
              164 12 625 63 742 83 33 6 43 12 43 26 0 11 -4 20 -10 21 -5 1 -86 -8 -180
              -20z
              M14380 15709 c-276 -76 -822 -191 -1160 -244 -251 -39 -351 -54 -395
              -59 -22 -2 -76 -9 -120 -15 -44 -5 -105 -13 -135 -16 -30 -3 -100 -9 -155 -15
              -437 -44 -688 -55 -1200 -55 -302 0 -589 8 -770 21 -60 4 -76 3 -65 -6 47 -35
              73 -40 237 -47 174 -8 998 -11 1183 -4 105 4 545 36 630 46 25 3 72 7 105 10
              57 5 136 15 395 51 93 12 333 50 505 79 130 22 498 98 630 130 257 61 485 120
              485 126 0 6 -47 30 -58 28 -4 0 -54 -14 -112 -30z
              M17480 15690 c-149 -15 -195 -22 -304 -46 -161 -35 -269 -73 -542
              -190 -134 -57 -248 -104 -254 -104 -16 0 -12 -17 6 -24 31 -12 55 -4 273 89
              341 147 485 190 736 220 402 49 942 -29 1509 -216 116 -38 146 -40 134 -7 -10
              25 -480 164 -710 209 -92 18 -281 48 -338 54 -230 24 -378 28 -510 15z
              M16700 15614 c-58 -25 -190 -81 -295 -124 -104 -43 -191 -83 -193
              -88 -1 -5 8 -12 21 -16 25 -6 160 45 442 168 108 47 150 70 150 82 0 10 -4 19
              -10 20 -5 2 -57 -17 -115 -42z
              M14585 15639 c-16 -5 -120 -32 -230 -60 -110 -27 -225 -56 -255 -64
              -87 -23 -487 -104 -665 -135 -201 -35 -492 -81 -605 -95 -25 -3 -76 -10 -115
              -15 -38 -5 -95 -12 -125 -15 -30 -3 -95 -10 -145 -15 -115 -12 -164 -16 -360
              -31 -88 -6 -164 -16 -170 -20 -6 -6 -4 -14 5 -23 24 -23 520 21 1025 90 286
              39 885 146 1024 184 20 5 90 21 156 36 142 31 411 100 534 137 32 9 33 11 16
              23 -21 16 -46 17 -90 3z
              M17500 15570 c-132 -12 -185 -19 -232 -29 -24 -6 -58 -12 -75 -16
              -85 -14 -285 -84 -449 -156 -100 -43 -187 -79 -193 -79 -17 0 -13 -17 5 -24
              30 -11 48 -6 198 58 199 85 244 103 329 126 270 76 542 97 832 65 130 -14 295
              -38 370 -55 22 -5 74 -16 115 -26 295 -66 754 -220 1130 -381 l185 -79 57
              -140 c81 -197 85 -204 108 -204 11 0 20 2 20 5 0 17 -145 353 -158 367 -52 51
              -724 309 -1052 403 -165 47 -401 103 -510 120 -19 3 -60 10 -90 16 -75 13
              -382 39 -450 37 -30 0 -93 -4 -140 -8z
              M14740 15554 c-72 -22 -309 -85 -390 -104 -41 -10 -144 -34 -228 -54
              -203 -48 -436 -97 -522 -111 -19 -3 -55 -10 -80 -15 -52 -11 -55 -12 -255 -44
              -221 -35 -549 -81 -660 -91 -33 -3 -96 -10 -140 -15 -44 -5 -116 -12 -160 -15
              -44 -3 -123 -10 -176 -15 -175 -17 -450 -25 -871 -25 -230 0 -418 -3 -418 -6
              0 -3 17 -13 37 -23 103 -49 1005 -23 1703 50 25 2 95 11 155 19 61 8 128 17
              150 20 22 2 56 7 75 10 19 2 55 7 80 10 25 4 113 17 195 31 83 14 175 29 205
              34 96 16 222 40 259 49 20 6 52 12 71 15 205 32 1060 246 1060 266 0 19 -47
              27 -90 14z
              M14815 15449 c-83 -25 -454 -123 -505 -134 -8 -2 -103 -24 -210 -49
              -197 -45 -660 -136 -820 -161 -19 -3 -75 -12 -125 -20 -89 -14 -202 -30 -280
              -39 -22 -3 -76 -10 -120 -15 -264 -34 -663 -69 -875 -77 -91 -3 -168 -8 -171
              -10 -4 -2 -146 -4 -318 -5 -171 -1 -311 -3 -311 -5 0 -2 19 -13 43 -25 41 -22
              47 -22 342 -15 339 8 428 12 605 26 69 5 161 12 205 15 44 3 116 10 160 15 44
              5 109 12 145 15 65 6 168 19 440 56 144 19 167 23 350 54 141 25 406 74 480
              90 227 49 235 50 525 121 179 43 449 116 559 151 45 14 48 17 31 29 -25 18
              -33 17 -150 -17z
              M17520 15449 c-142 -9 -197 -18 -362 -59 -125 -32 -163 -45 -333
              -117 -130 -56 -123 -52 -105 -63 26 -17 55 -11 135 26 94 43 287 108 387 129
              111 24 302 45 408 45 105 0 371 -23 440 -39 25 -5 65 -12 90 -16 93 -13 363
              -79 530 -130 296 -90 869 -310 907 -348 7 -7 31 -59 55 -116 40 -97 67 -135
              84 -118 4 4 -10 51 -31 105 -67 172 -60 163 -182 217 -320 140 -688 271 -1028
              363 -190 52 -513 108 -675 117 -58 3 -125 7 -150 9 -25 2 -101 -1 -170 -5z
              M15005 15379 c-56 -18 -328 -95 -495 -139 -84 -22 -393 -97 -455
              -110 -22 -4 -85 -17 -140 -29 -55 -12 -172 -35 -260 -51 -88 -16 -196 -36
              -240 -45 -44 -8 -98 -17 -120 -20 -22 -2 -62 -9 -90 -14 -27 -6 -72 -13 -100
              -16 -27 -3 -75 -10 -105 -15 -30 -5 -80 -12 -110 -15 -30 -3 -82 -10 -115 -15
              -33 -5 -87 -11 -120 -14 -33 -4 -103 -11 -155 -16 -312 -32 -510 -45 -905 -60
              -143 -5 -262 -10 -265 -10 -3 0 15 -10 40 -21 42 -19 57 -20 220 -15 280 11
              387 16 520 27 69 5 154 12 190 15 145 11 454 45 665 74 39 5 90 12 115 15 25
              3 70 10 100 15 30 5 71 12 90 15 122 18 627 115 780 149 183 41 416 98 610
              149 118 32 465 131 470 135 8 6 -33 32 -49 31 -9 0 -43 -10 -76 -20z
              M19210 15331 c0 -14 22 -27 93 -54 50 -19 173 -70 272 -112 99 -42
              192 -82 206 -88 22 -9 40 -44 117 -231 82 -200 93 -221 116 -224 25 -3 24 -2
              -36 145 -33 81 -77 189 -97 238 -34 84 -40 92 -81 112 -114 56 -550 233 -575
              233 -8 0 -15 -8 -15 -19z
              M17500 15327 c-3 -2 -36 -7 -75 -10 -79 -6 -186 -28 -290 -58 -89
              -27 -255 -91 -255 -100 0 -19 63 -16 121 6 418 164 853 162 1494 -5 243 -64
              535 -164 845 -290 174 -71 190 -80 201 -108 29 -82 42 -102 66 -102 14 0 23 3
              21 8 -1 4 -16 40 -32 81 -35 84 -13 71 -316 193 -412 166 -840 295 -1135 342
              -16 3 -59 10 -95 15 -36 6 -81 13 -100 16 -47 8 -444 19 -450 12z
              M15145 15294 c-134 -42 -374 -110 -495 -142 -317 -84 -298 -79 -670
              -161 -114 -24 -125 -29 -128 -50 -4 -22 -2 -23 35 -16 21 4 49 9 63 12 70 12
              584 135 685 163 296 82 417 117 530 152 122 38 128 41 105 54 -32 17 -30 17
              -125 -12z
              M9550 15247 c-34 -22 -62 -62 -75 -104 -14 -47 11 -23 108 103 23 30
              10 31 -33 1z
              M9634 15168 c-148 -176 -162 -196 -157 -223 3 -14 8 -25 12 -25 3 1
              27 29 54 64 26 35 81 102 122 149 41 47 75 90 75 95 0 8 -22 22 -34 22 -2 0
              -34 -37 -72 -82z
              M15280 15208 c-542 -169 -1119 -313 -1665 -413 -44 -8 -116 -22 -160
              -30 -79 -14 -227 -38 -315 -50 -25 -3 -72 -10 -105 -15 -33 -5 -82 -12 -110
              -15 -27 -3 -77 -10 -110 -15 -33 -5 -89 -12 -125 -15 -36 -3 -101 -10 -145
              -15 -44 -5 -111 -12 -150 -15 -38 -3 -122 -9 -185 -15 -140 -12 -255 -19 -335
              -20 l-60 -2 41 -19 c23 -10 54 -19 70 -20 32 -2 230 9 284 16 19 2 73 7 120
              10 47 3 121 10 165 15 44 5 107 12 140 15 498 48 1413 213 1905 345 274 73
              542 149 713 203 171 53 196 63 181 74 -25 18 -45 15 -154 -19z
              M17450 15200 c-88 -10 -197 -32 -295 -61 -84 -25 -91 -29 -74 -40 19
              -12 72 -11 106 2 54 20 209 47 340 59 245 21 550 -17 931 -116 198 -52 184
              -51 180 -24 -3 20 -16 27 -103 51 -129 36 -391 93 -525 114 -121 19 -449 28
              -560 15z
              M10600 15197 c0 -17 78 -50 129 -54 107 -7 946 -6 969 1 13 5 22 14
              20 23 -3 13 -68 15 -513 17 -280 2 -531 7 -557 11 -27 4 -48 5 -48 2z
              M20166 15175 c-3 -8 40 -124 95 -257 55 -134 108 -262 117 -285 14
              -37 46 -62 60 -48 5 5 -226 574 -241 593 -13 16 -24 15 -31 -3z
              M9767 15118 c-109 -124 -247 -302 -247 -319 0 -29 20 -33 38 -8 9 13
              81 102 160 198 108 130 140 176 130 182 -21 14 -23 12 -81 -53z
              M15425 15128 c-317 -106 -774 -233 -1125 -313 -85 -20 -183 -42 -217
              -50 -98 -24 -499 -102 -608 -119 -55 -9 -140 -23 -190 -31 -124 -20 -292 -44
              -340 -50 -22 -2 -92 -11 -155 -19 -155 -21 -372 -43 -560 -57 l-154 -12 44
              -19 c47 -20 110 -18 395 12 50 5 115 12 145 15 30 3 89 10 130 15 41 5 95 12
              120 15 67 7 305 43 535 80 111 18 190 33 375 70 69 14 139 28 155 31 162 31
              581 132 900 218 127 34 402 118 584 178 145 47 153 51 130 64 -31 17 -25 18
              -164 -28z
              M9916 15088 c-54 -64 -235 -284 -281 -341 -44 -55 -53 -86 -29 -94 5
              -2 58 56 116 129 58 73 140 173 182 222 60 70 74 91 65 102 -16 20 -23 18 -53
              -18z
              M15690 15089 c-73 -30 -486 -159 -695 -219 -467 -132 -741 -198
              -1165 -281 -58 -11 -136 -27 -175 -34 -38 -8 -88 -17 -110 -20 -22 -3 -74 -12
              -115 -20 -74 -13 -214 -35 -360 -55 -178 -24 -299 -40 -350 -45 -30 -3 -91
              -10 -135 -15 -44 -5 -116 -12 -160 -16 -44 -3 -82 -7 -84 -9 -2 -2 14 -11 35
              -20 39 -17 91 -15 317 11 273 32 614 81 847 123 63 11 131 23 150 26 257 44
              869 185 1180 272 343 96 899 272 900 284 0 5 -47 30 -53 28 -1 0 -13 -5 -27
              -10z
              M17480 15080 c-41 -5 -104 -15 -140 -21 l-65 -13 44 -13 c35 -10 64
              -10 140 -1 132 15 434 4 581 -21 299 -52 539 -117 855 -232 137 -50 249 -79
              304 -79 33 0 -12 21 -179 83 -437 162 -757 246 -1120 292 -79 10 -350 13 -420
              5z
              M9970 14948 c-204 -244 -300 -371 -289 -385 7 -7 15 -13 19 -13 4 0
              56 61 115 136 59 74 148 183 198 241 70 82 87 107 76 114 -8 5 -18 9 -24 8 -5
              0 -48 -46 -95 -101z
              M15720 14970 c-331 -113 -795 -252 -1090 -326 -164 -41 -302 -74
              -322 -78 -13 -2 -41 -9 -63 -14 -22 -6 -56 -14 -75 -17 -19 -3 -51 -10 -70
              -15 -68 -18 -269 -56 -625 -120 -161 -28 -353 -58 -495 -75 -25 -3 -76 -10
              -115 -15 -38 -5 -95 -12 -125 -15 -30 -3 -77 -8 -105 -12 l-50 -6 33 -14 c57
              -23 88 -21 477 33 712 99 1463 265 2155 475 215 65 673 218 683 229 5 4 -4 12
              -20 17 -23 9 -55 1 -193 -47z
              M18734 14989 c-11 -18 5 -26 160 -79 209 -73 517 -198 542 -221 45
              -42 75 -9 32 34 -35 35 -657 277 -712 277 -8 0 -18 -5 -22 -11z
              M10098 14892 c-92 -108 -186 -222 -266 -325 -44 -56 -51 -71 -41 -83
              7 -8 15 -13 19 -12 4 2 54 62 110 133 57 72 142 175 189 230 47 55 91 108 99
              117 12 14 11 18 -6 27 -18 10 -30 0 -104 -87z
              M17630 14962 c19 -13 199 -50 270 -56 76 -7 216 -31 330 -56 198 -45
              228 -51 324 -65 107 -16 146 -18 106 -5 -258 82 -598 158 -773 174 -131 12
              -271 16 -257 8z
              M16010 14944 c-82 -36 -676 -229 -915 -298 -294 -84 -451 -125 -710
              -185 -99 -23 -209 -49 -245 -57 -64 -15 -335 -70 -395 -79 -16 -3 -52 -10 -80
              -15 -27 -5 -66 -12 -85 -15 -36 -6 -73 -12 -237 -40 -50 -9 -109 -18 -130 -20
              -21 -3 -85 -12 -143 -20 -58 -8 -132 -19 -165 -23 l-60 -9 30 -13 c48 -21 117
              -16 415 31 332 53 648 112 925 174 322 72 734 178 970 250 510 155 938 302
              913 315 -31 17 -55 18 -88 4z
              M10277 14900 c-8 -11 -30 -37 -48 -58 -44 -47 -242 -290 -297 -362
              -37 -50 -40 -56 -26 -70 14 -13 19 -11 44 20 15 19 73 92 129 161 55 70 138
              168 182 219 63 71 79 94 68 101 -22 14 -37 11 -52 -11z
              M13599 14916 c-3 -3 -195 -37 -294 -52 -16 -3 -55 -9 -85 -14 -30 -5
              -75 -12 -100 -15 -25 -3 -70 -10 -100 -15 -30 -5 -79 -12 -109 -15 -30 -3 -82
              -9 -115 -15 -34 -5 -90 -12 -126 -15 -36 -3 -101 -10 -145 -15 -44 -5 -111
              -12 -150 -15 -38 -3 -122 -10 -185 -15 -130 -11 -215 -16 -445 -26 -88 -3
              -162 -8 -164 -10 -2 -1 14 -10 35 -19 33 -14 65 -16 214 -11 291 9 927 69
              1200 112 52 8 142 22 200 30 58 9 139 22 180 29 41 8 107 19 147 26 83 14 98
              21 90 43 -6 14 -37 22 -48 12z
              M16125 14858 c-446 -168 -1206 -403 -1515 -468 -19 -4 -80 -19 -136
              -34 -55 -14 -122 -30 -150 -36 -27 -5 -80 -17 -119 -25 -70 -15 -239 -51 -286
              -60 -153 -31 -416 -78 -494 -90 -16 -2 -70 -11 -120 -19 -49 -8 -116 -18 -148
              -22 -66 -7 -64 -20 4 -32 29 -5 78 -2 144 9 55 9 147 24 205 34 134 21 483 86
              490 90 3 2 22 6 43 9 21 3 66 13 100 21 34 8 134 30 222 50 541 121 1204 318
              1739 516 184 69 186 70 160 80 -32 13 -55 9 -139 -23z
              M10324 14762 c-167 -196 -324 -394 -324 -410 0 -23 35 -23 54 0 10
              13 70 88 134 166 63 79 151 184 194 234 44 49 78 91 76 92 -1 2 -12 8 -23 14
              -18 9 -31 -2 -111 -96z
              M16270 14783 c-459 -181 -1309 -443 -1800 -553 -19 -5 -64 -15 -100
              -23 -276 -64 -667 -141 -875 -173 -144 -22 -148 -23 -100 -41 32 -12 52 -12
              105 -4 36 6 81 13 100 16 39 6 100 17 210 40 41 8 91 17 110 20 19 3 65 12
              103 20 37 8 97 22 135 30 744 161 1441 365 2086 609 200 76 213 82 204 91 -19
              19 -73 10 -178 -32z
              M10483 14749 c-24 -28 -65 -76 -92 -107 -27 -31 -104 -124 -170 -207
              -107 -133 -118 -151 -104 -162 9 -7 20 -13 24 -13 4 0 40 42 80 93 97 122 263
              320 317 379 41 44 42 47 23 57 -30 16 -33 15 -78 -40z
              M16390 14700 c-651 -255 -1367 -472 -2085 -632 -58 -13 -78 -27 -66
              -47 7 -11 29 -9 127 14 126 28 591 145 684 172 305 85 786 237 990 313 47 17
              123 45 169 62 46 16 158 59 248 94 90 35 169 64 176 65 14 1 -43 28 -58 28 -5
              -1 -89 -31 -185 -69z
              M10604 14682 c-100 -112 -221 -260 -378 -461 -8 -10 -6 -17 8 -26 16
              -13 27 -3 114 108 52 67 148 182 211 256 145 167 140 159 121 171 -22 14 -20
              15 -76 -48z
              M16685 14686 c-73 -34 -445 -176 -630 -241 -493 -175 -1000 -324
              -1505 -444 -74 -18 -171 -41 -215 -51 -44 -11 -161 -36 -260 -55 -99 -19 -189
              -38 -200 -42 -18 -5 -18 -6 5 -15 39 -15 95 -11 225 17 66 14 154 32 195 41
              220 45 790 193 1095 285 413 124 933 307 1250 439 55 23 115 47 133 53 38 12
              41 22 10 31 -35 9 -49 6 -103 -18z
              M10724 14622 c-112 -126 -248 -291 -387 -470 -7 -9 -5 -18 6 -30 16
              -15 18 -15 34 8 50 71 246 309 360 436 40 45 73 86 73 92 0 8 -24 23 -33 22
              -1 0 -25 -26 -53 -58z
              M16915 14651 c-13 -12 -551 -225 -735 -291 -179 -64 -554 -190 -675
              -226 -275 -82 -474 -138 -655 -185 -138 -36 -651 -155 -698 -162 -49 -8 -49
              -14 -3 -27 48 -13 64 -10 341 54 604 141 1338 362 1845 554 349 133 667 265
              657 273 -13 13 -67 20 -77 10z
              M10885 14606 c-6 -8 -64 -76 -131 -152 -118 -136 -269 -322 -301
              -371 -14 -21 -14 -26 -2 -34 8 -5 17 -8 20 -6 4 1 55 63 115 137 59 74 162
              198 230 276 112 129 120 141 103 152 -23 15 -22 15 -34 -2z
              M16970 14543 c-122 -53 -417 -173 -513 -209 -49 -18 -67 -38 -50 -55
              9 -9 170 50 443 164 295 123 331 140 318 149 -27 17 -77 4 -198 -49z
              M10948 14472 c-37 -42 -78 -88 -90 -102 -27 -29 -227 -273 -277 -338
              -31 -40 -33 -45 -18 -60 9 -9 18 -14 20 -11 2 2 30 38 62 79 62 79 232 285
              256 310 83 88 159 181 154 190 -14 23 -44 5 -107 -68z
              M17120 14474 c-788 -340 -1558 -600 -2375 -801 l-150 -37 45 -13 c49
              -14 41 -16 315 57 769 204 1555 473 2130 730 138 62 264 110 288 110 29 0 19
              17 -14 24 -52 10 -57 8 -239 -70z
              M11079 14417 c-169 -185 -421 -496 -416 -512 10 -30 40 -9 112 82
              123 153 283 341 354 416 53 55 62 70 51 77 -26 16 -33 12 -101 -63z
              M17439 14459 c-90 -19 -120 -30 -359 -134 -570 -246 -1217 -473
              -1885 -659 -241 -67 -314 -86 -330 -86 -30 0 -6 -17 33 -24 52 -8 171 22 647
              165 527 157 1034 341 1580 574 291 124 347 138 544 139 158 0 160 2 30 30 -90
              20 -146 19 -260 -5z
              M11239 14393 c-115 -122 -382 -439 -448 -532 -20 -27 -21 -35 -10
              -42 8 -5 17 -9 20 -9 3 0 55 62 116 138 61 76 166 201 234 277 163 180 166
              185 148 196 -21 13 -22 12 -60 -28z
              M11273 14228 c-129 -142 -221 -251 -335 -395 -56 -70 -59 -76 -43
              -92 15 -15 17 -15 30 3 56 75 279 341 370 441 160 175 152 165 135 175 -8 6
              -18 10 -22 10 -3 0 -64 -64 -135 -142z
              M17520 14351 c-89 -13 -165 -39 -334 -113 -347 -152 -745 -304 -1201
              -460 -194 -66 -841 -258 -869 -258 -34 0 -14 -19 27 -25 34 -5 85 6 267 59
              642 185 1297 422 1868 675 149 66 234 84 392 83 200 -1 408 -39 723 -132 108
              -33 384 -127 395 -135 2 -2 213 -513 468 -1135 65 -157 78 -180 104 -180 17 0
              14 13 -32 123 -67 163 -194 471 -283 687 -40 96 -105 255 -145 352 -40 98 -78
              182 -84 187 -40 32 -544 191 -716 225 -233 47 -456 65 -580 47z
              M18280 14342 c8 -5 44 -17 80 -26 99 -24 493 -159 515 -177 6 -4 53
              -111 105 -238 73 -179 98 -231 112 -231 10 0 18 7 18 15 0 18 -187 476 -199
              488 -5 5 -82 34 -172 67 -126 45 -196 63 -303 80 -77 12 -146 24 -155 27 -14
              4 -14 3 -1 -5z
              M11449 14223 c-95 -101 -270 -304 -384 -446 -73 -91 -77 -99 -62
              -115 16 -15 18 -15 32 5 9 12 21 28 28 35 6 7 46 56 89 108 87 109 227 270
              342 395 63 68 77 88 65 96 -8 5 -17 9 -20 9 -4 0 -44 -39 -90 -87z
              M11579 14163 c-175 -182 -479 -544 -479 -569 0 -10 30 -29 35 -22 25
              42 258 321 370 443 204 222 199 216 184 226 -8 5 -17 9 -20 9 -3 0 -44 -39
              -90 -87z
              M15910 14141 c-315 -107 -550 -180 -795 -247 -201 -55 -637 -164
              -660 -165 -11 0 -38 -6 -60 -13 l-40 -13 41 -13 c37 -11 54 -9 180 20 507 118
              1063 282 1572 462 89 32 112 44 112 59 0 12 -7 19 -17 18 -10 -1 -160 -49
              -333 -108z
              M17480 14220 c-41 -10 -147 -50 -235 -88 -573 -249 -1322 -519 -1802
              -649 -57 -15 -103 -31 -103 -35 0 -4 19 -10 43 -13 46 -7 103 9 477 128 462
              148 970 338 1380 517 232 101 233 102 405 106 169 3 290 -12 510 -66 124 -30
              506 -151 533 -168 7 -5 96 -211 198 -458 327 -796 304 -744 330 -744 24 0 24
              0 8 38 -8 20 -56 136 -106 257 -50 121 -128 312 -175 425 -46 113 -109 267
              -140 344 -31 76 -65 149 -77 161 -21 23 -148 69 -376 138 -110 33 -141 41
              -255 67 -254 58 -481 73 -615 40z
              M18970 14237 c0 -2 6 -17 14 -33 8 -16 52 -121 99 -234 499 -1221
              521 -1270 544 -1270 13 0 23 1 23 3 0 1 -141 347 -314 767 -295 716 -316 765
              -340 768 -14 2 -26 1 -26 -1z
              M19110 14224 c0 -3 87 -216 198 -484 33 -80 142 -345 242 -590 100
              -245 186 -449 191 -454 12 -14 51 -15 45 -2 -17 41 -611 1484 -623 1512 -4 10
              -53 27 -53 18z
              M19250 14204 c0 -4 96 -241 214 -528 118 -286 259 -629 313 -761 86
              -211 101 -240 122 -243 19 -3 23 0 19 15 -3 10 -80 198 -171 418 -91 220 -229
              555 -307 745 -78 189 -147 348 -153 352 -15 9 -37 10 -37 2z
              M19380 14197 c0 -2 15 -39 34 -83 19 -43 85 -203 146 -354 62 -151
              139 -338 171 -415 31 -77 107 -261 169 -410 61 -148 115 -274 120 -279 4 -4
              15 -4 23 0 17 10 23 -9 -143 394 -114 278 -223 542 -345 840 -64 157 -120 288
              -123 291 -9 8 -52 22 -52 16z
              M11657 14048 c-137 -146 -256 -283 -385 -443 -65 -81 -70 -91 -56
              -105 14 -14 19 -11 49 25 222 269 303 363 398 463 182 192 174 182 157 192 -8
              5 -18 10 -22 10 -3 0 -67 -64 -141 -142z
              M19520 14176 c0 -2 13 -35 29 -72 29 -68 238 -575 391 -949 79 -194
              197 -480 207 -502 3 -7 16 -13 28 -13 l23 0 -35 88 c-45 112 -513 1250 -561
              1365 -30 73 -40 87 -59 87 -13 0 -23 -2 -23 -4z
              M19687 14088 c38 -91 140 -337 238 -578 34 -85 106 -261 160 -390 53
              -129 117 -284 142 -344 24 -60 48 -117 53 -127 10 -19 50 -33 50 -17 0 5 -25
              69 -55 141 -30 73 -171 416 -313 762 -243 590 -261 630 -285 633 l-26 3 36
              -83z
              M19790 14156 c0 -2 24 -61 54 -132 29 -71 136 -329 236 -574 101
              -245 218 -530 261 -635 60 -147 83 -191 100 -197 12 -5 24 -7 26 -5 2 2 -56
              147 -128 323 -72 175 -157 382 -189 459 -306 749 -313 765 -337 765 -13 0 -23
              -2 -23 -4z
              M19939 14108 c11 -24 55 -131 99 -238 44 -107 164 -400 267 -650 103
              -250 202 -491 220 -535 25 -61 37 -81 54 -83 11 -2 21 1 21 6 0 5 -32 87 -71
              183 -519 1270 -555 1354 -583 1357 l-25 3 18 -43z
              M20060 14142 c0 -5 14 -42 31 -83 17 -41 71 -173 121 -294 50 -121
              122 -296 160 -390 38 -93 94 -228 123 -300 90 -221 184 -445 195 -465 14 -26
              55 -28 45 -2 -3 9 -67 166 -142 347 -510 1238 -489 1190 -511 1193 -12 2 -22
              -1 -22 -6z
              M20193 14128 c3 -7 105 -256 227 -553 123 -297 264 -641 315 -765 76
              -185 96 -226 114 -228 11 -2 21 1 21 6 0 10 -72 188 -284 702 -51 124 -151
              366 -221 538 -119 290 -130 312 -153 312 -15 0 -22 -5 -19 -12z
              M11814 14018 c-162 -166 -504 -565 -504 -588 0 -15 33 -21 45 -8 6 7
              54 65 105 128 107 132 285 332 414 468 71 73 87 95 75 103 -8 5 -16 9 -20 9
              -3 0 -55 -51 -115 -112z
              M20330 14121 c0 -6 7 -25 15 -43 8 -18 57 -139 110 -268 149 -364
              156 -381 173 -378 9 2 17 8 18 13 2 6 -59 161 -135 345 -117 284 -141 335
              -159 338 -12 2 -22 -1 -22 -7z
              M20460 14123 c0 -5 76 -193 168 -418 93 -226 236 -573 318 -772 134
              -328 151 -363 171 -363 13 0 23 2 23 5 0 2 -33 85 -74 182 -40 98 -110 266
              -154 373 -44 107 -114 278 -156 380 -42 102 -116 282 -164 400 -74 179 -92
              216 -110 218 -12 2 -22 0 -22 -5z
              M20590 14121 c0 -6 43 -111 280 -686 84 -203 188 -455 230 -560 128
              -311 119 -295 147 -277 9 5 -13 68 -78 227 -50 121 -131 319 -181 440 -50 121
              -128 312 -175 425 -46 113 -104 255 -129 316 -38 94 -49 113 -70 117 -13 2
              -24 1 -24 -2z
              M17502 14100 c-40 -11 -155 -55 -255 -99 -462 -201 -1090 -431 -1575
              -577 -95 -28 -105 -44 -28 -44 56 0 559 162 901 291 77 28 167 62 200 75 106
              39 335 133 536 220 107 46 220 89 249 95 83 18 292 6 445 -26 175 -35 282 -64
              471 -127 l159 -53 48 -115 c27 -63 79 -189 116 -280 287 -698 283 -690 308
              -690 13 0 23 1 23 3 0 2 -15 39 -34 83 -19 43 -120 291 -226 549 -106 259
              -199 475 -208 482 -43 35 -500 169 -674 198 -18 3 -58 10 -88 16 -107 20 -288
              19 -368 -1z
              M20723 14108 c3 -7 40 -96 82 -198 42 -102 91 -221 109 -265 95 -228
              157 -379 277 -670 72 -176 134 -326 136 -332 6 -15 13 -16 32 -4 11 7 0 41
              -54 172 -37 90 -88 214 -112 274 -25 61 -78 190 -119 288 -41 98 -74 180 -74
              182 0 2 -31 77 -68 167 -38 90 -90 216 -116 281 -44 105 -51 117 -73 117 -16
              0 -23 -4 -20 -12z
              M20850 14115 c0 -2 9 -24 20 -47 23 -49 530 -1283 551 -1340 7 -21
              18 -38 25 -38 38 0 27 35 -128 410 -88 212 -204 495 -260 630 -55 135 -114
              278 -130 318 -25 61 -33 72 -54 72 -13 0 -24 -2 -24 -5z
              M20996 14083 c8 -21 55 -135 104 -253 140 -342 390 -950 414 -1006
              22 -55 35 -65 49 -42 6 9 -8 57 -46 148 -68 168 -418 1019 -459 1118 -25 61
              -33 72 -54 72 -24 0 -24 0 -8 -37z
              M21141 14047 c17 -39 64 -155 106 -257 41 -102 90 -221 109 -265 18
              -44 80 -195 138 -335 153 -374 136 -338 153 -315 7 11 12 27 9 35 -3 12 -241
              591 -380 925 -18 44 -52 125 -75 180 -35 86 -45 101 -66 103 l-24 2 30 -73z
              M21260 14083 c0 -16 243 -599 254 -611 10 -11 15 -10 24 1 10 11 -6
              58 -73 223 -140 343 -147 358 -168 377 -20 18 -37 22 -37 10z
              M11939 13953 c-184 -190 -391 -426 -502 -573 -25 -34 -25 -36 -7 -48
              16 -10 23 -6 51 31 140 180 361 429 542 611 57 58 75 81 65 87 -7 5 -18 9 -24
              9 -6 0 -62 -53 -125 -117z
              M21419 14002 c5 -10 73 -173 151 -362 77 -190 151 -370 164 -400 13
              -30 27 -64 31 -75 7 -17 9 -14 16 18 8 35 -3 67 -146 415 -120 294 -160 382
              -182 399 -34 28 -47 29 -34 5z
              M12034 13857 c-193 -197 -514 -570 -514 -597 0 -6 23 -20 34 -20 2 0
              25 28 51 63 95 121 335 394 478 542 80 82 143 153 140 157 -14 24 -55 -7 -189
              -145z
              M13985 14001 c-16 -5 -75 -16 -130 -26 -55 -10 -127 -24 -159 -31
              -33 -7 -63 -11 -67 -8 -4 2 -10 0 -13 -4 -3 -5 12 -14 34 -21 32 -9 58 -8 152
              9 251 46 243 44 243 65 0 23 -17 27 -60 16z
              M17688 13992 c-25 -2 -38 -8 -38 -17 0 -17 20 -22 110 -30 99 -9 221
              -32 370 -69 144 -37 367 -109 382 -124 5 -5 77 -173 159 -373 83 -200 171
              -414 197 -476 33 -81 52 -113 66 -117 11 -3 22 -3 24 -1 3 2 -70 185 -161 407
              -217 528 -242 587 -253 598 -17 17 -283 100 -428 135 -210 49 -363 73 -428 67z
              M17331 13905 c-407 -178 -876 -356 -1321 -500 -96 -32 -179 -61 -183
              -65 -5 -4 9 -11 31 -15 34 -5 77 6 279 73 370 124 765 275 1163 444 169 72
              197 88 186 107 -11 17 -23 14 -155 -44z
              M12196 13831 c-84 -84 -117 -124 -113 -135 4 -9 11 -16 18 -16 15 0
              270 256 262 263 -22 22 -56 -1 -167 -112z
              M7280 13920 c0 -5 4 -10 8 -10 5 0 60 -31 123 -68 63 -38 242 -144
              399 -237 157 -92 288 -172 291 -177 4 -5 9 -51 13 -101 3 -51 13 -162 21 -247
              8 -85 21 -229 30 -320 8 -91 17 -196 20 -235 3 -38 10 -110 15 -160 5 -49 11
              -119 14 -155 14 -178 37 -439 41 -470 2 -19 7 -73 10 -120 29 -423 105 -741
              240 -1015 59 -117 168 -285 188 -285 4 0 7 12 7 28 0 17 -19 53 -51 97 -60 83
              -166 297 -203 410 -44 139 -85 314 -100 430 -18 136 -26 208 -31 270 -4 63
              -15 184 -45 510 -6 61 -13 139 -15 175 -3 36 -10 117 -16 180 -5 63 -12 142
              -15 175 -2 33 -9 103 -14 155 -5 52 -12 129 -15 170 -3 41 -10 116 -15 165 -5
              50 -12 119 -15 155 -12 159 -18 208 -24 218 -3 5 -82 55 -176 109 -93 55 -270
              159 -393 231 -170 101 -231 132 -258 132 -18 0 -34 -4 -34 -10z
              M7562 13908 c10 -7 164 -98 342 -203 l325 -190 5 -40 c3 -22 12 -125
              21 -230 9 -104 22 -260 30 -345 8 -85 21 -233 30 -327 25 -284 45 -509 60
              -673 8 -85 19 -213 25 -285 6 -71 13 -155 16 -185 16 -178 79 -457 137 -607
              37 -94 115 -255 141 -288 15 -19 15 -18 16 18 0 25 -15 67 -46 128 -119 238
              -183 504 -219 919 -16 187 -37 424 -55 625 -5 61 -12 139 -15 175 -3 36 -12
              137 -20 225 -29 313 -35 381 -40 450 -3 39 -10 111 -15 160 -5 50 -12 128 -15
              175 -9 131 -8 131 -78 171 -34 19 -168 98 -297 174 -129 76 -253 142 -275 147
              -95 20 -92 19 -73 6z
              M7097 13898 l-29 -10 39 -22 c21 -13 138 -82 261 -155 123 -73 238
              -140 255 -150 37 -21 302 -177 333 -196 21 -13 27 -53 49 -330 3 -33 10 -103
              15 -155 5 -52 12 -126 15 -165 3 -38 12 -142 20 -230 22 -231 30 -326 60 -680
              44 -518 71 -698 143 -945 43 -145 143 -373 190 -430 16 -20 23 -22 33 -13 10
              10 2 33 -39 111 -131 250 -215 553 -247 892 -3 36 -10 108 -15 160 -6 52 -12
              129 -15 170 -3 41 -10 116 -15 165 -5 50 -12 126 -15 170 -3 44 -10 121 -15
              170 -5 50 -12 124 -15 165 -3 41 -10 118 -15 170 -5 52 -12 129 -15 170 -3 41
              -10 118 -15 170 -5 52 -12 129 -15 170 -4 41 -10 113 -16 160 -5 47 -9 93 -9
              102 0 16 -85 69 -535 333 -82 48 -181 107 -220 130 -143 85 -138 83 -168 73z
              M12265 13707 c-225 -228 -535 -584 -535 -615 0 -5 6 -14 13 -20 10
              -8 29 9 82 76 144 178 327 382 498 554 98 97 177 178 177 181 0 3 -10 7 -22
              11 -20 5 -52 -23 -213 -187z
              M17561 13864 c-30 -8 -89 -30 -130 -48 -357 -159 -849 -349 -1208
              -468 -89 -29 -162 -56 -163 -60 0 -3 17 -9 38 -13 32 -5 76 7 257 70 359 125
              618 226 985 383 224 96 225 96 315 99 50 1 128 -5 175 -13 47 -8 99 -17 115
              -20 65 -11 236 -56 355 -94 l125 -39 174 -423 c95 -233 174 -424 176 -426 7
              -7 45 -10 45 -3 0 21 -354 868 -368 883 -29 29 -407 134 -587 163 -144 23
              -241 26 -304 9z
              M6920 13840 c-8 -5 -12 -11 -10 -13 3 -3 70 -43 150 -89 80 -47 168
              -98 195 -115 234 -140 514 -303 523 -303 5 0 12 7 16 15 8 21 -2 27 -679 423
              -60 35 -118 70 -129 78 -24 16 -45 18 -66 4z
              M21620 13846 c0 -12 148 -375 162 -396 14 -23 -1 73 -22 140 -10 31
              -32 84 -49 116 -34 67 -91 154 -91 140z
              M12402 13653 c-104 -104 -226 -231 -272 -283 -46 -52 -95 -108 -109
              -125 -98 -112 -192 -238 -187 -250 9 -24 31 -17 56 18 126 170 430 504 644
              706 84 79 106 105 95 112 -8 5 -20 9 -26 9 -7 0 -98 -84 -201 -187z
              M6783 13774 c-22 -10 -14 -22 30 -46 40 -23 259 -152 502 -296 61
              -36 175 -103 255 -149 80 -47 148 -89 151 -95 4 -5 9 -47 13 -91 14 -167 16
              -194 26 -297 6 -58 12 -130 15 -160 37 -449 54 -612 65 -624 4 -4 14 -5 20 -1
              9 5 10 30 6 84 -23 266 -36 422 -41 461 -2 25 -7 79 -10 120 -3 41 -10 116
              -15 165 -5 50 -12 122 -14 160 -12 162 -19 204 -35 219 -9 8 -79 51 -156 96
              -77 46 -176 104 -220 130 -44 26 -185 109 -314 185 -234 138 -255 148 -278
              139z
              M12587 13646 c-187 -176 -396 -403 -571 -619 -70 -88 -85 -111 -75
              -123 18 -22 23 -18 70 44 113 150 275 329 510 564 141 142 254 260 251 263
              -18 18 -59 -10 -185 -129z
              M17625 13749 c-63 -7 -75 -11 -165 -52 -246 -113 -944 -385 -1122
              -437 -45 -14 -63 -29 -35 -31 6 0 24 -3 39 -5 45 -8 579 193 1068 401 l205 87
              85 -6 c142 -10 310 -45 524 -109 l110 -32 62 -150 c34 -83 101 -247 149 -365
              49 -118 89 -216 89 -217 1 -1 13 -4 28 -8 l27 -7 -55 134 c-30 73 -103 249
              -161 391 l-106 257 -111 35 c-157 48 -295 82 -411 99 -142 21 -153 22 -220 15z
              M12720 13586 c-176 -162 -293 -284 -454 -471 -190 -220 -238 -285
              -225 -301 6 -8 15 -14 19 -14 4 0 36 37 71 83 162 209 499 566 724 765 56 50
              67 64 55 72 -8 5 -19 10 -25 10 -5 -1 -80 -65 -165 -144z
              M6655 13690 c-10 -16 -1 -24 87 -75 46 -27 142 -83 213 -126 72 -42
              243 -143 380 -224 138 -81 253 -150 257 -154 6 -5 19 -124 33 -306 3 -33 9
              -107 15 -165 13 -139 38 -419 45 -500 7 -95 23 -263 40 -445 8 -88 17 -191 20
              -230 66 -837 373 -1463 856 -1746 144 -84 365 -130 476 -100 53 15 147 99 204
              184 102 153 161 312 264 717 63 243 195 691 225 763 5 12 36 96 69 187 212
              591 541 1249 873 1743 56 83 66 117 36 117 -14 0 -136 -182 -255 -380 -434
              -717 -791 -1598 -1008 -2485 -123 -505 -281 -784 -461 -811 -47 -7 -162 9
              -239 33 -74 23 -216 98 -287 152 -197 151 -387 413 -502 697 -48 118 -119 343
              -136 434 -19 97 -39 214 -44 255 -18 143 -26 220 -31 290 -3 44 -10 118 -15
              165 -5 47 -12 121 -15 165 -3 44 -12 148 -20 230 -21 226 -31 340 -60 680 -21
              255 -35 379 -43 386 -7 6 -170 102 -632 374 -129 76 -252 149 -272 161 -40 26
              -63 30 -73 14z
              M12945 13609 c-213 -188 -370 -345 -572 -574 -86 -98 -243 -294 -243
              -305 0 -15 33 -21 45 -8 6 7 47 58 91 112 186 234 503 564 709 739 82 71 95
              90 60 95 -13 1 -46 -21 -90 -59z
              M17470 13569 c-179 -80 -432 -182 -720 -290 -129 -48 -228 -90 -220
              -92 52 -17 72 -13 264 60 226 85 495 193 696 280 121 52 141 58 198 57 107 -4
              313 -44 482 -97 l74 -23 126 -303 c69 -167 133 -309 142 -315 46 -28 39 -7
              -158 468 l-76 185 -76 25 c-174 57 -423 106 -532 105 -57 0 -81 -7 -200 -60z
              M10053 13513 c-312 -483 -612 -1080 -834 -1665 -106 -280 -208 -596
              -240 -748 -40 -185 -113 -489 -146 -603 -14 -49 -22 -91 -19 -94 10 -10 25 39
              81 257 30 118 57 226 61 240 122 468 354 1099 590 1603 152 326 417 799 585
              1048 21 31 35 59 32 63 -21 21 -46 -2 -110 -101z
              M13023 13497 c-171 -150 -423 -403 -558 -562 -131 -154 -235 -285
              -235 -295 0 -4 6 -13 14 -19 11 -10 28 6 86 80 126 159 250 300 399 453 139
              141 363 348 443 410 39 29 41 40 9 49 -14 3 -56 -27 -158 -116z
              M6553 13594 c-3 -9 0 -20 8 -25 8 -5 206 -123 440 -261 235 -139 437
              -260 449 -269 17 -11 24 -27 27 -60 1 -24 10 -118 18 -209 25 -272 34 -381 40
              -455 3 -38 10 -113 15 -165 5 -52 12 -131 15 -175 3 -44 10 -118 15 -165 5
              -47 11 -119 15 -160 3 -41 10 -118 15 -170 5 -52 12 -124 15 -160 33 -354 141
              -736 291 -1031 251 -493 670 -812 1069 -814 113 0 171 19 250 83 85 68 156
              161 224 292 81 158 121 271 186 527 90 351 118 447 206 723 225 701 575 1438
              953 2009 59 88 73 117 65 128 -6 7 -15 13 -19 13 -4 0 -44 -55 -88 -122 -328
              -503 -610 -1066 -827 -1653 -127 -341 -217 -638 -325 -1060 -107 -417 -204
              -631 -354 -780 -85 -85 -137 -108 -256 -112 -268 -11 -582 157 -813 435 -189
              228 -340 534 -428 870 -53 204 -76 349 -104 657 -9 94 -22 238 -30 320 -8 83
              -17 184 -20 225 -3 41 -10 118 -15 170 -5 52 -12 129 -15 170 -4 41 -10 113
              -15 160 -5 47 -12 123 -15 170 -4 47 -12 146 -18 220 l-12 135 -85 51 c-47 28
              -240 143 -430 254 -190 111 -362 213 -382 226 -44 28 -56 30 -65 8z
              M9912 13538 c-160 -239 -435 -751 -597 -1113 -133 -297 -183 -428
              -194 -509 -6 -43 -11 -83 -11 -89 1 -7 23 44 49 113 208 538 519 1152 815
              1613 l37 57 -25 0 c-20 0 -35 -15 -74 -72z
              M10232 13543 c-529 -782 -997 -1867 -1252 -2903 -60 -247 -103 -384
              -122 -396 -4 -3 -26 8 -47 25 -38 30 -39 30 -44 9 -3 -12 -3 -26 0 -31 7 -11
              94 -67 105 -67 23 0 78 157 138 400 225 909 555 1745 983 2490 76 132 171 286
              255 414 50 77 61 100 51 107 -23 14 -27 11 -67 -48z
              M9795 13564 c-49 -20 -260 -383 -470 -809 -87 -176 -116 -246 -125
              -297 -17 -94 -10 -100 24 -21 121 282 385 783 558 1056 27 43 48 80 46 81 -1
              2 -17 -3 -33 -10z
              M10350 13477 c-582 -896 -974 -1805 -1245 -2887 -30 -118 -65 -249
              -80 -290 -40 -118 -96 -230 -114 -230 -21 0 -114 44 -153 71 -22 16 -32 19
              -41 10 -20 -20 5 -43 92 -88 119 -62 130 -60 179 35 56 107 95 223 153 456
              270 1075 690 2059 1221 2859 43 65 78 120 78 122 0 2 -8 9 -19 14 -16 9 -25
              -1 -71 -72z
              M13220 13488 c-278 -231 -572 -530 -801 -818 -42 -52 -79 -100 -82
              -107 -8 -13 1 -43 13 -43 4 0 52 55 106 123 226 281 520 582 777 796 101 84
              124 107 110 113 -31 12 -36 9 -123 -64z
              M6457 13513 c-24 -23 13 -49 316 -227 177 -104 379 -223 448 -264
              l127 -75 6 -91 c4 -50 13 -161 21 -246 8 -85 28 -310 45 -500 17 -190 35 -390
              40 -445 5 -55 12 -134 15 -175 8 -116 34 -323 56 -444 153 -867 618 -1507
              1214 -1672 87 -24 119 -28 240 -28 l140 -1 85 41 c154 74 281 220 390 449 74
              153 115 276 185 550 187 734 390 1284 690 1880 165 327 335 621 477 824 21 30
              37 55 36 55 -2 1 -11 7 -20 14 -15 12 -25 0 -93 -103 -511 -781 -912 -1730
              -1145 -2710 -71 -301 -193 -582 -319 -735 -74 -91 -135 -142 -216 -183 -60
              -30 -112 -39 -214 -39 -503 2 -1000 443 -1259 1117 -39 103 -106 333 -131 455
              -34 165 -44 233 -66 480 -8 96 -19 220 -25 275 -5 55 -16 178 -25 273 -28 316
              -46 516 -60 672 -8 85 -17 189 -20 232 -5 53 -12 81 -23 91 -9 7 -150 92 -312
              187 -162 95 -362 213 -443 262 -162 97 -151 91 -160 81z
              M11874 13488 c-16 -18 -80 -95 -142 -171 -104 -129 -111 -140 -96
              -155 16 -15 19 -14 43 18 34 44 230 281 248 299 8 7 12 18 10 25 -7 23 -34 16
              -63 -16z
              M13409 13468 c-139 -109 -329 -281 -485 -440 -229 -232 -317 -331
              -457 -514 -44 -58 -45 -62 -29 -77 16 -15 21 -12 64 46 217 293 608 690 931
              945 64 50 75 63 60 69 -10 3 -21 8 -24 9 -4 2 -31 -15 -60 -38z
              M17485 13443 c-172 -75 -552 -227 -670 -269 l-80 -28 44 -9 c35 -7
              55 -4 105 14 94 34 506 201 641 259 95 41 131 52 172 51 61 0 323 -52 404 -79
              l54 -19 100 -241 c55 -133 105 -247 111 -253 7 -6 19 -9 27 -7 12 2 -7 59 -93
              267 -60 145 -112 267 -117 272 -25 25 -421 110 -508 108 -26 0 -92 -23 -190
              -66z
              M10416 13333 c-524 -803 -924 -1744 -1185 -2790 -76 -301 -140 -471
              -221 -581 -30 -42 -32 -43 -73 -37 -130 22 -256 94 -376 214 -138 138 -245
              312 -336 545 -63 161 -128 423 -149 606 -11 91 -52 529 -81 860 -38 439 -46
              531 -55 625 -10 107 -38 427 -42 485 -2 27 -7 35 -23 35 -24 0 -24 -22 0 -287
              8 -93 22 -246 30 -340 9 -95 20 -218 25 -273 5 -55 12 -134 15 -175 3 -41 10
              -118 15 -170 5 -52 12 -129 15 -170 3 -41 10 -118 15 -170 5 -52 12 -129 15
              -170 46 -636 229 -1134 524 -1430 139 -140 278 -216 410 -227 l66 -5 28 37
              c87 111 160 299 238 609 270 1082 701 2078 1250 2887 28 42 35 59 26 68 -6 6
              -15 11 -19 11 -5 0 -55 -71 -112 -157z
              M9552 13424 c-23 -19 -58 -63 -78 -97 -91 -156 -213 -396 -219 -432
              -3 -22 -8 -50 -11 -62 -2 -13 0 -23 5 -23 5 0 13 10 17 23 13 42 279 531 326
              600 27 37 10 34 -40 -9z
              M13466 13340 c-76 -61 -144 -116 -151 -123 -6 -7 -70 -65 -141 -129
              -203 -183 -443 -449 -647 -716 -7 -9 -5 -18 6 -29 9 -9 18 -15 20 -12 2 2 46
              58 99 124 268 338 586 654 908 902 96 74 108 92 59 93 -8 0 -77 -50 -153 -110z
              M6340 13410 c-10 -6 -11 -12 -5 -18 8 -8 597 -357 818 -485 37 -21
              67 -44 67 -50 0 -7 7 -86 15 -177 8 -91 22 -244 30 -340 8 -96 19 -218 25
              -270 5 -52 12 -124 15 -160 6 -73 18 -215 41 -465 25 -276 71 -556 118 -715 8
              -25 24 -79 36 -120 59 -199 186 -472 301 -650 256 -395 620 -662 1002 -735
              106 -20 264 -21 350 -1 226 53 424 247 572 559 75 159 116 282 186 557 75 293
              125 467 196 685 223 682 538 1348 907 1915 55 85 72 118 64 127 -6 7 -14 13
              -18 13 -11 0 -170 -246 -276 -427 -119 -202 -354 -669 -446 -888 -199 -470
              -328 -858 -463 -1390 -73 -289 -114 -413 -190 -570 -181 -379 -401 -554 -696
              -555 -124 0 -290 36 -419 91 -489 208 -877 719 -1061 1397 -71 266 -103 473
              -134 882 -3 41 -10 118 -15 170 -5 52 -12 127 -15 165 -3 39 -12 138 -20 220
              -18 193 -33 362 -40 450 -12 146 -27 270 -33 276 -4 3 -140 84 -302 179 -419
              246 -500 294 -540 318 -39 24 -48 26 -70 12z
              M10543 13283 c-448 -691 -791 -1434 -1039 -2252 -67 -223 -81 -272
              -163 -591 -85 -329 -171 -521 -281 -625 -28 -27 -38 -30 -86 -28 -72 3 -162
              28 -250 69 -278 131 -519 451 -660 879 -76 234 -118 465 -149 825 -10 107 -19
              201 -21 208 -2 8 -12 12 -21 10 -21 -4 -21 -18 3 -278 25 -281 58 -473 113
              -672 163 -576 480 -966 869 -1068 110 -29 170 -27 214 8 124 95 226 322 329
              726 269 1061 655 1966 1180 2763 82 124 86 133 69 148 -16 15 -23 7 -107 -122z
              M13670 13328 c-135 -102 -304 -244 -407 -341 -154 -145 -169 -160
              -249 -247 -161 -174 -209 -231 -328 -384 -70 -90 -76 -100 -62 -115 10 -9 19
              -12 24 -6 5 6 53 66 107 134 267 338 615 679 940 920 128 95 123 90 105 101
              -25 16 -31 13 -130 -62z
              M17505 13320 c-88 -38 -242 -102 -343 -142 -176 -69 -202 -87 -127
              -88 29 0 227 76 502 194 73 31 147 56 165 56 45 0 175 -25 278 -52 l85 -23 76
              -184 c60 -147 80 -186 99 -193 12 -5 25 -7 27 -5 2 2 -36 98 -84 212 l-88 209
              -85 23 c-84 23 -298 64 -329 62 -9 0 -88 -31 -176 -69z
              M8385 13300 c6 -92 29 -350 45 -520 5 -58 12 -136 15 -175 3 -38 12
              -140 20 -225 23 -242 34 -369 40 -450 3 -41 10 -113 15 -160 5 -47 12 -123 15
              -170 6 -79 18 -202 31 -295 18 -135 100 -431 140 -505 28 -53 15 67 -21 184
              -55 184 -88 379 -109 641 -7 88 -31 367 -51 590 -8 94 -22 247 -30 340 -8 94
              -19 215 -25 270 -5 55 -16 183 -25 285 -19 218 -19 218 -46 250 l-20 25 6 -85z
              M19212 13368 c-7 -9 11 -62 59 -178 38 -91 97 -232 131 -315 34 -82
              62 -151 62 -151 5 -9 46 -13 46 -5 0 10 -218 547 -255 629 -16 34 -27 39 -43
              20z
              M13890 13323 c-56 -38 -250 -186 -325 -248 -269 -224 -598 -567 -803
              -839 -29 -38 -52 -72 -52 -76 0 -4 6 -13 14 -19 10 -9 29 10 89 88 296 384
              682 758 1062 1028 52 36 92 70 89 75 -9 14 -48 9 -74 -9z
              M6240 13315 c-7 -9 -11 -17 -9 -19 4 -4 252 -150 624 -368 121 -71
              223 -133 227 -137 5 -4 14 -74 22 -156 19 -192 44 -472 51 -555 3 -36 10 -108
              15 -160 5 -52 12 -129 15 -170 3 -41 10 -118 15 -170 5 -52 12 -129 15 -170
              39 -487 159 -941 344 -1295 126 -240 212 -362 381 -538 148 -154 291 -264 450
              -346 222 -114 372 -153 590 -154 160 -1 228 12 338 63 184 86 351 258 485 500
              90 163 157 353 242 685 111 434 220 780 358 1130 193 492 472 1035 729 1417
              32 48 58 91 58 96 0 5 -7 15 -15 22 -12 10 -28 -9 -98 -117 -486 -749 -839
              -1588 -1091 -2598 -111 -447 -243 -715 -455 -925 -135 -134 -251 -197 -402
              -221 -92 -14 -289 -6 -384 16 -469 108 -889 476 -1160 1015 -181 361 -293 802
              -330 1300 -3 47 -10 121 -15 165 -5 44 -12 116 -15 160 -3 44 -10 125 -15 180
              -5 55 -12 132 -15 170 -3 39 -10 113 -15 165 -5 52 -12 131 -16 175 -12 162
              -32 337 -41 345 -10 10 -860 510 -866 510 -3 0 -10 -7 -17 -15z
              M20674 13307 c-3 -9 14 -64 39 -124 255 -625 250 -613 275 -613 15 0
              22 5 19 13 -16 41 -199 484 -246 596 -54 130 -74 160 -87 128z
              M13975 13217 c-253 -182 -417 -318 -624 -521 -192 -189 -369 -389
              -511 -579 -40 -54 -42 -59 -27 -74 9 -9 18 -15 20 -12 199 263 324 408 526
              609 217 217 475 430 694 576 71 47 85 60 70 65 -34 14 -48 8 -148 -64z
              M17585 13221 c-60 -26 -170 -72 -242 -101 -73 -30 -133 -57 -133 -61
              0 -4 14 -9 32 -11 35 -6 59 3 301 106 l158 68 107 -21 c179 -35 160 -20 219
              -161 38 -92 57 -126 74 -132 12 -5 23 -7 25 -5 4 3 -99 261 -114 286 -9 16
              -67 33 -192 56 -47 8 -94 17 -105 20 -11 2 -69 -18 -130 -44z
              M21617 13264 c-4 -4 -7 -14 -7 -23 0 -19 93 -251 101 -251 3 0 10 10
              16 22 8 18 1 43 -34 128 -44 105 -63 136 -76 124z
              M14159 13179 c-147 -96 -279 -198 -279 -213 0 -8 4 -17 9 -20 5 -3
              73 40 153 97 79 56 169 118 201 137 53 32 70 50 48 50 -5 0 -16 2 -23 5 -8 3
              -57 -22 -109 -56z
              M6135 13220 c-11 -18 2 -26 230 -160 116 -68 259 -152 318 -187 114
              -67 137 -74 137 -45 0 13 -89 70 -322 207 -177 105 -330 191 -339 193 -9 2
              -20 -2 -24 -8z
              M14380 13162 c-14 -9 -86 -56 -160 -105 -435 -288 -846 -677 -1168
              -1106 -57 -76 -69 -98 -61 -113 5 -10 13 -18 17 -17 4 0 46 53 94 117 330 445
              768 848 1250 1150 87 54 123 82 112 86 -26 10 -58 6 -84 -12z
              M6031 13126 c-14 -17 -19 -13 144 -109 222 -130 658 -386 660 -389 2
              -2 8 -55 14 -118 18 -186 40 -430 46 -505 3 -38 10 -115 15 -170 14 -148 29
              -320 35 -405 66 -875 355 -1592 834 -2070 254 -253 527 -419 831 -503 253 -71
              560 -65 762 14 311 121 583 423 763 849 52 123 106 296 165 527 91 357 167
              608 280 922 37 103 48 147 41 154 -18 18 -36 -10 -74 -113 -101 -274 -195
              -581 -282 -924 -87 -342 -140 -497 -236 -688 -204 -409 -485 -657 -825 -729
              -135 -29 -358 -22 -514 15 -545 128 -1005 518 -1324 1121 -168 316 -301 761
              -350 1170 -12 93 -26 236 -31 310 -3 44 -8 98 -10 120 -3 22 -7 72 -10 110 -6
              79 -30 348 -45 500 -5 55 -12 134 -15 175 -6 87 -24 263 -28 266 -8 8 -822
              484 -827 484 -4 0 -13 -6 -19 -14z
              M17563 13082 c-73 -31 -133 -60 -133 -64 0 -5 20 -8 44 -8 29 0 75
              14 142 43 l100 44 84 -17 85 -17 29 -66 c25 -57 54 -86 69 -70 3 2 -10 41 -28
              86 -35 88 -45 96 -135 111 -112 18 -119 17 -257 -42z
              M8535 13088 c2 -24 9 -90 14 -148 6 -58 13 -139 16 -180 3 -41 10
              -118 15 -170 5 -52 12 -124 15 -160 3 -36 12 -137 20 -225 8 -88 19 -212 25
              -275 32 -382 42 -477 57 -585 9 -66 20 -127 25 -135 10 -18 8 243 -3 334 -4
              34 -17 174 -28 311 -22 263 -42 478 -66 735 -8 85 -17 182 -20 215 -13 173
              -19 214 -37 262 -25 67 -38 75 -33 21z
              M14462 13051 c-344 -209 -567 -385 -868 -685 -186 -187 -304 -323
              -451 -520 -63 -84 -72 -101 -61 -115 7 -9 13 -17 14 -19 1 -2 32 39 70 90 198
              272 453 546 739 794 183 159 537 403 717 495 31 16 38 23 27 30 -29 18 -60 7
              -187 -70z
              M14664 13013 c-545 -302 -1056 -755 -1428 -1266 -73 -100 -78 -110
              -63 -125 9 -9 18 -14 20 -11 2 2 43 58 92 124 377 513 863 940 1419 1248 66
              37 125 68 130 70 15 6 6 13 -25 20 -20 5 -53 -9 -145 -60z
              M5946 13024 c-11 -7 -18 -15 -15 -17 2 -2 175 -104 384 -227 209
              -123 384 -226 388 -230 5 -4 10 -22 12 -41 5 -41 23 -242 30 -319 8 -101 26
              -303 40 -460 8 -85 21 -236 30 -335 60 -670 213 -1185 490 -1645 297 -493 741
              -860 1215 -1006 170 -52 283 -69 470 -69 159 0 223 8 335 40 357 103 664 394
              875 829 86 176 157 384 221 639 172 687 378 1249 662 1807 97 189 239 437 341
              594 61 94 74 121 65 132 -20 25 -15 30 -127 -146 -272 -430 -514 -925 -697
              -1430 -121 -334 -172 -501 -266 -870 -120 -470 -212 -700 -378 -952 -266 -402
              -606 -600 -1031 -602 -345 -1 -705 128 -1030 367 -113 83 -361 329 -448 444
              -288 380 -490 853 -587 1372 -19 101 -53 340 -60 421 -3 36 -9 116 -15 178
              -18 207 -30 337 -35 407 -3 39 -10 113 -15 165 -5 52 -12 129 -15 170 -16 194
              -32 331 -41 339 -5 4 -166 100 -359 213 -192 113 -366 215 -385 226 -31 18
              -37 19 -54 6z
              M14833 12950 c-544 -282 -1029 -689 -1414 -1185 -133 -171 -171 -229
              -163 -248 9 -24 20 -21 41 8 325 456 659 795 1071 1088 159 113 326 214 515
              312 154 80 158 83 129 93 -26 9 -44 3 -179 -68z
              M17675 12996 l-30 -13 60 -12 c33 -6 68 -13 77 -15 24 -5 44 20 29
              37 -14 18 -99 20 -136 3z
              M15034 12901 c-332 -161 -611 -340 -899 -579 -143 -119 -430 -414
              -556 -572 -156 -196 -241 -317 -237 -337 6 -34 28 -22 69 39 178 262 467 586
              719 804 317 274 702 515 1085 679 35 15 38 18 20 25 -37 16 -60 9 -201 -59z
              M5850 12925 c-7 -9 -11 -17 -9 -20 3 -2 166 -99 364 -215 198 -117
              365 -217 372 -224 6 -6 14 -47 17 -91 4 -44 11 -123 16 -175 5 -52 12 -129 15
              -170 3 -41 10 -115 15 -165 5 -49 12 -124 15 -165 3 -41 10 -118 15 -170 5
              -52 12 -129 15 -170 10 -127 34 -323 50 -406 9 -43 20 -106 26 -139 37 -198
              135 -523 220 -724 358 -843 1012 -1414 1759 -1534 201 -33 352 -31 533 7 346
              73 657 297 884 636 184 276 286 528 408 1006 69 273 96 367 177 614 194 596
              484 1213 796 1694 58 89 62 100 48 114 -8 9 -17 14 -20 11 -13 -14 -143 -222
              -203 -324 -384 -658 -645 -1317 -857 -2160 -97 -390 -205 -652 -370 -905 -232
              -354 -524 -568 -886 -646 -107 -23 -341 -27 -471 -8 -206 30 -358 78 -564 179
              -284 139 -526 331 -747 595 -161 191 -351 508 -452 751 -117 285 -229 708
              -260 989 -4 30 -11 91 -16 135 -5 44 -12 114 -15 155 -3 41 -12 143 -20 225
              -8 83 -19 206 -24 275 -24 282 -43 498 -49 544 l-7 50 -205 121 c-353 209
              -551 325 -554 325 -2 0 -9 -7 -16 -15z
              M15253 12855 c-407 -171 -722 -366 -1074 -666 -249 -213 -531 -532
              -714 -807 -44 -66 -47 -74 -33 -88 14 -14 22 -6 84 83 477 687 1119 1197 1868
              1483 55 21 94 41 86 43 -51 17 -76 12 -217 -48z
              M11225 12853 c-487 -733 -869 -1627 -1100 -2577 -45 -185 -64 -248
              -115 -386 -96 -261 -224 -475 -374 -626 -182 -183 -349 -261 -596 -281 -74 -6
              -95 -11 -95 -23 0 -34 200 -21 335 22 36 11 108 43 160 72 79 42 115 71 211
              166 244 242 387 529 509 1020 227 910 541 1681 969 2379 42 69 98 157 124 196
              35 52 44 72 35 82 -17 21 -23 16 -63 -44z
              M15565 12831 c-484 -170 -947 -437 -1320 -760 -88 -76 -405 -402
              -405 -416 0 -37 31 -26 77 26 211 239 493 483 768 666 277 184 645 361 975
              469 76 25 78 27 45 34 -48 12 -55 11 -140 -19z
              M5781 12811 c-12 -22 -56 6 466 -301 l211 -125 6 -50 c3 -27 8 -84
              11 -125 3 -41 10 -115 15 -165 5 -49 12 -124 15 -165 3 -41 10 -122 15 -180 6
              -58 12 -132 15 -165 14 -159 36 -387 45 -445 5 -36 12 -83 15 -105 21 -153
              109 -534 131 -562 10 -14 15 -15 25 -6 9 9 4 42 -24 150 -50 194 -104 498
              -121 683 -3 30 -10 107 -16 170 -5 63 -17 187 -25 275 -8 88 -17 187 -19 220
              -5 55 -13 144 -36 398 -4 52 -13 94 -21 102 -8 8 -151 94 -319 193 -168 99
              -322 189 -342 201 -37 22 -37 22 -47 2z
              M11312 12743 c-187 -291 -408 -690 -520 -943 -11 -25 -36 -80 -56
              -124 -41 -89 -44 -106 -17 -106 14 0 30 26 66 108 150 337 402 800 581 1066
              25 38 31 53 22 63 -6 7 -14 13 -18 13 -4 0 -30 -35 -58 -77z
              M15788 12774 c-888 -260 -1609 -792 -2142 -1581 -63 -94 -67 -103
              -54 -122 14 -20 17 -16 79 77 374 566 860 1007 1442 1307 251 130 549 244 795
              305 96 24 105 34 37 43 -29 4 -72 -4 -157 -29z
              M13436 12588 c-204 -204 -343 -361 -472 -533 -77 -102 -74 -97 -59
              -118 11 -16 20 -7 91 85 168 221 402 481 584 647 107 97 130 131 90 131 -15 0
              -89 -68 -234 -212z
              M16165 12733 c-448 -104 -768 -219 -1092 -394 -148 -80 -405 -252
              -544 -363 -296 -237 -612 -594 -817 -922 -45 -73 -49 -84 -37 -100 7 -11 15
              -17 18 -14 2 3 32 50 67 105 275 432 649 815 1065 1090 452 299 888 467 1510
              581 l40 7 -40 8 c-43 8 -138 10 -170 2z
              M5732 12684 c3 -31 -10 -23 415 -271 208 -121 185 -87 203 -295 5
              -65 14 -169 20 -230 5 -62 12 -140 15 -173 14 -171 47 -510 56 -585 90 -744
              333 -1373 728 -1885 107 -139 365 -397 496 -496 269 -204 549 -342 845 -418
              120 -31 129 -33 275 -52 114 -15 390 -6 510 16 558 104 1024 519 1307 1164 95
              218 134 339 237 741 203 790 502 1501 893 2119 55 87 64 108 55 123 -15 23
              -20 18 -105 -118 -374 -596 -676 -1307 -868 -2044 -20 -74 -51 -198 -71 -274
              -211 -838 -685 -1441 -1276 -1621 -167 -51 -277 -66 -472 -66 -158 -1 -211 4
              -350 27 -106 18 -277 69 -405 121 -747 305 -1337 1020 -1608 1948 -105 358
              -146 619 -197 1255 -3 36 -12 130 -20 210 -7 80 -19 211 -26 292 -7 81 -15
              151 -18 156 -3 5 -58 39 -121 77 -96 56 -485 286 -513 302 -5 3 -7 -8 -5 -23z
              M16455 12645 c-610 -84 -1206 -313 -1662 -640 -186 -134 -294 -226
              -458 -390 -165 -165 -254 -269 -391 -460 -127 -175 -197 -291 -189 -315 3 -11
              10 -20 14 -20 5 0 45 57 89 128 146 232 292 415 495 621 356 361 744 616 1222
              804 247 96 604 189 860 223 28 4 88 12 135 18 l85 12 -32 17 c-36 19 -42 19
              -168 2z
              M6978 12645 c-6 -6 -4 -47 12 -215 6 -58 12 -134 15 -170 3 -36 12
              -132 20 -215 13 -137 29 -315 40 -455 45 -543 90 -791 205 -1130 245 -729 723
              -1258 1313 -1455 60 -20 115 -34 123 -31 17 7 18 35 2 40 -499 135 -917 486
              -1203 1011 -167 306 -296 719 -350 1115 -14 109 -17 138 -34 330 -21 228 -41
              458 -56 615 -8 88 -17 194 -20 235 -22 267 -30 325 -46 328 -8 1 -18 0 -21 -3z
              M8711 12564 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z
              M5690 12556 c0 -21 56 -63 177 -134 59 -34 160 -93 223 -130 l115
              -67 17 -185 c9 -102 24 -264 33 -360 8 -96 23 -251 31 -345 40 -437 108 -778
              224 -1126 247 -740 700 -1345 1282 -1711 298 -187 644 -313 973 -353 65 -8
              365 -8 430 0 623 75 1119 451 1453 1103 131 255 196 439 303 857 206 801 477
              1464 846 2063 89 144 103 180 77 189 -21 7 -256 -392 -386 -654 -172 -346
              -285 -622 -413 -1009 -72 -218 -88 -276 -190 -669 -194 -752 -508 -1261 -969
              -1569 -168 -113 -334 -185 -531 -231 -454 -107 -997 -16 -1459 246 -846 479
              -1401 1395 -1565 2579 -6 41 -13 102 -16 135 -3 33 -10 103 -15 155 -5 52 -12
              129 -15 170 -3 41 -10 116 -15 165 -5 50 -12 124 -15 165 -3 41 -10 120 -15
              175 -5 55 -12 129 -15 165 -3 36 -9 68 -13 72 -14 13 -535 318 -543 318 -5 0
              -9 -6 -9 -14z
              M11626 12487 c-471 -750 -756 -1429 -985 -2342 -107 -427 -218 -700
              -392 -966 -243 -370 -532 -590 -906 -690 -106 -28 -177 -34 -393 -33 -162 1
              -211 5 -290 23 -148 34 -206 51 -328 97 -596 228 -1086 719 -1398 1403 -57
              127 -84 161 -92 120 -8 -42 168 -390 294 -581 373 -565 886 -940 1469 -1072
              368 -83 743 -44 1045 109 193 98 399 275 546 470 220 293 358 603 479 1075
              239 942 536 1655 968 2328 49 76 57 95 47 107 -7 8 -15 15 -19 15 -3 0 -24
              -28 -45 -63z
              M16610 12529 c-714 -81 -1322 -315 -1838 -706 -301 -228 -565 -513
              -789 -848 -135 -204 -156 -241 -143 -265 6 -11 12 -20 14 -20 2 0 23 33 47 73
              464 786 1149 1325 2009 1581 121 36 222 61 325 80 50 10 112 22 139 27 83 16
              396 50 406 44 17 -11 11 11 -8 28 -20 18 -45 19 -162 6z
              M8727 12450 c3 -47 7 -87 9 -89 7 -8 3 83 -6 129 -6 33 -7 22 -3 -40z
              M18695 12387 c824 -279 1504 -698 2065 -1273 434 -445 759 -965 925
              -1484 34 -109 39 -118 98 -177 34 -35 65 -63 69 -63 4 0 8 10 8 22 0 13 -20
              43 -49 73 -43 45 -53 64 -95 189 -356 1057 -1282 2014 -2461 2541 -322 144
              -523 214 -635 221 l-85 5 160 -54z
              M5662 12404 c2 -9 95 -71 208 -138 l205 -122 7 -44 c5 -38 23 -226
              43 -465 44 -524 78 -778 141 -1052 86 -377 278 -861 459 -1163 314 -522 742
              -927 1240 -1170 347 -170 651 -243 1010 -244 318 -1 549 46 800 164 608 284
              1061 918 1276 1785 212 859 460 1477 850 2128 49 81 89 151 89 155 0 4 -5 13
              -12 20 -14 14 -17 11 -145 -203 -355 -595 -606 -1222 -809 -2025 -113 -452
              -253 -789 -448 -1084 -100 -150 -157 -221 -289 -354 -359 -363 -786 -544
              -1292 -545 -1046 -2 -2037 766 -2498 1938 -121 310 -190 566 -257 965 -11 64
              -36 292 -45 400 -3 41 -10 120 -15 175 -5 55 -12 129 -15 165 -3 36 -10 108
              -15 160 -5 52 -12 138 -16 190 -4 52 -10 105 -15 116 -4 12 -27 33 -51 46 -24
              14 -117 68 -207 121 -161 96 -206 114 -199 81z
              M18215 12405 c-45 -14 -43 -16 65 -41 98 -22 351 -96 465 -136 813
              -283 1539 -763 2071 -1368 348 -395 613 -857 755 -1310 l32 -105 105 -107 c58
              -59 109 -105 114 -102 21 13 1 44 -84 133 l-93 96 -53 160 c-199 594 -587
              1168 -1102 1629 -359 322 -698 550 -1135 766 -316 156 -619 272 -922 354 -169
              45 -172 45 -218 31z
              M16605 12394 c-49 -7 -114 -15 -144 -19 -82 -10 -285 -51 -417 -86
              -886 -230 -1602 -778 -2078 -1591 -52 -90 -56 -100 -43 -118 14 -20 18 -15 78
              87 248 425 587 803 959 1070 253 182 584 348 902 453 216 71 582 146 803 164
              220 18 215 17 204 38 -9 17 -20 19 -92 16 -45 -1 -122 -7 -172 -14z
              M19110 12377 c3 -3 64 -30 135 -61 1222 -536 2178 -1510 2551 -2600
              47 -136 71 -179 86 -152 19 34 -136 430 -262 673 -198 379 -436 700 -770 1034
              -432 433 -863 738 -1430 1012 -151 73 -185 86 -245 91 -38 4 -67 5 -65 3z
              M17979 12310 l-24 -19 27 -1 c56 0 322 -66 553 -137 487 -148 973
              -387 1400 -686 393 -276 793 -673 1051 -1042 206 -294 369 -624 465 -940 l34
              -110 145 -150 c80 -82 148 -151 152 -153 3 -2 8 7 10 20 3 18 -26 54 -130 164
              l-133 141 -48 144 c-245 732 -716 1359 -1416 1886 -562 423 -1277 746 -1965
              888 -94 19 -93 19 -121 -5z
              M19541 12326 c2 -2 45 -25 94 -51 765 -407 1388 -953 1835 -1609 134
              -198 300 -511 390 -738 30 -75 56 -136 57 -134 2 1 4 25 5 52 1 40 -9 74 -56
              184 -309 725 -863 1386 -1601 1912 -188 134 -478 310 -587 357 -37 16 -150 38
              -137 27z
              M5630 12276 c0 -20 24 -38 158 -116 86 -51 159 -94 161 -96 4 -4 18
              -126 25 -234 4 -47 11 -128 16 -180 5 -52 12 -126 15 -165 3 -38 10 -113 15
              -165 5 -52 12 -124 15 -160 11 -126 26 -196 56 -272 30 -75 47 -102 36 -55
              -13 51 -47 315 -62 482 -9 99 -23 252 -31 340 -8 88 -16 180 -19 205 -2 25 -7
              84 -11 132 -4 47 -9 90 -13 95 -3 5 -51 36 -106 68 -55 32 -133 77 -172 101
              -40 24 -75 44 -78 44 -3 0 -5 -11 -5 -24z
              M19895 12280 c-2 -4 8 -13 23 -19 103 -47 429 -268 637 -432 183
              -144 248 -202 435 -386 133 -130 180 -165 180 -130 0 16 -209 229 -325 332
              -241 214 -440 365 -719 544 -96 62 -141 85 -178 90 -27 5 -51 5 -53 1z
              M16620 12260 c-268 -31 -441 -67 -685 -142 -305 -93 -627 -247 -866
              -413 -63 -44 -202 -146 -209 -155 -3 -3 -50 -44 -105 -92 -228 -196 -468 -489
              -644 -782 -115 -192 -122 -207 -105 -230 16 -23 15 -25 49 43 43 86 162 277
              244 391 245 343 570 650 911 861 215 134 501 263 745 337 157 48 448 112 570
              126 183 21 241 26 314 30 45 3 85 7 88 10 3 3 3 12 0 21 -8 20 -102 19 -307
              -5z
              M20200 12246 c0 -3 42 -33 93 -67 512 -342 978 -791 1313 -1264 119
              -167 206 -309 309 -500 50 -93 52 -94 45 -27 -5 47 -19 79 -76 177 -362 622
              -856 1154 -1469 1584 -85 60 -139 91 -157 91 -14 0 -33 3 -42 6 -9 3 -16 4
              -16 0z
              M17821 12210 c-23 -16 -23 -18 -5 -23 10 -3 39 -8 64 -12 55 -8 232
              -43 280 -55 19 -5 58 -14 85 -21 491 -117 1058 -366 1505 -662 477 -316 878
              -708 1164 -1142 201 -304 322 -558 425 -892 l31 -102 181 -188 c99 -103 183
              -189 188 -191 5 -2 11 7 14 19 5 18 -25 54 -169 203 l-174 181 -41 130 c-224
              712 -689 1344 -1369 1862 -595 453 -1361 779 -2125 907 -20 3 -39 -2 -54 -14z
              M20500 12204 c1 -5 25 -26 56 -47 160 -111 416 -330 594 -507 336
              -334 543 -599 801 -1025 l19 -30 0 45 c0 40 -10 62 -93 190 -190 296 -385 537
              -652 806 -104 105 -239 234 -300 286 -163 139 -344 278 -363 278 -9 0 -27 3
              -39 6 -13 4 -23 2 -23 -2z
              M20760 12172 c0 -5 6 -12 14 -15 33 -13 276 -227 440 -387 290 -285
              481 -510 676 -800 81 -121 79 -119 78 -79 0 40 -28 91 -120 219 -234 327 -554
              674 -863 934 -66 56 -127 106 -135 113 -21 16 -90 28 -90 15z
              M11973 12028 c-120 -199 -188 -324 -284 -523 -238 -494 -395 -938
              -543 -1536 -128 -520 -334 -963 -601 -1294 -305 -379 -697 -627 -1144 -721
              -242 -52 -569 -53 -818 -4 -526 103 -997 356 -1403 752 -144 140 -286 305
              -371 429 -22 33 -42 59 -44 59 -7 0 44 -132 60 -157 78 -119 363 -414 530
              -549 343 -277 719 -466 1105 -554 224 -51 255 -54 525 -54 270 0 333 7 540 60
              349 91 716 322 971 612 328 372 545 817 694 1417 201 808 478 1488 853 2093
              43 70 50 102 22 102 -7 0 -49 -60 -92 -132z
              M5632 12123 c2 -23 19 -37 99 -85 l95 -57 18 -193 c10 -106 20 -213
              22 -238 12 -133 16 -160 37 -212 21 -57 42 -79 32 -35 -2 12 -11 99 -20 192
              -21 233 -35 392 -40 440 -2 22 -4 47 -4 57 -1 9 -20 27 -43 41 -24 14 -78 46
              -121 71 l-78 46 3 -27z
              M16715 12135 c-351 -33 -686 -111 -980 -230 -149 -60 -177 -77 -158
              -96 9 -9 43 1 135 39 227 93 539 181 728 207 25 3 70 10 100 15 62 11 110 16
              255 25 175 12 162 8 157 45 -2 12 -57 11 -237 -5z
              M21041 12114 c24 -22 64 -57 89 -79 227 -201 538 -538 722 -783 55
              -73 101 -132 103 -130 2 2 0 22 -4 44 -18 98 -422 568 -719 837 -137 125 -143
              129 -201 142 l-33 7 43 -38z
              M17686 12092 c-16 -19 -16 -20 16 -25 18 -4 56 -9 83 -12 28 -4 82
              -13 120 -21 39 -8 106 -22 150 -31 186 -37 524 -138 725 -216 136 -53 307
              -130 448 -202 116 -59 149 -72 160 -63 22 19 -13 41 -223 143 -429 208 -830
              339 -1285 419 -161 28 -175 29 -194 8z
              M12078 11942 c-264 -439 -466 -888 -638 -1417 -54 -167 -88 -286
              -160 -570 -90 -355 -164 -561 -291 -820 -340 -694 -871 -1154 -1499 -1299
              -192 -44 -342 -59 -554 -53 -216 5 -351 24 -546 74 -165 42 -163 42 -168 21
              -2 -12 5 -21 22 -28 31 -12 200 -57 240 -64 16 -3 52 -10 80 -15 212 -42 474
              -49 706 -20 768 95 1395 589 1777 1398 116 245 189 457 278 806 203 795 443
              1389 796 1972 63 103 66 112 50 127 -15 14 -22 5 -93 -112z
              M21500 11862 c96 -99 218 -231 270 -295 52 -63 96 -114 98 -112 7 7
              -70 145 -108 193 -122 155 -342 357 -420 387 -8 3 64 -75 160 -173z
              M16840 12013 c-610 -44 -1138 -214 -1582 -509 -322 -214 -623 -516
              -841 -846 -75 -112 -194 -319 -237 -411 -20 -41 -21 -52 -10 -75 l12 -27 28
              62 c78 171 270 478 408 650 395 494 944 849 1552 1003 113 29 287 64 365 74
              147 19 212 26 305 31 58 4 108 11 111 17 19 30 -11 38 -111 31z
              M17586 11971 c-17 -18 -16 -19 11 -25 15 -3 53 -8 83 -11 65 -7 244
              -37 349 -60 765 -162 1506 -532 2046 -1020 50 -45 107 -97 129 -116 119 -106
              361 -397 485 -582 178 -267 296 -517 403 -858 l43 -137 259 -271 c250 -260
              275 -282 276 -232 0 7 -112 128 -248 270 l-248 256 -32 105 c-155 502 -368
              885 -709 1270 -637 723 -1578 1228 -2603 1399 -203 34 -224 35 -244 12z
              M5660 11958 c0 -12 9 -31 20 -43 12 -13 23 -45 29 -85 9 -62 43 -160
              56 -160 4 0 4 3 2 8 -3 4 -8 54 -12 112 -3 58 -8 112 -11 121 -3 9 -22 29 -44
              43 -40 26 -40 26 -40 4z
              M12207 11898 c-192 -311 -394 -731 -537 -1117 -104 -281 -168 -493
              -256 -839 -95 -376 -173 -598 -305 -867 -215 -440 -517 -806 -864 -1047 -263
              -182 -579 -308 -895 -357 -63 -10 -101 -13 -281 -26 -218 -16 -592 38 -864
              123 -390 123 -844 391 -1137 671 -51 48 -57 45 -33 -12 34 -81 390 -345 660
              -489 307 -164 601 -261 945 -314 136 -20 183 -23 385 -20 244 4 339 16 555 70
              484 122 927 428 1261 871 271 360 463 787 605 1345 105 413 191 687 309 990
              116 299 288 654 439 908 70 118 84 147 75 163 -5 10 -11 19 -13 19 -2 0 -24
              -33 -49 -72z
              M16700 11865 c-167 -17 -369 -53 -495 -87 -418 -113 -713 -257 -1050
              -512 -101 -76 -380 -354 -469 -466 -198 -251 -458 -702 -441 -767 3 -13 9 -23
              13 -23 4 0 37 62 74 138 414 855 1085 1401 1973 1607 93 21 276 53 350 60 33
              3 105 10 159 15 92 8 115 17 116 43 0 10 -80 7 -230 -8z
              M12265 11733 c-307 -520 -566 -1176 -725 -1838 -293 -1221 -969
              -2036 -1909 -2300 -47 -13 -102 -27 -121 -30 -19 -3 -55 -11 -80 -16 -25 -6
              -103 -17 -175 -25 -683 -73 -1411 145 -2025 608 -119 89 -128 94 -118 65 16
              -46 31 -61 159 -152 361 -258 780 -445 1174 -524 261 -52 600 -67 850 -37 834
              101 1513 607 1943 1449 149 291 256 591 352 982 84 342 209 725 341 1045 79
              192 262 562 364 735 41 71 74 136 73 144 -8 38 -33 13 -103 -106z
              M17490 11845 c-7 -9 -11 -17 -9 -19 2 -2 37 -6 77 -10 89 -7 224 -28
              397 -62 718 -141 1398 -464 1956 -929 128 -107 376 -358 474 -480 282 -351
              453 -670 580 -1085 26 -85 50 -160 54 -166 17 -31 585 -613 592 -608 16 10 9
              51 -12 70 -12 10 -139 141 -283 289 l-262 270 -43 145 c-101 334 -218 582
              -411 870 -121 180 -230 311 -415 496 -340 341 -670 577 -1110 793 -445 219
              -861 348 -1353 421 -161 24 -215 25 -232 5z
              M12380 11667 c-116 -197 -364 -706 -386 -791 -6 -25 -4 -27 15 -24
              18 2 31 23 60 93 88 210 249 530 353 703 40 67 48 85 38 97 -7 8 -15 15 -19
              15 -3 0 -31 -42 -61 -93z
              M16615 11720 c-544 -73 -1006 -265 -1405 -583 -104 -83 -352 -334
              -430 -437 -176 -229 -290 -420 -413 -688 -51 -111 -53 -120 -34 -146 11 -15
              15 -11 37 41 294 714 808 1264 1445 1546 214 94 503 179 720 211 82 12 206 26
              252 30 50 3 83 18 83 37 0 14 -111 9 -255 -11z
              M17402 11720 c-6 -11 -12 -21 -12 -23 0 -2 25 -4 56 -5 31 -1 91 -6
              133 -12 42 -5 94 -12 115 -15 180 -22 539 -106 745 -174 1040 -346 1876 -1048
              2262 -1902 32 -70 90 -228 130 -351 l72 -223 261 -269 c144 -148 290 -300 325
              -338 62 -67 87 -77 79 -31 -1 11 -146 167 -320 347 -175 180 -318 332 -318
              338 0 18 -89 297 -127 398 -189 501 -549 978 -1031 1363 -240 192 -469 336
              -767 482 -456 224 -877 349 -1395 415 -192 24 -192 24 -208 0z
              M15154 11598 c-284 -195 -512 -414 -723 -693 -180 -237 -359 -543
              -347 -590 9 -36 19 -31 45 23 230 480 652 964 1091 1250 87 57 102 70 94 84
              -12 19 -49 3 -160 -74z
              M12453 11522 c-198 -350 -359 -719 -496 -1137 -72 -220 -84 -260
              -156 -545 -66 -263 -129 -462 -208 -660 -386 -972 -1044 -1611 -1898 -1845
              -526 -144 -1110 -119 -1665 70 -247 85 -516 213 -708 337 -34 22 -64 39 -66
              37 -3 -2 1 -17 8 -34 29 -67 460 -286 758 -385 227 -75 479 -131 668 -148 109
              -9 479 -9 590 0 362 31 803 181 1115 377 704 444 1193 1197 1445 2226 160 653
              405 1277 687 1748 28 47 33 60 23 72 -7 8 -15 15 -19 15 -3 0 -38 -57 -78
              -128z
              M17313 11591 c-11 -18 -8 -20 45 -25 173 -18 339 -37 352 -41 8 -2
              37 -6 64 -10 83 -10 384 -81 521 -123 867 -263 1620 -795 2060 -1457 168 -251
              253 -434 360 -773 43 -133 75 -215 91 -235 13 -16 176 -186 361 -378 321 -332
              352 -360 353 -311 0 6 -118 134 -262 284 -145 149 -302 313 -350 363 l-87 92
              -65 205 c-95 298 -161 449 -287 659 -242 402 -611 779 -1054 1076 -397 265
              -921 490 -1385 593 -52 11 -113 25 -135 30 -107 24 -464 70 -544 70 -15 0 -31
              -8 -38 -19z
              M16665 11593 c-226 -21 -577 -116 -810 -220 -636 -284 -1133 -823
              -1421 -1543 -30 -74 -34 -92 -24 -110 15 -29 20 -22 60 80 18 47 66 154 107
              237 364 746 963 1250 1710 1438 76 19 156 37 178 40 22 4 58 10 80 15 22 5 76
              12 120 15 50 5 87 13 98 23 35 31 19 36 -98 25z
              M12553 11423 c-269 -485 -475 -1014 -613 -1578 -41 -167 -101 -379
              -137 -485 -125 -370 -308 -740 -507 -1024 -43 -60 -75 -115 -72 -123 13 -33
              36 -9 138 142 270 398 478 888 608 1430 72 300 188 665 302 950 60 150 244
              533 335 697 44 79 47 89 32 103 -14 14 -22 4 -86 -112z
              M17223 11461 c-12 -18 -9 -20 40 -25 29 -3 99 -8 157 -11 241 -14
              573 -77 862 -165 750 -226 1430 -682 1856 -1244 230 -304 363 -585 508 -1075
              18 -62 23 -68 309 -364 159 -166 334 -347 389 -404 54 -57 102 -100 107 -97
              27 17 -11 64 -273 335 -458 474 -474 491 -486 529 -72 233 -122 382 -154 465
              -91 229 -242 486 -408 695 -101 126 -349 376 -479 484 -458 377 -1080 676
              -1666 802 -243 52 -397 73 -650 89 -93 6 -101 5 -112 -14z
              M16572 11444 c-379 -59 -743 -200 -1037 -403 -194 -134 -399 -326
              -549 -514 -170 -214 -341 -520 -450 -805 -47 -122 -53 -147 -44 -168 6 -13 14
              -24 17 -24 3 0 17 33 30 73 41 124 139 346 211 481 133 243 286 451 465 631
              373 372 824 598 1375 686 68 10 99 27 100 52 0 9 -13 8 -118 -9z
              M13632 11398 c-21 -29 -60 -86 -87 -126 -46 -70 -48 -74 -31 -90 12
              -11 20 -13 23 -6 3 9 105 157 161 235 8 10 11 23 7 29 -13 21 -36 8 -73 -42z
              M12672 11366 c-249 -437 -451 -958 -603 -1554 -75 -294 -109 -408
              -165 -566 -162 -451 -369 -834 -625 -1157 -103 -130 -313 -345 -444 -456 -248
              -209 -576 -394 -880 -498 -107 -36 -344 -100 -410 -109 -22 -4 -78 -13 -125
              -21 -47 -8 -164 -19 -261 -25 -491 -31 -1019 70 -1514 289 -77 34 -155 71
              -174 82 -41 24 -51 17 -25 -19 46 -64 468 -233 764 -306 296 -72 455 -91 775
              -91 310 0 458 16 718 80 889 216 1585 807 2039 1730 153 311 249 583 363 1029
              161 631 331 1070 611 1583 26 47 27 54 14 68 -14 14 -20 8 -58 -59z
              M19600 11401 c-9 -17 2 -28 98 -92 326 -220 625 -485 855 -758 250
              -296 444 -614 568 -931 29 -74 56 -139 61 -145 5 -6 14 -4 23 5 12 13 6 34
              -45 163 -103 257 -248 514 -423 751 -273 366 -673 737 -1069 990 -55 34 -58
              35 -68 17z
              M17141 11332 c-6 -11 -11 -23 -11 -26 0 -3 48 -6 108 -6 59 0 145 -4
              192 -10 47 -5 112 -12 145 -15 270 -27 668 -131 970 -252 812 -327 1428 -863
              1759 -1530 72 -145 105 -236 218 -605 l24 -78 305 -316 c167 -173 356 -370
              420 -437 110 -115 116 -121 127 -100 13 24 5 32 -561 615 l-250 257 -59 188
              c-31 103 -67 215 -79 248 -274 773 -982 1445 -1905 1808 -400 158 -826 250
              -1265 273 -122 7 -127 6 -138 -14z
              M12754 11237 c-123 -223 -256 -519 -350 -777 -77 -214 -136 -408
              -220 -732 -132 -513 -257 -840 -460 -1210 -154 -282 -404 -617 -593 -797 -372
              -354 -665 -541 -1106 -704 -147 -54 -432 -125 -569 -142 -23 -3 -79 -11 -126
              -17 -113 -16 -570 -16 -685 0 -49 7 -108 15 -130 18 -141 18 -381 74 -543 125
              -106 34 -352 128 -401 153 -36 19 -38 14 -10 -25 23 -32 57 -49 232 -113 237
              -87 553 -168 742 -190 204 -24 285 -30 445 -30 912 -1 1726 364 2324 1044 331
              376 587 829 776 1373 49 140 91 288 190 667 114 440 318 965 501 1295 62 112
              67 126 46 143 -10 9 -24 -10 -63 -81z
              M16466 11290 c-875 -183 -1527 -796 -1852 -1744 -49 -143 -49 -145
              -31 -170 12 -17 19 -2 64 130 289 850 817 1416 1553 1664 74 25 271 76 328 85
              41 7 72 26 72 46 0 13 -31 10 -134 -11z
              M17057 11196 l-17 -26 99 0 c95 0 126 9 105 30 -5 5 -45 12 -90 16
              -79 6 -80 6 -97 -20z
              M12825 11085 c-206 -387 -397 -907 -510 -1385 -124 -524 -322 -1012
              -574 -1417 -338 -545 -767 -953 -1281 -1221 -284 -148 -617 -261 -905 -306
              -16 -3 -79 -13 -140 -23 -159 -26 -700 -26 -860 0 -60 10 -121 20 -135 22
              -161 26 -398 88 -591 154 -79 28 -145 48 -147 46 -2 -2 4 -15 13 -28 29 -44
              319 -136 635 -201 41 -9 93 -18 115 -21 22 -4 67 -10 100 -16 86 -13 278 -24
              440 -24 238 0 307 5 550 41 89 13 352 76 455 109 137 45 322 120 453 186 300
              150 558 338 812 593 516 517 873 1187 1090 2046 148 589 296 995 506 1399 46
              87 68 140 64 152 -4 10 -10 19 -15 19 -5 0 -39 -56 -75 -125z
              M17476 11193 c-3 -3 -6 -14 -6 -23 0 -13 24 -19 133 -34 172 -24 253
              -40 401 -76 670 -168 1247 -488 1688 -936 140 -144 221 -242 323 -394 159
              -240 231 -399 345 -767 l69 -224 108 -112 c773 -805 787 -819 800 -801 16 21
              40 -6 -455 507 -212 218 -392 407 -402 420 -9 12 -45 112 -78 222 -100 323
              -157 463 -261 640 -276 468 -678 843 -1227 1144 -370 203 -885 370 -1299 421
              -49 6 -99 13 -111 15 -12 3 -24 2 -28 -2z
              M21287 11163 c-13 -13 -7 -40 14 -59 32 -29 188 -237 276 -369 124
              -186 230 -374 328 -585 l40 -85 3 47 c3 41 -6 64 -74 200 -125 251 -272 484
              -440 700 -126 162 -131 167 -147 151z
              M16450 11149 c-149 -39 -237 -66 -315 -96 -584 -225 -1035 -682
              -1309 -1328 -63 -148 -156 -420 -156 -455 0 -24 28 -48 34 -29 100 324 126
              396 206 564 128 275 277 490 480 696 208 211 365 325 612 444 111 53 341 135
              439 156 30 7 53 18 59 29 18 34 13 36 -50 19z
              M12922 10983 c-203 -397 -348 -801 -483 -1338 -66 -261 -170 -586
              -250 -778 -376 -903 -952 -1561 -1694 -1937 -368 -187 -733 -294 -1170 -341
              -151 -17 -536 -17 -675 0 -58 7 -123 14 -145 17 -136 16 -339 61 -534 118 -83
              24 -151 40 -151 36 0 -11 54 -57 75 -63 11 -3 58 -16 105 -30 82 -24 278 -70
              350 -82 19 -3 60 -10 90 -15 30 -5 80 -12 110 -15 30 -4 93 -10 140 -15 111
              -13 469 -12 590 0 52 6 118 12 145 15 28 3 77 10 110 16 377 65 678 163 987
              321 361 184 695 444 971 756 472 534 791 1181 997 2022 115 468 273 903 466
              1281 30 58 54 110 54 116 0 6 -6 16 -14 22 -12 10 -25 -9 -74 -106z
              M16976 11061 c-10 -10 -14 -21 -9 -24 4 -2 98 -5 208 -6 185 -1 448
              -24 570 -51 50 -10 143 -29 181 -36 48 -9 201 -53 349 -101 702 -228 1333
              -702 1677 -1259 122 -197 178 -329 284 -673 l75 -246 107 -110 c193 -198 647
              -668 744 -769 87 -92 118 -111 118 -73 0 15 -17 35 -204 227 -161 166 -293
              303 -559 578 l-166 172 -41 133 c-23 72 -64 202 -91 287 -170 523 -533 985
              -1054 1342 -579 396 -1325 628 -2019 628 -138 0 -155 -2 -170 -19z
              M16295 10966 c-336 -111 -588 -267 -836 -516 -293 -296 -506 -679
              -650 -1168 -28 -96 -30 -115 -18 -123 23 -15 28 -11 39 34 6 23 35 117 65 209
              185 570 506 1013 940 1298 111 72 319 172 450 215 99 33 135 53 135 77 0 13
              -20 9 -125 -26z
              M13017 10874 c-99 -198 -263 -598 -305 -744 -5 -19 -26 -89 -47 -155
              -20 -66 -61 -218 -91 -337 -133 -533 -290 -944 -510 -1337 -384 -683 -897
              -1187 -1533 -1505 -334 -168 -677 -272 -1071 -325 -174 -23 -222 -26 -475 -26
              -244 0 -304 3 -440 21 -159 22 -178 25 -220 34 -22 5 -55 12 -73 15 -29 5
              -106 22 -239 55 l-32 8 16 -25 c23 -34 26 -35 319 -98 38 -8 298 -43 369 -50
              84 -8 528 -8 605 0 30 3 89 10 130 15 41 5 94 12 116 15 23 3 86 14 140 25 55
              11 113 23 129 26 50 10 234 63 330 96 510 172 969 465 1350 859 528 547 895
              1249 1121 2144 126 500 241 831 419 1203 72 149 77 164 62 179 -14 14 -20 7
              -70 -93z
              M16941 10943 c-30 -4 -60 -25 -61 -43 0 -3 102 -5 228 -5 301 0 448
              -14 702 -65 110 -22 321 -79 410 -110 320 -112 562 -230 810 -395 457 -304
              801 -705 970 -1130 37 -94 38 -97 125 -375 53 -168 74 -223 98 -250 17 -19
              204 -215 416 -435 620 -643 557 -581 570 -558 10 19 -16 49 -276 320 -158 164
              -379 392 -491 507 -112 115 -207 218 -212 230 -5 11 -37 111 -70 221 -132 432
              -219 614 -419 879 -95 126 -325 358 -456 462 -424 335 -939 573 -1465 678
              -302 60 -695 91 -879 69z
              M13087 10717 c-161 -350 -301 -756 -381 -1104 -85 -368 -239 -810
              -395 -1138 -386 -809 -964 -1416 -1697 -1783 -354 -177 -795 -311 -1179 -356
              -22 -3 -85 -11 -140 -17 -55 -7 -197 -12 -315 -12 -188 0 -263 5 -470 29 -48
              6 -247 39 -318 53 l-43 9 26 -28 c18 -19 44 -31 83 -39 91 -18 232 -40 297
              -46 33 -3 98 -10 145 -14 185 -18 451 -13 705 15 349 37 797 163 1118 313 49
              22 91 41 94 41 13 0 308 170 387 223 861 578 1421 1450 1736 2707 116 461 234
              804 393 1141 40 85 52 120 45 132 -5 9 -13 17 -17 17 -5 0 -38 -65 -74 -143z
              M16261 10814 c-309 -118 -575 -301 -778 -532 -87 -100 -102 -127 -84
              -145 9 -9 20 -2 49 33 64 79 225 240 302 303 141 114 318 216 488 282 55 22
              77 36 88 57 17 34 16 34 -65 2z
              M16845 10803 c-18 -5 -34 -20 -35 -33 0 -3 125 -6 278 -6 287 -1 408
              -11 642 -55 442 -83 867 -254 1233 -496 89 -60 127 -73 127 -44 0 25 -246 179
              -449 280 -487 244 -993 362 -1541 360 -129 -1 -244 -4 -255 -6z
              M13215 10694 c-156 -336 -273 -668 -371 -1054 -72 -279 -113 -426
              -165 -580 -167 -502 -422 -1002 -700 -1373 -231 -308 -507 -585 -789 -794
              -469 -347 -992 -566 -1592 -669 -70 -12 -98 -20 -98 -30 0 -25 27 -27 122 -10
              689 123 1243 375 1758 799 119 98 399 381 502 507 357 438 629 939 829 1526
              63 186 98 308 169 587 61 242 120 435 191 628 48 130 140 352 175 421 28 55
              28 58 10 83 -12 17 -15 13 -41 -41z
              M16845 10668 c-64 -6 -94 -13 -102 -25 -22 -28 -15 -36 25 -29 20 3
              102 11 182 17 347 25 829 -40 1182 -160 693 -235 1223 -638 1515 -1154 79
              -139 119 -241 222 -571 l90 -288 53 -57 c47 -50 440 -458 769 -796 69 -71 159
              -165 200 -208 67 -71 74 -77 86 -61 7 10 12 22 11 26 -2 4 -75 80 -163 170
              -88 90 -284 291 -435 448 -151 157 -320 331 -375 388 -55 56 -105 117 -112
              135 -7 18 -40 120 -73 227 -101 324 -129 400 -188 519 -168 342 -452 650 -822
              896 -451 298 -973 477 -1537 525 -142 12 -400 11 -528 -2z
              M16120 10605 c-502 -256 -873 -750 -1058 -1410 -34 -119 -34 -122
              -12 -128 12 -3 21 1 24 12 81 282 148 460 243 641 197 376 463 653 800 832 69
              37 111 66 120 82 20 37 5 34 -117 -29z
              M13291 10552 c-141 -317 -235 -590 -327 -952 -175 -686 -346 -1118
              -627 -1590 -565 -947 -1418 -1603 -2401 -1845 -189 -46 -262 -60 -490 -90
              -232 -30 -761 -29 -917 2 -30 6 -33 5 -21 -9 7 -9 30 -24 50 -34 33 -16 74
              -18 387 -20 363 -3 495 6 745 50 107 19 312 67 380 89 25 8 72 22 105 32 164
              48 445 166 615 258 748 402 1340 1029 1755 1856 199 397 317 723 451 1251 96
              376 242 800 344 1000 19 36 19 39 2 59 -17 21 -18 19 -51 -57z
              M17059 10546 c-2 -3 -49 -6 -104 -7 -141 -2 -276 -23 -292 -45 -19
              -26 -16 -37 10 -31 96 25 196 32 447 31 296 0 424 -13 680 -70 833 -184 1539
              -702 1809 -1330 36 -83 221 -654 221 -682 0 -14 48 -65 619 -658 547 -567 527
              -548 540 -540 26 16 4 47 -137 190 -81 83 -265 273 -407 421 -142 149 -327
              339 -409 423 -83 84 -152 154 -153 155 -1 1 -32 99 -68 217 -164 528 -214 641
              -396 882 -78 104 -297 323 -409 410 -428 331 -972 548 -1535 612 -138 16 -408
              30 -416 22z
              M13352 10358 c-104 -248 -194 -526 -267 -823 -185 -755 -469 -1397
              -854 -1930 -252 -350 -588 -693 -911 -932 -403 -298 -864 -516 -1350 -638
              -116 -29 -279 -63 -340 -70 -25 -3 -52 -8 -60 -10 -8 -2 -89 -12 -180 -20
              -165 -16 -647 -22 -686 -9 -12 4 -9 -2 11 -19 38 -32 55 -34 241 -36 1330 -14
              2519 601 3319 1718 139 195 266 407 388 651 212 421 321 724 463 1280 72 282
              176 604 260 807 46 109 53 135 44 152 -6 12 -12 21 -14 21 -2 0 -31 -64 -64
              -142z
              M16058 10417 c-294 -185 -516 -441 -693 -801 -69 -141 -174 -427
              -191 -518 -5 -33 -4 -38 13 -38 16 0 24 16 43 83 154 539 435 956 822 1215 44
              30 84 66 93 82 9 17 12 30 7 30 -5 0 -47 -24 -94 -53z
              M16810 10395 c-157 -19 -206 -32 -225 -61 -14 -22 -14 -24 2 -24 10
              0 34 5 53 10 173 51 561 61 850 24 615 -79 1187 -341 1584 -724 225 -217 372
              -441 459 -700 33 -97 157 -491 179 -569 11 -37 12 -38 647 -695 212 -219 422
              -437 467 -484 78 -83 81 -85 93 -64 11 21 0 34 -151 190 -307 315 -923 954
              -965 1002 -38 43 -49 67 -94 212 -154 498 -182 573 -279 738 -115 197 -325
              426 -539 585 -417 312 -911 501 -1466 561 -158 17 -472 16 -615 -1z
              M13436 10210 c-85 -217 -147 -416 -222 -708 -140 -548 -264 -892
              -464 -1292 -113 -225 -280 -507 -385 -650 -233 -315 -343 -441 -592 -680 -555
              -533 -1278 -898 -2051 -1035 -223 -40 -590 -71 -757 -63 l-76 3 28 -24 27 -24
              216 6 c118 4 244 11 280 16 36 5 90 12 120 15 500 57 1094 265 1555 545 774
              471 1391 1202 1795 2126 109 251 226 581 285 810 8 28 39 149 70 270 61 239
              145 502 219 688 38 94 45 121 35 133 -6 8 -14 14 -18 14 -3 0 -32 -68 -65
              -150z
              M11803 10313 c-16 -6 -51 -123 -128 -428 -117 -459 -221 -740 -390
              -1055 -436 -809 -1085 -1294 -1910 -1427 -140 -22 -627 -26 -745 -5 -41 8 -88
              16 -105 18 -160 23 -461 111 -648 190 -221 94 -472 232 -630 345 -31 23 -59
              40 -61 38 -2 -2 2 -18 9 -36 10 -23 41 -49 112 -96 424 -277 892 -452 1358
              -507 183 -21 514 -19 680 4 935 132 1665 725 2101 1705 114 255 179 453 279
              841 36 140 75 288 87 327 21 71 19 96 -9 86z
              M16900 10271 c-111 -10 -195 -24 -304 -53 -61 -17 -80 -27 -89 -45
              -5 -13 -8 -25 -5 -28 2 -3 36 6 74 19 149 50 246 61 549 61 285 -1 355 -7 575
              -52 743 -152 1362 -574 1630 -1111 47 -93 84 -200 205 -587 30 -99 61 -194 68
              -212 7 -18 131 -154 297 -325 476 -489 792 -816 859 -889 52 -57 66 -67 78
              -58 7 7 13 18 11 25 -2 7 -187 203 -413 435 -225 233 -496 512 -600 620 l-191
              197 -28 94 c-51 166 -174 552 -189 593 -23 63 -95 203 -144 280 -204 317 -555
              603 -963 785 -366 163 -732 245 -1134 254 -105 2 -234 1 -286 -3z
              M15954 10175 c-201 -170 -355 -377 -485 -650 -53 -113 -138 -340
              -154 -412 -6 -31 -5 -32 16 -25 18 7 31 34 65 133 128 379 300 657 540 876 76
              69 139 153 116 153 -5 0 -49 -34 -98 -75z
              M13573 10198 c-6 -18 -37 -102 -68 -187 -62 -169 -112 -338 -175
              -591 -118 -475 -266 -882 -459 -1268 -574 -1146 -1467 -1944 -2576 -2303 -130
              -42 -387 -106 -530 -133 -188 -34 -288 -45 -645 -69 l-35 -2 35 -19 c31 -17
              48 -18 160 -12 142 8 160 10 323 31 325 43 666 134 988 263 716 287 1355 787
              1841 1442 371 499 668 1105 852 1735 68 233 72 249 101 370 51 215 126 457
              195 635 29 75 35 100 27 118 -14 30 -22 28 -34 -10z
              M17020 10144 c-228 -13 -395 -47 -530 -107 -42 -19 -58 -32 -67 -56
              -6 -17 -10 -31 -8 -31 2 0 30 13 63 30 153 76 316 109 573 117 845 26 1698
              -368 2088 -966 95 -147 120 -213 317 -856 34 -109 -14 -54 649 -740 275 -285
              531 -551 569 -591 55 -60 71 -72 82 -63 8 6 14 16 14 21 0 6 -65 78 -145 160
              -80 82 -360 372 -622 645 l-477 496 -74 236 c-41 130 -85 274 -99 321 -57 189
              -135 345 -246 496 -67 91 -261 284 -363 361 -362 275 -863 466 -1339 512 -132
              12 -310 19 -385 15z
              M13628 9963 c-85 -238 -255 -870 -238 -887 17 -17 36 2 47 45 90 372
              175 670 233 830 34 91 35 98 17 123 -11 15 -19 1 -59 -111z
              M16980 10007 c-3 -3 -37 -7 -75 -8 -154 -5 -332 -59 -476 -146 -55
              -32 -81 -55 -93 -80 -26 -55 -19 -57 44 -8 80 62 244 139 355 167 84 21 111
              23 385 22 312 0 365 -5 612 -63 507 -119 974 -399 1228 -736 56 -74 69 -87 82
              -79 18 12 5 41 -56 118 -80 101 -247 265 -346 338 -330 245 -789 418 -1230
              463 -80 8 -425 18 -430 12z
              M19304 9975 c-4 -10 28 -51 102 -128 182 -187 304 -357 409 -567 54
              -108 112 -269 216 -605 38 -124 48 -146 88 -190 36 -40 386 -404 608 -634 36
              -36 45 -41 58 -31 8 7 11 18 7 25 -4 7 -157 168 -341 359 l-335 346 -96 303
              c-69 219 -113 339 -158 431 -104 216 -248 417 -427 598 -97 100 -122 117 -131
              93z
              M15871 9906 c-92 -104 -201 -272 -275 -426 -64 -136 -129 -305 -123
              -323 7 -22 56 43 77 103 58 169 197 422 303 553 40 48 95 153 85 162 -2 2 -32
              -29 -67 -69z
              M15206 9892 c-99 -169 -211 -440 -276 -671 -27 -93 -30 -115 -19
              -122 8 -5 18 -7 23 -6 5 2 23 57 41 122 60 220 179 504 284 681 22 38 27 54
              18 63 -6 6 -14 11 -18 11 -4 0 -28 -35 -53 -78z
              M13746 9898 c-17 -47 -63 -193 -95 -303 -11 -38 -41 -153 -66 -255
              -118 -475 -264 -877 -465 -1285 -277 -560 -596 -1005 -1017 -1415 -395 -386
              -799 -662 -1288 -878 -399 -177 -735 -271 -1235 -346 l-85 -12 33 -17 c35 -19
              60 -18 227 9 382 63 783 187 1139 351 839 387 1551 1044 2053 1893 311 527
              529 1072 694 1740 21 85 63 237 93 338 44 143 54 187 46 202 -6 11 -12 20 -15
              20 -2 0 -10 -19 -19 -42z
              M16917 9868 c-251 -23 -445 -114 -612 -290 -70 -74 -83 -93 -107
              -161 -14 -42 -25 -77 -23 -77 2 0 14 17 26 38 73 119 201 247 324 322 165 101
              322 132 642 126 264 -5 431 -29 659 -94 443 -128 817 -368 1034 -664 73 -99
              151 -251 180 -351 12 -39 52 -169 90 -287 37 -118 80 -255 95 -305 31 -104
              -34 -30 630 -716 215 -221 464 -480 555 -575 126 -132 168 -170 178 -161 7 5
              12 16 10 23 -2 7 -159 174 -349 371 -190 197 -483 500 -651 674 l-305 315
              -117 375 c-79 252 -133 407 -166 475 -210 435 -734 786 -1370 918 -203 43
              -526 62 -723 44z
              M13842 9763 c-20 -51 -79 -257 -102 -353 -232 -998 -671 -1893 -1259
              -2570 -211 -242 -480 -494 -725 -679 -599 -451 -1258 -731 -2021 -857 l-40 -7
              40 -18 c46 -20 48 -20 345 48 1546 355 2785 1498 3423 3160 107 277 178 506
              266 856 27 106 63 240 80 299 26 85 30 111 21 127 -14 27 -15 26 -28 -6z
              M16880 9730 c-89 -10 -155 -32 -155 -51 0 -17 31 -16 130 1 90 16
              411 17 540 2 272 -33 516 -103 752 -217 251 -121 435 -261 580 -440 91 -113
              145 -222 203 -405 23 -74 57 -182 75 -240 19 -58 56 -177 84 -265 l51 -160
              118 -120 c65 -66 223 -229 352 -363 128 -133 238 -241 244 -239 6 2 12 10 14
              18 1 8 -138 161 -310 340 -172 179 -329 342 -349 363 -38 41 -37 38 -226 646
              -79 255 -147 378 -291 531 -208 222 -486 388 -832 498 -141 44 -248 68 -440
              97 -64 10 -464 13 -540 4z
              M13937 9623 c-5 -8 -37 -130 -108 -408 -371 -1446 -1139 -2596 -2219
              -3323 -479 -322 -1074 -569 -1645 -682 -54 -11 -57 -13 -40 -26 28 -21 73 -17
              213 17 710 173 1356 499 1910 961 248 208 538 506 707 728 27 36 52 67 55 70
              8 8 132 177 181 250 254 371 492 838 662 1295 98 264 163 479 251 828 58 229
              64 261 52 279 -8 11 -16 16 -19 11z
              M16911 9599 c-163 -20 -298 -88 -412 -206 -152 -157 -241 -344 -326
              -687 -161 -653 -401 -1322 -654 -1828 -64 -128 -82 -189 -60 -202 9 -6 84 141
              194 379 205 447 431 1101 562 1635 46 187 78 284 130 396 106 229 252 374 440
              441 79 28 82 28 325 28 260 0 355 -9 540 -52 506 -117 915 -392 1079 -728 23
              -47 90 -242 162 -475 70 -227 131 -406 143 -422 22 -28 583 -610 1075 -1115
              235 -242 297 -300 308 -291 8 6 12 14 11 19 -3 8 -309 327 -574 599 -83 85
              -297 307 -475 492 l-323 338 -54 172 c-206 664 -211 677 -277 783 -96 152
              -229 283 -406 398 -224 146 -536 263 -804 302 -22 4 -69 11 -105 17 -81 13
              -413 17 -499 7z
              M16437 9520 c-133 -126 -231 -282 -306 -487 -22 -62 -70 -226 -105
              -365 -124 -486 -267 -914 -455 -1356 -108 -256 -140 -423 -37 -197 211 465
              405 1026 540 1564 82 326 136 467 241 627 54 84 160 200 219 241 20 14 27 25
              23 37 -11 29 -35 17 -120 -64z
              M15722 9434 c-24 -47 -37 -82 -31 -78 13 7 83 154 77 160 -2 2 -23
              -35 -46 -82z
              M16980 9469 c-173 -13 -262 -50 -373 -158 -68 -65 -120 -138 -169
              -236 -54 -106 -84 -198 -148 -450 -180 -705 -394 -1288 -699 -1905 -75 -151
              -105 -223 -103 -242 2 -16 7 -28 11 -28 11 0 284 560 359 740 199 474 358 954
              486 1467 25 100 58 215 72 255 99 275 258 453 445 498 125 30 434 22 649 -16
              494 -88 942 -362 1100 -674 13 -25 83 -235 156 -467 73 -232 136 -428 141
              -437 4 -8 87 -95 183 -193 96 -98 353 -362 570 -588 616 -640 643 -667 663
              -659 10 3 17 10 15 15 -3 9 -209 224 -817 854 -229 237 -453 469 -499 517
              l-84 86 -118 379 c-65 208 -127 405 -139 437 -55 157 -194 322 -384 459 -262
              188 -636 316 -997 342 -175 12 -208 12 -320 4z
              M14026 9450 c-3 -8 -24 -89 -46 -180 -212 -845 -516 -1535 -954
              -2165 -419 -603 -994 -1138 -1591 -1481 -373 -214 -826 -397 -1235 -499 -98
              -24 -99 -25 -61 -41 32 -14 43 -12 165 22 397 109 771 262 1106 452 551 312
              1051 744 1463 1264 487 617 875 1408 1100 2248 81 302 88 338 73 369 -11 19
              -16 22 -20 11z
              M16900 9326 c-83 -18 -142 -51 -204 -115 -72 -73 -98 -112 -152 -225
              -44 -94 -61 -148 -139 -451 -128 -493 -281 -945 -472 -1395 -80 -188 -276
              -595 -362 -750 -45 -82 -51 -99 -44 -125 l9 -30 43 75 c145 255 393 792 523
              1130 138 358 240 679 349 1100 63 247 85 320 116 395 61 147 169 278 268 326
              48 23 58 24 290 24 243 0 278 -3 467 -46 403 -90 773 -329 897 -578 15 -31 85
              -240 155 -466 70 -225 130 -418 134 -427 10 -25 1428 -1498 1442 -1498 4 0 12
              6 18 13 11 13 -82 111 -1045 1110 -211 218 -383 402 -383 409 0 8 -21 79 -46
              158 -25 80 -66 210 -91 290 -134 433 -140 448 -231 561 -197 248 -601 446
              -1037 508 -151 22 -420 26 -505 7z
              M14085 9144 c-213 -828 -554 -1572 -1004 -2193 -181 -249 -300 -389
              -531 -625 -587 -599 -1290 -1026 -2081 -1265 -154 -46 -159 -49 -130 -60 36
              -16 54 -12 236 46 507 162 1006 422 1440 748 105 79 292 230 315 255 3 3 60
              56 128 119 254 236 561 599 772 916 195 291 423 715 560 1040 127 304 252 673
              335 998 26 99 27 110 14 139 -15 31 -15 30 -54 -118z
              M21295 9209 c-4 -5 15 -33 41 -62 92 -99 362 -377 367 -377 2 0 7 10
              10 21 5 18 -31 60 -191 225 -108 112 -202 204 -209 204 -6 0 -15 -5 -18 -11z
              M16962 9199 c-85 -11 -95 -14 -151 -59 -57 -45 -131 -158 -171 -263
              -16 -39 -55 -178 -88 -307 -141 -550 -285 -982 -489 -1465 -98 -232 -328 -702
              -437 -890 -66 -116 -74 -135 -67 -165 l7 -34 33 59 c454 804 758 1555 975
              2410 75 293 91 345 139 445 29 59 59 103 102 146 73 75 104 84 299 84 546 0
              1095 -244 1254 -556 11 -23 67 -187 122 -365 56 -178 119 -377 139 -441 l37
              -117 469 -487 c259 -268 584 -606 724 -751 148 -154 261 -263 269 -261 41 8
              34 17 -234 291 -635 650 -1192 1234 -1199 1258 -3 13 -40 132 -82 264 -218
              696 -206 667 -328 790 -221 223 -591 375 -1009 415 -124 11 -222 11 -314 -1z
              M14200 9054 c-376 -1414 -1100 -2549 -2117 -3319 -50 -39 -110 -83
              -133 -99 -41 -28 -47 -48 -17 -54 32 -6 289 192 547 424 777 698 1384 1710
              1711 2853 49 172 51 185 37 214 l-14 30 -14 -49z
              M16925 9055 c-63 -32 -132 -137 -175 -265 -12 -36 -47 -162 -77 -280
              -231 -912 -581 -1774 -1015 -2507 -43 -72 -78 -140 -78 -150 1 -54 20 -37 92
              85 466 783 799 1603 1049 2582 82 317 127 421 212 484 26 19 41 21 195 20 202
              -1 355 -26 535 -84 212 -70 379 -163 493 -275 93 -91 91 -87 264 -645 50 -162
              101 -323 113 -357 21 -59 42 -83 537 -595 284 -293 614 -635 734 -760 173
              -180 222 -226 233 -217 7 6 13 16 13 21 0 6 -73 85 -163 177 -460 471 -1284
              1326 -1295 1345 -6 12 -53 152 -103 311 -185 592 -187 599 -227 660 -130 202
              -499 389 -882 448 -131 21 -416 21 -455 2z
              M16980 8927 c-46 -23 -104 -161 -160 -382 -278 -1091 -662 -2014
              -1173 -2817 -41 -66 -48 -82 -42 -109 l7 -31 50 78 c402 628 762 1406 1008
              2185 73 232 111 366 185 654 38 151 69 246 96 303 l40 82 129 0 c305 0 598
              -83 839 -237 79 -50 164 -140 183 -194 9 -24 57 -177 108 -339 50 -162 112
              -359 136 -437 l45 -143 296 -307 c163 -170 499 -518 746 -775 355 -369 453
              -465 464 -457 7 6 13 16 13 22 0 6 -69 81 -153 166 -84 86 -265 273 -402 416
              -137 143 -313 325 -391 405 -238 245 -527 548 -535 563 -5 7 -37 108 -73 223
              -171 552 -219 700 -240 733 -52 81 -197 191 -340 256 -76 35 -254 94 -341 115
              -152 35 -446 52 -495 27z
              M19118 8919 c-2 -7 11 -55 30 -108 19 -53 49 -145 67 -206 18 -60 50
              -162 70 -225 20 -63 47 -150 60 -193 22 -69 32 -87 97 -156 72 -79 439 -459
              737 -766 86 -88 230 -236 320 -328 148 -153 165 -167 179 -153 14 14 -9 40
              -240 278 -140 145 -429 444 -641 665 l-386 401 -65 204 c-35 111 -85 271 -111
              353 -26 83 -56 171 -67 198 -19 44 -42 61 -50 36z
              M14296 8900 c-3 -8 -21 -71 -42 -140 -292 -995 -822 -1919 -1494
              -2604 -555 -565 -1177 -975 -1875 -1236 -177 -66 -175 -65 -137 -78 26 -9 46
              -4 144 32 470 177 875 399 1293 712 469 351 927 838 1268 1351 351 527 647
              1161 841 1800 35 113 36 123 22 150 -10 20 -16 24 -20 13z
              M17022 8753 c-28 -68 -47 -131 -77 -258 -171 -711 -474 -1529 -805
              -2172 -147 -284 -263 -483 -492 -845 -25 -38 -28 -52 -21 -73 8 -26 13 -20 95
              106 565 877 978 1840 1252 2919 30 118 64 242 76 274 l23 59 111 -7 c132 -7
              163 -11 253 -31 175 -40 350 -112 456 -189 93 -66 115 -94 143 -182 13 -43 66
              -209 115 -369 50 -159 107 -341 126 -403 20 -62 46 -122 58 -135 11 -12 140
              -146 285 -297 145 -151 395 -410 555 -575 160 -165 372 -384 471 -486 168
              -175 180 -186 197 -171 17 15 11 23 -100 136 -262 268 -506 521 -1178 1219
              -146 150 -214 229 -223 255 -7 20 -52 161 -99 312 -47 151 -109 350 -138 441
              -30 98 -62 180 -76 198 -41 52 -138 119 -254 175 -200 97 -406 146 -610 146
              l-123 0 -20 -47z
              M6910 8779 c0 -29 50 -113 93 -157 64 -66 250 -231 337 -299 179
              -139 590 -377 620 -358 28 17 -1 40 -140 110 -277 140 -496 301 -742 545 -93
              91 -168 162 -168 159z
              M14312 8503 c-154 -462 -375 -950 -599 -1321 -41 -68 -49 -102 -23
              -102 15 0 70 89 180 292 118 216 238 472 337 718 76 188 203 552 203 581 0 8
              -6 23 -13 33 -11 15 -22 -11 -85 -201z
              M17116 8623 c-8 -27 -36 -131 -61 -233 -250 -999 -657 -1967 -1178
              -2800 -60 -96 -133 -210 -162 -252 -37 -53 -52 -84 -48 -98 3 -11 9 -20 14
              -20 11 0 209 301 316 480 488 815 859 1720 1104 2691 28 112 55 212 60 221 10
              18 4 18 159 -3 178 -23 379 -99 512 -192 l57 -39 40 -127 c22 -69 90 -289 153
              -488 l113 -363 215 -222 c118 -123 390 -405 604 -628 721 -748 706 -734 724
              -716 15 15 9 24 -74 109 -118 121 -347 358 -515 532 -74 77 -267 277 -429 445
              -162 168 -338 350 -391 405 l-96 100 -37 120 c-21 66 -50 161 -66 210 -27 85
              -84 267 -165 527 -35 113 -39 119 -86 157 -54 43 -222 129 -304 156 -128 43
              -296 74 -397 75 l-46 0 -16 -47z
              M13236 8652 c-2 -4 -32 -77 -66 -162 -34 -85 -111 -254 -170 -375
              -249 -506 -532 -911 -903 -1291 -350 -360 -696 -617 -1122 -835 -484 -248
              -970 -394 -1500 -449 -60 -6 -125 -13 -144 -16 l-35 -6 30 -18 c28 -18 36 -18
              154 -5 152 17 290 38 396 60 922 189 1730 650 2363 1351 321 355 581 740 801
              1189 103 209 230 507 230 538 0 16 -27 31 -34 19z
              M14441 8468 c-84 -264 -227 -611 -376 -913 -492 -995 -1189 -1798
              -2046 -2358 -235 -154 -540 -318 -794 -428 -108 -47 -109 -48 -80 -59 37 -14
              66 -3 320 120 1106 539 2009 1455 2614 2650 136 268 301 662 386 919 25 76 26
              86 13 113 l-14 30 -23 -74z
              M17225 8518 c-2 -7 -25 -96 -51 -198 -106 -420 -252 -865 -446 -1358
              -15 -38 -18 -56 -10 -64 15 -15 30 -2 47 39 156 372 358 990 464 1417 17 65
              32 121 36 124 11 11 149 -18 252 -53 116 -40 136 -43 131 -18 -4 23 -91 57
              -237 94 -122 31 -179 36 -186 17z
              M17316 8328 c-8 -29 -35 -129 -61 -223 -291 -1065 -748 -2069 -1339
              -2944 -42 -62 -88 -128 -101 -145 -22 -29 -23 -35 -10 -52 14 -19 14 -19 30 1
              46 58 316 473 407 625 477 801 870 1757 1108 2698 6 23 15 42 21 42 20 0 137
              -42 211 -77 l77 -36 20 -61 c11 -34 63 -200 116 -371 53 -170 112 -359 132
              -420 l34 -110 107 -110 c59 -60 224 -231 367 -380 459 -475 538 -555 551 -555
              35 0 19 29 -63 113 -155 161 -603 626 -768 798 l-160 166 -56 184 c-32 101
              -78 252 -104 334 -26 83 -65 208 -87 280 -22 71 -44 137 -49 146 -14 27 -113
              77 -224 115 -139 46 -141 46 -159 -18z
              M14537 8330 c-4 -8 -29 -78 -58 -155 -175 -476 -423 -969 -696 -1384
              -246 -375 -463 -642 -783 -962 -230 -231 -350 -337 -605 -534 -234 -181 -602
              -406 -897 -548 -84 -41 -160 -76 -168 -79 -10 -4 -6 -9 15 -17 26 -11 38 -8
              112 25 105 46 368 188 498 267 805 491 1476 1172 1992 2022 226 372 426 792
              591 1241 27 71 28 83 17 109 -9 22 -14 26 -18 15z
              M17785 8289 c-4 -5 -2 -22 4 -37 5 -15 71 -225 146 -467 76 -242 140
              -445 144 -452 8 -15 476 -502 1245 -1296 143 -148 266 -276 273 -285 9 -13 16
              -15 30 -6 10 6 17 14 16 18 -1 4 -48 56 -105 115 -171 177 -599 621 -1023
              1061 l-400 415 -64 205 c-35 113 -77 248 -93 300 -110 355 -140 440 -153 440
              -8 0 -17 -5 -20 -11z
              M17433 8212 c-6 -4 -38 -105 -72 -224 -252 -883 -601 -1702 -1039
              -2434 -134 -224 -211 -343 -437 -669 -7 -10 -5 -19 6 -30 15 -15 23 -6 95 97
              632 902 1109 1925 1430 3066 19 70 39 130 43 135 9 9 73 -16 88 -35 5 -7 31
              -83 57 -168 27 -85 76 -245 111 -355 35 -110 77 -247 95 -304 l32 -104 266
              -276 c147 -152 339 -350 427 -441 88 -91 317 -328 509 -527 192 -200 355 -363
              362 -363 31 0 21 25 -33 84 -60 66 -609 636 -1113 1156 -427 440 -371 363
              -450 617 -90 290 -198 640 -211 681 -6 18 -21 39 -32 47 -37 24 -123 54 -134
              47z
              M14581 8064 c-152 -406 -419 -936 -651 -1294 -169 -261 -383 -548
              -534 -719 -444 -501 -871 -864 -1386 -1178 -124 -75 -392 -222 -456 -249 l-39
              -17 33 -10 c39 -12 52 -7 257 105 350 191 774 487 1035 723 275 249 454 433
              660 680 438 525 798 1137 1076 1828 59 148 63 161 51 190 l-13 31 -33 -90z
              M14672 7930 c-112 -294 -335 -746 -522 -1060 -468 -787 -1124 -1486
              -1848 -1969 -159 -106 -357 -225 -499 -301 -83 -44 -95 -60 -43 -60 66 0 528
              282 815 498 842 634 1513 1486 1987 2522 87 190 138 317 138 344 0 11 -4 28
              -10 36 -7 12 -11 10 -18 -10z
              M10865 7838 c-432 -395 -897 -616 -1485 -705 -146 -22 -593 -25 -745
              -5 -425 57 -838 193 -1212 399 l-91 51 10 -30 c12 -34 45 -56 184 -126 266
              -133 618 -256 857 -297 17 -4 50 -10 72 -15 22 -4 60 -11 85 -14 25 -3 86 -10
              135 -17 119 -15 517 -15 625 0 47 6 103 14 125 17 539 69 1107 350 1518 752
              53 53 73 93 45 91 -7 0 -62 -45 -123 -101z
              M17486 7921 c-3 -4 -21 -60 -40 -122 -334 -1093 -795 -2053 -1392
              -2901 -79 -112 -94 -148 -61 -148 12 0 184 240 299 420 382 595 711 1253 964
              1928 67 180 187 535 219 650 10 34 21 62 26 62 4 0 12 -15 18 -32 48 -156 115
              -370 125 -403 7 -22 19 -40 27 -40 31 0 30 4 -124 498 -28 88 -45 114 -61 88z
              M14740 7750 c0 -7 -41 -101 -90 -211 -299 -659 -673 -1238 -1134
              -1759 -99 -112 -494 -495 -611 -594 -297 -248 -710 -532 -969 -666 l-29 -15
              36 -8 c33 -8 46 -2 209 96 287 172 533 348 772 551 682 579 1218 1270 1638
              2111 143 284 210 440 202 470 -7 27 -24 44 -24 25z
              M20965 7630 c-10 -15 9 -40 88 -119 70 -69 76 -72 86 -53 10 18 2 30
              -65 101 -73 78 -96 93 -109 71z
              M14776 7493 c-85 -197 -301 -607 -434 -823 -389 -630 -822 -1135
              -1387 -1616 -199 -170 -511 -392 -762 -542 -46 -27 -80 -52 -76 -55 21 -22 73
              -1 201 79 660 416 1215 931 1700 1577 253 337 462 678 673 1102 120 242 138
              285 133 313 -4 18 -9 32 -13 32 -4 0 -20 -30 -35 -67z
              M17482 7463 c-5 -10 -15 -36 -22 -58 -68 -216 -244 -663 -382 -970
              -251 -559 -642 -1240 -955 -1663 -41 -55 -53 -79 -46 -90 5 -8 13 -15 18 -15
              19 -1 275 372 437 638 356 583 678 1261 909 1912 30 84 57 153 60 153 3 0 27
              -72 55 -160 l49 -160 179 -188 c99 -103 311 -322 470 -487 160 -165 349 -361
              421 -435 527 -546 501 -520 521 -505 18 13 11 21 -101 137 -66 68 -199 204
              -295 303 -96 99 -353 365 -570 590 -217 226 -438 455 -491 510 l-96 100 -53
              170 c-29 94 -57 185 -62 203 -10 35 -32 42 -46 15z
              M14781 7192 c-459 -923 -1027 -1647 -1801 -2293 -143 -120 -549 -407
              -649 -459 -19 -10 -21 -14 -10 -21 31 -19 62 -4 230 109 945 637 1703 1513
              2241 2588 87 175 100 214 79 236 -3 4 -44 -68 -90 -160z
              M14876 7083 c-166 -340 -433 -773 -671 -1093 -220 -295 -381 -478
              -664 -760 -303 -302 -613 -558 -925 -766 -117 -78 -118 -79 -87 -86 27 -8 41
              -1 128 59 295 202 485 353 768 614 552 507 1059 1179 1430 1894 65 124 82 166
              78 188 -3 15 -8 27 -12 27 -4 0 -24 -35 -45 -77z
              M17775 7090 c-3 -5 0 -18 7 -28 12 -18 838 -877 1247 -1297 107 -110
              209 -215 226 -233 21 -22 37 -31 48 -26 9 3 17 10 17 15 0 4 -136 149 -302
              321 -426 441 -1060 1099 -1142 1186 -67 70 -88 83 -101 62z
              M17469 7033 c-6 -15 -24 -62 -39 -103 -272 -725 -731 -1582 -1184
              -2210 -67 -93 -73 -105 -58 -116 9 -7 20 -10 24 -8 12 8 162 224 281 404 360
              548 669 1144 923 1775 40 99 76 181 81 182 5 2 110 -101 233 -229 318 -332
              720 -748 890 -923 80 -82 211 -219 292 -303 133 -138 150 -152 168 -142 20 10
              12 21 -111 148 -73 75 -245 254 -383 397 -138 143 -431 446 -651 673 -220 228
              -407 427 -415 445 -20 41 -38 45 -51 10z
              M20060 7023 c0 -16 409 -453 430 -461 5 -2 13 5 18 15 9 15 -24 53
              -206 241 -120 123 -223 223 -229 222 -7 0 -13 -8 -13 -17z
              M14925 6888 c-285 -547 -649 -1069 -1073 -1536 -72 -78 -82 -95 -71
              -108 11 -13 18 -10 57 28 56 55 244 272 353 408 269 336 499 683 716 1082 60
              109 79 153 76 172 -3 14 -9 26 -13 26 -4 0 -25 -33 -45 -72z
              M13412 6723 c-332 -466 -739 -886 -1188 -1223 -136 -102 -386 -268
              -524 -347 -200 -115 -531 -272 -733 -348 l-48 -18 31 -14 c30 -12 39 -10 141
              32 948 388 1752 1040 2393 1939 43 61 53 82 45 92 -6 8 -15 14 -19 14 -4 0
              -48 -57 -98 -127z
              M17516 6782 c-2 -4 -22 -52 -44 -107 -166 -415 -406 -897 -670 -1345
              -99 -168 -336 -531 -440 -674 -70 -96 -78 -116 -44 -116 19 1 18 0 158 201
              367 528 721 1170 973 1764 49 116 93 210 97 210 8 0 193 -190 829 -850 504
              -523 569 -587 588 -579 10 3 17 10 15 15 -3 7 -550 576 -1198 1246 -129 134
              -240 243 -247 243 -6 0 -14 -3 -17 -8z
              M14935 6624 c-226 -399 -488 -781 -754 -1099 -357 -427 -809 -852
              -1229 -1155 -62 -45 -62 -45 -35 -52 32 -9 44 -1 243 153 713 555 1304 1244
              1790 2088 74 129 81 146 73 174 -8 30 -10 28 -88 -109z
              M16530 6513 c-152 -324 -360 -707 -531 -980 -65 -102 -228 -349 -262
              -395 -18 -24 -18 -28 -4 -48 14 -21 17 -17 99 102 213 308 431 683 623 1068
              133 265 195 399 195 419 0 6 -8 11 -18 11 -13 0 -39 -45 -102 -177z
              M15036 6530 c-117 -227 -482 -763 -705 -1035 -193 -236 -485 -544
              -741 -781 -78 -72 -352 -299 -433 -358 -37 -28 -67 -53 -67 -58 0 -4 13 -8 29
              -8 21 0 56 21 128 78 687 538 1253 1184 1728 1969 92 152 97 165 88 195 l-9
              33 -18 -35z
              M17491 6378 c-91 -210 -329 -687 -447 -896 -79 -139 -94 -182 -64
              -182 13 0 38 42 160 264 111 203 304 596 391 794 29 67 56 122 59 122 5 0 308
              -312 990 -1017 134 -139 246 -253 249 -253 3 0 13 4 21 10 17 11 35 -9 -509
              555 -696 719 -745 770 -761 773 -11 2 -36 -47 -89 -170z
              M15025 6249 c-392 -647 -892 -1241 -1468 -1742 -78 -69 -233 -194
              -259 -211 -27 -16 -22 -26 13 -26 33 0 86 40 299 224 470 406 920 919 1278
              1456 187 281 221 342 213 380 -6 33 -7 32 -76 -81z
              M17541 6163 c-248 -540 -638 -1216 -943 -1630 -43 -59 -47 -73 -18
              -73 23 0 73 66 226 295 277 415 541 887 772 1382 57 123 65 155 41 160 -11 3
              -32 -34 -78 -134z
              M8350 6206 c24 -24 39 -28 190 -47 108 -13 244 -21 392 -24 264 -3
              331 2 336 28 3 13 -3 17 -25 17 -414 0 -704 15 -883 46 l-35 6 25 -26z
              M15065 6059 c-376 -601 -910 -1219 -1440 -1669 -85 -73 -155 -134
              -155 -136 0 -2 15 -4 33 -4 19 0 42 9 57 23 14 12 59 51 100 86 491 417 968
              958 1341 1521 141 212 140 210 131 245 -7 26 -10 23 -67 -66z
              M17784 6116 c-4 -11 101 -126 352 -387 198 -205 407 -422 466 -483
              98 -102 107 -110 123 -95 15 15 -5 38 -262 304 -153 159 -360 374 -461 479
              -101 105 -190 192 -198 194 -8 2 -17 -4 -20 -12z
              M19114 6085 c-5 -11 20 -43 82 -108 292 -302 309 -318 327 -312 9 4
              17 10 17 14 0 11 -393 416 -408 419 -6 2 -15 -4 -18 -13z
              M17562 5898 c-207 -429 -506 -942 -763 -1310 -49 -69 -89 -129 -89
              -133 0 -3 12 -5 27 -3 22 2 46 33 148 183 261 388 488 780 688 1185 50 102 92
              187 94 190 2 2 61 -54 131 -125 252 -257 709 -731 741 -767 34 -39 46 -44 70
              -29 11 7 -14 39 -115 143 -147 150 -644 665 -748 776 -38 39 -75 72 -83 72 -7
              0 -52 -79 -101 -182z
              M15053 5797 c-170 -255 -383 -532 -597 -777 -190 -218 -563 -584
              -764 -752 -40 -33 -40 -38 -2 -38 24 0 57 25 172 127 308 274 671 664 923 993
              152 197 168 220 280 385 89 132 100 154 96 182 -6 31 -10 26 -108 -120z
              M17664 5798 c-210 -418 -423 -787 -667 -1153 -55 -82 -106 -158 -115
              -168 -19 -21 -10 -31 21 -24 27 7 125 147 299 427 171 275 274 458 463 823 22
              42 43 77 46 77 3 0 113 -111 244 -247 499 -518 504 -523 525 -510 18 10 11 20
              -78 112 -54 55 -214 221 -357 369 -339 351 -334 346 -345 346 -5 0 -22 -24
              -36 -52z
              M15130 5671 c-143 -211 -340 -467 -544 -706 -123 -143 -516 -544
              -661 -673 l-80 -72 35 0 c32 0 50 13 155 111 360 335 710 729 986 1109 170
              234 171 235 162 269 l-8 29 -45 -67z
              M17674 5528 c-112 -223 -346 -623 -518 -883 -57 -88 -103 -161 -102
              -163 7 -6 60 20 73 36 75 96 403 643 528 880 44 83 85 151 90 149 6 -1 140
              -135 298 -299 230 -237 291 -295 308 -291 11 3 19 9 17 14 -7 20 -621 649
              -634 649 -8 0 -34 -41 -60 -92z
              M15169 5493 c-33 -54 -227 -308 -333 -438 -174 -213 -375 -432 -585
              -637 -208 -204 -211 -208 -177 -208 32 0 52 17 248 213 297 294 563 604 799
              925 65 88 79 114 79 145 0 20 -2 37 -4 37 -2 0 -15 -17 -27 -37z
              M11603 5414 c-283 -171 -596 -315 -925 -426 -87 -29 -158 -56 -158
              -60 0 -4 16 -10 35 -14 40 -7 150 28 400 129 326 131 810 388 803 427 -6 28
              -33 18 -155 -56z
              M17747 5378 c-8 -13 -45 -79 -82 -148 -81 -148 -243 -421 -343 -575
              -39 -60 -70 -111 -68 -112 1 -2 16 2 33 8 25 8 51 43 140 187 110 178 222 369
              303 517 l44 79 216 -222 c191 -197 217 -221 233 -210 15 12 -7 38 -213 253
              -126 132 -234 241 -239 243 -5 2 -16 -7 -24 -20z
              M15149 5238 c-205 -279 -421 -528 -728 -840 -182 -185 -193 -198
              -164 -198 26 0 51 21 164 133 157 154 332 343 444 477 44 52 83 97 86 100 8 7
              177 224 227 291 38 51 56 109 33 109 -4 0 -32 -33 -62 -72z
              M16822 5105 c-121 -193 -348 -531 -386 -573 -17 -20 -18 -24 -6 -32
              8 -5 20 -10 25 -9 25 0 445 635 445 672 0 38 -34 13 -78 -58z
              M17756 5117 c-54 -97 -170 -291 -239 -401 -37 -59 -67 -110 -65 -111
              2 -2 17 3 34 10 32 13 116 140 260 394 l59 104 131 -137 c95 -100 136 -137
              150 -134 10 2 19 8 19 15 0 15 -293 313 -308 313 -7 0 -25 -24 -41 -53z
              M13565 5059 c-148 -152 -529 -467 -769 -634 -82 -57 -96 -70 -80 -76
              10 -4 24 -8 30 -8 14 -1 256 175 408 298 248 198 491 424 482 446 -9 25 -30
              17 -71 -26z
              M15150 5017 c-197 -250 -374 -452 -594 -674 l-141 -143 35 0 c32 0
              46 11 149 117 187 194 372 403 534 606 71 89 87 115 87 143 0 19 -1 34 -2 34
              -2 0 -32 -37 -68 -83z
              M17745 4833 c-106 -178 -98 -161 -69 -155 28 5 58 43 114 140 19 34
              40 62 45 62 6 0 29 -23 53 -50 44 -51 56 -57 82 -40 12 8 1 24 -60 85 -41 41
              -79 75 -85 75 -6 0 -42 -53 -80 -117z
              M15132 4780 c-90 -112 -270 -316 -384 -436 -49 -50 -88 -94 -88 -97
              0 -3 21 4 48 16 51 23 160 135 362 372 84 98 114 142 125 179 8 27 13 51 12
              53 -2 1 -36 -37 -75 -87z" />

        </g>

      </svg>
      <h3 style={{ marginTop: '1rem', marginLeft: '4rem' }}>Mano Izquierda</h3>
    </div>
  );
};

export default HandLeft_F;
