import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class AssistanceService {
    getMarkWorkerClock(id_persona, tipoTrabajador, numdoc, id_reloj, inicio, fin, { page = 1, pageSize = 20, pagination = 1 }) {
        const ruta = `${API_URL}/assistance/worker/clock?id_persona=${id_persona}&tipoTrabajador=${tipoTrabajador}&numdoc=${numdoc}&id_reloj=${id_reloj}&inicio=${inicio}&fin=${fin}&page=${page}&pageSize=${pageSize}&pagination=${pagination}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new AssistanceService();
