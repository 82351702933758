// actions.js
export const setSelectedInfo = (info) => ({
    type: 'SET_SELECTED_INFO',
    payload: info,
});

export const setOpenLoader = (isOpen) => ({
    type: 'SET_OPEN_LOADER',
    payload: isOpen,
});

export const setTitleLoader = (title) => ({
    type: 'SET_TITLE_LOADER',
    payload: title,
});
