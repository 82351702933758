import React, { useEffect } from 'react';
import HandLeft from './Hands/HandLeft_F';
import HandRight from './Hands/HandRight_F';
import WorkerService from '../../../../services/worker';
import { useSelector, useDispatch } from 'react-redux';
import { simpleAlerts } from '../../../../utils/alerts';
import { setOpenLoader, setTitleLoader } from '../../../../config/actions';
import { CODES } from '../../../../utils/codesHTTP';

const HandSVG = (props) => {
  const { dataBiometric, setDataBiometric, highlightedFingers, setHighlightedFingers, selectedRowPersona, setSelectedRowPersona, selectedFingerId, setSelectedFingerId } = props;

  const infoClock = useSelector(state => state.infoClock);
  const dispatch = useDispatch();
  const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
  const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

  useEffect(() => {
    if (selectedFingerId)
      saveWorkerFingerPrint();
  }, [selectedFingerId]);

  useEffect(() => {

    if (!dataBiometric || dataBiometric.length === 0) {
      return;
    }

    const dedoIds = dataBiometric.map((dedo) => dedo.dedo_id);
    setHighlightedFingers(dedoIds);
  }, [dataBiometric]);

  const saveWorkerFingerPrint = async () => {
    try {
      if (!infoClock || !infoClock.id_reloj) {
        simpleAlerts({ message: "Seleccione un reloj", type: "warning" });
        return;
      }
      if (!selectedRowPersona || !selectedRowPersona[0]) {
        simpleAlerts({ message: "Seleccione un trabajador", type: "warning" });
        return;
      }

      handleOpenSpinner(true);
      handleTitleSpinner("Registrando Huella ...");
      const body = {
        id_reloj: infoClock.id_reloj,
        id_dedo: selectedFingerId,
        id_persona: selectedRowPersona[0].id_persona,
        tipoTrabajador: 'AP',
        numdoc: selectedRowPersona[0].numdoc
      };
      
      handleOpenSpinner(true);
      handleTitleSpinner("Registrando Huella ...");
      const result = await WorkerService.saveWorkerFingerPrint(body);
      if (result.status === CODES.SUCCESS_200) {
        simpleAlerts({ message: result?.data?.message || "Guardado!", type: "warning" });
      } else {
        simpleAlerts({ message: result?.response?.data?.error || result?.response?.data?.message || "Error en la busqueda!", type: "error" });
      }
    } catch (error) {
      console.log(`Error saveWorkerSede: ${error}`);
    } finally {
      handleOpenSpinner(false);
      handleTitleSpinner(null);
      setSelectedFingerId(null);
    }
  }

  const handleFingerClick = (fingerId) => {
    setSelectedFingerId(fingerId);
  };

  return (
    <div style={{ display: 'flex', width: '100%', marginLeft: '0' }}>
      <HandLeft
        highlightedFingers={highlightedFingers}
        onFingerClick={handleFingerClick}
      />
      <HandRight
        highlightedFingers={highlightedFingers}
        onFingerClick={handleFingerClick}
      />

    </div>
  );
};

export default HandSVG;