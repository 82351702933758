import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/actions';
import SearchIcon from '@mui/icons-material/Search';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CustomInput from "../../general/CustomInput";
import CustomInfo from '../../general/CustomInfo';
import CustomButton from '../../general/CustomButton';
import CustomSelect from '../../general/CustomSelect';
import WorkerService from '../../../../services/worker';
import { validationMessages } from '../../../../utils/messagesError';
import { CODES } from '../../../../utils/codesHTTP';
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';

const InformationUser = ({ states, profiles, infoUser, setInfoUser }) => {

  const dispatch = useDispatch();
  const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
  const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

  const [viewPassword, setViewPassword] = useState(false);

  const getWorkerInfo = async () => {
    if (!infoUser.numdoc) {
      simpleAlerts({ message: validationMessages.numdoc, type: "error" });
      return;
    }

    try {
      let data = _.cloneDeep(infoUser);
      handleOpenSpinner(true);
      handleTitleSpinner("Buscando persona");
      const result = await WorkerService.getWorkerInfo(infoUser.numdoc);
      if (result.status === CODES.SUCCESS_200) {
        const tmpInfo = result?.data;
        data.usuario = null;
        data.clave = null;
        data.id_persona = tmpInfo.id_persona;
        data.tipoTrabajador = tmpInfo.tipoPersona;
        data.trabajador = tmpInfo.trabajador;
        data.id_perfil = 2;
        data.id_estado = 1;
      } else {
        simpleAlerts({ message: result?.response?.data?.message || "Error en la busqueda!", type: "error" });
      }
      setInfoUser(data);
    } catch (error) {
      console.log(`Error en getWorkerInfo: ${error}`);
    } finally {
      handleOpenSpinner(false);
      handleTitleSpinner(null);
    }
  }

  const cleanFields = (value) => {
    let data = _.cloneDeep(infoUser);
    data.numdoc = value;
    setInfoUser(data);
  }

  return (
    <div className='row'>
      <div className='col-md-6'>
        {infoUser?.id_usuario ?
          <CustomInfo
            title={"Documento"}
            placeholder={"Número de documento"}
            value={infoUser?.numdoc}
            name="numdoc"
          />
          : <div className='d-flex justify-content-evenly align-items-end'>
            <CustomInput
              title={"Documento"}
              placeholder={"Escribe número de documento"}
              value={infoUser?.numdoc}
              required={true}
              name="numdoc"
              onChange={(e) => cleanFields(e.target.value)}
            />
            <CustomButton
              title={'Buscar trabajador'}
              variant={'warning'}
              value={<SearchIcon />}
              onClick={getWorkerInfo}
            />
          </div>}
      </div>
      <div className='col-md-12'>
        <CustomInfo
          title={"trabajador"}
          value={infoUser?.trabajador}
          name="trabajador"
        />
      </div>
      <div className='col-md-6'>
        {infoUser?.id_usuario ?
          <CustomInfo
            title={"Usuario"}
            value={infoUser?.usuario}
            name="Usuario"
          />
          : <CustomInput
            title={"Usuario"}
            placeholder={"Escribe nickname"}
            value={infoUser?.usuario}
            required={true}
            name="usuario"
            onChange={(e) => setInfoUser({ ...infoUser, usuario: e.target.value })}
          />}
      </div>
      <div className='col-md-6'>
        <div className='d-flex justify-content-evenly align-items-end'>
          <CustomInput
            title={"Clave"}
            placeholder={"Escribe una clave valida"}
            value={infoUser?.clave}
            required={infoUser?.id_usuario ? false : true}
            name="clave"
            type={viewPassword ? 'text' : 'password'}
            onChange={(e) => setInfoUser({ ...infoUser, clave: e.target.value })}
          />
          <CustomButton
            title={'Ver clave'}
            variant={'secondary'}
            value={viewPassword ? <RemoveRedEyeIcon fontSize='small' /> : <VisibilityOffIcon fontSize='small' />}
            onClick={() => setViewPassword(!viewPassword)}
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div>
          <label className="form-label title-legend">
            Perfil
          </label>
          <CustomSelect
            placeholder={'Seleccionar Perfil'}
            options={profiles}
            value={+infoUser?.id_perfil || 0}
            onChange={(value, event) => setInfoUser({ ...infoUser, id_perfil: profiles?.find(element => +element.value === +value)?.value })}
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div>
          <label className="form-label title-legend">
            Estado
          </label>
          <CustomSelect
            placeholder={'Seleccionar Estado'}
            options={states}
            value={+infoUser?.id_estado || 0}
            onChange={(value, event) => setInfoUser({ ...infoUser, id_estado: states?.find(element => +element.value === +value)?.value })}
          />
        </div>
      </div>
    </div>
  )
}

export default InformationUser;