import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './App.css';
import './assets/styles/style.scss';
import { ToastContainer, } from "react-toastify";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import theme from './assets/styles/themeConfig';
import SpinnerLoader from './app/components/general/SpinnerLoader';
import Login from './app/pages/Login/Login';
import NavBar from './app/components/frame/NavBar';
import Clocks from './app/pages/Clocks/Clocks';
import Workers from './app/pages/Workers/Workers';
import Reports from './app/pages/Reports/Reports';
import { Global } from './utils/general';
import Users from './app/pages/Users/Users';
import Profiles from './app/pages/Profiles/Profiles';
import Menu from './app/components/frame/Menu';
import UserService from './services/user';
import { CODES } from './utils/codesHTTP';
import _ from 'lodash';

function App() {
  const pathInit = Global.path;
  const [userToken, setUserToken] = useState(null)
  const openLoader = useSelector(state => state.openLoader);
  const titleLoader = useSelector(state => state.titleLoader);

  const [showMsgCmd, setShowMsgCmd] = useState(false);
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    const storedToken = JSON.parse(localStorage.getItem(Global.info));
    const tokenExpiration = localStorage.getItem(Global.infoTime);

    if (storedToken && tokenExpiration && new Date() < new Date(tokenExpiration)) {
      setUserToken(storedToken);

      // Configurar intervalo para actualización periódica
      const intervalId = setInterval(() => {
        getUserNotification();
      }, 60000); // Actualizar cada 60 segundos (ajusta el tiempo según tus necesidades)
      //60000 = 60seg
      //30000 = 30seg

      // Limpiar intervalo al desmontar el componente
      return () => clearInterval(intervalId);
    }
  }, []);

  const getUserNotification = async () => {
    try {
      const result = await UserService.getUserNotification();
      if (result.status === CODES.SUCCESS_200)
        setNotifications(result?.data);
    } catch (error) {
      console.log(`Error en getUserNotification: ${error}`);
    }
  }

  const viewNotification = async (id_notificacion) => {
    try {
      const result = await UserService.viewNotification({ id_notificacion: id_notificacion });
      if (result.status === CODES.SUCCESS_200) {
        let updData = _.cloneDeep(notifications);
        setNotifications(updData?.filter(e => +e.id_notificacion !== +id_notificacion));
      }
    } catch (error) {
      console.log(`Error en viewNotification: ${error}`);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <SpinnerLoader open={openLoader} title={titleLoader} />
      <ToastContainer limit={3} />
      {userToken ?
        <div className="App" style={{ minHeight: '100vh', }}>
          <div className="header">
            <NavBar userToken={userToken} />
            <div style={{ marginTop: '5px' }}>
              <BodyWeb pathInit={pathInit} userToken={userToken} />
            </div>

            <div id="floatingButton" className="floating-button"
              onClick={() => setShowMsgCmd(!showMsgCmd)}
              style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: '#157347',
                color: '#fff',
                fontSize: '30px',
                textAlign: 'center',
                lineHeight: '50px',
                cursor: 'pointer',
                zIndex: '9999',
              }}
            >
              <span className="plus-sign"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >🔔</span>
              {notifications?.length > 0 &&
                <div className="contenedor"
                  style={{
                    position: 'relative',
                    display: 'inline-block',
                    textAlign: 'center',
                    top: '-5.4rem',
                  }}>
                  <div style={{
                    backgroundColor: '#EA1B66',// '#0288d1',
                    height: '30px',
                    width: '30px',
                    borderRadius: '0% 50% 50% 50%',
                    transform: 'rotate(225deg)', //'rotate(45deg)',
                    boxShadow: 'inset -25px 10px 0px -10px #FF4178', //'inset -25px 10px 0px -10px #03a9f4',
                  }}></div>
                  <div className="centrado"
                    style={{
                      position: 'absolute',
                      top: '60%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      fontSize: '20px',
                      fontWeight: 'bold',
                      color: 'white'
                    }}
                  >
                    {notifications?.length}
                  </div>
                </div>
              }
            </div>
            {showMsgCmd &&
              <div id="messageBox" className="message-box"
                style={{
                  position: 'fixed',
                  bottom: '3.5rem', //'12%', //'75px',
                  right: '4.5rem', //'9%', //'75px',
                  width: '25rem', //'200px',
                  height: '10rem', //'100px',
                  backgroundColor: '#fff',
                  border: '1px solid #ccc',
                  padding: '10px',
                  zIndex: '9998',
                }}
              >
                <span className="badge text-bg-dark message-text" style={{ fontWeight: 'bold', }}>Comandos ejecutados:</span><br />
                <div style={{ height: '90%', overflow: 'scroll' }}>
                  <table className="table table-bordered table-sm" style={{ fontSize: '10px', tableLayout: 'fixed' }}>
                    <thead>
                      <tr style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
                        <th scope="col" style={{ width: '23px', left: 0 }}>✅</th>
                        <th scope="col" style={{ width: '30px', left: '23px' }}>#</th>
                        <th scope="col" style={{ width: '130px', left: '53px' }}>Comando</th>
                        <th scope="col" style={{ width: '80px', left: '153px' }}>Respuesta</th>
                        <th scope="col" style={{ width: '100px', left: '253px' }}>Reloj</th>
                        <th scope="col" style={{ width: '150px', left: '353px' }}>Nombre</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notifications?.map((notify, idxNotify) => (
                        <tr key={notify.id_notificacion}>
                          <td
                            style={{ cursor: 'pointer', position: 'sticky', left: 0, zIndex: 1 }}
                            onClick={() => viewNotification(notify.id_notificacion)}
                          >✅</td>
                          <th scope="row">{idxNotify + 1}</th>
                          <td style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{notify.descripcionCmd}</td>
                          <td style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{notify.descEstadoCmd}</td>
                          <td>{notify.serial}</td>
                          <td style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{notify.trabajador}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
              </div>
            }
            <Menu userToken={userToken} setUserToken={setUserToken} />
          </div>
        </div>
        : <Login setUserToken={setUserToken} />
      }
    </ThemeProvider>
  );
}

function BodyWeb({ pathInit, userToken }) {
  return (
    <div style={{ height: "100%", }}>
      <Routes>
        <Route path={`${(pathInit || '/')}`} element={<Clocks userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/user`} element={<Users userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/clock`} element={<Clocks userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/worker`} element={<Workers userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/reports`} element={<Reports userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/profiles`} element={<Profiles userToken={userToken} />}></Route>
      </Routes>
    </div>
  )
}

export default App;
