import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class WorkerService {
    getWorkerInfo(numdoc) {
        const ruta = `${API_URL}/worker/info/${numdoc}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getWorkerBySede(id_sede, { search = '', page = 1, pageSize = 20, pagination = 1 }) {
        const ruta = `${API_URL}/worker/sede?id_sede=${id_sede}&search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getWorkerExternalBySede(id_sede, { search = '', page = 1, pageSize = 20, pagination = 1 }) {
        const ruta = `${API_URL}/worker/external/sede?id_sede=${id_sede}&search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getWorkerExternal({ search = '', page = 1, pageSize = 20, pagination = 1 }) {
        const ruta = `${API_URL}/worker/external?search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getWorkerByReloj(id_reloj, { search = '', page = 1, pageSize = 20, pagination = 1 }) {
        const ruta = `${API_URL}/worker/clock?id_reloj=${id_reloj}&search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getWorkerBySedeXReloj(id_sede, id_reloj, { search = '', page = 1, pageSize = 20, pagination = 1 }) {
        const ruta = `${API_URL}/worker/sede-clock?id_sede=${id_sede}&id_reloj=${id_reloj}&search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveWorker(body) {
        const ruta = `${API_URL}/worker`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveWorkerSede(body) {
        const ruta = `${API_URL}/worker/sede`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveWorkerSedes(body) {
        const ruta = `${API_URL}/worker/sedes`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveWorkerSedeMassive(body) {
        const ruta = `${API_URL}/worker/sede/massive`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getWorkerBiometric(numdoc) {
        const ruta = `${API_URL}/worker/biometric?numdoc=${numdoc}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveWorkerFingerPrint(body) {
        const ruta = `${API_URL}/worker/fingerPrint`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    requestAssistancesWorker(body) {
        const ruta = `${API_URL}/worker/request-assistances`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
    
    updateInformationWorkerFromClock(body) {
        const ruta = `${API_URL}/worker/update-info/from-clock`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
    
    requestInfoWorkerFromClock(body) {
        const ruta = `${API_URL}/worker/request-info/from-clock`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new WorkerService();
