import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/actions';
import tomarHuellaImg from '../../../../assets/images/tomarHuella.png';
import WorkerService from '../../../../services/worker';
import Swal from 'sweetalert2';
import { CODES } from '../../../../utils/codesHTTP';
import { simpleAlerts } from '../../../../utils/alerts';
import { validationMessages } from '../../../../utils/messagesError';
import { Select } from "antd";
const { Option } = Select;

const TakeFingerPrint = ({ fingers, infoWorker }) => {
  const dispatch = useDispatch();
  const infoClock = useSelector(state => state.infoClock);
  const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
  const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

  const handsOpt = [{ id: 'DERECHA', value: 'DERECHA', label: 'DERECHA' }, { id: 'IZQUIERDA', value: 'IZQUIERDA', label: 'IZQUIERDA' }]
  const [handSelect, setHandSelect] = useState('DERECHA');

  const fingersOpt = fingers?.filter(e => e.descMano === handSelect);
  const [dataBiometric, setDataBiometric] = useState(null);

  useEffect(() => {
    getWorkerBiometric();
  }, [infoWorker]);

  const getWorkerBiometric = async () => {
    try {
      handleOpenSpinner(true);
      handleTitleSpinner("Buscando Huellas");

      const result = await WorkerService.getWorkerBiometric(infoWorker?.numdoc);
      if (result.status === CODES.SUCCESS_200) {
        setDataBiometric(result?.data?.data || result?.data);
      }
    } catch (error) {
      console.log(`Error en getWorkerBiometric: ${error}`);
    } finally {
      handleOpenSpinner(false);
      handleTitleSpinner(null);
    }
  }

  const saveWorkerFingerPrint = async (finger, infoFinger) => {
    let resultSwal;
    if (infoFinger) {
      resultSwal = await Swal.fire({
        title: `Seguro de volver a tomar la huella de la Mano: ${finger.descMano}, Dedo: ${finger.descDedo}`,
        text: 'Se reemplazara la información existente',
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
      });
    } else {
      resultSwal = await Swal.fire({
        title: `Seguro de tomar la huella de la Mano: ${finger.descMano}, Dedo: ${finger.descDedo}`,
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
      });
    }

    if (resultSwal.isConfirmed) {
      try {
        if (!infoClock || !infoClock.id_reloj) {
          simpleAlerts({ message: validationMessages.clock, type: "warning" });
          return;
        }
        if (!infoWorker) {
          simpleAlerts({ message: validationMessages.numdoc, type: "warning" });
          return;
        }

        handleOpenSpinner(true);
        handleTitleSpinner("Registrando Huella ...");
        const body = {
          id_reloj: infoClock.id_reloj,
          id_dedo: finger.id_dedo,
          id_persona: infoWorker.id_persona,
          tipoTrabajador: infoWorker.tipoTrabajador,
          numdoc: infoWorker.numdoc,
        };

        const result = await WorkerService.saveWorkerFingerPrint(body);
        if (result.status === CODES.SUCCESS_200) {
          simpleAlerts({ message: result?.data?.message || "Guardado!", type: "warning" });
        } else {
          simpleAlerts({ message: result?.response?.data?.error || result?.response?.data?.message || "Error en la busqueda!", type: "error" });
        }
      } catch (error) {
        console.log(`Error saveWorkerSede: ${error}`);
      } finally {
        handleOpenSpinner(false);
        handleTitleSpinner(null);
      }
    }
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <div>
          <label className="form-label title-legend">
            Manos
          </label>
          <Select
            mode={"single"}
            maxTagCount={'responsive'}
            className="select"
            size={'small'}
            optionFilterProp="children"
            popupClassName='my-drop-down'
            value={handSelect || ''}
            onChange={(value, event) => setHandSelect(value)}
            style={{
              width: '100%',
              fontSize: '15px',
            }}
            showSearch
          >
            <Option value={0}><em>Seleccionar Tipo de usuario</em></Option>
            {handsOpt?.map((opt, idx) => {
              return (
                <Option key={opt?.value?.toString() + opt?.label?.replace(/\s+/g, '')} value={opt?.value}>
                  {opt?.label}
                </Option>
              )
            })}
          </Select>
        </div>
      </div>
      <div className='col-12 d-flex justify-content-between mt-2'>
        {fingersOpt?.map((finger) => {
          const data = dataBiometric?.find(e => +e.dedo_id === +finger.id_dedo);
          return (
            <div
            key={`finger-id${finger.id_dedo}`}
              className={`card card-fingerprint ${data ? 'exist-fingerprint' : 'border-danger'}`}
              onClick={() => saveWorkerFingerPrint(finger, data)}
            >
              <div className="card-header align-text-center" style={{ fontSize: '10px' }}>{finger.descDedo}</div>
              <div className="card-body text-success d-flex justify-content-center">
                <img src={tomarHuellaImg} width={40} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default TakeFingerPrint;