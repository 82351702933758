import React, { useState } from 'react';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomCheck from '../general/CustomCheck';
import BallotIcon from '@mui/icons-material/Ballot';
import CustomButton from '../general/CustomButton';
import { sortDataListComplete, filterData, getValueFromPath } from '../../../utils/function';
import { CODES } from '../../../utils/codesHTTP';
import ClockFunctions from '../../pages/Clocks/Logic/ClockFunctions';
import { simpleAlerts } from '../../../utils/alerts';
import CustomModal from '../general/CustomModal';
import _ from 'lodash';

const columns = [
  { id: 'id_reloj', label: '#', width: 15, align: 'center' },
  { id: 'serial', label: 'Nro. Serial', width: 50, align: 'center', order: true, },
  { id: 'descripcion', label: 'Descripcion.', width: 80, align: 'center', order: true, },
  { id: 'summary', label: 'resume.', width: 20, align: 'center', order: true, },
  { id: 'sel', label: 'seleccionar', width: 10, align: 'center' },
];

const columnsMap = {
  id_sede: 'sede.desSede',
};

const BodyClocks = (props) => {
  const { forCheckBody, data, search, setSearch, getInfo, dataSel, setDataSel, } = props;

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [relojSedes, setRelojSedes] = useState(null);

  const handleOpen = (reloj_sedes) => {
    setRelojSedes(reloj_sedes);
    setOpen(true);
  };

  const resultSearch = filterData(data, search, ['serial', columnsMap.id_sede,]);

  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
  const handleSortTable = (key) => {
    if (key === 'id_estado') {
      key = columnsMap.id_estado;
    }
    if (key === 'id_sede') {
      key = columnsMap.id_sede;
    }
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const srtdData = sortDataListComplete(resultSearch, sortConfigTable);
  // /** fin ordenamiento de columnas */

  const handleChangeGeneral = (checked) => {
    let newData;
    if (checked)
      newData = data;
    else
      newData = [];
    return setDataSel(newData);
  }

  const handleChangeCheck = (checked, info) => {
    let newData = _.cloneDeep(dataSel);
    if (checked) {
      newData.push(info);
    } else {
      newData = newData.filter((e) => +e.id_reloj !== +info.id_reloj);
    }
    return setDataSel(newData);
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && getInfo) {
      getInfo();
    }
  };

  return (
    <>
      <CustomModal
        size={'sm'}
        open={open}
        handleClose={handleClose}
        title={'Lista de agrupamientos'}
        subTitle={'Detalle de agrupamientos del reloj'}
        bodyModal={
          <div className="mt-2 table-responsive" style={{ maxHeight: '30rem' }}>
            <table className="table table-dark table-bordered border-primary table-striped table-sm align-middle">
              <thead className='sticky-top'>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Cód.</th>
                  <th scope="col">Agrupamiento</th>
                </tr>
              </thead>
              <tbody className='table-group-divider table-light'>
                {relojSedes?.map((row, idxRow) => {
                  const grouping = row.agrupamiento;
                  return (
                    <tr key={`grouping-row-${idxRow}`}>
                      <th scope="row">{idxRow + 1}</th>
                      <td>{`${grouping.id_sede}`}</td>
                      <td>{`${grouping.desEmpresa} / ${grouping.desCliente} / ${grouping.desUnidad} / ${grouping.desSede}`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        }
      />
      <TextField
        hiddenLabel
        id="filled-hidden-label-small"
        placeholder='Busqueda por serial'
        variant="filled"
        size="small"
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton color='primary' style={{ padding: 2, border: 'solid 1px' }}
              onClick={getInfo}
            >
              <SearchIcon fontSize='small' />
            </IconButton>
          ),
          style: { fontSize: 12, },
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyPress={handleKeyPress}
        className='textField-search'
        inputProps={{ style: { fontSize: 12, color: 'black', fontWeight: 'bold', }, }}
      />
      <div className="table-body-frame">
        <div className="table-body">
          <table className='table table-striped table-hover table-bordered table-sm'>
            <thead className='sticky-top order-1' style={{ whiteSpace: 'nowrap' }}>
              <tr className="table-header table-dark">
                {columns.map((col) => (
                  <th
                    key={col.id}
                    className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                    style={{ width: col.width, maxWidth: col.width, }}
                    onClick={() => col.order && handleSortTable(col.id)}
                  >
                    {col.id === 'actions' ?
                      <span className="actions-icon">
                        <SettingsIcon style={{ fontSize: 'medium' }} />
                      </span>
                      : col.id === 'summary' ?
                        <span className="actions-icon">
                          <BallotIcon style={{ fontSize: 'medium' }} />
                        </span>
                        : col.id === 'sel' ?
                          <div style={{ marginTop: '-1rem' }}>
                            <CustomCheck
                              forCheck={`checkbox-title-body${forCheckBody}`}
                              checked={(dataSel?.length === data?.length && dataSel?.length > 0) ? true : false}
                              onChange={(e) => handleChangeGeneral(e.target.checked)}
                            />
                          </div>
                          : <>
                            {col.label}
                            {col.order && <span className="sort-icon"><OrderedListOutlined /></span>}
                          </>}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {srtdData?.map((row, idxRow) => {
                return (
                  <tr key={"row" + idxRow}>
                    {columns.map((col, indexColumn) => {
                      const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                      return (
                        <td
                          key={'col' + indexColumn}
                          className={`${col.align}`}
                          style={{ width: col.width, maxWidth: col.width, }}
                        >
                          {col.format ?
                            (value ? col.format(value) : '')
                            : col.id === '#' ?
                              idxRow + 1
                              : col.id === 'summary' ?
                                <CustomButton
                                  title='Ver detalle de agrupamientos'
                                  variant={'primary'}
                                  value={<BallotIcon style={{ fontSize: '14px' }} />}
                                  onClick={() => handleOpen(row.reloj_sedes)}
                                />
                                : col.id === 'sel' ?
                                  <CustomCheck
                                    forCheck={`${row.id_reloj}${forCheckBody}`}
                                    checked={(dataSel?.find(e => e.id_reloj === row.id_reloj) ? true : false)}
                                    onChange={(e) => handleChangeCheck(e.target.checked, row)}
                                  />
                                  : value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default BodyClocks;