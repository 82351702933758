import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import ProfileService from '../../../../services/profile';
import MainInput from '../../../components/general/CustomInput';
import { CODES } from '../../../../utils/codesHTTP';
import SpinnerSmall from '../../SpinnerLoad/SpinnerSmall';
import { simpleAlerts } from '../../../../utils/alerts';
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/actions';

const InfoProfile = (props) => {
    const { typeEdition, system, infoProfile, setInfoProfile, screens, screensProfile, setScreensProfile, screensDelete, setScreensDelete } = props;

    const url = "'" + window.location.pathname + "'";
    const [screensCol, setScreensCol] = useState(null);
    const [openSmall, setOpenSmall] = useState(true);
    const [screensSystem, setScreensSystem] = useState(null);
    const dispatch = useDispatch();
    const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
    const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));
  

    useEffect(() => {
        calColumnsScreens();
    }, [screensSystem])

    useEffect(() => {
        setScreensSystem(screens?.filter(e => e.sistema === system))
    }, [system])

    useEffect(() => {
        setScreensDelete([]);
        setScreensProfile([]);
        if (infoProfile?.id_perfil && typeEdition === 2) {
            setOpenSmall(true);
            getPantallasByPerfil(infoProfile?.id_perfil);
        } else
            setOpenSmall(false);
    }, [infoProfile?.id_perfil, typeEdition])

    const calColumnsScreens = () => {
        let cantXcol = 1;
        let toltalCol = screensSystem?.length / cantXcol;
        let arrayScreens = [];
        for (let i = 0; i < toltalCol; i++) {
            arrayScreens.push(screensSystem.slice((i * cantXcol), (i * cantXcol) + cantXcol));
        }
        setScreensCol(arrayScreens);
    }
       

    const getPantallasByPerfil = async (id) => {
        try {
            handleOpenSpinner(true);
            handleTitleSpinner("Buscando Permisos");
            const result = await ProfileService.getPantallasByPerfil(system, url, id);
            if (result.status === CODES.SUCCESS_200) {
                setScreensProfile(result.data);
                setTimeout(() => { setOpenSmall(false); }, "500");
            } else {
                simpleAlerts('Hubo un error en perfiles de pantallas', 'error');
            }
        } catch (err) {
            console.log('getPantallasByPerfil', err);
        }finally{
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    };

    const handleChangeCheck = (value, p_screen) => {
        let updScreensDelete = [].concat(screensDelete);
        let updScreensProfile = [].concat(screensProfile);
      
        if (updScreensProfile.length === 0) {
            updScreensProfile?.push(p_screen);
        } else {
            if (value === true) {
                updScreensProfile?.push(p_screen);
            } else {
                updScreensDelete.push(updScreensProfile?.find(e => e.id_pantalla === p_screen.id_pantalla));
                updScreensProfile = updScreensProfile?.filter(e => e.id_pantalla !== p_screen.id_pantalla);
            }
        }
        setScreensDelete(updScreensDelete)
        return setScreensProfile(updScreensProfile)
    }

    return (
        <>
            <Grid container>
                <Grid item md={6} xs={12}>
                    <MainInput
                        title={'Descripción'}
                        value={infoProfile?.desPerfil}
                        name={'desPerfil'}
                        placeholder={"Ingrese descripción"}
                        onChange={(e) => setInfoProfile({ ...infoProfile, desPerfil: e.target.value })}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <MainInput
                        title={'Código'}
                        value={infoProfile?.codPerfil}
                        name={'codPerfil'}
                        placeholder={"Ingrese código"}
                        onChange={(e) => setInfoProfile({ ...infoProfile, codPerfil: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Box
                display={'flex'}
                overflow={'scroll'}
                padding={'2px 0 0 5px'}          
            >
            <SpinnerSmall open={openSmall} />
                {screensCol?.map((screen, idx) => {
                    return (
                        <Box key={'box' + idx}
                            sx={{ marginRight: '5px', paddingTop: 1,paddingBottom: 1, minWidth:'100px'}}
                        >
                            <div className="checkbox-custom" >
                                {screen.map((aScreen, idxScreen) => {
                                    return (
                                        <ElementCheck
                                            key={'screen' + idxScreen}
                                            system={system}
                                            info={aScreen}
                                            dataScreensProfile={screensProfile}
                                            handleChangeCheck={handleChangeCheck}
                                        />
                                    )
                                })}
                            </div>
                        </Box>
                    )
                })}
            </Box>
        </>
    );
};

const ElementCheck = (props) => {
    const { system, info, dataScreensProfile, handleChangeCheck } = props;
    return (
        <>
            <input type="checkbox" id={`checkbox-rect` + info.desPagina} name="check"
                checked={dataScreensProfile?.find(e => e.id_pantalla === info.id_pantalla) && system === info.sistema ? true : false}
                onChange={(e) => handleChangeCheck(e.target.checked, info)}
            />
            <label htmlFor={`checkbox-rect` + info.desPagina} >{info.desPagina}</label>
        </>
    )
}

export default InfoProfile;