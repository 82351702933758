import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/actions';
import WorkerService from '../../../services/worker';
import GeneralService from '../../../services/general';
import MaintenanceWorkers from './MaintenanceWorkers';
import UploadWorkersClock from './UploadWorkersClock';
import MaintenanceBiometric from '../Workers/MaintenanceBiometric';
import { CODES } from '../../../utils/codesHTTP';
import CustomSwitch from '../../components/general/CustomSwitch';
import AssistanceHistory from './AssistanceHistory';
import { mapData } from '../../../utils/function';

const Workers = ({ userToken }) => {
    const infoGrouping = useSelector(state => state.infoGrouping);

    const dispatch = useDispatch();
    const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
    const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (tabIndex, event) => {
        event.preventDefault();
        setActiveTab(tabIndex);
    };

    const pageSize = 20;
    const [dataWorkers, setDataWorkers] = useState(null);
    const [searchWorkerS, setSearchWorkerS] = useState('');
    const [pageWorkerS, setPageWorkerS] = useState(1);
    const [totalPageWorkerS, setTotalPageWorkerS] = useState(1);

    const [allWorkers, setAllWorkers] = useState(false); //PARA EXTERNOS TODOS O LOS DE LA SEDE
    const [chkType, setChkType] = useState(true); //tipos de trabajadores ASISPROD - EXTERNOS

    const [selectedIdPersona, setSelectedIdPersona] = useState(null);
    const [selectedRowPersona, setSelectedRowPersona] = useState([]);
    const [selectedFingerId, setSelectedFingerId] = useState(null);

    const [dataTypeUserClock, setDataTypeUserClock] = useState(null);

    useEffect(() => {
        getTipoUsuarioReloj();
    }, []);

    useEffect(() => {
        setDataWorkers(null);
        if (activeTab !== 2 && activeTab !== 3)
            getWorkers();
    }, [chkType, allWorkers, infoGrouping, pageWorkerS]);

    const getTipoUsuarioReloj = async () => {
        try {
            setDataTypeUserClock(null);
            const result = await GeneralService.getTipoUsuarioReloj();
            if (result.status === CODES.SUCCESS_200) {
                const tmp = mapData(result.data, ['id_tipoUsu', 'descTipoUsu']);
                setDataTypeUserClock(tmp);
            }
        } catch (error) {
            console.log(`Error en getTipoUsuarioReloj: ${error}`);
        }
    }

    const getWorkers = async () => {
        if (infoGrouping && chkType) {
            setSelectedIdPersona(null);
            getWorkerBySede();
        }
        if (allWorkers) {
            setSelectedIdPersona(null);
            getWorkerExternal();
        } else {
            if (infoGrouping && !chkType) {
                getWorkerExternalBySede();
                setSelectedIdPersona(null);
            }
        }
    }

    const getWorkerBySede = async () => {
        try {
            handleOpenSpinner(true);
            handleTitleSpinner("Buscando trabajadores");
            setDataWorkers(null);

            const lstSedes = infoGrouping.map(e => +e.value);
            const result = await WorkerService.getWorkerBySede(lstSedes, { search: searchWorkerS, page: pageWorkerS, pageSize: pageSize });
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result?.data?.data || result?.data);
                setTotalPageWorkerS(result?.data?.last_page || 0);
            }
        } catch (error) {
            console.log(`Error en getWorkerBySede: ${error}`);
        } finally {
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    const getWorkerExternalBySede = async () => {
        try {
            handleOpenSpinner(true);
            handleTitleSpinner("Buscando trabajadores externos ...");
            setDataWorkers(null);
            const lstSedes = infoGrouping.map(e => +e.value);
            const result = await WorkerService.getWorkerExternalBySede(lstSedes, { search: searchWorkerS, page: pageWorkerS, pageSize: pageSize });
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result?.data?.data || result?.data);
                setTotalPageWorkerS(result?.data?.last_page || 0);
            }
        } catch (error) {
            console.log(`Error en getWorkerExternalBySede: ${error}`);
        } finally {
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    const getWorkerExternal = async () => {
        try {
            handleOpenSpinner(true);
            handleTitleSpinner("Buscando trabajadores externos ...");
            setDataWorkers(null);
            const result = await WorkerService.getWorkerExternal({ search: searchWorkerS, page: pageWorkerS, pageSize: pageSize });
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result?.data?.data || result?.data);
                setTotalPageWorkerS(result?.data?.last_page || 0);
            }
        } catch (error) {
            console.log(`Error en getWorkerExternalBySede: ${error}`);
        } finally {
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    return (
        <div>
            <ul className="nav nav-tabs justify-content-center flex-column flex-sm-row nav-tabs-body">
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                        href="#"
                        onClick={(e) => handleTabClick(0, e)}
                        title='Mantenimiento de trabajadores'
                    >
                        Mant. Trabajadores
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                        href="#"
                        onClick={(e) => handleTabClick(1, e)}
                        title='Mantenimiento de biometrico'
                    >
                        Mant. Biometrico
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                        href="#"
                        onClick={(e) => handleTabClick(2, e)}
                        title='Subir Trabajadores al Reloj'
                    >
                        Subir Trabaj. al Reloj
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 3 ? 'active' : ''}`}
                        href="#"
                        onClick={(e) => handleTabClick(3, e)}
                        title='Historial de Asistencias'
                    >
                        Hist. de Asistencias
                    </a>
                </li>
            </ul>

            <div style={{ margin: '20px 30px 0px 30px' }}>
                <div className='row justify-content-center'>
                    <div className='col-9'>
                        <CustomSwitch
                            leftLabel={"ASISPROD"}
                            rightLabel={"EXTERNO"}
                            checked={chkType}
                            onChange={() => setChkType(!chkType)}
                        />
                    </div>
                </div>
                {activeTab === 0 &&
                    <div className='animate__animated animate__backInRight'>
                        <MaintenanceWorkers
                            pageSize={pageSize}
                            pageWorkerS={pageWorkerS}
                            setPageWorkerS={setPageWorkerS}
                            totalPageWorkerS={totalPageWorkerS}
                            searchWorkerS={searchWorkerS}
                            setSearchWorkerS={setSearchWorkerS}
                            dataWorkers={dataWorkers}
                            getWorkers={getWorkers}
                            allWorkers={allWorkers}
                            setAllWorkers={setAllWorkers}
                        />
                    </div>
                }
                {activeTab === 1 &&
                    <div className='animate__animated animate__backInRight'>
                        <MaintenanceBiometric
                            pageWorkerS={pageWorkerS}
                            setPageWorkerS={setPageWorkerS}
                            totalPageWorkerS={totalPageWorkerS}
                            searchWorkerS={searchWorkerS}
                            setSearchWorkerS={setSearchWorkerS}
                            dataWorkers={dataWorkers}
                            getWorkers={getWorkers}
                            selectedIdPersona={selectedIdPersona}
                            setSelectedIdPersona={setSelectedIdPersona}
                            selectedRowPersona={selectedRowPersona}
                            setSelectedRowPersona={setSelectedRowPersona}
                            selectedFingerId={selectedFingerId}
                            setSelectedFingerId={setSelectedFingerId}
                        />
                    </div>
                }
                {activeTab === 2 &&
                    <div className='animate__animated animate__backInRight'>
                        <UploadWorkersClock
                            userToken={userToken}
                            dataTypeUserClock={dataTypeUserClock}
                        />
                    </div>
                }
                {activeTab === 3 &&
                    <div className='animate__animated animate__backInRight'>
                        <AssistanceHistory
                        // allWorkers={allWorkers}
                        // setAllWorkers={setAllWorkers}
                        // chkType={chkType}
                        // setChkType={setChkType}

                        // pageWorkerS={pageWorkerS}
                        // setPageWorkerS={setPageWorkerS}
                        // totalPageWorkerS={totalPageWorkerS}
                        // searchWorkerS={searchWorkerS}
                        // setSearchWorkerS={setSearchWorkerS}

                        // dataWorkers={dataWorkers}
                        // getWorkers={getWorkers}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default Workers