import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/actions';
import { Box, Grid, } from '@mui/material'
import ReportService from '../../../services/report';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import WorkerService from '../../../services/worker';
import GeneralService from '../../../services/general';
import BodyWorkers from '../../components/Workers/BodyWorkers';
import AddUploadClock from './AddUploadClock/AddUploadClock';
import CustomButton from '../../components/general/CustomButton';
import workerHappy from '../../../assets/images/worker_happy.png';
import workerSad from '../../../assets/images/worker_sad.png';
import excelImg from '../../../assets/images/excel.png';
import { CODES } from '../../../utils/codesHTTP';
import { simpleAlerts } from '../../../utils/alerts';
import { validationMessages } from '../../../utils/messagesError';
import Swal from 'sweetalert2';

const UploadWorkersClock = ({ userToken, dataTypeUserClock }) => {
    const idUsuario = userToken?.user?.id_usuario;
    const infoClock = useSelector(state => state.infoClock);
    const infoCampus = useSelector(state => state.infoCampus);
    const infoGrouping = useSelector(state => state.infoGrouping);
    const grouping = useSelector(state => state.grouping);
    const clocks = useSelector(state => state.clocks);
    const dispatch = useDispatch();
    const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
    const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const pageSize = 20;
    const [searchWorkerS, setSearchWorkerS] = useState('');
    const [pageWorkerS, setPageWorkerS] = useState(1);
    const [totalPageWorkerS, setTotalPageWorkerS] = useState(0);
    const [workersSede, setWorkersSede] = useState(null);
    const [selectWS, setSelectWS] = useState([]);

    const [searchWorkerC, setSearchWorkerC] = useState('');
    const [pageWorkerC, setPageWorkerC] = useState(1);
    const [totalPageWorkerC, setTotalPageWorkerC] = useState(0);
    const [workersClock, setWorkersClock] = useState(null);

    const [selectWC, setSelectWC] = useState([]);

    const [idEstado, setIdEstado] = useState(1);
    const [typeUserClock, setTypeUserClock] = useState(2);
    const [passUserClock, setPassUserClock] = useState(null);
    const [cardUserClock, setCardUserClock] = useState(null);
    const [typeSave, setTypeSave] = useState(null);

    const [infoWorker, setInfoWorker] = useState(null);

    const [checkMassive, setCheckMassive] = useState(false);
    const [checkFingerPrint, setCheckFingerPrint] = useState(false);

    const [fingers, setFingers] = useState(null);

    useEffect(() => {
        getDedos();
    }, []);

    useEffect(() => {
        setWorkersSede(null);
        setSelectWS([]);
        setWorkersClock(null);
        setSelectWC([]);
        const groupingSelect = grouping?.filter(g => +g.reloj_id === +infoClock?.id_reloj);
        if (infoClock?.value && infoGrouping &&
            (groupingSelect?.length === infoGrouping?.length)) {
            init();
        }
    }, [infoClock, infoGrouping,]);

    useEffect(() => {
        if (infoClock?.value && infoGrouping)
            getWorkerBySedeXReloj();
    }, [pageWorkerS,]);

    useEffect(() => {
        if (infoClock?.value)
            getWorkerByReloj();
    }, [pageWorkerC,]);

    const init = async () => {
        handleOpenSpinner(true);
        handleTitleSpinner("Cargando trabajadores ...")
        const promises = [];
        if (infoClock?.value) {
            promises.push(getWorkerByReloj());
        }
        if (infoClock?.value && infoGrouping) {
            promises.push(getWorkerBySedeXReloj());
        }

        Promise.all(promises)
            .then(() => {
                handleOpenSpinner(false);
                handleTitleSpinner(null);
            });
    }

    const getDedos = async () => {
        try {
            const result = await GeneralService.getDedos();
            if (result.status === CODES.SUCCESS_200) {
                result?.data?.forEach((el) => {
                    el.id = el.id_dedo;
                    el.value = el.id_dedo;
                    el.label = el.descDedo;
                })
                setFingers(result?.data);
            }
        } catch (error) {
            console.log(`Error en getDedos: ${error}`);
        }
    }

    const getWorkerByReloj = async () => {
        try {
            handleOpenSpinner(true);
            handleTitleSpinner("Buscando los trabajadores del reloj ...");
            setWorkersClock(null);
            const result = await WorkerService.getWorkerByReloj(infoClock.id_reloj, { search: searchWorkerC, page: pageWorkerC, pageSize: pageSize });
            if (result.status === CODES.SUCCESS_200) {
                setWorkersClock(result?.data?.data || result?.data);
                setTotalPageWorkerC(result?.data?.last_page || 0);
            }
        } catch (error) {
            console.log(`Error en getWorkerByReloj: ${error}`);
        } finally {
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    const getWorkerBySedeXReloj = async () => {
        if (!infoGrouping) {
            simpleAlerts({ message: validationMessages.sede });
            return;
        }
        if (!infoClock?.value) {
            simpleAlerts({ message: validationMessages.clock });
            return;
        }
        try {
            handleOpenSpinner(true);
            handleTitleSpinner("Buscando los trabajadores de la sede ...");
            setWorkersSede(null);
            const lstSedes = infoGrouping?.reduce((acumulador, e) => {
                acumulador.push(+e.id_sede);
                return acumulador;
            }, [])
            const result = await WorkerService.getWorkerBySedeXReloj(lstSedes, infoClock.id_reloj, { search: searchWorkerS, page: pageWorkerS });
            if (result.status === CODES.SUCCESS_200) {
                setWorkersSede(result?.data?.data || result?.data);
                setTotalPageWorkerS(result?.data?.last_page || 0);
            }
        } catch (error) {
            console.log(`Error en getWorkerByReloj: ${error}`);
        } finally {
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    // !FUNCION PARA OBTENER LOS RELOJES - PARA MASIVOS
    const validateListClock = () => {
        //* obtenemos todos los ids de las sede de los agrupamientos seleccionados
        const ids_sede = infoGrouping.map(grupo => parseInt(grupo.id_sede));
        const ids_sede_set = new Set(ids_sede);

        // const reloj_ids = grouping
        //     .filter(grupo => ids_sede.includes(parseInt(grupo.id_sede)))
        //     .map(grupo => parseInt(grupo.reloj_id));

        //* buscamos los ids de los relojes que contengan alguna de las sedes
        const reloj_ids_set =
            new Set(grouping.filter(grupo => ids_sede_set.has(parseInt(grupo.id_sede)))
                .map(grupo => parseInt(grupo.reloj_id))
            );
        const reloj_ids = [...reloj_ids_set];

        //* obtenemos los relojes
        const clocksFinal = clocks.filter(cock => reloj_ids.includes(cock.id_reloj))
        return clocksFinal
    }
    // !FUNCION PARA OBTENER LOS RELOJES - PARA MASIVOS

    const assignWorker = async (info) => {
        if (checkMassive) {
            const clocksFinalTMP = validateListClock();
            if (!clocksFinalTMP || clocksFinalTMP?.length === 0) {
                simpleAlerts({ message: validationMessages.clockLength });
                return;
            }
            if (typeSave === 'SAVE') {
                if (idEstado === 1) {
                    if (!selectWS || selectWS?.length === 0) {
                        simpleAlerts({ message: validationMessages.trabajadoresLength });
                        return;
                    }
                } else {
                    if (!selectWC || selectWC?.length === 0) {
                        simpleAlerts({ message: validationMessages.trabajadoresLength });
                        return;
                    }
                }
            }
        } else {
            if (!idEstado || idEstado === 0) {
                simpleAlerts({ message: validationMessages.estado });
                return;
            }
            if (!infoClock || infoClock?.id_reloj === 0) {
                simpleAlerts({ message: validationMessages.clock });
                return;
            }
            if (typeSave === 'SAVE') {
                if (idEstado === 1) {
                    if (!selectWS || selectWS?.length === 0) {
                        simpleAlerts({ message: validationMessages.trabajadoresLength });
                        return;
                    }
                } else {
                    if (!selectWC || selectWC?.length === 0) {
                        simpleAlerts({ message: validationMessages.trabajadoresLength });
                        return;
                    }
                }
            }
        }
        updateInformationWorkerToClock(info);
    }

    const updateInformationWorkerToClock = async (info) => {
        const listWorker = (idEstado === 1 ? selectWS : selectWC)
        let htmlSubtitle;
        let clocksFinalTMP = [];
        if (checkMassive) {
            clocksFinalTMP = validateListClock();
            const clocksTable = clocksFinalTMP?.map((clock) => `
                <tr key="clock-${clock.serial}">
                    <th scope="row" style="background-color: #f2f2f2;">${clock.serial}</th>
                    <td>${clock.descripcion}</td>
                </tr>`
            )?.join("");
            htmlSubtitle =
                `<table style="font-size:12px; border: solid 1px #D5DBDB; border-radius: 25px;">
            <tbody>
                ${clocksTable}
            </tbody>
            </table>`
        } else {
            htmlSubtitle = `<label style="font-size: 12px;">Actualizra la información información del reloj (${infoClock.serial})</label>`
        }

        if (checkFingerPrint) {
            htmlSubtitle += `<label style="font-size: 12px;"> - Tambien se enviaran las huellas</label>`
        }

        let resultSwal;
        if (info) {
            resultSwal = await Swal.fire({
                title: `Seguro de actualizar la información del usuario (${info?.trabajador})`,
                html: htmlSubtitle,
                showCancelButton: true,
                confirmButtonText: "Si",
                cancelButtonText: "No",
            });
        } else {
            resultSwal = await Swal.fire({
                title: `Seguro de ${+idEstado === 1 ? 'agregar a ' : 'retirar de '} ${listWorker?.length} trabajador(es) a`,
                html: htmlSubtitle,
                showCancelButton: true,
                confirmButtonText: "Si",
                cancelButtonText: "No",
            });
        }

        if (resultSwal.isConfirmed) {
            try {
                const body = {
                    id_reloj: infoClock.id_reloj,
                    numdoc: info?.numdoc,
                    id_persona: info?.id_persona,
                    tipoTrabajador: info?.tipoTrabajador,
                    id_estado: idEstado,
                    tipoUsuario_id: typeUserClock,
                    clave: passUserClock,
                    tarjeta: cardUserClock,
                    checkFingerPrint: (typeSave === 'SAVE' ? true : checkFingerPrint),
                    relojes: clocksFinalTMP,
                    trabajadores: listWorker,
                }

                handleOpenSpinner(true);
                handleTitleSpinner('Enviando solicitud (puede tomar varios minutos) ...')
                const result = await WorkerService.updateInformationWorkerFromClock(body);
                if (result.status === CODES.SUCCESS_200) {
                    simpleAlerts({ message: (result?.data?.message || 'Esperando respuesta de solicitud!'), type: (+result?.data?.status === 0 ? 'error' : 'success'), })
                } else {
                    simpleAlerts({
                        message: (result?.error ||
                            result?.response?.data?.message ||
                            result?.response?.data?.error ||
                            'Hubo un error!'), type: (result?.type || 'error'),
                    })
                }
                return [];
            } catch (error) {
                console.log(`Error en saveClock: ${error.message}`);
            } finally {
                handleOpenSpinner(false);
                handleTitleSpinner(null);
                cleanInfo();
            }
        }
    }

    const cleanInfo = () => {
        handleClose();
        setTypeUserClock(2);
        setPassUserClock(null);
        setCardUserClock(null);
        setIdEstado(null);
        setInfoWorker(null);
        setSelectWS([]);
        setSelectWC([]);
        setCheckMassive(false);
        setCheckFingerPrint(false);
        setTypeSave(null);
    }

    const handleWayModal = (info, type, estado_id) => {
        setInfoWorker(info);
        setTypeSave(type)
        setIdEstado(estado_id);
        handleOpen();
    }

    const requestAssistancesWorker = async (info, fechaInicio, fechaFin) => {
        const htmlSubtitle =
            ((fechaInicio !== '' && fechaFin !== '') ?
                `<label style="font-size: 12px;">Las fechas solicitadas son: <b>${fechaInicio} - ${fechaFin}</b></label>`
                : `<label style="font-size: 12px;"><b>Solicitara todas las asistencias del trabajador <br />(esta acción puede tomar varios minutos)</b></label>`)
        const resultSwal = await Swal.fire({
            title: `Seguro solicitar las asistencias de (${info.trabajador})`,
            html: htmlSubtitle,
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
        });

        if (resultSwal.isConfirmed) {
            try {
                const body = {
                    fechaInicio: fechaInicio,
                    fechaFin: fechaFin,
                    id_reloj: infoClock.id_reloj,
                    numdoc: info.numdoc,
                    id_persona: info.id_persona,
                    tipoTrabajador: info.tipoTrabajador,
                }

                handleOpenSpinner(true);
                handleTitleSpinner('Enviando solicitud (puede tomar varios minutos) ...')
                const result = await WorkerService.requestAssistancesWorker(body);
                if (result.status === CODES.SUCCESS_200) {
                    simpleAlerts({ message: (result?.data?.message || 'Esperando respuesta de solicitud!'), type: 'success', })
                } else {
                    simpleAlerts({
                        message: (result?.error ||
                            result?.response?.data?.message ||
                            result?.response?.data?.error ||
                            'Hubo un error!'), type: (result?.type || 'error'),
                    })
                }
                return [];
            } catch (error) {
                console.log(`Error en saveClock: ${error.message}`);
            } finally {
                handleOpenSpinner(false);
                handleTitleSpinner(null);
                cleanInfo();
            }
        }
    }

    const exportWorkerOnClock = async (info) => {
        try {
            if (!infoGrouping) {
                simpleAlerts({ message: validationMessages.sede });
                return;
            }
            const idsSedes = infoGrouping?.map(sede => sede.id_sede);
            const body = {
                id_usuario: idUsuario,
                id_sede: idsSedes,
                numdoc: info?.numdoc
            }

            handleOpenSpinner(true);
            handleTitleSpinner('Generando Reporte ...');
            const result = await ReportService.exportWorkerOnClock(body);
            if (result.status === CODES.SUCCESS_200) {
                const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'Trabajadores_En_Reloj.xlsx';
                link.click();
                URL.revokeObjectURL(url);
                simpleAlerts({ message: 'Reporte generado correctamente', type: 'success' })
            } else {
                simpleAlerts({ message: 'No se pudo generar reporte', type: 'error' })
            }
            return []
        } catch (error) {
            console.log(`Error en generateSCTR: ${error}`);
        } finally {
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    return (
        <>
            <AddUploadClock
                open={open}
                cleanInfo={cleanInfo}
                saveWorker={
                    typeSave === 'ASSISTANCE' ?
                        requestAssistancesWorker
                        : (typeSave === 'SAVE' || typeSave === 'UPDATE') ?
                            assignWorker
                            : null}
                checkMassive={checkMassive}
                setCheckMassive={setCheckMassive}
                checkFingerPrint={checkFingerPrint}
                setCheckFingerPrint={setCheckFingerPrint}
                fingers={fingers}
                infoWorker={infoWorker}
                typeSave={typeSave}
                idEstado={idEstado}
                dataTypeUserClock={dataTypeUserClock}
                typeUserClock={typeUserClock}
                setTypeUserClock={setTypeUserClock}
                passUserClock={passUserClock}
                setPassUserClock={setPassUserClock}
                cardUserClock={cardUserClock}
                setCardUserClock={setCardUserClock}
            />
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 10, gap: 10, }}>
                    <CustomButton
                        title='Agregar trabajador del reloj'
                        styleType={'outline'}
                        variant={'success'}
                        value={<>
                            Buscar trabajadores <SearchIcon fontSize='inherit' />
                        </>}
                        onClick={init}
                    />
                    <CustomButton
                        title='Trabajadores asignados a un reloj'
                        styleType={'outline'}
                        variant={'success'}
                        value={<img src={excelImg} width={25} />}
                        onClick={exportWorkerOnClock}
                    />
                </Grid>
                <Grid item xs={11} sm={11} md={5}>
                    <span className="badge text-dark" style={{ fontSize: '1rem' }}>Trabajadores de {infoCampus?.label}:</span>
                    <BodyWorkers
                        forCheckBody={'-1'}
                        pageSize={pageSize}
                        page={pageWorkerS}
                        setPage={setPageWorkerS}
                        totalPage={totalPageWorkerS}
                        data={workersSede}
                        search={searchWorkerS}
                        setSearch={setSearchWorkerS}
                        getInfo={getWorkerBySedeXReloj}
                        dataSel={selectWS}
                        setDataSel={setSelectWS}
                    />
                </Grid>
                <Grid item xs={11} sm={11} md={1}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'row', sm: 'row', md: 'column' },
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <CustomButton
                            title='Agregar trabajador del reloj'
                            styleType={'outline'}
                            variant={'success'}
                            value={<>
                                <img src={workerHappy} width={35} /> <AddCircleIcon fontSize='medium' />
                            </>}
                            onClick={() => { handleWayModal(null, 'SAVE', 1) }}
                        />
                        <CustomButton
                            title='Retirar trabajador del reloj'
                            styleType={'outline'}
                            variant={'danger'}
                            value={<>
                                <RemoveCircleIcon fontSize='medium' /> <img src={workerSad} width={35} />
                            </>}
                            onClick={() => { handleWayModal(null, 'SAVE', 2) }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={11} sm={11} md={5}>
                    <span className="badge text-dark" style={{ fontSize: '1rem' }}>Trabajadores en Reloj: {infoClock?.serial}</span>
                    <BodyWorkers
                        columns={
                            [
                                { id: '#', label: '#', width: 30, align: 'center', },
                                { id: 'numdoc', label: 'Doc.', width: 100, align: 'center', order: true, },
                                { id: 'trabajador', label: 'Trabajador', width: 400, align: 'left', order: true, },
                                { id: 'actions', label: 'actions', width: 150, align: 'center', },
                                { id: 'sel', label: 'seleccionar', width: 40, align: 'center', },
                            ]
                        }
                        forCheckBody={'-2'}
                        handleActions={handleWayModal}
                        pageSize={pageSize}
                        page={pageWorkerC}
                        setPage={setPageWorkerC}
                        totalPage={totalPageWorkerC}
                        data={workersClock}
                        search={searchWorkerC}
                        setSearch={setSearchWorkerC}
                        getInfo={getWorkerByReloj}
                        dataSel={selectWC}
                        setDataSel={setSelectWC}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default UploadWorkersClock;