import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedInfo } from '../../../config/actions';
import CustomSelect from './CustomSelect';
import UserService from '../../../services/user';
import { CODES } from '../../../utils/codesHTTP';
import { mapData } from '../../../utils/function';

const GroupingSedeUser = ({ userToken, }) => {
    const isMobile = window.innerWidth <= 576; // Define el ancho de pantalla para considerar como móvil
    const customtStyle = {
        marginTop: isMobile ? 5 : 0,
    };

    /***/
    const dispatch = useDispatch();
    const selectedInfo = useSelector(state => state);
    /***/

    const id_usuario = userToken?.user?.id_usuario;
    const [grouping, setGrouping] = useState(null);

    const [clocksAll, setClocksAll] = useState(null);
    const [groupingAll, setGroupingAll] = useState(null);

    const [groupingValues, setGroupingValues] = useState(null);

    useEffect(() => {
        getAgrupamientoByUsuario();
    }, [])

    useEffect(() => {
        setGrouping(null);
        setGroupingValues(null);
        dispatch(setSelectedInfo({ infoGrouping: null }));
        if (selectedInfo?.infoClock) {
            const groupingTMP = groupingAll?.filter(e => +e.reloj_id === +selectedInfo?.infoClock?.id_reloj);
            dispatch(setSelectedInfo({ infoGrouping: groupingTMP }));
            setGrouping(groupingTMP);
            setGroupingValues(groupingTMP?.reduce((acumulador, e) => {
                acumulador.push(e.value);
                return acumulador;
            }, []))
        }
    }, [selectedInfo?.infoClock])

    const getAgrupamientoByUsuario = async () => {
        const result = await UserService.getAgrupamientoByUsuario(id_usuario);
        if (result.status === CODES.SUCCESS_200) {
            result.data?.forEach(element => {
                element.labelClock = `${element.serial} (${element.descripcion})`
                element.reloj_sedes?.forEach(ele => {
                    ele.labelGrouping = `${ele.agrupamiento?.desEmpresa.substring(0, 40)} / ${ele.agrupamiento?.desCliente.substring(0, 45)} / ${ele.agrupamiento?.desUnidad.substring(0, 35)} / ${ele.agrupamiento?.desSede.substring(0, 20)}`
                    ele.idGrouping = ele.agrupamiento?.id_sede;
                })
            })
            const relojesTmp = mapData(result.data, ["id_reloj", "labelClock", "id_reloj", "serial", "fechaUpd", "estado_id", "descripcion", "linea", "fechaCData", "fechaDevice",]);
            dispatch(setSelectedInfo({ clocks: relojesTmp }));
            setClocksAll(relojesTmp);
            const agrupamitosTmp = result.data?.reduce((acumulador, e) => {
                const agrupamiento = mapData(e.reloj_sedes, [
                    "idGrouping",
                    "labelGrouping",
                    "id_relojSede",
                    "reloj_id",
                    "agrupamiento.id_empresa",
                    "agrupamiento.desEmpresa",
                    "agrupamiento.rucEmpresa",
                    "agrupamiento.id_cliente",
                    "agrupamiento.desCliente",
                    "agrupamiento.rucCliente",
                    "agrupamiento.id_unidad",
                    "agrupamiento.desUnidad",
                    "agrupamiento.id_sede",
                    "agrupamiento.desSede",
                ]);
                return acumulador.concat(agrupamiento);
            }, []);
            dispatch(setSelectedInfo({ grouping: agrupamitosTmp }));
            setGroupingAll(agrupamitosTmp);
        }
    }

    const handleChangeGrouping = (value) => {
        const valueIntegers = value.map(value => +value);
        dispatch(setSelectedInfo({ infoGrouping: grouping?.filter(grupo => valueIntegers.includes(+grupo.value)) }));
        return setGroupingValues(value)
    }

    return (
        <>
            <CustomSelect
                placeholder={'Seleccionar Reloj'}
                customtStyle={customtStyle}
                options={clocksAll}
                value={selectedInfo?.infoClock}
                width={'35%'}
                onChange={(value, event) => dispatch(setSelectedInfo({ infoClock: clocksAll?.find(element => +element.value === +value) }))}
            />
            <div className="me-2"></div>
            <CustomSelect
                placeholder={'Seleccionar Agrupamiento'}
                customtStyle={customtStyle}
                mode={'multiple'}
                options={grouping}
                value={groupingValues}
                onChange={handleChangeGrouping}
            />
        </>
    );
};

export default GroupingSedeUser;