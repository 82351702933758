import ClockService from '../../../../services/clock';
import WorkerService from '../../../../services/worker';
import { validationMessages } from '../../../../utils/messagesError';
import { formatDate } from '../../../../utils/function';

class ClockFunctions {
    constructor(perfil) {
        this._perfil = perfil;
    }

    getColumnsReloj() {
        if (+this._perfil === 1) {
            return [
                { id: '#', label: '#', width: 50, align: 'center', },
                { id: 'serial', label: 'Serial.', width: 250, align: 'center', order: true, },
                { id: 'descripcion', label: 'Descripcion', width: 300, align: 'center', order: true, },
                { id: 'linea', label: 'Linea', width: 100, align: 'center', },
                { id: 'fechaUpd', label: 'Ult. actualizacion', width: 200, align: 'center', format: (value) => formatDate(new Date(value)), },
                { id: 'actions', label: 'actions', width: 60, align: 'center', },
                { id: 'advanced', label: 'Advanced', width: 180, align: 'center', },
            ];
        } else {
            return [
                { id: '#', label: '#', width: 50, align: 'center' },
                { id: 'serial', label: 'Serial.', width: 300, align: 'center', order: true },
                { id: 'fechaUpd', label: 'Ult. actualizacion', width: 200, align: 'center', format: (value) => formatDate(new Date(value)) },
            ];
        }
    }

    getColumnsMapReloj() {
        return {
            id_estado: 'estado.descEstado',
            id_sede: 'sede.desSede',
            /*id_unidad: 'sede.unidad.desUnidad',
            id_cliente: 'sede.unidad.cliente.desCliente',
            id_empresa: 'sede.unidad.cliente.empresa.desEmpresa',*/
        };
    }

    /* validacion de guardado */
    async saveRelojAndSedes(body) {
        if (+this._perfil === 1)
            return this._saveRelojAndSedes(body);
        else
            return { status: 400, error: validationMessages.permits, type: 'warning' };
    }

    async _saveRelojAndSedes(body) {
        try {
            if (!body.serial || body.serial === '') {
                return { status: 400, error: validationMessages.serial, type: 'warning' };
            }
            if (!body.descripcion || body.descripcion === '') {
                return { status: 400, error: validationMessages.descripcionReloj, type: 'warning' };
            }
            if (body.reloj_sedes.length > 0) {
                const relojSedes = body.reloj_sedes;
                for (let i = 0; i < relojSedes.length; i++) {
                    const sede = relojSedes[i];
                    if (!sede.hasOwnProperty('id_sede') || isNaN(parseInt(sede.id_sede, 10))) {
                        throw new Error(`${validationMessages.sede}.`);
                    }
                }
            }
            const camposEliminar = ['fechaUpd', 'fechaMod', 'fechaCData', 'fechaDevice'];
            for (const campo of camposEliminar) {
                if (body.hasOwnProperty(campo)) {
                    delete body[campo];
                }
            }
            const result = await ClockService.saveRelojAndSedes(body);
            return result;
        } catch (error) {
            return { status: 400, error: error.message, };
        }
    }

    async saveClock(infoClock, sede) {
        if (+this._perfil === 1)
            return this._saveClockAdmin(infoClock, sede);
        else
            return { status: 400, error: validationMessages.permits, type: 'warning' };
    }

    async _saveClockAdmin(infoClock) {
        try {
            const result = await ClockService.saveReloj(infoClock);
            return result;
        } catch (error) {
            return { status: 400, error: error.message, };
        }
    }

    /* obtener relojes */
    async getRelojes(id_usuario) {
        if (+this._perfil === 1)
            return this._getRelojesAdmin();
        else
            return this._getRelojesByUser(id_usuario);
    }

    async _getRelojesAdmin() {
        try {
            const result = await ClockService.getRelojes();
            return result
        } catch (error) {
            return { status: 400, error: error.message, };
        }
    }

    async _getRelojesByUser(id_usuario) {
        try {
            const result = await ClockService.getRelojesByUser(id_usuario);
            return result
        } catch (error) {
            return { status: 400, error: error.message, };
        }
    }

    /* validacion de borrar usuarios del reloj */
    async deleteUsersClock(id_reloj) {
        if (+this._perfil === 1)
            return this._deleteUsersClock(id_reloj);
        else
            return { status: 400, error: validationMessages.permits, type: 'warning' };
    }

    async _deleteUsersClock(id_reloj) {
        try {
            const result = await ClockService.deleteUsersClock({ id_reloj: id_reloj });
            return result;
        } catch (error) {
            return { status: 400, error: error.message, };
        }
    }

    /* validacion de borrar asistencias del reloj */
    async deleteAssistanceClock(id_reloj) {
        if (+this._perfil === 1)
            return this._deleteAssistanceClock(id_reloj);
        else
            return { status: 400, error: validationMessages.permits, type: 'warning' };
    }

    async _deleteAssistanceClock(id_reloj) {
        try {
            const result = await ClockService.deleteAssistanceClock({ id_reloj: id_reloj });
            return result;
        } catch (error) {
            return { status: 400, error: error.message, };
        }
    }

    /* validacion de solicitud de asistencias */
    async requestAssistancesWorker(infoClock, dateInit, dateEnd) {
        return this._requestAssistancesWorker(infoClock, dateInit, dateEnd);
    }

    async _requestAssistancesWorker(infoClock, dateInit, dateEnd) {
        try {
            if (!infoClock || +infoClock.id_reloj === 0) {
                return { status: 400, error: validationMessages.clock, type: 'warning' };
            }
            const body = {
                fechaInicio: dateInit,
                fechaFin: dateEnd,
                id_reloj: infoClock.id_reloj,
            }
            const result = await WorkerService.requestAssistancesWorker(body);
            return result;
        } catch (error) {
            return { status: 400, error: error.message, };
        }
    }

    /* validacion de solicitud de informacion de usuario del reloj */
    async requestInfoWorkerFromClock(infoClock) {
        return this._requestInfoWorkerFromClock(infoClock);
    }

    async _requestInfoWorkerFromClock(infoClock) {
        try {
            if (!infoClock || +infoClock.id_reloj === 0) {
                return { status: 400, error: validationMessages.clock, type: 'warning' };
            }
            const body = {
                id_reloj: infoClock.id_reloj
            }
            const result = await WorkerService.requestInfoWorkerFromClock(body);
            return result;
        } catch (error) {
            return { status: 400, error: error.message, };
        }
    }

    /* validacion de guardar nuevo agrupamiento */
    async saveRelationRelojAndSedes(infoClock, sede_id) {
        return this._saveRelationRelojAndSedes(infoClock, sede_id);
    }

    async _saveRelationRelojAndSedes(infoClock, sede_id) {
        try {
            if (!infoClock || +infoClock.id_reloj === 0) {
                return { status: 400, error: validationMessages.clock, type: 'warning' };
            }
            if (!sede_id || +sede_id === 0) {
                return { status: 400, error: validationMessages.sede, type: 'warning' };
            }

            const body = {
                reloj_id: infoClock.id_reloj,
                sede_id: sede_id
            }
            const result = await ClockService.saveRelationRelojAndSedes(body);
            return result;
        } catch (error) {
            return { status: 400, error: error.message, };
        }
    }

    /* validacion de eliminacion de agrupamientos del reloj */
    async deleteGroupingFromClock(id_relojSede) {
        return this._deleteGroupingFromClock(id_relojSede);
    }

    async _deleteGroupingFromClock(id_relojSede) {
        try {
            if (!id_relojSede || +id_relojSede === 0) {
                return { status: 400, error: validationMessages.clock, type: 'warning' };
            }

            const body = {
                id_relojSede: id_relojSede,
            }
            const result = await ClockService.deleteGroupingFromClock(body);
            return result;
        } catch (error) {
            return { status: 400, error: error.message, };
        }
    }
}

export default ClockFunctions;
