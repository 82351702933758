import React, { useState } from 'react';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import SettingsIcon from '@mui/icons-material/Settings';
import { sortDataListComplete, filterData, formatDate, getValueFromPath } from '../../../utils/function';

const columns = [
  { id: '#', label: '#', width: 50, align: 'center', },
  { id: 'descripcionCmd', label: 'Comando', width: 300, align: 'center', },
  { id: 'usuario', label: 'Solicitante', width: 80, align: 'center', },
  { id: 'fechaEjecucion', label: 'Ejecucion', width: 150, align: 'center', format: (value) => formatDate(new Date(value), 'date-time', 'long'), },
  { id: 'fechaInicio', label: 'Inicio', width: 150, align: 'center', format: (value) => formatDate(new Date(value), 'date-time', 'long'), },
  { id: 'fechaFin', label: 'Fin', width: 150, align: 'center', format: (value) => formatDate(new Date(value), 'date-time', 'long'), },
  { id: 'descEstadoCmd', label: 'Respuesta', width: 300, align: 'center', },
  { id: 'numdoc', label: 'Documento', width: 80, align: 'center', },
  { id: 'trabajador', label: 'Trabajador', width: 300, align: 'center', },
  { id: 'tipoPersona', label: 'Tipo', width: 80, align: 'center', },
];

const columnsMap = {};

const BodyClocksHistory = (props) => {
  const { forCheckBody, search, data, } = props;

  const resultSearch = filterData(data, search, []);

  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const srtdData = sortDataListComplete(resultSearch, sortConfigTable);
  // /** fin ordenamiento de columnas */

  return (
    <div className="table-body-frame">
      <div className="table-body">
        <table className='table table-striped table-hover table-bordered table-sm'>
          <thead className='sticky-top' style={{ whiteSpace: 'nowrap' }}>
            <tr className="table-header table-dark">
              {columns.map((col) => (
                <th
                  key={col.id}
                  className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                  style={{ width: col.width, maxWidth: col.width, }}
                  onClick={() => col.order && handleSortTable(col.id)}
                >
                  {col.id === 'actions' ?
                    <span className="actions-icon">
                      <SettingsIcon fontSize='small' />
                    </span>
                    : col.id === 'sel' ?
                      <div className="item" style={{ marginTop: '-1rem' }}>
                        <div className="checkbox-circle">
                          <input type="checkbox" id={`checkbox-title-body${forCheckBody}`} name="check" />
                          <label htmlFor={`checkbox-title-body${forCheckBody}`}></label>
                        </div>
                      </div>
                      :
                      <>
                        {col.label}
                        {col.order && <span className="sort-icon"><OrderedListOutlined /></span>}
                      </>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {srtdData?.map((row, idxRow) => {
              return (
                <tr key={"row" + idxRow}>
                  {columns.map((col, indexColumn) => {
                    const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                    return (
                      <td
                        key={'col' + indexColumn}
                        className={`${col.align}`}
                        style={{ width: col.width, maxWidth: col.width, }}
                      >
                        {col.format ?
                          (value ? col.format(value) : '')
                          : col.id === '#' ?
                            idxRow + 1
                            : value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BodyClocksHistory;