import React from 'react';
import CustomInput from "../../general/CustomInput";
import CustomSelect from '../../general/CustomSelect';
import CustomCheck from '../../general/CustomCheck';

const InformationUpload = ({ typeSave, idEstado, checkMassive, setCheckMassive, checkFingerPrint, setCheckFingerPrint, dataTypeUserClock, typeUserClock, setTypeUserClock, passUserClock, setPassUserClock, cardUserClock, setCardUserClock, }) => {
  return (
    <div className='row'>
      {(+idEstado === 1) &&
        <>
          <div className='col-md-6'>
            <div>
              <label className="form-label title-legend">
                Perfil
              </label>
              <CustomSelect
                placeholder={'Seleccionar Tipo de usuario'}
                options={dataTypeUserClock}
                value={+typeUserClock || 0}
                onChange={(value, event) => setTypeUserClock(dataTypeUserClock?.find(element => +element.value === +value)?.value)}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <CustomInput
              title={"Contraseña"}
              placeholder={"Escribe contraseña del reloj"}
              value={passUserClock}
              name="password"
              onChange={(e) => setPassUserClock(e.target.value)}
            />
          </div>
          <div className='col-md-6'>
            <CustomInput
              title={"Tarjeta"}
              placeholder={"Escribe el codigo de tarjeta del usuario"}
              value={cardUserClock}
              name="card"
              onChange={(e) => setCardUserClock(e.target.value)}
            />
          </div>
          {(typeSave === 'UPDATE') &&
            <div className='col-md-12 mt-2'>
              <CustomCheck
                forCheck={'worker-to-somes-clock-and-fingerprints'}
                title={`Incluir las huellas en la actualización`}
                checked={checkFingerPrint}
                onChange={() => setCheckFingerPrint(!checkFingerPrint)}
                fontSize={'small'}
              />
            </div>}
        </>
      }
      <div className='col-md-12 d-flex justify-content-center mt-2'>
        <CustomCheck
          forCheck={'worker-to-somes-clock'}
          title={`${+idEstado === 1 ? 'Agregar de manera masiva a todos los relojes!' : 'Retirar de manera masiva de todos los relojes!'}`}
          checked={checkMassive}
          onChange={() => setCheckMassive(!checkMassive)}
          fontSize={'small'}
        />
      </div>
    </div>
  )
}

export default InformationUpload;