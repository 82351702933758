import React from 'react';
import { useDispatch, } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/actions';
import UserService from '../../../../services/user';
import CustomModal from "../../../components/general/CustomModal";
import CustomButton from '../../../components/general/CustomButton';
import InformationUser from '../../../components/Users/AddInfo/InformationUser';
import { CODES } from '../../../../utils/codesHTTP';
import { simpleAlerts } from '../../../../utils/alerts';
import InformationClocks from '../../../components/Users/AddInfo/InformationClocks';

const AddUser = (props) => {
    const { open, handleClose, viewNumber, setViewNumber, states, profiles, infoUser, setInfoUser, getUsers, } = props;

    const dispatch = useDispatch();
    const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
    const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

    const cleanInfo = () => {
        handleClose();
        setInfoUser(null);
        setViewNumber(null);
    }

    const saveUser = async (e) => {
        e.preventDefault();
        try {
            handleOpenSpinner(true);
            handleTitleSpinner(infoUser?.id_usuario ? "Actualizando usuario ..." : "Guardando usuario ...");
            const result = await UserService.register(infoUser);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts({ message: result?.data?.message || "Guardo!", type: "success" });
                cleanInfo();
                getUsers();
            } else {
                simpleAlerts({ message: result?.response?.data?.error || result?.response?.data?.message || "Error en la busqueda!", type: "error" });
                handleOpenSpinner(false);
                handleTitleSpinner(null);
            }
        } catch (error) {
            console.log(`Error en saveWorker: ${error}`);
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    return (
        <>
            <CustomModal
                size={viewNumber === 1 ? 'sm' : 'md'}
                open={open}
                handleClose={cleanInfo}
                formSubmit={saveUser}
                title={'Mantenimiento trabajadores'}
                subTitle={'Creación y edición de trabajadores externos'}
                bodyModal={
                    viewNumber === 1 ?
                        <InformationUser
                            states={states}
                            profiles={profiles}
                            infoUser={infoUser}
                            setInfoUser={setInfoUser}
                        />
                        : <InformationClocks
                            infoUser={infoUser}
                        />
                }
                buttonActions={[
                    viewNumber === 1 ?
                        <CustomButton
                            key='btn-modal-user-save1'
                            variant={'primary'}
                            value={'Guardar'}
                            type={"submit"}
                        />
                        : null
                ]}
            />
        </>
    )
}

export default AddUser