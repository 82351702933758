import React, { } from 'react';
import GroupingSedeUser from '../general/GroupingSedeUser';
import logoLP from '../../../assets/images/logoLP.png';

const NavBar = ({ userToken }) => {

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <button className="btn btn-sm"><img src={logoLP} width={60} /></button>
                <button className="navbar-toggler ml-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                    <div style={{ marginRight: '10px' }}></div>
                    <GroupingSedeUser userToken={userToken} />
                    <div style={{ marginRight: '10px' }}></div>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;