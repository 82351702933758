import React from 'react';
import CustomInput from "../../general/CustomInput";
import CustomInfo from '../../general/CustomInfo';

const RequestAssistances = ({ typeSave, infoWorker, dateInit, setDateInit, dateEnd, setDateEnd }) => {

  return (
    <div className='row'>
      {(typeSave !== 'ALL') &&
        < div className='col-md-12'>
          <div>
            <CustomInfo
              title={'Nombre del trabajador'}
              value={infoWorker.trabajador}
              name={'trabajador'}
            />
          </div>
        </div>
      }
      <div className='col-md-6'>
        <CustomInput
          title={"Fecha Inicio"}
          type={"date"}
          value={dateInit}
          name="dateInit"
          onChange={(e) => setDateInit(e.target.value)}
        />
      </div>
      <div className='col-md-6'>
        <CustomInput
          title={"Fecha Fin"}
          type={"date"}
          value={dateEnd}
          name="dateEnd"
          onChange={(e) => setDateEnd(e.target.value)}
        />
      </div>
    </div >
  )
}

export default RequestAssistances;