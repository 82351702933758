export const validationMessages = {
    permits: 'No tiene los permisos para la accion solicitada!',
    estado: 'El estado necesario para el proceso!',
    password: 'Debe ingresar una contraseña!',
    sede: 'Debe seleccionar una sede valida!',
    clock: 'Debe seleccionar un reloj!',
    sede_clock: 'Recuerda que debes seleccionar una sede y un reloj!',
    numdoc: 'Debe ingresar un Nro. Documento!',
    notFoundPerson: 'Persona no encontrada!',
    clockLength: 'Debe seleccionar algun reloj!',
    trabajadoresLength: 'Debe seleccionar algun trabajador!',
    id_relojSede: 'Debe seleccionar un agrupamiento ya asignado!',
    serial: 'Debe ingresar un serial valido',
    descripcionReloj: 'Debe ingresar una descripcion valida',
};