import React from 'react';
import { useDispatch, } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/actions';
import '../../../assets/styles/login.scss';
import AuthService from '../../../services/auth';
import { Global } from '../../../utils/general';
import { CODES } from '../../../utils/codesHTTP';
import { clearBrowserCache } from '../../../utils/function';

const Login = ({ setUserToken }) => {
    const info = Global.info;
    const infoTime = Global.infoTime;

    const dispatch = useDispatch();
    const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
    const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

    const handleSubmit = async (event) => {
        event.preventDefault();
        handleOpenSpinner(true);
        handleTitleSpinner('Validando ...');
        try {
            let data = new FormData(event.currentTarget);
            const result = await AuthService.signIn(data);
            if (result?.status === CODES.SUCCESS_200) {
                const expirationDate = new Date(new Date().getTime() + result?.data?.expires_in * 1000);
                localStorage.setItem(infoTime, expirationDate);
                localStorage.setItem(info, JSON.stringify(result?.data));
                setUserToken(result?.data);
                clearBrowserCache()
            }
            return [];
        } catch (error) {
            console.log(`Error en signIn: ${error}`);
        } finally {
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    };

    return (
        <div className='body-login'>
            <form className="login" onSubmit={handleSubmit}>
                <input type="text" placeholder="Username" name='usuario' />
                <input type="password" placeholder="Password" name='clave' />
                <button type='submit'>Login</button>
            </form>
        </div>
    )
}

export default Login