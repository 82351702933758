import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class GeneralService {
    getEmpresas() {
        let ruta = `${API_URL}/general/companies`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getClientesByEmpresa(empresa_id) {
        let ruta = `${API_URL}/general/client/${empresa_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getUnidadesByCliente(cliente_id) {
        let ruta = `${API_URL}/general/units/${cliente_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getSedesByUnidad(unidad_id) {
        let ruta = `${API_URL}/general/campus/${unidad_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getEstados() {
        let ruta = `${API_URL}/general/state`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getPantallas() {
        let ruta = `${API_URL}/general/screens`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getTipoUsuarioReloj() {
        let ruta = `${API_URL}/general/type-user-clock`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getDedos() {
        let ruta = `${API_URL}/general/finger`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new GeneralService();