const initialState = {
    headquarters: null,
    units: null,
    clients: null,
    companies: null,
    clocks: null,
    grouping: null,

    infoCompany: null,
    infoClient: null,
    infoUnit: null,
    infoCampus: null,
    infoClock: null,
    infoGrouping: null,
    openLoader: false,
    titleLoader: null,
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_INFO':
            return { ...state, ...action.payload };
        case 'SET_OPEN_LOADER':
            return { ...state, openLoader: action.payload };
        case 'SET_TITLE_LOADER':
            return { ...state, titleLoader: action.payload };
        default:
            return state;
    }
};

export default appReducer;
