import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { setOpenLoader, setTitleLoader } from '../../../config/actions';
import AddInfoBiometric from '../../pages/Workers/AddInfoBiometric/AddInfoBiometric';
import BodyWorkersBiometric from '../../components/Workers/BodyWorkersBiometric';
import WorkerService from '../../../services/worker';
import { CODES } from '../../../utils/codesHTTP';
import HandSVG from '../../components/general/HandDrawing/HandSVG';

const MaintenanceBiometric = (props) => {
  // Usa useSelector para obtener los estados de Redux que necesitas
  const {
    pageWorkerS, setPageWorkerS, totalPageWorkerS,
    searchWorkerS, setSearchWorkerS,
    dataWorkers, getWorkers,
    selectedIdPersona, setSelectedIdPersona,
    selectedRowPersona, setSelectedRowPersona,
    selectedFingerId, setSelectedFingerId
  } = props

  const dispatch = useDispatch();
  const infoCampus = useSelector(state => state.infoCampus);
  const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
  const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

  // Variables para el modal, saber si esta abierto o cerrado
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false);

  const [dataBiometric, setDataBiometric] = useState(null);
  const [highlightedFingers, setHighlightedFingers] = useState([]);

  useEffect(() => {
    setDataBiometric(null);
    setSelectedFingerId(null);
    setHighlightedFingers("")
    if (selectedIdPersona)
      getWorkerBiometric();
  }, [selectedIdPersona]);

  const getWorkerBiometric = async () => {
    try {
      handleOpenSpinner(true);
      handleTitleSpinner("Buscando Huellas");

      const result = await WorkerService.getWorkerBiometric(selectedIdPersona);
      if (result.status === CODES.SUCCESS_200) {
        setDataBiometric(result?.data?.data || result?.data);

      }
    } catch (error) {
      console.log(`Error en getWorkerBiometric: ${error}`);
    } finally {
      handleOpenSpinner(false);
      handleTitleSpinner(null);
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AddInfoBiometric
        handleClose={handleClose}
        open={open}
      />
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={10} sm={10} md={5}>
          <span className="badge text-dark" style={{ fontSize: '1rem', paddingBottom: '1rem' }}>Trabajadores en la Sede:  {infoCampus?.desSede}</span>
          <BodyWorkersBiometric
            data={dataWorkers}
            page={pageWorkerS}
            setPage={setPageWorkerS}
            totalPage={totalPageWorkerS}
            search={searchWorkerS}
            setSearch={setSearchWorkerS}
            getInfo={getWorkers}

            selectedIdPersona={selectedIdPersona}
            setSelectedIdPersona={setSelectedIdPersona}

            selectedRowPersona={selectedRowPersona}
            setSelectedRowPersona={setSelectedRowPersona}
          />
        </Grid>

        <Grid item xs={10} sm={10} md={5}>
          <HandSVG
            dataBiometric={dataBiometric}
            setDataBiometric={setDataBiometric}
            highlightedFingers={highlightedFingers}
            setHighlightedFingers={setHighlightedFingers}

            selectedIdPersona={selectedIdPersona}
            setSelectedIdPersona={setSelectedIdPersona}

            selectedRowPersona={selectedRowPersona}
            setSelectedRowPersona={setSelectedRowPersona}

            selectedFingerId={selectedFingerId}
            setSelectedFingerId={setSelectedFingerId}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default MaintenanceBiometric