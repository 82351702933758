export const colors =
{
    primary: {
        hex: '#12486B', //azul oscuro
        rg: 'rgb(18, 72, 107)'
    },
    secondary: {
        hex: '#F5FCCD', //besh
        rg: 'rgb(245, 252, 205)'
    },
    tertiary: {
        hex: '#419197', //entre verde y azul
        rg: 'rgb(65, 145, 151)'
    },
    quaternary: {
        hex: '#78D6C6', //verde claro
        rg: 'rgb(120, 214, 198)'
    },
}