import React, { useState, useEffect } from 'react'
import { useDispatch, } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/actions';
import ClockMaintenance from './ClockMaintenance';
import ClockOperationHistory from './ClockOperationHistory';
import { CODES } from '../../../utils/codesHTTP';
import { simpleAlerts } from '../../../utils/alerts';
import ClockFunctions from './Logic/ClockFunctions';

const Clocks = ({ userToken }) => {
    const clock = new ClockFunctions(userToken?.perfil?.id_perfil);
    const id_usuario = userToken?.user?.id_usuario;
    const dispatch = useDispatch();
    const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
    const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (tabIndex, event) => {
        event.preventDefault();
        setActiveTab(tabIndex);
    };

    const [dataClocks, setDataClocks] = useState(null);

    useEffect(() => {
        setDataClocks(null);
        getRelojes();
    }, []);

    const getRelojes = async () => {
        handleOpenSpinner(true);
        handleTitleSpinner('Cargando relojes ...');
        try {
            const result = await clock.getRelojes(id_usuario);
            if (result.status === CODES.SUCCESS_200) {
                setDataClocks(result.data);
            } else {
                simpleAlerts({ message: (result?.error || result?.response?.data?.error || 'Hubo un error!'), type: (result?.type || 'error'), })
            }
        } catch (error) {
            console.log(`Error en getRelojes: ${error}`);
        } finally {
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    return (
        <div>
            <ul className="nav nav-tabs justify-content-center flex-column flex-sm-row nav-tabs-body">
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                        href="#"
                        onClick={(e) => handleTabClick(0, e)}
                    >
                        Mantenimiento de Relojes
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                        href="#"
                        onClick={(e) => handleTabClick(1, e)}
                    >
                        Historial de Operaciones
                    </a>
                </li>
            </ul>

            <div style={{ margin: '20px 30px 0px 30px' }}>
                {activeTab === 0 &&
                    <div className='animate__animated animate__backInRight'>
                        <ClockMaintenance
                            userToken={userToken}
                            dataClocks={dataClocks}
                            getRelojes={getRelojes}
                        />
                    </div>
                }
                {activeTab === 1 &&
                    <div className='animate__animated animate__backInRight'>
                        <ClockOperationHistory
                            dataClocks={dataClocks}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default Clocks