import React from 'react';
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/actions';
import SearchIcon from '@mui/icons-material/Search';
import CustomInput from "../../general/CustomInput";
import CustomInfo from '../../general/CustomInfo';
import CustomButton from '../../general/CustomButton';
import ExternoService from "../../../../services/externo";
import { validationMessages } from '../../../../utils/messagesError';
import { CODES } from '../../../../utils/codesHTTP';
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';

const newPerson = {
  numdoc: "",
  apePaterno: "",
  apeMaterno: "",
  nombres: "",
  documento_id: "",
  direccion: "",
  email: "",
  telefono: "",
  usuario_id: null,
}

const InformationWorker = ({ infoPerson, setInfoPerson }) => {

  const dispatch = useDispatch();
  const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
  const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

  const searchPerson = async () => {
    if (!infoPerson.numdoc) {
      simpleAlerts({ message: validationMessages.numdoc, type: "error" });
      return;
    }

    try {
      let data = newPerson;
      data.numdoc = infoPerson.numdoc;

      handleOpenSpinner(true);
      handleTitleSpinner("Buscando persona");
      const result = await ExternoService.apiDNI(infoPerson.numdoc);
      if (result.status === CODES.SUCCESS_200) {
        if (result?.data?.error) {
          simpleAlerts({ message: result?.data?.error, type: "error" });
        } else if (!Object.keys(result?.data).length) {
          simpleAlerts({ message: validationMessages?.notFoundPerson, type: "warning" });
        } else {
          data = _.cloneDeep(infoPerson);
          data.apePaterno = result?.data?.apellidoPaterno;
          data.apeMaterno = result?.data?.apellidoMaterno;
          data.nombres = result?.data?.nombres;
          data.documento_id = result?.data?.tipoDocumento;
        }
      } else {
        simpleAlerts({ message: result?.response?.data?.message || "Error en la busqueda!", type: "error" });
      }
      setInfoPerson(data);
    } catch (error) {
      console.log(`Error en apiDNI: ${error}`);
    } finally {
      handleOpenSpinner(false);
      handleTitleSpinner(null);
    }
  }

  const cleanFields = (value) => {
    let data = _.cloneDeep(infoPerson);
    data.numdoc = value;
    setInfoPerson(data);
  }

  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className='d-flex justify-content-evenly align-items-end'>
          <CustomInput
            title={"Documento"}
            placeholder={"Escribe número de documento"}
            value={infoPerson?.numdoc}
            required={true}
            name="numdoc"
            onChange={(e) => cleanFields(e.target.value)}
          />
          <CustomButton
            title={'Buscar trabajador'}
            variant={'warning'}
            value={<SearchIcon />}
            onClick={searchPerson}
          />
        </div>
      </div>
      <div className='col-md-6'>
        <CustomInfo
          title={"Nombre"}
          value={infoPerson?.nombres}
          name={"nombres"}
        />
      </div>
      <div className='col-md-6'>
        <CustomInfo
          title={"Ape. Paterno"}
          value={infoPerson?.apePaterno}
          name="apePaterno"
        />
      </div>
      <div className='col-md-6'>
        <CustomInfo
          title={"Ape. Materno"}
          placeholder={"Escribe ape. materno del trabajador"}
          value={infoPerson?.apeMaterno}
          name="apeMaterno"
        />
      </div>
      <div className='col-md-6'>
        <CustomInput
          title={"telefono"}
          placeholder={"Escribe telefono del trabajador"}
          value={infoPerson?.telefono}
          required={true}
          name="telefono"
          onChange={(e) => setInfoPerson({ ...infoPerson, telefono: e.target.value })}
        />
      </div>
      <div className='col-md-6'>
        <CustomInput
          title={"Email"}
          placeholder={"Escribe email del trabajador"}
          value={infoPerson?.email}
          required={true}
          name="email"
          type={"email"}
          onChange={(e) => setInfoPerson({ ...infoPerson, email: e.target.value })}
        />
      </div>
      <div className='col-md-6'>
        <CustomInput
          title={"Dirección"}
          placeholder={"Escribe dirección del trabajador"}
          value={infoPerson?.direccion}
          required={true}
          name="direccion"
          onChange={(e) => setInfoPerson({ ...infoPerson, direccion: e.target.value })}
        />
      </div>
    </div>
  )
}

export default InformationWorker;