import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/actions';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ConstructionIcon from '@mui/icons-material/Construction';
import CustomCheck from '../general/CustomCheck';
import CustomPagination from '../general/CustomPagination';
import updateUserInfoImg from '../../../assets/images/update_user_info_reloj.png'
import assistanceHistoryImg from '../../../assets/images/assistance_history.png'
import buscarInfoUserImg from '../../../assets/images/buscar_info_user.png'
import identificionHuellaImg from '../../../assets/images/identificionHuella.png'
import WorkerService from '../../../services/worker';
import { globalIndex, sortDataList, } from '../../../utils/function';
import _ from 'lodash';
import CustomButton from '../general/CustomButton';
import Swal from 'sweetalert2';
import { CODES } from '../../../utils/codesHTTP';
import { simpleAlerts } from '../../../utils/alerts';

const columnsDefault = [
  { id: '#', label: '#', width: 30, align: 'center', },
  { id: 'numdoc', label: 'Doc.', width: 100, align: 'center', order: true, },
  { id: 'trabajador', label: 'Trabajador', width: 400, align: 'left', order: true, },
  { id: 'sel', label: 'seleccionar', width: 40, align: 'center', },
];

const BodyWorkers = (props) => {
  const { columns = columnsDefault, handleActions, forCheckBody, pageSize, page, setPage, totalPage, data, search, setSearch, getInfo, dataSel, setDataSel, } = props;

  const infoClock = useSelector(state => state.infoClock);
  const dispatch = useDispatch();
  const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
  const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const srtdData = sortDataList(data, sortConfigTable);
  // /** fin ordenamiento de columnas */

  const handleChangeGeneral = (checked) => {
    let newData;
    if (checked)
      newData = data;
    else
      newData = [];
    return setDataSel(newData);
  }

  const handleChangeCheck = (checked, info) => {
    let newData = _.cloneDeep(dataSel);
    if (checked) {
      newData.push(info);
    } else {
      newData = newData.filter((e) => e.numdoc !== info.numdoc);
    }
    return setDataSel(newData);
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && getInfo) {
      getInfo();
    }
  };

  const requestInfoWorkerFromClock = async (info) => {
    const resultSwal = await Swal.fire({
      title: `Seguro de solicitar la información del usuario (${info.trabajador})`,
      html: `<label style="font-size: 12px;">Se pedira información al reloj (${infoClock.serial})</label>`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });

    if (resultSwal.isConfirmed) {
      try {
        const body = {
          id_reloj: infoClock.id_reloj,
          numdoc: info.numdoc,
          id_persona: info.id_persona,
          tipoTrabajador: info.tipoTrabajador,
        }

        handleOpenSpinner(true);
        handleTitleSpinner('Enviando solicitud (puede tomar varios minutos) ...')
        const result = await WorkerService.requestInfoWorkerFromClock(body);

        if (result.status === CODES.SUCCESS_200) {
          simpleAlerts({ message: (result?.data?.message || 'Esperando respuesta de solicitud!'), type: (+result?.data?.status === 0 ? 'error' : 'success'), })
        } else {
          simpleAlerts({
            message: (result?.error ||
              result?.response?.data?.message ||
              result?.response?.data?.error ||
              'Hubo un error!'), type: (result?.type || 'error'),
          })
        }
        return [];
      } catch (error) {
        console.log(`Error en saveClock: ${error.message}`);
      } finally {
        handleOpenSpinner(false);
        handleTitleSpinner(null);
      }
    }
  }

  return (
    <>
      <TextField
        hiddenLabel
        id="filled-hidden-label-small"
        placeholder='Busqueda por código / trabajador'
        variant="filled"
        size="small"
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton color='primary' onClick={getInfo} style={{ padding: 2, border: 'solid 1px' }}>
              <SearchIcon fontSize='small' />
            </IconButton>
          ),
          style: { fontSize: 12, },
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyPress={handleKeyPress}
      />

      <div className="table-body-frame">
        <div className="table-body">
          <table className="table table-striped table-hover table-bordered table-sm">
            <thead className='sticky-top' style={{ whiteSpace: 'nowrap' }}>
              <tr className="table-header table-dark">
                {columns.map((col) => (
                  <th
                    key={col.id}
                    className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                    style={{ width: col.width, maxWidth: col.width, }}
                    onClick={() => col.order && handleSortTable(col.id)}
                  >
                    {col.id === 'actions' ?
                      <span className="actions-icon">
                        <ConstructionIcon style={{ fontSize: 'medium' }} />
                      </span>
                      : col.id === 'sel' ?
                        <div style={{ marginTop: '-1rem' }}>
                          <CustomCheck
                            forCheck={`checkbox-title-body${forCheckBody}`}
                            checked={(dataSel?.length === data?.length && dataSel?.length > 0) ? true : false}
                            onChange={(e) => handleChangeGeneral(e.target.checked)}
                          />
                        </div>
                        : <>
                          {col.label}
                          {col.order && <span className="sort-icon"><OrderedListOutlined /></span>}
                        </>}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {srtdData?.map((row, idxRow) => {
                return (
                  <tr key={"row" + idxRow}>
                    {columns.map((col, indexColumn) => {
                      const value = row[col.id];
                      return (
                        <td key={'col' + indexColumn}
                          style={{
                            textAlign: col.align,
                            width: col.width,
                            maxWidth: col.width,
                          }}
                        >
                          {col.id === 'actions' ?
                            <div className='d-flex justify-content-evenly gap-1'>
                              <CustomButton
                                title='Solicitar asistencias'
                                variant={'warning'}
                                styleType={'outline'}
                                value={<img src={assistanceHistoryImg} width={18} />}
                                onClick={() => handleActions(row, 'ASSISTANCE', 0)}
                              />
                              <CustomButton
                                title='Actualizar información usuario'
                                variant={'primary'}
                                styleType={'outline'}
                                value={<img src={updateUserInfoImg} width={18} />}
                                onClick={() => handleActions(row, 'UPDATE', 1)}
                              />
                              <CustomButton
                                title='Solicitar información de usuario'
                                variant={'dark'}
                                styleType={'outline'}
                                value={<img src={buscarInfoUserImg} width={18} />}
                                onClick={() => requestInfoWorkerFromClock(row)}
                              />
                              <CustomButton
                                title='Tomar o actualizar huellas'
                                variant={'success'}
                                styleType={'outline'}
                                value={<img src={identificionHuellaImg} width={18} />}
                                onClick={() => handleActions(row, 'FINGERPRINT', 1)}
                              />
                            </div>
                            : col.id === 'sel' ?
                              <CustomCheck
                                forCheck={`${row.numdoc}${forCheckBody}`}
                                checked={(dataSel?.find(e => e.numdoc === row.numdoc) ? true : false)}
                                onChange={(e) => handleChangeCheck(e.target.checked, row)}
                              />
                              : col.id === '#' ?
                                globalIndex(page, pageSize, idxRow)
                                : value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {totalPage > 1 &&
          < div className="table-footer">
            <CustomPagination
              totalPages={totalPage}
              page={page}
              setPage={setPage}
            />
          </div>
        }
      </div >
    </>
  );
}

export default BodyWorkers;