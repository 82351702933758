import React, { useState, } from 'react';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

const AddInfoBiometric = (props) => {
    const { open, handleClose} = props;
    const url = "'" + window.location.pathname + "'";

    return (
       <></>
    );
};

export default AddInfoBiometric;