import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/actions';
import { Grid, } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AssistanceService from '../../../services/assistance';
import WorkerService from '../../../services/worker';
import ReportService from '../../../services/report';
import BodyWorkersAssistance from '../../components/Workers/BodyWorkersAssistance';
import CustomButton from '../../components/general/CustomButton';
import CustomSelect from '../../components/general/CustomSelect';
import { getDateOL, mapData } from '../../../utils/function';
import { CODES } from '../../../utils/codesHTTP';
import CustomInput from '../../components/general/CustomInput';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { simpleAlerts } from '../../../utils/alerts';
import { validationMessages } from '../../../utils/messagesError';


const AssistanceHistory = (props) => {
    const infoGrouping = useSelector(state => state.infoGrouping);
    const infoClock = useSelector(state => state.infoClock);
    const dispatch = useDispatch();
    const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
    const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));

    const pageSize = 20
    const [dataAssistance, setDataAssistance] = useState(null);
    const [workersOpt, setWorkersOpt] = useState(null);
    const [selectedWorker, setSelectedWorker] = useState(null);
    const [pageAssistance, setPageAssistance] = useState(1);
    const [totalPageAssistance, setTotalPageAssistance] = useState(1);

    const [inicio, setInicio] = useState(getDateOL('', 'short', -15));
    const [fin, setFin] = useState(getDateOL());

    const [dataWorkers, setDataWorkers] = useState(null);

    useEffect(() => {
        getWorkerBySede();
    }, [infoGrouping]);

    useEffect(() => {
        setWorkersOpt(mapData(dataWorkers, ['id_persona', 'trabajador', 'numdoc', 'tipoTrabajador', 'id_persona', 'trabajador']));
    }, [dataWorkers]);

    useEffect(() => {
        if (selectedWorker && inicio && fin)
            getMarkWorkerClock();
    }, [selectedWorker, inicio, fin, pageAssistance]);

    const getMarkWorkerClock = async () => {
        try {
            handleOpenSpinner(true);
            handleTitleSpinner("Buscando assistencias ...");
            setDataAssistance(null);
            const result = await AssistanceService.getMarkWorkerClock(selectedWorker?.id_persona, selectedWorker?.tipoTrabajador, selectedWorker?.numdoc, infoClock?.id_reloj, inicio, fin, { page: pageAssistance, pageSize: pageSize });
            if (result.status === CODES.SUCCESS_200) {
                setDataAssistance(result?.data?.data || result?.data);
                setTotalPageAssistance(result?.data?.last_page || 0);
            }
        } catch (error) {
            console.log(`Error en getMarkWorkerClock: ${error}`);
        } finally {
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    const exportAssistanceByClock = async () => {
        if (!infoClock || !infoClock?.value) {
            simpleAlerts({ message: validationMessages.clock, type: "warning" });
            return;
        }
        try {
            const body = {
                desReloj: (infoClock?.label || infoClock?.serial),
                id_reloj: infoClock?.id_reloj,
                fecIni: inicio,
                fecFin: fin,
            }
            handleOpenSpinner(true);
            handleTitleSpinner("Generando reporte de asistencias ...");

            const result = await ReportService.exportAssistanceByClock(body);
            if (result.status === CODES.SUCCESS_200) {
                const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${(infoClock?.label || infoClock?.serial)}_ASISTENCIAS.xlsx`;
                link.click();
                URL.revokeObjectURL(url);
                simpleAlerts({ message: 'Reporte generado exitosamente', type: 'success' })
            } else {
                simpleAlerts({ message: 'Error al generar el reporte de asistencias', type: 'error' })
            }
            return []
        } catch (error) {
            console.log(`Error en generateSede: ${error}`);
        } finally {
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    const getWorkerBySede = async () => {
        try {
            handleOpenSpinner(true);
            handleTitleSpinner("Buscando trabajadores");
            setDataWorkers(null);

            const lstSedes = infoGrouping?.reduce((acumulador, e) => {
                acumulador.push(+e.id_sede);
                return acumulador;
            }, [])
            const result = await WorkerService.getWorkerBySede(lstSedes, { search: '', pagination: 0 });
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result?.data?.data || result?.data);
            }
        } catch (error) {
            console.log(`Error en getWorkerBySede: ${error}`);
        } finally {
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={8} sm={8} md={7}>
                <div className='d-flex justify-content-between align-items-end'>
                    <div style={{ width: '63%' }}>
                        <label className="form-label title-legend">
                            Lista trabajadores
                        </label>
                        <CustomSelect
                            placeholder={'Seleccionar Trabajador'}
                            options={workersOpt}
                            value={selectedWorker?.value || 0}
                            onChange={(value, event) => setSelectedWorker(workersOpt?.find(element => +element.value === +value))}
                        />
                    </div>
                    <CustomInput
                        title={"Inicio"}
                        type={"date"}
                        value={inicio}
                        onChange={(e) => setInicio(e.target.value)}
                    />
                    <CustomInput
                        title={"fin"}
                        type={"date"}
                        value={fin}
                        onChange={(e) => setFin(e.target.value)}
                    />
                    <CustomButton
                        title={"Buscar asistencias"}
                        value={<SearchIcon fontSize='small' />}
                        onClick={getMarkWorkerClock}
                    />
                    <CustomButton
                        className="btn btn-success"
                        title={"Reporte por sede"}
                        value={<SimCardDownloadIcon fontSize='small' />}
                        onClick={exportAssistanceByClock}
                    />
                </div>
            </Grid>
            <Grid item xs={8} sm={8} md={7}>
                <BodyWorkersAssistance
                    data={dataAssistance}
                    pageSize={pageSize}
                    page={pageAssistance}
                    setPage={setPageAssistance}
                    totalPage={totalPageAssistance}
                />
            </Grid>
        </Grid>
    )
}

export default AssistanceHistory;