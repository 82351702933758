import axios from "axios";
import { Global } from '../utils/general';

const API_URL = Global.url;

class AuthService {
    signIn(body) {
        const ruta = `${API_URL}/login`;
        return axios
            .post(ruta, body)
            .catch((error) => {
                return error.response;
            });
    }

    signOut(body) {
        const ruta = `${API_URL}/logout`;
        return axios
            .post(ruta, body)
            .catch((error) => {
                return error.response;
            });
    }
}

export default new AuthService();