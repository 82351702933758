import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/actions';
import { Grid, } from '@mui/material';
import WorkerService from '../../../services/worker';
import BodyMaintenanceWorkers from '../../components/Workers/BodyMaintenanceWorkers';
import CustomButton from '../../components/general/CustomButton';
import AddWorker from './AddInfo/AddWorker';
import { simpleAlerts } from '../../../utils/alerts';
import { validationMessages } from '../../../utils/messagesError';
import { CODES } from '../../../utils/codesHTTP';

const newPerson = {
    numdoc: "",
    apePaterno: "",
    apeMaterno: "",
    nombres: "",
    documento_id: "",
    direccion: "",
    email: "",
    telefono: "",
    usuario_id: null,
}

const MaintenanceWorkers = (props) => {
    const { pageSize, pageWorkerS, setPageWorkerS, totalPageWorkerS, searchWorkerS, setSearchWorkerS, dataWorkers, getWorkers, allWorkers, setAllWorkers } = props
    
    const infoGrouping = useSelector(state => state.infoGrouping);
    const valueSedes = infoGrouping?.map(e => +e.value);

    const dispatch = useDispatch();
    const handleOpenSpinner = (value) => dispatch(setOpenLoader(value));
    const handleTitleSpinner = (value) => dispatch(setTitleLoader(value));
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [infoPerson, setInfoPerson] = useState(null);
    const [dataSelect, setDataSelect] = useState([]);

    useEffect(() => {
        setDataSelect([]);
    }, [dataWorkers]);

    const editInformation = (info) => {
        setInfoPerson(info);
        handleOpen();
    }

    const saveWorkerSedeMassive = async () => {
        if (!valueSedes || valueSedes.length <= 0) {
            simpleAlerts({ message: validationMessages.sede, type: "warning" });
            return;
        }
        if (dataSelect?.length === 0) {
            simpleAlerts({ message: validationMessages.trabajadoresLength, type: "warning" });
            return;
        }
        try {
            handleOpenSpinner(true);
            handleTitleSpinner("Asignando trabajadores ...");
            const body = {
                sedes: valueSedes,
                id_estado: 1,
                trabajadores: dataSelect,
            }
            const result = await WorkerService.saveWorkerSedeMassive(body);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts({ message: result?.data?.message || "Guardo!", type: "success" });
                getWorkers();
            } else {
                simpleAlerts({ message: result?.response?.data?.error || result?.response?.data?.message || "Error en la asignación!", type: "error" });
            }
        } catch (error) {
            console.log(`Error en saveWorker: ${error}`);
        } finally {
            setDataSelect([]);
            handleOpenSpinner(false);
            handleTitleSpinner(null);
        }
    }

    return (
        <>
            <AddWorker
                open={open}
                handleClose={handleClose}
                infoPerson={infoPerson}
                setInfoPerson={setInfoPerson}
                getWorkers={getWorkers}
            />
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={10} sm={10} md={9}>
                    <span className="badge text-dark" style={{ fontSize: '1rem' }}>Trabajadores:</span>
                    <BodyMaintenanceWorkers
                        pageSize={pageSize}
                        page={pageWorkerS}
                        setPage={setPageWorkerS}
                        totalPage={totalPageWorkerS}
                        data={dataWorkers}
                        search={searchWorkerS}
                        setSearch={setSearchWorkerS}
                        editInfo={editInformation}
                        getInfo={getWorkers}
                        dataSel={dataSelect}
                        setDataSel={setDataSelect}
                        allWorkers={allWorkers}
                        setAllWorkers={setAllWorkers}
                    />
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                    <CustomButton
                        size={"md"}
                        title={"Crear Nuevo Trabajador"}
                        value={"Nuevo"}
                        onClick={() => editInformation(newPerson)}
                    />
                    &nbsp;&nbsp;
                    <CustomButton
                        size={"md"}
                        title={"Asignar trabajadores a la sede"}
                        value={"Trabajador ➡️ Sede"}
                        variant={"warning"}
                        onClick={saveWorkerSedeMassive}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default MaintenanceWorkers;