import React, { useState } from 'react';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomCheck from '../general/CustomCheck';
import CustomPagination from '../general/CustomPagination';
import CustomButton from '../general/CustomButton';
import { globalIndex, sortDataList, } from '../../../utils/function';
import _ from 'lodash';

const columns = [
  { id: '#', label: '#', width: 30, align: 'center', },
  { id: 'grouping', label: 'sede', width: 200, align: 'center', order: true, },
  { id: 'numdoc', label: 'Doc.', width: 100, align: 'center', order: true, },
  { id: 'trabajador', label: 'Trabajador', width: 200, align: 'left', order: true, },
  { id: 'actions', label: 'Ver', width: 25, align: 'center' },
  { id: 'sel', label: 'seleccionar', width: 25, align: 'center' },
];

const BodyMaintenanceWorkers = (props) => {
  const { forCheckBody, pageSize, page, setPage, totalPage, data, search, setSearch, editInfo, getInfo, dataSel, setDataSel, allWorkers, setAllWorkers } = props;

  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const srtdData = sortDataList(data, sortConfigTable);
  // /** fin ordenamiento de columnas */

  const handleChangeGeneral = (checked) => {
    let newData;
    if (checked)
      newData = data;
    else
      newData = [];
    return setDataSel(newData);
  }

  const handleChangeCheck = (checked, info) => {
    setDataSel(prevData => {
      if (checked) {
        // Verificar si el objeto ya existe en el array
        const exists = prevData.some(e => e.numdoc === info.numdoc);

        if (!exists) {
          // Si no existe, hacer push del nuevo objeto
          return [...prevData, info];
        }
      } else {
        // Filtrar el objeto basado en el numdoc
        const newData = prevData.filter(e => e.numdoc !== info.numdoc);
        return newData;
      }

      // Si el objeto ya existe y el checkbox está marcado, retornar el estado actual sin cambios
      return prevData;
    });
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && getInfo) {
      getInfo();
    }
  };

  return (
    <>
      <div className='d-flex justify-content-between align-items-end'>
        <TextField
          hiddenLabel
          id="filled-hidden-label-small"
          placeholder='Busqueda por código / trabajador'
          variant="filled"
          size="small"
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton color='primary' onClick={getInfo} style={{ padding: 2, border: 'solid 1px' }}>
                <SearchIcon fontSize='small' />
              </IconButton>
            ),
            style: { fontSize: 12, },
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={handleKeyPress}
          className='textField-search'
          inputProps={{ style: { fontSize: 12, color: 'black', fontWeight: 'bold', }, }}
        />
        &nbsp;
        <div className='me-3'>
          <input type="checkbox" className="btn-check" id="btn-check-outlined"
            checked={allWorkers}
            onChange={() => setAllWorkers(!allWorkers)}
          />
          <label className="btn btn-outline-primary btn-sm" htmlFor="btn-check-outlined">Todos</label>
        </div>
      </div>

      <div className="table-body-frame">
        <div className="table-body">
          <table className="table table-striped table-hover table-bordered table-sm">
            <thead className='sticky-top' style={{ whiteSpace: 'nowrap' }}>
              <tr className="table-header table-dark">
                {columns.map((col) => (
                  <th
                    key={col.id}
                    className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                    style={{ width: col.width, maxWidth: col.width, }}
                    onClick={() => col.order && handleSortTable(col.id)}
                  >
                    {col.id === 'sel' ?
                      <div style={{ marginTop: '-1rem' }}>
                        <CustomCheck
                          forCheck={`checkbox-title-body${forCheckBody}`}
                          checked={(dataSel?.length === data?.length && dataSel?.length > 0) ? true : false}
                          onChange={(e) => handleChangeGeneral(e.target.checked)}
                        />
                      </div>
                      : <>
                        {col.label}
                        {col.order && <span className="sort-icon"><OrderedListOutlined /></span>}
                      </>}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {srtdData?.map((row, idxRow) => {
                return (
                  <tr key={"row" + idxRow}>
                    {columns.map((col, indexColumn) => {
                      const value = row[col.id];
                      return (
                        <td
                          key={'col' + indexColumn}
                          className={`${col.align}`}
                          style={{ width: col.width, maxWidth: col.width, }}
                        >
                          {col.id === 'sel' ?
                            <CustomCheck
                              forCheck={`${row.numdoc}${forCheckBody}`}
                              checked={(dataSel?.find(e => e.numdoc === row.numdoc) ? true : false)}
                              onChange={(e) => handleChangeCheck(e.target.checked, row)}
                            />
                            : col.id === 'actions' ?
                              <div className='d-flex justify-content-between'>
                                <CustomButton
                                  variant={'primary'}
                                  title='Detalle trabajador'
                                  value={<VisibilityIcon style={{ fontSize: '13px' }} />}
                                  onClick={() => editInfo(row)}
                                />
                              </div>
                              : col.id === '#' ?
                                globalIndex(page, pageSize, idxRow)
                                : col.id === 'grouping' ?
                                `${row.desEmpresa?.substring(0,30)} | ${row.desCliente?.substring(0,30)} | ${row.desUnidad?.substring(0,20)} | ${row.desSede?.substring(0,10)}`
                                : value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {totalPage > 1 &&
          < div className="table-footer">
            <CustomPagination
              totalPages={totalPage}
              page={page}
              setPage={setPage}
            />
          </div>
        }
      </div >
    </>
  );
}

export default BodyMaintenanceWorkers;